import React from "react";
import * as dc from "dc";
import { scaleLinear } from "d3";
import * as d3 from "d3";
import { ChartTemplate } from "./chartTemplate";

var title = "Data";


var numFormat = d3.format(".2f");
const tableChartFunc = (divRef, ndx, config, layout) => {

  var groupKey = "gender";
  if (config) {
    groupKey = config.groupKey;
    title = config.title;
  }

  const chartDimension = ndx.dimension((d) => { return d[groupKey] ? d[groupKey] : "N.A."; });

  //create a table container node and append to parent div as dc.datatable only creates thead and tbody elements
  var tResponsive = document.createElement("div");
  tResponsive.className = "table-responsive";
  var tContainer = document.createElement("table");
  tContainer.className = "table table-sm table-bordered table-hover";

  divRef.appendChild(tResponsive);
  tResponsive.appendChild(tContainer);

  //use table container as target to render datatable 
  var chart = dc.dataTable(tContainer);

  const pElement = divRef.parentElement.parentElement;

  var width = pElement.clientWidth,
    height = pElement.clientHeight;

  //prepare columns to represent keys, label will be used as colHeader
  var columns = config.columns ? [...config.columns] : [];
  //skipping old pattern of saving columns which are string type
  columns = columns.filter(x=> typeof x !=="string");
  

  if (columns.length == 0) {
    const colKeys = Object.keys(chartDimension.top(1)[0]);
    (colKeys).forEach(colKey => {
      //let colKey= colDisplayName
      columns.push(
        {
          label: colKey,
          format: function (d) {
            return d.hasOwnProperty(`${colKey}_openurl`) ?
              "<a target='_blank' href='" + d[`${colKey}_openurl`] + "'>" + d[colKey] + "</a>"
              :
              d[colKey];
          }
        }
      );
    });

  }

  //TODO check this block with Sridhar
  //need to refactor the link
  columns.map((col, index) => {
    let colkey;
    let colDisplayName;
    //modified col from string to object.. the check is needed to support previosuly created items as string
    if (typeof col == "string") {
      colkey = col;
      colDisplayName = col;
    }
    else {
      colkey = col.colkey || col.label
      colDisplayName = col.colDisplayName || col.label
    }
    // if (typeof colkey == "string") {//added this check as sometime we may get object if it has already be converted as link
    columns[index] = {
      colkey: col.colkey,
      colDisplayName: col.colDisplayName,
      label: colDisplayName,
      format: function (d) {
        return d.hasOwnProperty(`${colkey}_openurl`) ?
          "<a target='_blank' href='" + d[`${colkey}_openurl`] + "'>" + d[colkey] + "</a>"
          :
          d[colkey];
      }
    }
    //}
  })

  //replace link href with actual target link
  var linkColName = "Name";
  var colIdx = columns.findIndex(item => (typeof item === "string" && linkColName.toLowerCase() === item.toLowerCase()));
  if (colIdx >= 0) {
    columns[colIdx] = {
      label: linkColName,
      format: function (d) { return "<a target='_blank' href='" + d[`name_openurl`] + "'>" + d["name"] + "</a>" }
    }
  }

  chart
    .width(width)
    .height(height)
    .dimension(chartDimension)
    .columns(columns);

  chart.render();
  return chart;
};
export const TableChart = (props) => (
  <ChartTemplate chartFunction={tableChartFunc} title={props.config.title} config={props.config} layout={props.layout} />
);

export default TableChart;
