import React from "react";

import "./Layout.css";

class Layout extends React.Component {
  render() {
    //const { Footer, Content } = AntLayout;

    return <div className="App container">{this.props.children}</div>;
  }
}

export default Layout;
