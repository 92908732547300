import React from "react";

import DBService from "../../../Services/dexieForm";
import Service from "../../../Services/api";

import { withStyles } from "@material-ui/core/styles";
//import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";

import Paper from "@material-ui/core/Paper";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import Builder from "../../RenderBuilder/index.js";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    //padding:10
  },

  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    flexGrow:1
  },
  root: {
    // padding:10
  },
});

class BuilderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      meth: "",
      id: -1,
      selectedFile: null,
      components: [],
      display: "wizard",
      dbSchema:""
    }; //default

    this.handleChange = this.handleChange.bind(this);
    this.handleOnFileChange = this.handleOnFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnUISchemaChange = this.handleOnUISchemaChange.bind(this);
    this.handleOnUIDisplayChange=this.handleOnUIDisplayChange.bind(this);
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    console.log("id", params.id);
    //DBService.initialize();
    // if(Number(params.id)>0){

    // }
    // else{
    //   return
    // }
    //const item = await DBService.getFramework(params.id);
    // if(params.id > 0)
    // {
      const item = await Service.getFrameworkDetails(params.id);
      if (item) 
      {
        this.setState({
          meth: item.meth,
          name: item.name,
          id:Number(params.id),
        });

        const shcemaJson= JSON.parse(item.meth); //this.tryParseJSON(item.meth);   
    
        if(shcemaJson){
          this.setState({ components: shcemaJson.components,display: shcemaJson.display });
        }
      }
    //}
  }

  async getJSONFromFile(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onloadend = (e) => {
        const content = reader.result;
        resolve(content);
      };
      reader.onerror = (e) => {
        //const content=reader.result;
        reject(e);
      };
      reader.readAsText(file);
    });
  }

  handleChange(event) {
    let schemaText=event.target.value;
    const shcemaJson=this.tryParseJSON(schemaText);
    
    this.setState({
      [event.target.name]: event.target.value,
    });
    if(shcemaJson){
      this.setState({ components: shcemaJson.components,display: shcemaJson.display });
    }
  }

  tryParseJSON (jsonString){
    try {
        var o = JSON.parse(jsonString);
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }

    return false;
}

  handleOnUISchemaChange(builder) {
    const {schema,dbSchema}= builder
    this.setState({ components: schema.components,display: schema.display, meth:JSON.stringify(schema), dbSchema:dbSchema });
  }

  handleOnUIDisplayChange(display) {
    this.setState({ display: display });
  }

  async handleOnFileChange(event) {
    let content = await this.getJSONFromFile(event.target.files[0]);
    this.setState({
      selectedFile: event.target.files[0],
      meth: content,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    console.log("A form was submitted: " + JSON.stringify(this.state));
    DBService.initialize();

    const id = this.state.id;

    if (id < 1) {
      //add
      // const newId = await DBService.addFramework({
      //   name: this.state.name,
      //   meth: this.state.meth,
      //   fullSchema:this.state.dbSchema
      // });

      // console.log("newId", newId);

      // var result = await Service.Graphql(`mutation{
      //   createFramework(name:"${this.state.name}",inputJson:"${this.state.meth}",userCreated:2)
      // }`);
      var result = await Service.addFramework(this.state.name, this.state.meth,this.state.dbSchema);
      console.log(result);
      if(result)
      //this.props.history.push(`/build/${newId}`);
      this.props.history.push(`/frameworkList`);
    } else {
      //name is the unique identifier and update works based on name
      const updated = Service.addFramework(this.state.name, this.state.meth,this.state.dbSchema);
      console.log("updated", updated);
    }
  }

  render() {
    const { classes } = this.props;
    return (
    <div>
      {/* <CssBaseline />         */}
      <div className="pageHeaderPanel">
        <div className="page-header1">
          <h5> Framework Builder</h5>
          <div className="btn-toolbar justify-content-end ">
              <button  className="btn  btn-icon btn-outline-secondary btnAction"
                to={`/navbar`} Tooltip="Back">
                <i className="fas fa-arrow-left"></i>
              </button>
              
            </div>

        </div>
      </div>

      <div className="tablePanel-body  paneltopTable">
       <div className="col-md-12">
        <form
          className={classes.form}
          // noValidate
          onSubmit={this.handleSubmit}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="name"
            name="name"
            autoComplete="name"
            autoFocus
            value={this.state.name}
            onChange={this.handleChange}
            disabled={this.state.id>-1}
          />
          <label htmlFor="upload-file">
            <input
              style={{ display: "none" }}
              id="upload-file"
              name="upload-file"
              type="file"
              accept=".json"
              onChange={this.handleOnFileChange}
            />
            <Button color="primary" variant="contained" component="span">
              Select File
            </Button>
          </label>
          {this.state.selectedFile && (
            <span>File:{this.state.selectedFile.name}</span>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="meth"
            label="meth"
            name="meth"
            autoComplete="meth"
            autoFocus
            value={this.state.meth}
            onChange={this.handleChange}
            rows="10"
            multiline
          />
          <Builder
            Components={this.state.components}
            Display={this.state.display}
            OnChange={this.handleOnUISchemaChange}
            OnDisplayChange={this.handleOnUIDisplayChange}
          ></Builder>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Save
          </Button>
        </form>
        </div>
      </div>
    </div>
      
    );
  }
}

export default withStyles(styles)(BuilderForm);
