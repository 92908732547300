import React from "react";
import RenderForm from "./../../RenderForm";
import { withStyles } from "@material-ui/core/styles";
import Service from "../../../Services";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import * as FormUtil from "../../../Utils/formData";
import { ENTITY_TYPES } from "../../../config/constant";
import { Tabs, Tab } from 'react-bootstrap'
import LookUpConfig from "../../../views/LookUpConfig";

const styles = (theme) => ({
  actionButton: {
    padding: "3px",
  },
  actionsContainer: {
    //float:'right'
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"

  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
});

class InstanceForm extends React.Component {
  constructor(props) {
    super(props);
    console.log("props-", this.props);

    const {
      match: { params },
    } = this.props;

    this.submissionDataTemp = {};
    this.state = {
      entityName: "",
      components: [],
      display: "form",
      submission: this.submissionDataTemp,
      id: Number(params.entityId),
      entityTypeId: Number(params.entityTypeId),
      schemaText: "",
      dataText: "",
      renderversion: 1,
      activeTabId: 1,
      tabs: [
        {
          id: 1,
          name: 'General'
        },
        {
          id: 2,
          name: 'Look Up Management'
        },
      ],
      lookupData: {}
    }; //default

    this.handleChange = this.handleChange.bind(this);
    this.handleTab = this.handleTab.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLookUpData = this.handleLookUpData.bind(this);
    this.LookUpCallBackFn = null;
  }

  async componentDidMount() {
    //const item = await Service().getAdminEntityInstance(this.state.id);
    await this.bindDetails()
  }

  async bindDetails() {
    const item = await Service().getEntitiesDetailByEntityTypeId(this.state.entityTypeId, this.state.id);
    console.log("item-", item);
    if (item) {

      this.submissionDataTemp = {
        data: item.dataJSON ? item.dataJSON.data : {},
      };

      const meth = item.formJSON;
      if (meth) {
        this.setState({
          components: meth.components,
          display: meth.display,
          entityName: item.entityName,
          submission: this.submissionDataTemp
        });
      }

    } else {
      //not a valid methFormId
      console.log("not a valid ID");
      this.props.history.replace(`/navbar`);
      return;
    }
  }
  handleTab(tabId) {

    const activeTabData = this.state.tabs.find(x => x.id == tabId);

    if (activeTabData) {
      this.setState({
        activeTabId: tabId
      })
    }


  }

  handleLookUpData(callBackFn) {
    this.LookUpCallBackFn = callBackFn;
  }
  handleChange(submission) {
    if (submission.changed) {
      console.log(submission);
      //--------------------------------------->//
      //todo this is workaround as setstate is causing infinite rerender for submission
      //. submission has to move to state later
      this.submissionDataTemp = submission;
      //const submissionData = submission.data;
      //this.setState({ submission: submission });
      //this.setState({ schemaText: JSON.stringify() });
      console.log(FormUtil.dataToSave(this.state.components, submission.data))
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    ///need to check for entitytype as userType
    // if (event.target && event.target.checkValidity && !event.target.checkValidity(null, true, null, true)) {
    //   return false;
    // }
    // else if(this.submissionDataTemp && this.submissionDataTemp.isValid && this.submissionDataTemp.isValid==false) {
    //   alert("Please fill all the mandatory information");
    //   return false;
    // }
    ///need to check for entitytype as userType
    let additionalData = {

    }
    if (typeof this.LookUpCallBackFn === "function") {
      additionalData.lookupData = this.LookUpCallBackFn()
    }
    let params = {
      dataJSON: JSON.stringify(this.submissionDataTemp.data),
      id: this.state.id,
      entityTypeId: this.state.entityTypeId
    };

    if (additionalData) {
      params.additionalData = JSON.stringify(additionalData)
    }

    //let resultId = await Service().saveUserDetail(params);
    let resultId = await Service().saveEntityDetail(params);
    if (resultId > -1) {
      if (this.state.id < 0) {//reload
        this.props.history.push(`/adminInstance/${this.state.entityTypeId}/${resultId}`)
      }
      else
        this.bindDetails()
    }

  }

  render() {
    const { classes } = this.props;
    const showSchemaData = false;
    const EntityInfo = ENTITY_TYPES[this.state.entityTypeId] || {};
    return (
<form onSubmit={this.handleSubmit}>
        <div className="pageHeaderPanel">
          <div className="page-header1">

            <h5 > {/* {this.state.entityName} */}{EntityInfo.name} Detail </h5>


            <div className="btn-toolbar justify-content-end ">
              <Link className="btn  btn-icon btn-outline-secondary btnAction"
                to={`/adminInstanceList/${this.state.entityTypeId}`} Tooltip="Back">
                <i className="fas fa-arrow-left"></i>
              </Link>&nbsp;
              <Button className="btn  btn-icon btn-outline-primary btnAction" type="submit" title="Save">
                <i className="fas fa-save"></i>
              </Button>
              {/* <Button  className="btn  btn-icon btn-outline-primary btnAction" type="submit"  title="Save and Close" onClick={this.actionButton}>
                <i className="far fa-save"></i>
              </Button>
              <Button  className="btn  btn-icon btn-outline-primary btnAction" type="submit"  title="Save and New" onClick={this.actionButton}>
                <i class="fas fa-file-export"></i>
              </Button>
              <Button  className="btn  btn-icon btn-outline-primary btnAction" type="submit"  title="Close" onClick={this.actionButton}>
                <i class="fas fa-times-circle"></i>
              </Button>
              <Button  className="btn  btn-icon btn-outline-primary btnAction" type="submit"  title="Delete" onClick={this.actionButton}>
                <i className="fas fa-trash"></i>
              </Button>
              <Button  className="btn  btn-icon btn-outline-primary btnAction" type="submit"   title="Copy" onClick={this.actionButton}>
                <i className="fas fa-copy"></i>
              </Button>          */}
              <Button type="button" className="btn  btn-icon btn-outline-primary btnAction"
                onClick={() =>
                  this.props.history.push(
                    //`/entityTypeList/${x.entityTypeId}/${-1}/${x.entityTypeId}/${x.entityId}`,
                    `/adminInstanceList/${this.state.entityTypeId}`
                  )}
                color="primary"
                title="Close">
                <i className="fa fa-times"></i>
              </Button>
            </div>
          </div>
        </div>
                    <div className="tablePanel-body noPanelBody">
              {
                this.state.entityTypeId == 10 &&
                <Tabs
                  id="controlled-tab-example"
                  activeKey={this.state.activeTabId}
                  onSelect={(k) => this.handleTab(k)}
                  className="mb-3"
                >
                  {this.state.tabs.map(x => {
                    return <Tab eventKey={x.id} title={x.name}>
                      {/* <p>{x.name}</p> */}
                    </Tab>


                  })
                  }
                </Tabs>
              }

              {
                this.state.activeTabId == 1 &&

                <div>
                  <RenderForm
                    Components={this.state.components}
                    Display={this.state.display}
                    Submission={this.state.submission}
                    OnChange={this.handleChange}
                    onSubmit={this.handleSubmit}
                  ></RenderForm>
                  {showSchemaData && <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    //required
                    //fullWidth
                    id="schema"
                    label="schema"
                    name="schema"
                    value={this.state.schemaText}
                    rows="10"
                    multiline
                  />}
                  {showSchemaData && <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    //required
                    //fullWidth
                    id="data"
                    label="Data"
                    name="data"
                    value={this.state.dataText}
                    rows="10"
                    multiline
                  />
                  }
                </div>
              }
              {
                this.state.activeTabId == 2 &&
                <LookUpConfig frameworkId={this.state.id}
                  entityId={this.state.id}
                  entityTypeId={this.state.entityTypeId}
                  onChange={this.handleLookUpData}
                  history={this.props.history}
                ></LookUpConfig>
              }
        </div>
      </form>
    );
  }
}

export default withStyles(styles)(InstanceForm);
