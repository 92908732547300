import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CardCounts from "../../components/Common/CardCounts";
import ColumnPicker from "../../components/Common/ColumnPicker";
import Filters from "../../components/Common/Filters";
import SaveView from "../../components/Common/SaveView";
import Service from "../../Services";

import Typography from "@material-ui/core/Typography";
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Fab from '@material-ui/core/Fab';
import IconButton from "@material-ui/core/IconButton";
// import { filters } from "dc";

import Loading from "../../components/Common/Loading"
import Modal from '../../components/Common/Modal'

const styles = (theme) => ({
  table: {
    minWidth: 650,
  },
  cell: {
    fontSize: 12,
  },
  alignItems: {
    display: "flex",
    //width: 600,
    //"justify-content": "space-between",
    "padding-bottom": 20,
    flexDirection: "row",
    //flexWrap: "nowrap"
    justifyContent: "space-between",
  },
  formDiv: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
});

const ViewTypes = {
  DefaultForUser: 3,
  DefaultForAll: 4
}
class EntityTypeList extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = this.props;

    this.state = {
      loading: true,
      entities: [],
      columnsToDisplay: [],
      id: Number(params.entityId) || -1,
      entityTypeId: Number(params.entityTypeId) || -1,
      pid: Number(params.pentityId) || -1,
      pentityTypeId: Number(params.pentityTypeId) || -1,
      viewId: Number(params.viewId) || -1,
      frameworkId: -1,
      viewName: "",
      pageNumber: 1,
      rowsPerPage: 25,
      sortByColumnId: "",
      sortOrder: 'asc',
      totalRecordsCount: 0,
      filtersData: {},
      Permissions: {
        showSetDefaultForAll: false,
        showNew: false,
        showDelete: false
      },
      showConfirmDialog: false,
      confirmHandler: () => { },
    };
    this.handleClickNew = this.handleClickNew.bind(this);
    this.handleColumnListChange = this.handleColumnListChange.bind(this);
    this.handlefilterHandler = this.handlefilterHandler.bind(this)
    this.handleFilterReset = this.handleFilterReset.bind(this);
    this.handleSaveMyView = this.handleSaveMyView.bind(this);
    this.handleSaveViewForAll = this.handleSaveViewForAll.bind(this);
    this.handleResetMyView = this.handleResetMyView.bind(this);
    this.handleConfirmDialog = this.handleConfirmDialog.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  async componentDidMount() {
    await this.getData();
    this.getFilterData();
  }

  handleClickNew(item) {
    const entityTypeId = this.state.entityTypeId === -1 ? 2 : this.state.entityTypeId
    const pentityTypeId = this.state.pentityTypeId === -1 ? 2 : this.state.pentityTypeId
    this.props.history.push(`/entity/${entityTypeId}/-1/${pentityTypeId}/${this.state.pid}`);
  }

  handleSort(col, order) {
    console.log('Sort triggered')
    this.setState({
      sortByColumnId: col,
      sortOrder: order
    }, () => {
      this.getData();
    })

  }

  handleColumnListChange(coldata) {
    this.setState({
      columnsToDisplay: [...coldata]
    })
  }
  handleConfirmDialog(openState) {
    if (!openState) {
      this.setState({
        showConfirmDialog: openState,
        confirmHandler: () => { }
      })
    }

  }

  handlefilterHandler(filterdata) {
    this.setState({
      filtersData: { ...filterdata }
    }, () => {
      this.getData()
    })

  }

  handleFilterReset() {

    this.getFilterData();
  }

  async handleSaveMyView(e) {
    e.preventDefault();
    this.setState({ loading: true })
    await this.saveViewForDefault(ViewTypes.DefaultForUser)
    this.setState({ loading: false })
    this.getData();

  }

  async handleSaveViewForAll(e) {
    await this.saveViewForDefault(ViewTypes.DefaultForAll)
    this.getData();

  }

  async saveViewForDefault(viewType) {
    const params = {
      viewName: '',
      EntityTypeId: this.state.entityTypeId,
      EntityId: this.state.id,
      ParentEntityTypeId: this.state.pentityTypeId,
      ParentEntityId: this.state.pid,
      viewId: -1,
      viewType: viewType,
      filtersData: {
      },
      columns: this.state.columnsToDisplay,
    }
    const result = await Service().setUserDefaultViewForRegister(
      params
    );

  }

  async handleResetMyView(e) {
    e.preventDefault()
    const result = await Service().resetUserDefaultViewForRegister(
      {
        entityTypeId: this.state.entityTypeId,
        entityId: this.state.id,
        pentityTypeId: this.state.pentityTypeId,
        pentityId: this.state.pid,
      }
    );
    this.getData();
  }

  async componentWillReceiveProps(newProps) {
    const {
      match: { params },
    } = newProps;

    if (Number(params.entityId) !== this.state.id ||
      Number(params.entityTypeId) !== this.state.entityTypeId ||
      Number(params.pentityId) !== this.state.pid ||
      Number(params.pentityTypeId) !== this.state.pentityTypeId
    ) {
      this.setState(
        {
          id: Number(params.entityId) || -1,
          entityTypeId: Number(params.entityTypeId) || -1,
          pid: Number(params.pentityId) || -1,
          pentityTypeId: Number(params.pentityTypeId) || -1,
          viewId: Number(params.viewId) || -1,
          filtersData: {},
          columnsToDisplay: [],
          pageNumber: 1
        }, () => {
          this.getData()
          this.getFilterData();
        }

      );
    }
  }

  async getData() {

    this.setState({ loading: true })
    const fulldata = await Service().getListByEntityIdAndType(
      this.state.entityTypeId,
      this.state.id,
      this.state.pentityTypeId,
      this.state.pid,
      this.state.viewId,
      this.state.filtersData,
      this.state.pageNumber,
      this.state.rowsPerPage,
      this.state.sortByColumnId,
      this.state.sortOrder
    );
    const items = fulldata.data;

    //let currPageNumber = 1;

    const additionalData = JSON.parse(fulldata.additionalDataJson)
    const { dataMeta } = additionalData
    let permisionsData = this.state.Permissions
    if (additionalData.permissionDataSet) {
      if (Array.isArray(additionalData.permissionDataSet) && additionalData.permissionDataSet.length > 0) {
        const dbPermissions = additionalData.permissionDataSet[0]
        permisionsData.showSetDefaultForAll = (dbPermissions.moduleadmin || dbPermissions.admin)
        permisionsData.showNew = dbPermissions.write ? true : false
        permisionsData.showDelete = (dbPermissions.delete ? true : false)

      }
    }
    const FRAMEWORK_ID = additionalData.fulldata.length > 0 ? additionalData.fulldata[0].FrameworkID : -1
    let viewName = "";
    let columnsToDisplay = []
    if (Array.isArray(additionalData.columnsMeta) && additionalData.columnsMeta.length > 0) {
      viewName = additionalData.columnsMeta[0].viewName
      if (this.state.columnsToDisplay.length > 0) {
        columnsToDisplay = this.state.columnsToDisplay;
      }
      else {
        columnsToDisplay = additionalData.columnsMeta.map((x, i) => {
          return {
            colName: x.stepitemname,
            colId: x.stepitemkey,
            isSelected: (x.stepitemkey === "name" ? true : x.isSelected),
            orderid: i//x.orderby
          }

        }).sort(function (a, b) {
          return a.orderid - b.orderid;;
        });
      }
    }

    var list = [];
    if (Array.isArray(items)) {
      list = items.map((x, i) => {

        let entityObj = {
          name: x.name,
          id: x.entityId,
          children: x.children.map((y) => {
            y.ListPath = `/entityTypeList/${y.navListPath}`
            y.handleListClick = () => {
              this.props.history.push(
                y.ListPath
              );
            }
            return y;
          }),
          AddPath: `/entity/${x.entityTypeId === 3 ? 9 : 3}/-1/${x.entityTypeId}/${x.entityId}`,
          EditPath: `/entity/${x.entityTypeId}/${x.entityId}/${x.pentityTypeId}/${x.pentityId}`,
          ChildListPath: `/entityTypeList/${x.entityTypeId}/${-1}/${x.entityTypeId
            }/${x.entityId}`,
          //ChildListPath:`/entityTypeList/${x.navListPath}`,
          handleClick: () => {
            this.props.history.push(
              //`/entityTypeList/${x.entityTypeId}/${-1}/${x.entityTypeId}/${x.entityId}`,
              `/entity/${x.entityTypeId}/${x.entityId}/${x.pentityTypeId}/${x.pentityId}`
            );
          },
          handleImportClick: () => {
            this.props.history.push(
              `/import/${x.entityTypeId}/${x.entityId}/${x.pentityTypeId}/${x.pentityId}`
            );
          },
          handleDelete: async (e) => {
            e.preventDefault()
            this.setState(
              {
                showConfirmDialog: true,
                confirmHandler: async () => {
                  const result = await Service().deleteEntity(
                    {
                      entityTypeId: x.entityTypeId,
                      entityId: x.entityId,
                      pentityTypeId: x.pentityTypeId,
                      pentityId: x.pentityId,
                    }
                  );
                  this.getData()
                }
              }
            )

          },
          ...x,
          delete: x.entityTypeId === 9 ? permisionsData.showDelete : x.delete
        };

        columnsToDisplay.map(col => {
          entityObj[col.colId] = additionalData.fulldata[i][col.colId]
          //entityObj[col.colId+"_color"]="red"
        });

        return entityObj
      });
    }

    this.setState({
      loading: false,
      entities: list,
      columnsToDisplay: columnsToDisplay,
      viewName: viewName,
      //pageNumber: currPageNumber,
      totalRecordsCount: dataMeta ? dataMeta.totalrows : list.length,
      frameworkId: FRAMEWORK_ID,
      Permissions: {
        ...permisionsData,
      },
      showConfirmDialog: false
    });



  }

  async getFilterData() {
    const filterInformation = await Service().getFilterDataByEntityIdAndType(
      this.state.entityTypeId,
      this.state.id,
      this.state.pentityTypeId,
      this.state.pid,
      this.state.viewId,
      this.state.frameworkId
    );
    this.setState({
      filtersData: { ...filterInformation }
    })
  }

  render() {
    const list = this.state.entities;
    const showSetDefaultForAll = this.state.Permissions.showSetDefaultForAll;
    const showNew = this.state.Permissions.showNew;
    const showDelete = this.state.Permissions.showDelete;
    var currPageData = list//;.slice((this.state.pageNumber - 1) * this.state.rowsPerPage, this.state.pageNumber * this.state.rowsPerPage);
    const headers = [...this.state.columnsToDisplay.filter(col => col.isSelected === 1 || col.isSelected === true)]
    const { classes, displayCards } = this.props;
    //return <NavBoard NavItems={this.state.navItems}></NavBoard>;

    const renderGrid = () => {
      this.setState({ loading: true });
      currPageData = list.slice((this.state.pageNumber - 1) * this.state.rowsPerPage, this.state.pageNumber * this.state.rowsPerPage);
      this.setState({ loading: false });
      this.forceUpdate();
    };

    var handleChangePage = (newPage) => {
      this.state.pageNumber = newPage + 1;
      //renderGrid();
      //this.getData();
      this.setState({
        pageNumber: newPage + 1
      }, () => {
        this.getData();
      })
    };

    var handleChangeRowsPerPage = (event) => {
      // this.state.rowsPerPage = parseInt(event.target.value, 10);
      // renderGrid();
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        pageNumber: 1 //reset to 1
      }, () => {
        this.getData();
      })
    };

    return (

      <div className="pageHeaderPanel">
          <div className="page-header1">
     

          <div className="btn-toolbar justify-content-end">
          <div className="btn  btn-icon btn-outline-secondary btnAction"
            onClick={() => this.props.history.goBack()} title="Back">
            <i className="fas fa-arrow-left"></i>
          </div>
          {showNew && <div className="btn  btn-icon btn-outline-primary btnAction" onClick={this.handleClickNew} title="Add New">
            <i className="fas fa-plus"></i>
          </div>
          }
        </div>
        </div>

        {this.state.entityTypeId === 9 &&
          <>
            <br />

            <div className="btn-toolbar justify-content-end">
              <ColumnPicker key={this.state.columnsToDisplay.length} columnHandler={this.handleColumnListChange} columns={this.state.columnsToDisplay}
              >
              </ColumnPicker>&nbsp;
              <SaveView columns={this.state.columnsToDisplay} entityParams={{
                entityTypeId: this.state.entityTypeId,
                id: this.state.id,
                pentityTypeId: this.state.pentityTypeId,
                pid: this.state.pid,
                viewId: this.state.viewId
              }
              }
                viewName={this.state.viewName}
                filtersData={this.state.filtersData}
              >
              </SaveView>&nbsp;
              <Filters filterHandler={this.handlefilterHandler}
                filtersData={this.state.filtersData}
                filterResetHandler={this.handleFilterReset}
              ></Filters>&nbsp;
              {showSetDefaultForAll && <div className="btn  btn-icon btn-outline-primary btnAction" title="Set As Default View For All">
                <div onClick={(e) => this.handleSaveViewForAll(e)} ><i className="fa fa-list" ></i></div>
              </div>
              }

              <div className="btn  btn-icon btn-outline-primary btnAction" title="Set As My Default View">
                <div onClick={(e) => this.handleSaveMyView(e)} ><i className="fa fa-list" ></i></div>
              </div>
              <div className="btn  btn-icon btn-outline-primary btnAction" title="Reset My Default View">
                <div onClick={(e) => this.handleResetMyView(e)} ><i className="fa fa-undo" ></i></div>
              </div>
            </div>
          </>
        }
        {this.state.loading && <div class="col-md-12"><Loading></Loading></div>}
        {!this.state.loading && <CardCounts
          Headers={[...headers]}
          Data={currPageData}
          DisplayCard={displayCards}
          ShowName={this.state.entityTypeId === 9 ? false : true}
          ShowDelete={false}

          Pagination={{
            page: this.state.pageNumber - 1,
            rowsPerPage: this.state.rowsPerPage,
            count: this.state.totalRecordsCount,
            handleChangePage: handleChangePage,
            handleChangeRowsPerPage: handleChangeRowsPerPage,
          }}
          Sorting={
            {
              handleSort: this.handleSort,
              sortByColumnId: this.state.sortByColumnId,
              sortOrder: this.state.sortOrder
            }
          }
        ></CardCounts>}
        {//wip make this common component
          this.state.showConfirmDialog && (
            <Modal closeModal={() => this.handleConfirmDialog(false)} title="Warning"
              footerActions={[{ Name: "Confirm", className: "btn btn-secondary mr-3", callBack: () => { this.state.confirmHandler() } },
              { Name: "Cancel", className: "btn btn-primary", callBack: () => { this.handleConfirmDialog(false) } }
              ]}>
              <p>This action will delete all the directly associated entities and unlink related entities</p>
            </Modal>
          )
        }
      </div>
    );
  }
}

export default withStyles(styles)(EntityTypeList);
