import React from "react";
import { Route, Switch } from "react-router-dom";

import AdminLayout from "./layouts/AdminLayout";

import HOCRoute from "./components/HOCRoute";
import Home from "./views/Home";
import Login from "./views/Login";
import FormsLogin from "./views/FormsLogin";
import SignUp from "./views/SignUp";
import NotFound from "./views/NotFound";
import Builder from "./components/Builder";
import BuilderList from "./components/BuilderList";
import MyForm from "./components/Form";
import FormList from "./components/FormList";
import Layout from "./components/Layout";
import PublicLayout1 from "./components/PublicLayout";
import NavMain from "./views/NavMain";
import NavAdmin from "./views/NavAdmin";
import BasicPlanner from "./components/Planner/Basic";
import Assessments from "./components/Assessments";
import FrameworkList from "./components/Framework/List"
import Framework from "./components/Framework/Item"
import UniverseList from "./components/Universe/List"
import Universe from "./components/Universe/Item"
import RegisterList from "./components/Register/List"
import Register from "./components/Register/Item"
import AdminUniverse from "./components/AdminUniverse"
import AdminFormsList from "./components/AdminForms/List"
import ConfigFormsList from "./components/ConfigForms/List"
import AdminFormsBuilder from "./components/AdminForms/Builder"
import AdminFormsInstance from "./components/AdminForms/Instance"
import AdminFormsInstanceList from "./components/AdminForms/InstanceList"

import RegisterInstanceList from "./components/RegisterInstance/List"
import RegisterInstance from "./components/RegisterInstance/Item"

import EntityTypeList from "./views/EntityTypeList";
import Grids from "./views/Grids";
import EntityInstanceForm from "./components/EntityInstance"
import ReportViewerForm from "./components/ReportDetail"
import Dashboard from "./components/DashBoard/DashBoard"

import CustomFormsBuilderList from "./components/CustomForms/BuilderList"
import CustomFormsBuilder from "./components/CustomForms/Builder"
import CustomFormsTypeList from "./components/CustomForms/TypeDefList"
import CustomFormsTypeDef from "./components/CustomForms/TypeDef"

//
import CustomFormsTypeFormList from "./components/CustomForms/TypeFormList"
import CustomFormsInstanceList from "./components/CustomForms/InstanceList"
import CustomFormsInstance from "./components/CustomForms/Instance"


import RenderFile from "./views/RenderFile"

//import EmailBuilder from "./components/Common/EmailTemplate/Builder";
import textEditor from "./components/Common/EmailTemplate/Editor";
import ImportData from "./views/ImportData";
import LookUpConfig from "./views/LookUpConfig";

export default function Routes({ appProps }) {
  console.log("Routes");

  const AuthRoutes = [
    { path: "/navAdmin", component: NavAdmin },
    { path: "/frameworkList", component: FrameworkList },
    { path: "/framework/:id", component: Framework },
    { path: "/universeList", component: UniverseList },
    { path: "/universe/:id", component: Universe },
    { path: "/adminUniverse", component: AdminUniverse },
    { path: "/registerList", component: RegisterList },
    { path: "/register/:id", component: Register },
    { path: "/registerInstance/:id", component: RegisterInstanceList },
    { path: "/registerInstance/:registerId/:metaId/:id", component: RegisterInstance },

    { path :"/configForms", component:ConfigFormsList},

    { path: "/adminforms", component: AdminFormsList },
    { path: "/adminbuild/:id", component: AdminFormsBuilder },
    { path: "/adminInstance/:entityTypeId/:entityId", component: AdminFormsInstance },
    { path: "/adminInstanceList/:entityTypeId", component: AdminFormsInstanceList },
    { path: "/entityTypeList/:entityTypeId/:entityId/:pentityTypeId/:pentityId/:viewId?", component: EntityTypeList },
    { path: "/entity/:entityTypeId/:entityId/:pentityTypeId/:pentityId/:linkEntityId?/:linkFramweworkId?/:linkApiKey?", component: EntityInstanceForm },
    { path: "/customBuilder/:typeId/:id", component: CustomFormsBuilder },
    { path: "/customBuilderList/:typeId", component: CustomFormsBuilderList }, 
    
    { path: "/customFormDef/:typeId", component: CustomFormsTypeDef, },
    { path: "/customForms", component: CustomFormsTypeList },

    { path: "/dashBoard/:id?", component: Dashboard },
    
    { path: "/reportDetail/:reportId?",component:ReportViewerForm},

    { path: "/templateTypes", component: CustomFormsTypeFormList },
    { path: "/templateList/:typeId", component: CustomFormsInstanceList },
    { path: "/template/:typeId/:id", component: CustomFormsInstance },

    { path: "/renderfile", component: RenderFile },

    { path: "/grids", component: Grids },
     //{ path: "/emailBuilder", component: EmailBuilder},
     { path: "/editor", component: textEditor},
     { path:"/import/:entityTypeId?/:entityId?/:pentityTypeId?/:pentityId?", component: ImportData},
     { path:"/lookups", component: LookUpConfig},
    
  ];
  return (
    <Switch>
      <HOCRoute.AuthenticatedRoute
        path="/"
        exact
        component={NavMain}
        layout={AdminLayout}
        appProps={appProps}
      />      
      <HOCRoute.UnauthenticatedRoute
        path="/login"
        layout={PublicLayout1}
        exact
        component={Login}
        appProps={appProps}
      />
      <HOCRoute.UnauthenticatedRoute
        path="/formlogin"
        layout={PublicLayout1}
        exact
        component={FormsLogin}
        appProps={appProps}
      />
      <HOCRoute.UnauthenticatedRoute
        path="/signup"
        layout={PublicLayout1}
        exact
        component={SignUp}
        appProps={appProps}
      />
      <HOCRoute.AuthenticatedRoute
        path="/navbar"
        exact
        component={NavMain}
        appProps={appProps}
        layout={AdminLayout}
      />
       <HOCRoute.AuthenticatedRoute
        path="/basicplanner"
        exact
        component={BasicPlanner}
        appProps={appProps}
        layout={AdminLayout}
      />
      <HOCRoute.AuthenticatedRoute
        path="/assess"
        exact
        component={Assessments}
        appProps={appProps}
        layout={AdminLayout}
      />
      <HOCRoute.AuthenticatedRoute
        path="/buildList"
        exact
        component={BuilderList}
        appProps={appProps}
        layout={AdminLayout}
      />
      <HOCRoute.AuthenticatedRoute
        path="/build/:id"
        exact
        component={Builder}
        appProps={appProps}
        layout={AdminLayout}
      />
      <HOCRoute.AuthenticatedRoute
        path="/form/:metaId/:id"
        exact
        component={MyForm}
        appProps={appProps}
        layout={AdminLayout}
      />
      <HOCRoute.AuthenticatedRoute
        path="/formList/:metaId"
        exact
        component={FormList}
        appProps={appProps}
        layout={AdminLayout}
      />
      {AuthRoutes.map((route, index) => (
        <HOCRoute.AuthenticatedRoute
          path={route.path}
          exact
          component={route.component}
          appProps={appProps}
          layout={AdminLayout}
          key={`route_${index}`}
        />
      ))}
      {/* <Route path="/abc" component={Layout} /> */}
      {/* Finally, catch all unmatched routes */}
      <Route 
        render={(props) => (          
            <AdminLayout>              
              <NotFound {...props} />
            </AdminLayout>
          
        )} />
    </Switch>
  );
}
