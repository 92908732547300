import GraphqlWrapper from "./graphql";
import axios from "axios";

const { Graphql } = GraphqlWrapper

const apis = {
  getUsers: async (searchParams) => {//just mock with data
    console.log(searchParams)
    const list = [
      { name: "ABC", id: 1, description: "desc1" },
      { name: "DEF", id: 2, description: "desc2" },
      { name: "GHI", id: 3, description: "desc3" },
    ];

    // if (searchText.indexOf("A") > -1) return [list[0]];
    // return list;

    var result = await Graphql(`{
      Helper {
        getContactsForEntity(entityId:${searchParams.entityId},entityTypeId:${searchParams.entityTypeId},
                      pentityTypeId:${searchParams.pentityTypeId},pentityId:${searchParams.pentityId},
                      frameworkId:${searchParams.frameworkId},
                      searchText:"${searchParams.searchText ? searchParams.searchText : ''}"
                      ){
                username,
                userid                
              }
          }      
        }`);
    const tranform = result.data.Helper.getContactsForEntity
    return tranform;

  },

  getContactsForEntityWithoutPermissions: async (searchParams) => {//just mock with data

    const isSearch = searchParams.isSearch ? 1 : 0
    var result = await Graphql(`{
      Helper {
        getContactsForEntityWithoutPermissions(entityId:${searchParams.entityId},entityTypeId:${searchParams.entityTypeId},
                      pentityTypeId:${searchParams.pentityTypeId},pentityId:${searchParams.pentityId},
                      frameworkId:${searchParams.frameworkId},
                      searchText:"${searchParams.searchText ? searchParams.searchText : ''}",
                      isSearch:${isSearch}
                      ){
                name,
                id,
                role,
                notify
              }
          }      
        }`);
    const tranform = result.data.Helper.getContactsForEntityWithoutPermissions
    return tranform;

  },

  async getlookupsForFramework(payload) {
    const url = `/list/framework/${payload.frameworkId}`;
    try {

      const response = await axios.get(url);
      return response.data;
    } catch (e) {
      console.log(e);
    }
  },

  async getRawlookupsForFramework(payload) {
    const url = `/list/frameworkRaw/${payload.frameworkId}`;
    try {

      const response = await axios.get(url);
      return response.data;
    } catch (e) {
      console.log(e);
    }
  },

  async getLookupsForExport(payload) {
    const url = `/list/frameworkLookupExport/${payload.frameworkId}`;
    try {

      const response = await axios.get(url);
      return response.data;
    } catch (e) {
      console.log(e);
    }
  },


  getLinkEntityRegisterList: async (searchParams) => {

    var result = await Graphql(`{
      Helper {
        getLinkEntityRegisterList(id:${searchParams.id},
                        conditionId:${searchParams.conditionId},
                        searchText:"${searchParams.searchText ? searchParams.searchText : ''}",
                        apikey:"${searchParams.apikey}",
                        pentityId:${searchParams.pentityId},
                        pentityTypeId:${searchParams.pentityTypeId},
                        frameworkId:${searchParams.frameworkId},
                        isSameLevel:${searchParams.isSameLevel},
                        configKey:"${searchParams.configKey}"
                      ){
                frameworkId,
                registerId,
                register,
                Universe,
                UniversePath
              }
          }      
        }`);
    const tranform = result.data.Helper.getLinkEntityRegisterList
    return tranform;

  },

  getEntityListForLinking: async (searchParams) => {

    var result = await Graphql(`{
      Helper {
        getEntityListForLinking(id:${searchParams.id},
                        conditionId:${searchParams.conditionId},
                        searchText:"${searchParams.searchText ? searchParams.searchText : ''}"
                        apikey:"${searchParams.apikey}",
                        isSameLevel:${searchParams.isSameLevel},
                        configKey:"${searchParams.configKey}"
                      ){
                dataJson
              }
          }      
        }`);
    const tranform = result.data.Helper.getEntityListForLinking
    return JSON.parse(tranform.dataJson);;

  },


  getLinkEntityFrameworkList: async (params) => {


    var result = await Graphql(`{
      Helper {
        getLinkEntityFrameworkList(entityId:${params.entityId},
                      frameworkId:${params.frameworkId > -1 ? params.frameworkId : -1},   
                      entityTypeId:${params.entityTypeId},
                      apikey:"${params.apikey}",
                      ){
                name,
                id
              }
          }      
        }`);
    const tranform = result.data.Helper.getLinkEntityFrameworkList
    return tranform;

  },


  getCustomKeyProcedures: async (params) => {


    var result = await Graphql(`{
      Helper {
        getCustomKeyProcedures(
          entityTypeId:${params.entityTypeId > -1 ? params.entityTypeId : -1},   
          entityId:${params.entityId > -1 ? params.entityId : -1},   
          pentityTypeId:${params.pentityTypeId > -1 ? params.pentityTypeId : -1},   
          pentityId:${params.pentityId > -1 ? params.pentityId : -1},   
          frameworkId:${params.frameworkId > -1 ? params.frameworkId : -1},   
          customkey:"${params.customkey}",
                      ){
                name,
                id,
                idString
              }
          }      
        }`);
    const tranform = result.data.Helper.getCustomKeyProcedures
    return tranform;

  },

  getLinkedEntityList: async (params) => {
    var result = await Graphql(`{
      Helper {
        getLinkedEntityList(entityId:${params.entityId},
                      frameworkId:${params.frameworkId > -1 ? params.frameworkId : -1},
                      apikey:"${params.apikey}",
                      ){
                dataJson
              }
          }      
        }`);
    const tranform = result.data.Helper.getLinkedEntityList
    return JSON.parse(tranform.dataJson);

  },

  addLinkEntity: async (params) => {
    //params.entitiesToLink="abc"
    var result = await Graphql(`{
      Helper {
        addLinkEntity(entitiesToLink:"""${JSON.stringify(params.entitiesToLink)}""",
                      frameworkId:${params.frameworkId > 0 ? params.frameworkId : -1},
                      entityId:${params.entityId},
                      entityTypeId:${params.entityTypeId},
                      copyDependent:${params.copyDependent},
                      isCopy:${params.isCopy},
                      apikey:"${params.apikey}",
                      relationType:"${params.relationType}"
                      ){
                dataJson
              }
          }      
        }`);
    const tranform = result.data.Helper.addLinkEntity
    return JSON.parse(tranform.dataJson);

  },

  getFrameworkRoleList: async (params) => {
    var result = await Graphql(`{
      Helper {
        getFrameworkRoleList(entityId:${params.entityId},
          entityTypeId:${params.entityTypeId}
                      ){
                name,
                id
              }
          }      
        }`);
    const tranform = result.data.Helper.getFrameworkRoleList
    return tranform;

  },

  getRegisterListForFramework: async (params) => {
    var result = await Graphql(`{
      Helper {
        getRegisterListForFramework(
                      methId:${params.selectedMethId} ,
                      linkMode:  ${params.linkMode}            
                      ){
                name,
                id
              }
          }      
        }`);
    const tranform = result.data.Helper.getRegisterListForFramework
    return tranform;

  },

  getEntityWorkflowData: async (params) => {
    var result = await Graphql(`{
      Helper {
        getEntityWorkflowData(entityId:${params.entityId},
                      entityTypeId:${params.entityTypeId},
                      frameworkId:${params.frameworkId},
                      registerId:${params.registerId}
                      ){
                dataJson
              }
          }      
        }`);
    const tranform = result.data.Helper.getEntityWorkflowData
    return JSON.parse(tranform.dataJson);

  },

  getTabEntityDetails: async (params) => {
    var result = await Graphql(`{
      Helper {
        getTabEntityDetails(entityId:${params.entityId},
                      entityTypeId:${params.entityTypeId},
                      pentityTypeId:${params.pentityTypeId},
                      pentityId:${params.pentityId},
                      frameworkId:${params.frameworkId},
                      multiEntityKey:"${params.multiEntityKey ? params.multiEntityKey : ''}",
                      ){
                dataJson
              }
          }      
        }`);
    const tranform = result.data.Helper.getTabEntityDetails
    return JSON.parse(tranform.dataJson);

  },

  getQueryGridData: async (params) => {
    var result = await Graphql(`{
      Helper {
        getQueryGridData(entityId:${params.entityId},
                      entityTypeId:${params.entityTypeId},
                      pentityTypeId:${params.pentityTypeId},
                      pentityId:${params.pentityId},
                      frameworkId:${params.frameworkId},
                      customGridKey:"${params.customGridKey}",
                      apikey:"${params.apikey}",
                      linkId:${typeof params.linkId == "number" ? params.linkId : -1},
                      linkFrameworkId:${typeof params.linkFrameworkId == "number" ? params.linkFrameworkId : -1},
                      linkApiKey:"${params.linkApiKey ? params.linkApiKey : ''}",
                      ){
                dataJson
              }
          }      
        }`);
    const tranform = result.data.Helper.getQueryGridData
    return JSON.parse(tranform.dataJson);

  },

  getCustomGridList: async (params) => {
    var result = await Graphql(`{
      Helper {
        getCustomGridList(entityId:${params.entityId},
          entityTypeId:${params.entityTypeId},
          customGridKey:"${params.customGridKey}"
                      ){
                        dataJson
              }
          }      
        }`);
    const tranform = result.data.Helper.getCustomGridList
    return JSON.parse(tranform.dataJson);

  },

  getCustomGridListSearch: async (params) => {
    //console.log('Context PId',params.stateId)
    var result = await Graphql(`{
      Helper {
        getCustomGridListSearch(entityId:${params.entityId},
          entityTypeId:${params.entityTypeId},
          stateId:${params.stateId > 0 ? params.stateId : -1},
          searchText:"${params.searchText ? params.searchText : ''}",
          customGridKey:"${params.customGridKey}"
                      ){
                        dataJson
              }
          }      
        }`);
    const tranform = result.data.Helper.getCustomGridListSearch
    return JSON.parse(tranform.dataJson);

  },

  getMainSearchResults: async (params) => {
    var result = await Graphql(`{
      Helper {
        getMainSearchResults(
          searchText:"${params.searchText ? params.searchText : ''}",
          urlString:"""${params.urlString ? params.urlString : ''}"""
                      ){
                        dataJson
              }
          }      
        }`);
    const tranform = result.data.Helper.getMainSearchResults
    return JSON.parse(tranform.dataJson);

  },

  getBreadcrumbResults: async (params) => {
    var result = await Graphql(`{
      Helper {
        getBreadcrumbResults(
          urlString:"""${params.urlString ? params.urlString : ''}"""
                      ){
                        dataJson
              }
          }      
        }`);

    try {
      const tranform = result.data.Helper.getBreadcrumbResults
      return JSON.parse(tranform.dataJson);
    }
    catch (e) {
      return {
        list: []
      }
    }

  },

  getEntityTypelistForRegisterSelect: async (params) => {
    var result = await Graphql(`{
      Helper {
        getEntityTypelistForRegisterSelect{
                name,
                id
              }
          }      
        }`);
    const tranform = result.data.Helper.getEntityTypelistForRegisterSelect
    return tranform;

  },

  getItemsListForRegisterSelect: async (params) => {
    var result = await Graphql(`{
      Helper {
        getItemsListForRegisterSelect(
          entityTypeId:${params.typeId}
          ){
          dataJson
              }
          }      
        }`);
    const tranform = result.data.Helper.getItemsListForRegisterSelect
    return JSON.parse(tranform.dataJson);

  },

  getStepItemsbyEntity: async (params) => {
    var result = await Graphql(`{
      Helper {
        getStepItemsbyEntity(
          entityTypeId:${params.entityTypeId},
          entityId:${params.entityId}
          ){
          dataJson
              }
          }      
        }`);
    const tranform = result.data.Helper.getStepItemsbyEntity
    return JSON.parse(tranform.dataJson).list;

  },

  getLookupValueBystepitemId: async (params) => {
    var result = await Graphql(`{
      Helper {
        getLookupValueBystepitemId(
          entityTypeId:${params.entityTypeId},
          entityId:${params.entityId},
          stepItemId:${params.stepItemId}
          ){
          dataJson
              }
          }      
        }`);
    const tranform = result.data.Helper.getLookupValueBystepitemId
    return JSON.parse(tranform.dataJson).list;

  },

  importDataForEntity: async (params) => {
    var result = await Graphql(`{
      Helper {
        importDataForEntity(
                  dataJSON:"""${params.dataJSON}"""
                  )
                  {
                    dataJson
                  }
      }
    }`);
    if (result) {
      return result.data.Helper.importDataForEntity;
    }
    return null;
  },

  getUserPreferences: async (params) => {
    var result = await Graphql(`{
      Helper {
        getUserPreferences {
                dataJson
              }
          }      
        }`);
    try {
      const tranform = result.data.Helper.getUserPreferences
      return JSON.parse(tranform.dataJson);
    }
    catch (e) {
      return {
      }
    }
  },
};

export default apis