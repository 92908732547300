import React from "react";


import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
//import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Badge from "@material-ui/core/Badge";
import ColorPicker from "../ColorPicker"
import { Pagination } from "react-bootstrap";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import EntityInstanceForm from "../../EntityInstance";
import Modal from '../Modal';
import { setElementToFocus } from './../../../Utils/formData'

const styles = ((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    padding: "23px 9px 0px 0px",
    width: "100%",
    justifyContent: "space-between",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    verticalAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "#349feb",
    height: 80,
  },
  item2: {
    padding: theme.spacing(1),
    textAlign: "center",
    verticalAlign: "center",
    color: theme.palette.text.secondary,
    margin: 10,
    //backgroundColor: "#11ff33",
    //height: 80,
  },
  viewButtonLabel: { textTransform: "none", color: "white" },
  table: {
    minWidth: 650,
  },
  cell: {
    fontSize: 10
  },
  formDiv: {
    margin: theme.spacing(2, 2),
    display: "flex",
    flexDirection: "column",
  },
  colHeader: {
    position: "sticky",
    top: 0,
    zIndex: 2,
    background: "white"
  }
}));

const DynamicGrid = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      selectedEntityToView: null
    }
    this.handleDialog = this.handleDialog.bind(this);
    this.handleOnNavItemClick = this.handleOnNavItemClick.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
  }
  handleDialog(openState) {
    this.setState({ showDialog: openState })
  }
  handleSelection(e,item){
    if(this.props.HandleCheckboxSelect){
      this.props.HandleCheckboxSelect(e,item);
    }
  }
  handleOnNavItemClick(item, itemObj) {
    const itemToFocus = itemObj.elementApiKeyToFocus //itemObj.tabname;
    if (itemToFocus) {
      setElementToFocus(itemToFocus)
    }

    const [entityTypeId, entityId, pentityTypeId, pentityId] = item.split('/')
    //alert(JSON.stringify(params))

    this.setState({
      showDialog: true,
      selectedEntityToView: {
        entityTypeId: entityTypeId,
        entityId: entityId,
        pentityTypeId: pentityTypeId,
        pentityId: pentityId,
      }
    })
  }

  render() {
    const {
      Headers: headers = [],
      Data: data,
      DisplayCard: displayCard,
      Pagination: pagination,
      ShowName: showName = true,
      NavigationInDialog: navigationInDialog = true,
      AllowRadioSelection: allowradioSelection = false,
      AllowCheckboxSelection: allowCheckboxSelection = false,
      HandleRadioSelect: handleRadioSelect = () => { }
    } = this.props

    const classes = {};//todo fill classes
    return (<div className={classes.root} >
      <Grid>
        {displayCard &&
          data &&
          data.map((item, i) => (
            <Card className={classes.item2} key={`card_${i}`}>
              <CardHeader
                action={
                  <IconButton aria-label="settings">
                    <MoreVertIcon />
                  </IconButton>
                }
                onClick={item.handleClick}
                style={{ backgroundcolor: "#349feb" }}
                title={item.name}
                subheader="September 14, 2016"
              />

              <CardActions disableSpacing>
                {item.children.map((childItem, j) => (
                  <IconButton key={`child_${j}`} onClick={childItem.handleListClick}>
                    <Badge
                      color="secondary"
                      //className={classes['.MuiBadge-colorSecondary']}
                      overlap="circle"
                      badgeContent={childItem.count}
                      style={{ margin: "0px" }}
                    >
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        {childItem.name[0]}
                        {/* R add margin 0 for alignment on counts */}
                      </Avatar>
                    </Badge>
                  </IconButton>
                ))}
              </CardActions>
            </Card>
          ))}
        {!displayCard && data && (
          <div component={Paper} className="table-responsive">
            <table size="small" className="table table-hover m-b-0">
              <thead>
                <tr className="table-primary ">
                  {
                    (allowradioSelection || allowCheckboxSelection) && <th>Select</th>
                  }
                  {showName && <th className={classes.colHeader}>
                    Name
                  </th>}
                  {headers && headers.map((col, j) => (
                    <th width={col.width} align="left" key={col.colName + j} className={classes.colHeader}>
                      {col.colName}
                    </th>
                  ))}
                  {/* <TableCell align="right">
                <b>Actions</b>
              </TableCell> */}
                </tr>
              </thead>
              <tbody>
                {data.map((item, i) => (
                  // <TableRow key={`grid_${i}`} onClick={(e) => { e.preventDefault(); this.handleOnNavItemClick(item) }}>
                  <tr key={`grid_${i}`} >
                    {
                      (allowradioSelection || allowCheckboxSelection) && <td>
                        {
                          allowradioSelection && <input
                            type="radio"
                            //value={x.sheetColumn}
                            checked={item.isSelectedForCompare}
                            name="selectGridData"
                            onChange={() => {
                              handleRadioSelect(item);
                            }}
                          />
                        }
                        {
                          allowCheckboxSelection && <input
                            type="checkbox"
                            key={`gridcheck_${i}`}
                            //value={x.sheetColumn}
                            checked={item.selected}
                            name="selected"
                            onChange={(e) => {
                              this.handleSelection(e,item);
                            }}
                          />
                        }

                      </td>
                    }
                    {showName && <td align="left" scope="row" >
                      <div style={{ textTransform: 'none', cursor: "pointer" }} onClick={item.handleClick}>{item.name}</div>
                    </td>}
                    {headers && headers.map((col, j) => (
                      <td align="left" key={col.colId + j}  >
                        {item.hasOwnProperty(col.colId + "_navurl") &&
                          <div class="btn-link" style={{ textTransform: 'none', cursor: "pointer" }}
                            onClick={() => { this.handleOnNavItemClick(item[col.colId + "_navurl"], item) }}>
                            {item[col.colId]}
                          </div>

                        }
                        {item.hasOwnProperty(col.colId + "_openurl") &&
                          <a href={`${item[col.colId + "_openurl"]}`} target={`${item[col.colId + "_openurlTarget"]}`} rel="noopener noreferrer">{item[col.colId]}</a>

                        }
                        {item.hasOwnProperty(col.colId + "_icon") &&
                          <i className={`fa fa-${item[col.colId + "_icon"]}`} aria-hidden="true"
                          ></i>
                        }
                        {!item.hasOwnProperty(col.colId + "_navurl") && !item.hasOwnProperty(col.colId + "_openurl")
                          && !item.hasOwnProperty(col.colId + "_icon")
                          && <div>{item[col.colId]}</div>
                        }
                        {item.hasOwnProperty(col.colId + "_color") &&
                          <ColorPicker disableEdit={true} color={item[col.colId + "_color"]} ></ColorPicker>
                        }

                      </td>
                    ))}

                    {/* <TableCell className={classes.cell} align="right" style={{ verticalAlign: 'top' }} >
                  <button className="btn actionButton"><Link to={item.EditPath} title="Edit"><i className="fa fa-pen" aria-hidden="true"></i></Link> </button>
                  <button className="btn actionButton"><Link to={item.AddPath} title="Add"><i className="fa fa-plus" aria-hidden="true"></i></Link> </button>
                </TableCell> */}
                  </tr>
                ))}
              </tbody>
            </table>


          </div>

        )}
        {
          this.state.showDialog && this.state.selectedEntityToView.entityId > -1 && (
            <Modal closeModal={() => this.handleDialog(false)}>

              {/* <h1>Alpha</h1> 
          */}
              {/* Need to find the best way to pass as url params */}
              <EntityInstanceForm match={{
                params: {
                  entityId: this.state.selectedEntityToView.entityId,
                  entityTypeId: this.state.selectedEntityToView.entityTypeId,
                  pentityTypeId: this.state.selectedEntityToView.pentityTypeId,
                  pentityId: this.state.selectedEntityToView.pentityId
                }
              }}
                location={{
                  key: (new Date()).getUTCMilliseconds()
                }}
                renderType="modalForm"
                handleModalDialog={(dlgState) => this.handleDialog(dlgState)}
              >
              </EntityInstanceForm>

            </Modal>
          )
        }
        {!data && (
          <Grid item xs={3} spacing={1}>
            <Paper className={classes.paper}>
              <Button color="primary">Coming Soon</Button>
            </Paper>
          </Grid>
        )}
      </Grid>
    </div >)
  }
}
// export default function List({
//     Headers: headers = [],
//     Data: data,
//     DisplayCard: displayCard,
//     Pagination: pagination,
//     ShowName: showName = true,
//     NavigationInDialog: navigationInDialog = true
//   }) {
//   const classes = useStyles();
//   const history = useHistory();

//   const [showEntityDialog, setShowEntityDialog] = useState(false);
//   const [selectedEntityToView, setSelectedEntityToView] = useState(null)

//   const changePage = (event, newPage) => {
//     pagination.handleChangePage(newPage);
//   };
//   const changePageSize = (event) => {
//     pagination.handleChangeRowsPerPage(event);
//   };

//   const handleOnNavItemClick = (item) => {

//     setShowEntityDialog(true)
//     setSelectedEntityToView({
//       entityTypeId: item.entityTypeId,
//       entityId: item.entityId,
//       pentityTypeId: item.pentityTypeId,
//       pentityId: item.pentityId,
//     })
//   }

//   return (
//     <div className={classes.root}>
//       <Grid container spacing={3} justify="flex-start">
//         {displayCard &&
//           data &&
//           data.map((item, i) => (
//             <Card className={classes.item2} key={`card_${i}`}>
//               <CardHeader
//                 action={
//                   <IconButton aria-label="settings">
//                     <MoreVertIcon />
//                   </IconButton>
//                 }
//                 onClick={item.handleClick}
//                 style={{ backgroundcolor: "#349feb" }}
//                 title={item.name}
//                 subheader="September 14, 2016"
//               />

//               <CardActions disableSpacing>
//                 {item.children.map((childItem, j) => (
//                   <IconButton key={`child_${j}`} onClick={childItem.handleListClick}>
//                     <Badge
//                       color="secondary"
//                       //className={classes['.MuiBadge-colorSecondary']}
//                       overlap="circle"
//                       badgeContent={childItem.count}
//                       style={{ margin: "0px" }}
//                     >
//                       <Avatar aria-label="recipe" className={classes.avatar}>
//                         {childItem.name[0]}
//                         {/* R add margin 0 for alignment on counts */}
//                       </Avatar>
//                     </Badge>
//                   </IconButton>
//                 ))}
//               </CardActions>
//             </Card>
//           ))}
//         {!displayCard && data && (
//           <TableContainer component={Paper} >
//             <Table size="small">
//               <TableHead>
//                 <TableRow className="table-primary">
//                   {showName && <TableCell className={classes.colHeader}>
//                     <b>Name</b>
//                   </TableCell>}
//                   {headers && headers.map((col, j) => (
//                     <TableCell align="left" key={col.colName + j} className={classes.colHeader}>
//                       <b>{col.colName}</b>
//                     </TableCell>
//                   ))}
//                   {/* <TableCell align="right">
//                     <b>Actions</b>
//                   </TableCell> */}
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {data.map((item, i) => (
//                   <TableRow key={`grid_${i}`} onClick={(e) => { e.preventDefault(); handleOnNavItemClick(item) }}>
//                     {showName && <TableCell align="left" scope="row" style={{ verticalAlign: 'top' }} >
//                       <div style={{ textTransform: 'none', cursor: "pointer" }} onClick={item.handleClick}>{item.name}</div>
//                     </TableCell>}
//                     {headers && headers.map((col, j) => (
//                       <TableCell align="left" key={col.colId + j} style={{ verticalAlign: 'top' }} >
//                         {
//                           col.colId === "name" && <div color="primary" style={{ textTransform: 'none', cursor: "pointer" }} onClick={item.handleClick}>{item.name}</div>
//                         }{
//                           col.colId !== "name" && <div>{item[col.colId]}</div>
//                         }
//                         {item.hasOwnProperty(col.colId + "_color") &&
//                           <ColorPicker disableEdit={true} color={item[col.colId + "_color"]} ></ColorPicker>
//                         }
//                       </TableCell>
//                     ))}

//                     {/* <TableCell className={classes.cell} align="right" style={{ verticalAlign: 'top' }} >
//                       <button className="btn actionButton"><Link to={item.EditPath} title="Edit"><i className="fa fa-pen" aria-hidden="true"></i></Link> </button>
//                       <button className="btn actionButton"><Link to={item.AddPath} title="Add"><i className="fa fa-plus" aria-hidden="true"></i></Link> </button>
//                     </TableCell> */}
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>


//           </TableContainer>
//         )}

//         {
//           showEntityDialog && selectedEntityToView.entityId > -1 && (
//             <Modal closeModal={() => setShowEntityDialog(false)}>

//               {/* <h1>Alpha</h1> 
//               */}
//               {/* Need to find the best way to pass as url params */}
//               <EntityInstanceForm match={{
//                 params: {
//                   entityId: selectedEntityToView.entityId,
//                   entityTypeId: selectedEntityToView.entityTypeId,
//                   pentityTypeId: selectedEntityToView.pentityTypeId,
//                   pentityId: selectedEntityToView.pentityId
//                 }
//               }} >
//                 location={{
//                   key: (new Date()).getUTCMilliseconds()
//                 }}
//               </EntityInstanceForm>

//             </Modal>
//           )
//         }
//         {!data && (
//           <Grid item xs={3} spacing={1}>
//             <Paper className={classes.paper}>
//               <Button color="primary">Coming Soon</Button>
//             </Paper>
//           </Grid>
//         )}
//       </Grid>
//     </div>
//   );
// }

export default DynamicGrid