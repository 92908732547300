import React from "react";
import { CXContext } from "./DataContext";
import * as dc from "dc";
import { rhythm } from "../../Utils/typography";
import { css } from "glamor";

const ResetButton = props => {
  const style = css({
    padding: rhythm(0.1),
    display: "inline",
    cursor: 'pointer',
    float: 'right',
    '&:hover': {
      background: "#ddd",
    }
  });
  const undoFilters = (e) => {
    e.preventDefault();
    props.chart.filterAll();
    dc.redrawAll();
  }
  return (
    <button {...style} class="btn  btn-icon btn-outline-info btnAction" title="Refresh" onClick={undoFilters}>
      <i class="fa fa-undo"></i>
    </button>
  );
};
export const ChartTemplate = props => {
  /*
  We render the dc chart using an effect. We want to pass the chart as a prop after the dc call,
  but there is nothing by default to trigger a re-render and the prop, by default would be undefined.
  To solve this, we hold a state key and increment it after the effect ran. 
  By passing the key to the parent div, we get a rerender once the chart is defined. 
  */
  const context = React.useContext(CXContext);
  const [chart, updateChart] = React.useState(null);
  const [cProps, setCProps] = React.useState(props);
  const [chartKey, setchartKey] = React.useState(props.config.chartKey);

  const { entityId, entityTypeId } = props.config
  const ndx = context.ndx[`${entityId}_${entityTypeId}`];
  const div = React.useRef(null);

  React.useEffect(() => {
    const newChart = cProps.chartFunction(div.current, ndx, cProps.config); // chartfunction takes the ref and does something with it
    if (typeof newChart.render === 'function') {
      newChart.render();
      updateChart(newChart);
    }
    else {
      div.current.append(newChart);
    }
  }, [chartKey]); {/*Run this exactly once */ }
  const cType = cProps.chartFunction.name.toLowerCase();
  const chartStyles = css({
    width: '100%',
    height: 'auto',
    boxSizing: 'border-box',
    padding: rhythm(1),
    '& label': {
      textTransform: 'capitalize',
      textDecoration: 'underline'
    }
  })
  // if(cType == "tablechartfunc"){
  //   return (<div>    
  //     <ResetButton chart={chart} /> 
  //     <label>{props.title}</label>
  //     <table ref={div} class="table table-sm table-bordered"></table>
  //     </div>)
  // }
  // else{
  //   return(<div ref={div} {...chartStyles}>    
  //     <ResetButton chart={chart} /> 
  //     <label>{props.title}</label>
  //   </div>);
  // }
  return (<div ref={div} {...chartStyles}>
    <div className="widgetHeading">
      <ResetButton chart={chart} />
      {props.config && props.config.edithandler &&
        <button className="btn  btn-icon btn-outline-info btnAction widgetEdit" aria-label="Edit" title="Edit" onClick={(e) => {
          e.preventDefault();
          props.config.edithandler();
        }}>
          <i className="fas fa-pen"></i>
        </button>
      }
      
      <span>{props.title}</span>
    </div>
  </div>);
};
