import React from "react";
import DBService from "../../Services/dexieForm";
import { Link } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
//import Link from '@material-ui/core/Link';

const styles = (theme) => ({
  table: {
    minWidth: 650,
  },
  cell:{
    fontSize:12
  }
});

class BuilderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { builderFormsList: [] }; //default

    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    DBService.initialize();
    let list = await DBService.getFormMetaList();
    this.setState({ builderFormsList: list });
  }

  handleClick(item) {
    console.log("clicked Item - ", item);
    this.props.history.push(`/build/${item.id}`);
  }

  render() {
    const list = this.state.builderFormsList;
    const classes = this.props;
    return (
      <div className="dynamicForm">
        <div>
          <div className="alignItems">
            <span>
              <b>Form Builder List</b>
            </span>
            <Link to="/build/-100">Add New</Link>
          </div>
        </div>
        {list.length > 0 && (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell><b>Form Meth Name</b></TableCell>
                  <TableCell align="right"><b>Actions</b></TableCell>                 
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                    
                      <Button onClick={() => this.handleClick(item)}>{item.name}</Button>
                       
                    </TableCell>
                    
                    <TableCell className={classes.cell} align="right">
                    <Link to={`formList/${item.id}`}>List</Link>
                    </TableCell>
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {list.length == 0 &&
        <div className="tablePanel-body noPanelBody">
           <h2>No forms</h2>
        </div>         
      }
      </div>
    );
  }
}

export default withStyles(styles)(BuilderList);
