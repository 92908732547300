import React from "react";
//import DBService from "../../../Services/dexieForm";
import Service from "../../../Services";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
//import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";
import CustomList from "../../Common/List";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  table: {
    minWidth: 650,
  },
  cell: {
    fontSize: 12,
  },
  alignItems: {
    display: "flex",
    //width: 600,
    //"justify-content": "space-between",
    "padding-bottom": 20,
    flexDirection: "row",
    //flexWrap: "nowrap"
    justifyContent: "space-between",
  },
  formDiv: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
  },
});

class ConfigFormsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { builderFormsList: [], page: 0, rowsPerPage: 5 }; //default

    this.handleClick = this.handleClick.bind(this);
    
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowsPerPageChange = this.handleRowsPerPageChange.bind(this);
  }

  async componentDidMount() {
    await this.getData(0, this.state.rowsPerPage);
  }

  async handlePageChange(event, newPage) {
    await this.getData(newPage, this.state.rowsPerPage);
  }

  async handleRowsPerPageChange(event) {
    const noOfRows = parseInt(event.target.value, 10);
    //setRowsPerPage(parseInt(event.target.value, 10));
    //setPage(0);
    await this.getData(0, noOfRows);
  }

  async getData(page, rowsPerPage, searchText) {
    //DBService.initialize();
    let list = await Service().getConfigFormsList(1); //pass one to get the existing
    if (list) {
      list = list.map((x) => {
        return {
          name: x.name,
          path: `/adminInstance/${x.id}/1`,
          handleClick: () => {
            this.props.history.push(`/adminInstance/${x.id}/1`);
          },
        };
      });

      this.setState({
        builderFormsList: list,
        page: page,
        rowsPerPage: rowsPerPage,
      });
    }
  }

  handleClick(item) {
    console.log("clicked Item - ", item);
    this.props.history.push(`/adminInstance/${item.id}/1`);
  }


  render() {
    const list = this.state.builderFormsList;
    const { classes, displayCards } = this.props;
    return (
      <div className="pageHeaderPanel">
        <div className="page-header1">
             <h5>Config Forms List</h5> 
            <div className="btn-toolbar justify-content-end ">
              <Link  className="btn  btn-icon btn-outline-secondary btnAction"
                to={`/navbar`} Tooltip="Back">
                <i className="fas fa-arrow-left"></i>
              </Link>&nbsp;
            
            </div>
        </div>
        <CustomList
          Data={list}
          DisplayCard={displayCards}
          Pagination={{
            count: list.length,//this.state.totalRecordsCount,
            page: this.state.page,
            rowsPerPage: this.state.rowsPerPage,
            handleChangePage: this.handlePageChange,
            handleChangeRowsPerPage: this.handleRowsPerPageChange,
          }}
        ></CustomList>
        {list.length == 0 &&
          <div className="tablePanel-body noPanelBody">
            <h2>No Admin Forms</h2>
          </div>
        }
      </div>
    );
  }
}

export default withStyles(styles)(ConfigFormsList);
