import React from "react";
import { withStyles } from "@material-ui/core/styles";

import lookUpService from "../../Services/lookupApi";
import { exportToCSV } from "../../Utils/exportFile";

const styles = (theme) => ({
    root: {
        height: "100vh",
    },
});

let negativeCounter = -1;

class ImportData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lookups: [],
            columns: [
            ]
        };

        this.updateItem = this.updateItem.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.handleApiKeyChange = this.handleApiKeyChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleExportExcel = this.handleExportExcel.bind(this);
        this.handleImportClick = this.handleImportClick.bind(this);
    }

    //onChange


    async componentDidMount() {

        if (this.props.frameworkId > -1) {
            const list = await lookUpService.getRawlookupsForFramework({
                frameworkId: this.props.frameworkId,
            });

            const columns = await lookUpService.getStepItemsbyEntity({
                entityId: this.props.frameworkId,
                entityTypeId: 1
            });

            this.setState({
                lookups: list,
                columns: columns
            })
        }
    }
    handleApiKeyChange(items, e) {

        items.map(x => {
            x.apikey = e.target.value
        })
        this.setState({
            lookups: { ...this.state.lookups }
        });
    }

    handleDelete(parentArray, indexToRemove) {


        parentArray.splice(indexToRemove, 1)
        this.setState({
            lookups: { ...this.state.lookups }
        });
    }


    handleAddItem(item) {

        let apiKey = item.children.length > 0 ? item.children[0].apikey : "";

        item.children.push({
            apikey: apiKey,
            lookupname: "",
            lookupvalue: (--negativeCounter) + "",
            children: []
        })
        this.setState({
            lookups: { ...this.state.lookups }
        });

    }
    updateItem(event, item) {

        let value = event.target.value;
        item[event.target.name] = value;
        this.setState({
            lookups: { ...this.state.lookups }
        });

    }
    handleImportClick(e) {
        e.preventDefault();
        this.props.history.push(
            `/import/${this.props.entityTypeId}/${this.props.entityId}/-1/-1`
        );
    }

    async handleExportExcel() {
        const fullData = await lookUpService.getLookupsForExport({
            frameworkId: this.props.frameworkId,
        });
        exportToCSV(fullData.data, fullData.fileNameWithoutExtn);
    }

    render() {
        const classes = this.props.classes;

        //temp hack to pass child state to parent
        //passing the callback to return the current lookup state
        if (typeof this.props.onChange === "function") {
            this.props.onChange(() => {
                return this.state.lookups
            })
        }

        const renderLevels = (items, isRootChildren = false) => {

            return (<>

                {

                    items && items.length > 0 &&
                    items.map((x, index) => {
                        return <>
                            <ul className="wtree">
                                <li>
                                    <span>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <select className="form-control"
                                                    name="apikey"
                                                    value={x.apikey} onChange={(e) => {
                                                        isRootChildren ? this.updateItem(e, x)
                                                            : this.handleApiKeyChange(items, e)
                                                    }}
                                                >
                                                    <option value="-1">Select</option>
                                                    {this.state.columns.map(x =>
                                                        <option value={x.StepItemKey} key={x.StepItemKey}>
                                                            {x.StepItemName}
                                                        </option>
                                                    )
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <input className="form-control" name="lookupname" value={x.lookupname} onChange={(e) => this.updateItem(e, x)} /></div>
                                            <div className="col-md-3">
                                                {
                                                    false &&
                                                    <input className="form-control" name="lookupvalue" disabled value={x.lookupvalue} onChange={(e) => this.updateItem(e, x)} />
                                                }
                                            </div>
                                            <div className="col-md-3">
                                                <input className="" type="button" value={"ADD CHILD"} onClick={() => this.handleAddItem(x)} >
                                                </input>
                                                <input className="" type="button" value={"Delete"} onClick={() => this.handleDelete(items, index)} >
                                                </input>
                                            </div>

                                        </div>

                                    </span>
                                    {renderLevels(x.children, false)}
                                </li>
                            </ul>
                        </>
                    }

                    )
                }
            </>)



        }
        return (
            <>

                Look ups:<div className="col-md-3">
                    <input className="" type="button" value={"ADD"} onClick={() => this.handleAddItem(this.state.lookups)} >
                    </input>
                    <input className="" type="button" value={"EXPORT"} onClick={() => this.handleExportExcel()} >
                    </input>
                    <button className="btn btn-icon btn-outline-primary btnAction" onClick={this.handleImportClick} title="Import"><i className="fa fa-file-excel-o" aria-hidden="true"></i></button>
                </div>
                <table>
                    <tr>
                        <td>apikey</td> <td>Label</td>
                         <td>
                            {/* Value */}
                            </td>
                    </tr>
                </table>
                {

                    renderLevels(this.state.lookups.children, true)
                }

            </>
        )

    }
}

export default withStyles(styles)(ImportData);
