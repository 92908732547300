import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

export default function TablePaginationActions(props){
    // const classes = useStyles1();
    // const theme = useTheme();
    const theme = { direction: 'ltr' }
    const { count, page, rowsPerPage, onChangePage, rowsPerPageOptions, onChangeRowsPerPage } = props;
    let goToPageValue;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    const handleGoToButton = (e) => {
        const pageNumber = goToPageValue.value ? Number(goToPageValue.value) - 1 : 0
        onChangePage(pageNumber);
    };


    return (
        <div className="paginationTable">
            <div className="row">
                <div className="col-md-4">
                    <div className="rowsPerPage"> {rowsPerPage} Rows per Page: <select onChange={onChangeRowsPerPage} value={rowsPerPage}> {rowsPerPageOptions && rowsPerPageOptions.map(x => <option>{x}</option>)} </select>
                    </div>
                </div>
                <div className="col-md-4 text-center">
                    <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">{theme.direction === 'rtl' ?
                        <LastPageIcon /> :
                        <FirstPageIcon />}
                    </IconButton>
                    <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">{theme.direction === 'rtl' ?
                        <KeyboardArrowRight /> :
                        <KeyboardArrowLeft />}
                    </IconButton>
                    <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">{theme.direction === 'rtl' ?
                        <KeyboardArrowLeft /> :
                        <KeyboardArrowRight />}
                    </IconButton>
                    <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">{theme.direction === 'rtl' ?
                        <FirstPageIcon /> :
                        <LastPageIcon />}
                    </IconButton>
                </div>
                <div className="col-md-4">
                    <div className="input-group text-right goToPage">
                        <div class="input-group-prepend">
                            <label class="input-group-text">Go to Page</label>
                        </div>
                        <input type="number" defaultValue={page + 1} ref={inputValue => goToPageValue = inputValue} /> <div className="input-group-append">
                            <span class="input-group-text">/ {Math.ceil(count / rowsPerPage)}</span>
                            <button className="btn  btn-icon btn-primary" type="button" onClick={handleGoToButton}>Go</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}