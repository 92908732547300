import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./edit"
import ColorPicker from "./../../../Common/ColorPicker"

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";


const jsonString={ "values":[
  {
  "name":"High",
    "color":"red"
  },
  {
  "name":"low",
    "color":"green"
  }
  ]
  }


/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const ToggleCustomComp = class extends Component {
  constructor(props) {
    super(props);


    let data;
    try{
      data=JSON.parse(props.component.jsonData);
    }
    catch(e){
      data={ values:[]}//set default
    }

    this.state = {
      value: props.value ? props.value :props.submissionData ,
      jsonData:data,
      selectValues:props.component.selectValues      
    };
  }
  // static getDerivedStateFromProps(props, current_state) {
  //   if (props.submissionData && current_state.submissionData !== props.submissionData) {
  //     return {
  //       value: props.submissionData,        
  //     }
  //   }
  //   return null
  // }
  setValue = (event) => {
    console.log('Selected',event.target.value)
   const selectedItem=this.state.selectValues.find(x=>x.color===event.target.value)
    this.setState(
      prevState => ({ value:{ 
        selectVal:event.target.value,
        name:selectedItem?selectedItem.name:'',
        value:selectedItem?selectedItem.value:''
      } }),
      // () => this.props.onChange(null,this.state.value)
      () => this.props.onChange(this.state.value)
    );
  };

  render() {
    const data=this.state.jsonData; 
    const disableComponent=this.props.component.disabled;
    let selectdColor="blue";
    let selectedVal="High";
 
    

    let dataUpdated=this.state.value;  //should update state from the props
    if(dataUpdated && data.values){

      let selectedOne=data.values.find(x=>x.name==dataUpdated.selectVal);
      if(selectedOne){
        selectedVal=selectedOne.name;
        selectdColor=selectedOne.color;
      }
    }

    let selectedValueFromDDL=this.state.value?this.state.value.selectVal:null

    return (
        <div>
          
 
     {/* {data && 
    <select
    value={selectedVal}
    
    onChange={this.setValue}
    style={{color:selectdColor}}
    >
    {data.values.map(item=>
      <option style={{color:item.color}} key={item.name} >{item.name}</option>
    )
  }
      </select>
  } */}
   <div className="form-inline"> 
      <select disabled={disableComponent} value={selectedValueFromDDL} onChange={this.setValue}>
        {this.state.selectValues && this.state.selectValues.map((item,i)=>
          <option style={{color:item.color}} key={item.name+i} value={item.color} >{item.name}</option>
        )}
      </select>
      <ColorPicker disabled={disableComponent} key={selectedValueFromDDL} disableEdit={true} color={selectedValueFromDDL} ></ColorPicker>
    </div>
                
                {/* Styling issue start {data &&    
                <FormControl >
                              <InputLabel id="choice">Rating</InputLabel>

                              <Select
                                labelId="choice"
                                id="choice-select"
                                
                                
                                value={selectedVal}
                                onChange={this.setValue}
                                style={{color:this.state.value?this.state.value.color:"yellow"}}
                              >
                                {data.values.map((item) => (
                                  <MenuItem style={{color:item.color}} value={item.name} key={item.name}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
              } Styling issue end */}

      </div>
    );
  }
};

export default class Toggle extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "1ColoredDDL",
      icon: "square",
      group: "basic",
      documentation: "",
      weight: -10,
      schema: Toggle.schema()
    };
  }

  
  
  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "colored",
      label: "Default Label"
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    //console.log('this.dataForSetting',this.dataForSetting)
    // if (this.shouldSetValue) {
    //   //this.setValue(this.dataForSetting);
    //   this.updateValue(this.dataForSetting);
    // }
    return ReactDOM.render(
      <ToggleCustomComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }
  

  setValue(data) {
    console.log('Called wit data-',data);
 //hacked to rerender the react component by adding new prop //temp fix
  //hacked to rerender the react component by adding new prop submissionData//temp fix
   //TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO
   if (data && this.element) {
    ReactDOM.render(
      <ToggleCustomComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // updatedvalue of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        submissionData={data}
      />,
      this.element
    );
    }
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
