import React, { Component } from "react";
import { useHistory } from 'react-router-dom';
import EntityInstanceForm from "../../EntityInstance";
import Modal from '../Modal';

export default function NavButton(props){
    let propsObj=props;
    if(!props)
    {
        propsObj= {
            component:{
                buttonText:"Add",
                navUrl:"/navbar",
                disabled:false
            }
        }
    }
    const { buttonText, navUrl, disabled, entityModal } = propsObj.component
    const history = useHistory();
    const [showDialog, setShowDialog] = React.useState(false);
    const [selectedEntityToView, setselectedEntityToView] = React.useState(null);

   
    const handleDialog=(openState) =>{
        setShowDialog(openState);
    }
    const handleOnNavItemClick=(item) =>{
      
  
      const [entityTypeId, entityId, pentityTypeId, pentityId,
        linkEntityId,linkFramweworkId,linkApiKey
    ] = item.split('/')
      //alert(JSON.stringify(params))
  
      setShowDialog(true);
        setselectedEntityToView( {
          entityTypeId: entityTypeId,
          entityId: entityId,
          pentityTypeId: pentityTypeId,
          pentityId: pentityId,
          linkEntityId,
          linkFramweworkId,
          linkApiKey
        }
      )
    }

        return (
            <>

{
    entityModal && <div class="btn-link" style={{ textTransform: 'none', cursor: "pointer" }}
    onClick={(e) => {e.preventDefault(); handleOnNavItemClick( navUrl) }}>
    {buttonText}
  </div>
}
             {
                !entityModal && <a href={navUrl} target="_blank" >{buttonText}</a>
             }           
                

                {
          showDialog && selectedEntityToView  && (
            <Modal closeModal={() => handleDialog(false)}>

              {/* <h1>Alpha</h1> 
          */}
              {/* Need to find the best way to pass as url params */}
              <EntityInstanceForm match={{
                params: {
                  entityId: selectedEntityToView.entityId,
                  entityTypeId: selectedEntityToView.entityTypeId,
                  pentityTypeId: selectedEntityToView.pentityTypeId,
                  pentityId: selectedEntityToView.pentityId,
                  linkEntityId: selectedEntityToView.linkEntityId,
                  linkFramweworkId: selectedEntityToView.linkFramweworkId,
                  linkApiKey: selectedEntityToView.linkApiKey
                }
              }}
                location={{
                  key: (new Date()).getUTCMilliseconds()
                }}
                renderType="modalForm"
                handleModalDialog={(dlgState) => handleDialog(dlgState)}
              >
              </EntityInstanceForm>

            </Modal>
          )
        }
                
                {/* <button disabled={disabled}
                    onClick={(e) => {
                        e.preventDefault();
                        
                        console.log(props);
                        //props.FORMIO_ROOT._submission.data.textField
                        props.FORMIO_ROOT._submission.data.container.textField=1;
                        props.FORMIO_ROOT._submission.data.container1.textField1=2;
                        props.FORMIO_ROOT._submission.data.container2.textField2=3;
                            
                        props.FORMIO_ROOT.triggerRedraw()
                    }}

                >
                    {buttonText}
                </button > */}
            </>

        );
}


// class NavButtonComp extends Component {
//     constructor(props) {
//         super(props);

//     }

//     render() {
//         const { buttonText, navUrl, disabled } = this.props.component

//         return (
//             <>

//                 <a href={navUrl} >{buttonText}</a>
                
//                 <button disabled={disabled}
//                     onClick={(e) => {
//                         e.preventDefault();
                        
//                         useHistory.push(navUrl);
//                     }}

//                 >
//                     {buttonText}
//                 </button >
//             </>

//         );
//     }
// }
// export default NavButtonComp