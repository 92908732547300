import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from "./contexts/ConfigContext";
import './index.scss';
import store from "./store";

//import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import {requestInterceptor,responseSuccessInterceptor,responseErrorInterceptorWrapper} from './Utils/interceptor'

axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(responseSuccessInterceptor,responseErrorInterceptorWrapper());

ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  <Provider store={store}>
  <ConfigProvider>
    <Router>
      <App />
      </Router>
  </ConfigProvider>
</Provider>,
  // <Router>
  //   <App />
  // </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
