import React from "react";
import RenderForm from "./../../RenderForm";
import { withStyles } from "@material-ui/core/styles";
import Service from "../../../Services";
import { Link } from "react-router-dom";
import Fab from '@material-ui/core/Fab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from "@material-ui/core/Typography";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import IconButton from "@material-ui/core/IconButton";
import { ENTITY_TYPES } from "../../../config/constant";
import DashboardWrapper from "../../Common/Dashboards"

import TextField from "@material-ui/core/TextField";
import * as FormUtil from "../../../Utils/formData";
import { searchComponentsByType } from "../../../Utils/formData";

const EntityTypeIds = {
  Dashboard: 23,
  LandingPages: 20
}

const styles = (theme) => ({
  actionButton: {
    padding: "3px",
  },
  actionsContainer: {
    //float:'right'
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"

  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
});

function updateValByPath(obj, path, val) {
  let props = path.split(".");
  let currObj = obj;
  let index = 0;
  for (index = 0; index < props.length - 1; index++) {
    if (!currObj[props[index]]) currObj[props[index]] = {};
    currObj = currObj[props[index]];
  };
  currObj[props[index]] = val;
  return obj;
};

class InstanceForm extends React.Component {
  constructor(props) {
    super(props);
    console.log("props-", this.props);

    const {
      match: { params },
    } = this.props;

    this.submissionDataTemp = {};
    this.state = {
      entityName: "",
      components: [],
      display: "form",
      submission: this.submissionDataTemp,
      id: Number(params.id),
      typeId: Number(params.typeId),
      schemaText: "",
      dataText: "",
      isDashboardEntity: Number(params.typeId) == 23,
      finalDashboardConfig: null
    }; //default

    this.handleChange = this.handleChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleImportClick = this.handleImportClick.bind(this);
  }

  async componentDidMount() {
    //const item = await Service().getAdminEntityInstance(this.state.id);
    const item = await Service().getEntitiesDetailByEntityTypeId(this.state.typeId, this.state.id);
    console.log("item-", item);
    if (item) {

      let queryParams = {};

      if (this.props.location) {
        const searchParams = new URLSearchParams(this.props.location.search);
        for (const [key, value] of searchParams.entries()) {
          queryParams = updateValByPath(queryParams, key, value);
        }
      }

      this.submissionDataTemp = {
        data: item.dataJSON ? item.dataJSON.data : {},
      };
      this.submissionDataTemp.data = { ...this.submissionDataTemp.data, ...queryParams };

      const meth = item.formJSON;

      let finalDashboardConfig = null;
      if (meth && meth.finalDashboardConfig) {
        finalDashboardConfig = meth.finalDashboardConfig;
      } else {//nneded for migration from old formio to standalone
        if (meth && Array.isArray(meth.components)) {
          const existingCustomComps = searchComponentsByType(meth.components, "dashboardSelect");
          if (existingCustomComps && Array.isArray(existingCustomComps) && existingCustomComps.length > 0) {
            finalDashboardConfig = existingCustomComps[0].defaultValue
          }

        }

      }

      this.setState({
        components: meth.components,
        display: meth.display,
        entityName: item.entityName?item.entityName:item.name,
        submission: this.submissionDataTemp,
        finalDashboardConfig: finalDashboardConfig
      });
    } else {
      //not a valid methFormId
      console.log("not a valid ID");
      this.props.history.replace(`/navbar`);
      return;
    }
  }

  handleChange(submission) {
    if (submission.changed) {
      console.log(submission);
      //--------------------------------------->//
      //todo this is workaround as setstate is causing infinite rerender for submission
      //. submission has to move to state later
      this.submissionDataTemp = submission;
      //const submissionData = submission.data;
      //this.setState({ submission: submission });
      //this.setState({ schemaText: JSON.stringify() });
      console.log(FormUtil.dataToSave(this.state.components, submission.data))
    }
  }
  handleImportClick(e) {
    e.preventDefault();
    this.props.history.push(
      `/import/${this.state.typeId}/${this.state.id}/-1/-1`
    );
  }

  async handleSubmit(event) {
    event.preventDefault();
    ///need to check for entitytype as userType

    ///need to check for entitytype as userType
    let params = {
      dataJSON: JSON.stringify(this.submissionDataTemp.data),
      id: this.state.id,
      entityTypeId: this.state.typeId
    };
    //let resultId = await Service().saveUserDetail(params);
    let resultId = await Service().saveEntityDetail(params);
    if (resultId > -1)
      alert('Save success');

  }

  render() {
    const { classes } = this.props;
    const showSchemaData = false;
    const nonEditableEntityTypeIds = [EntityTypeIds.Dashboard, EntityTypeIds.LandingPages]
    const showSaveButtons = nonEditableEntityTypeIds.indexOf(this.state.typeId) === -1 ? true : false;
    const renderReadOnly = this.props.renderReadOnly ? this.props.renderReadOnly : false;
    const EntityInfo = ENTITY_TYPES[this.state.typeId] || {};
    return (
      <div className="dynamicForm">
        <form onSubmit={this.handleSubmit}>

          <div className="pageHeaderPanel">
            <div className="page-header1 marb10">
              <h5> {EntityInfo.name} Detail</h5>
              <div className="btn-toolbar justify-content-end">
                {!renderReadOnly && (<>
                  <div className={classes.actionsContainer}>
                    <Fab color="default" aria-label="back" variant="extended" className="btn btn-icon btn-outline-primary btnAction" href={this.props.isLightUser ? `${global.defaultHomeUrl}`:`/adminInstanceList/${this.state.typeId}`} title="Back">
                      <ArrowBackIcon fontSize="medium" />
                    </Fab>

                    {
                      showSaveButtons && (<>
                        <Fab color="primary" onClick={this.handleSubmit} className="btn btn-icon btn-outline-primary btnAction" title="Save" variant="extended" >
                          <SaveOutlinedIcon fontSize="small" />
                        </Fab>
                        <Fab color="primary" className="btn btn-icon btn-outline-primary btnAction" title="Save and Close" variant="extended" >
                          <SaveAltOutlinedIcon fontSize="small" />
                        </Fab>
                        <Fab color="primary" className="btn btn-icon btn-outline-primary btnAction" title="Save and New" variant="extended" >
                          <NoteAddOutlinedIcon fontSize="small" />
                        </Fab>
                        <Fab color="primary" className="btn btn-icon btn-outline-primary btnAction" title="Close" variant="extended" >
                          <CancelOutlinedIcon fontSize="small" />
                        </Fab>
                        <Fab color="primary" className="btn btn-icon btn-outline-primary btnAction" title="Delete" variant="extended" >
                          <DeleteOutlineOutlinedIcon fontSize="small" />
                        </Fab>
                        <Fab color="secondary" className="btn btn-icon btn-outline-primary btnAction" title="Copy" variant="extended" >
                          <FileCopyRoundedIcon fontSize="small" />
                        </Fab>
                        <button className="btn btn-icon btn-outline-primary btnAction" onClick={this.handleImportClick} title="Import"><i className="fa fa-file-excel-o" aria-hidden="true"></i></button>
                      </>)
                    }
                  </div>
                </>)}
              </div>
            </div>

            <div className="tablePanel-body">

              {
                this.state.isDashboardEntity
                && (this.state.finalDashboardConfig || this.state.id < 0)
                && <DashboardWrapper updateFormIoValue={() => { }}
                  configValue={this.state.finalDashboardConfig}
                  currentContext={{
                    entityId: this.state.id,// this.props.component.entityId,
                    entityTypeId: this.state.typeId,// this.props.component.entityTypeId,
                    title:this.state.entityName,
                    pentityId: -1,// this.props.component.pentityId,
                    pentityTypeId: -1,// this.props.component.pentityTypeId,
                    dashApiKey: "dashApiKey"// this.props.component.key
                  }
                  }
                  isFormIoBuilderRendered={false}
                ></DashboardWrapper>
              }
              {
                !this.state.isDashboardEntity &&
                <RenderForm
                  Components={this.state.components}
                  Display={this.state.display}
                  Submission={this.state.submission}
                  OnChange={this.handleChange}
                ></RenderForm>
              }

              {showSchemaData && <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                variant="outlined"
                margin="normal"
                //required
                //fullWidth
                id="schema"
                label="schema"
                name="schema"
                value={this.state.schemaText}
                rows="10"
                multiline
              />}
              {showSchemaData && <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                variant="outlined"
                margin="normal"
                //required
                //fullWidth
                id="data"
                label="Data"
                name="data"
                value={this.state.dataText}
                rows="10"
                multiline
              />
              }

            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(InstanceForm);
