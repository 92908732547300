import React from "react";
import ReactDOM from "react-dom";
import Chart from "react-google-charts";

function daysToMilliseconds(days) {
  return days * 24 * 60 * 60 * 1000;
}
//format in which data should be presented to google Gantt chart
const columns = [
    { type: 'string', label: 'Task ID' },
    { type: 'string', label: 'Task Name' },
    { type: 'string', label: 'Resource' },
    { type: 'date', label: 'Start Date' },
    { type: 'date', label: 'End Date' },
    { type: 'number', label: 'Duration' },
    { type: 'number', label: 'Percent Complete' },
    { type: 'string', label: 'Dependencies' },
  ];

//transform the data from server into the following format, before supplying to chart 
const rows = [
    [
        'Research',
        'Find sources',
        'Resource 1',
        new Date('2021-10-17 09:30:00'),
        new Date('2021-10-18 23:30:00'),
        null,
        100,
        null,
      ],
      [
        'Write',
        'Write paper',
        'Resource 2',
        new Date('2021-10-19 00:30:00'),
        new Date('2021-10-19 23:30:00'),
        null,
        25,
        'Research',
      ],
      [
        'Cite',
        'Create bibliography',
        'Resource 2',
        new Date('2021-10-20 05:30:00'),
        new Date('2021-10-20 23:30:00'),
        null,
        20,
        'Research',
      ],
      [
        'Complete',
        'Hand in paper',
        'Resource 3',
        new Date('2021-10-21 05:30:00'),
        new Date('2021-10-21 16:30:00'),
        null,
        0,
        'Cite,Write',
      ]
];

class GanttChart extends React.Component {
    
constructor(props){
    super(props);       
    this.state = {
        chartImageURI: "",
        chartTitle: props.chartTitle ? props.chartTitle : "Critical Path",
        chartHeight: props.chartHeight ? props.chartHeight:"480px"

    };
};
render() {
        return (           
                <div>
                    <h5>{this.state.chartTitle}</h5>
                    <div className="GanttChart">
                        <Chart
                            chartType="Gantt"
                            loader={<div>Loading Chart</div>}
                            data={[columns, ...rows]}
                            width={"100%"}
                            height={this.state.chartHeight}               
                            options={{
                                gantt: {
                                criticalPathEnabled: true,
                                criticalPathStyle: {
                                    stroke: '#e64a19',
                                    strokeWidth: 5,
                                }
                                }
                            }}

                            legendToggle
                        />
                    <div>
                        <img src={this.state.chartImageURI} />
                    </div>
                    </div>
                </div>
        );
    }
}
export default GanttChart;