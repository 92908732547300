import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./edit";
import lookUpService from "./../../../../../Services/lookupApi";
import DashbaordWrapper from "../../../../Common/Dashboards"

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */


const DashboardComp = class extends React.Component {
  constructor(props) {
    super(props);

    let value = props.value ? props.value : props.submissionData;
    let valueToLoad = props.component.defaultValue;
    this.state = {
      value: value,
    };

    this.updateFormIoValue = this.updateFormIoValue.bind(this);

  }

  async componentDidMount() {
    this.mounted = true;

  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateFormIoValue(newData) {

    let finalObj = {
      ...this.props.component.defaultValue,
      ...newData
    }
    this.setState({
      finalDashboardConfig: { ...finalObj }
    })
    this.props.component.defaultValue = { ...finalObj };
  }

  render() {

    let defaultVal = "";
    try {
      defaultVal = JSON.stringify(this.props.component.defaultValue)
    }
    catch (e) { }
    return (
      <>
        {/* DefaultValue- {defaultVal} */}
        <DashbaordWrapper updateFormIoValue={this.updateFormIoValue}
          configValue={this.props.component.defaultValue}
          currentContext={{
            entityId:this.props.component.entityId,
            entityTypeId:this.props.component.entityTypeId,
            pentityId:this.props.component.pentityId,
            pentityTypeId:this.props.component.pentityTypeId,
            dashApiKey: this.props.component.key
          }
          }
          isFormIoBuilderRendered={this.props.isFormIoBuilder}
        ></DashbaordWrapper>
      </>
    );
  }
};

export default class Dashboard extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "15 Dashboard Select",
      icon: "square",
      group: "basic",
      documentation: "",
      weight: -10,
      schema: Dashboard.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "dashboardSelect",
      label: "Select Dashboard",
      logic: [
        {
          "name": "on Load",
          "trigger": {
            "type": "javascript",
            "javascript": "result= true;"
          },
          "actions": [
            {
              "name": "populateIds",
              "type": "mergeComponentSchema",
              //              "schemaDefinition": "schema = { myWidgetURI:data[data.apiKeyToPick] }"
              "schemaDefinition": "schema = {frameworkId:data.frameworkId, entityId:data.entityId,entityTypeId:data.entityTypeId,pentityId:data.pentityId, pentityTypeId:data.pentityTypeId }"
            }
          ]
        }
      ]
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    //console.log('this.dataForSetting',this.dataForSetting)
    // if (this.shouldSetValue) {
    //   //this.setValue(this.dataForSetting);
    //   this.updateValue(this.dataForSetting);
    // }

    return ReactDOM.render(
      <DashboardComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        contextData={this._data}
        isFormIoBuilder={this.root._form.isBuilder}
      />,
      element
    );
  }

  setValue(data) {
    console.log("Called wit data-", data);
    //hacked to rerender the react component by adding new prop //temp fix
    //hacked to rerender the react component by adding new prop submissionData//temp fix
    //TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO
    if (data && this.element) {
      ReactDOM.render(
        <DashboardComp
          component={this.component} // These are the component settings if you want to use them to render the component.
          value={this.dataValue} // updatedvalue of the component.
          onChange={this.updateValue} // The onChange event to call when the value changes.
          submissionData={data}
          contextData={this._data}
          isFormIoBuilder={this.root._form.isBuilder}
        />,
        this.element
      );
    }
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
