//import TextFieldComponent from 'formiojs/components/textfield/TextField';
import DateTimeComponent from 'formiojs/components/datetime/DateTime';
export default class CustomTextFieldComponent extends DateTimeComponent {
    // constructor() {
    //     super({
    //         type: "fixedDate",
    //         label: "Fixed Button",
    //     })
    // }
    // Override the createLabel method.
    //   createLabel(container) {
    //     super.createLabel(container);
    //     this.addClass(this.labelElement, 'text-success');
    //   }

    static get builderInfo() {
        return {
            title: "Fixed DateTime",
            icon: "square",
            group: "basic",
            documentation: "",
            weight: -10,
            schema: CustomTextFieldComponent.schema(),
        };
    }

    static schema() {
        return DateTimeComponent.schema({
            type: "fixedDate",
            label: "Fixed DateTime",
            //hideLabel: true
        });
    }
}