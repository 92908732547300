import React from "react";
//import "./styles.css";
import * as services from "./../../Services/auth";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import sideImage from "./../../assets/default.png";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Loading from "./../../components/Common/Loading"



function Copyright(props) {
  var copyrightInfo = (props && props.data) ? props.data : {};
  if(copyrightInfo && copyrightInfo.comp_name)
  {
    return ( <Typography variant="body2" color="textSecondary" align="center">
           {"Copyright © "}
            <Link color="inherit" href={copyrightInfo.comp_url||''}>
              {copyrightInfo.comp_name}
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
            </Typography>);
   
  }
  else{
    return (<Typography/>)
  };
}


const styles = (theme) => ({
  // table: {
  //   minWidth: 650,
  // },
  // cell:{
  //   fontSize:12
  // }
  root: {
    height: "100vh",
    maxHeight: "calc(100vh - 64px)",

  },
  image: {
    backgroundImage: `url(${sideImage})`,
    backgroundRepeat: "no-repeat",
    
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  flexRow: {
    //margin: theme.spacing(8, 4),
    display: "flex",
    //flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      userNameRequired:false,
      username: "",
      password: "",
      clientCode: "",
      onPremises: false,
      database: "",
      databaseList: [
        { name: "Dev", id: 1 },
        { name: "QA", id: 2 },
        { name: "UAT", id: 3 },
      ],
      copyrightInfo:{}
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
  }


  async componentDidMount() {
      let config = await services.getLoginConfig();
      window.localStorage.removeItem("envSettings");      
      window.localStorage.removeItem("clientCode");         
      window.localStorage.removeItem("displayName");
      if(config){
        var dnsCode = window.location.host.split(".")[0];
        var dnsSpecific = config.Connections.filter(data => (data.client && data.client.toLowerCase() == dnsCode.toLowerCase()));
        //if DNS Specific DB list available, use the filtered list else use full list
        if(dnsSpecific.length>0){
          config.Connections = dnsSpecific;
        }

        this.setState({
          databaseList:config.Connections,
          onPremises:Boolean(config.onPremise),
          loading:false,
          copyrightInfo:(config.Settings&&config.Settings.copyright?config.Settings.copyright:{})
        })

        if(this.state.databaseList.length==1){
          this.setState({clientCode:this.state.databaseList[0].name});
          this.setState({onPremises:false});
          this.handleSubmit()//autologin when only one db
        }
        if(config.Settings)
          window.localStorage.setItem("envSettings",JSON.stringify(config.Settings));
        
      }

  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleChecked(event) {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  }

  async handleSubmit() {
    //event.preventDefault();
    console.log("fromState", this.state);
    const data = {
      username: this.state.username,
      clientCode: this.state.clientCode,
      password: this.state.password,
      database:this.state.database
    };
    this.setState({loading:true});
    const result = await services.formLogin(data);
    this.setState({loading:false});
    if(result && result.token){
      this.props.userHasAuthenticated(true);
      localStorage.setItem("formActivePages", JSON.stringify({}))//clear track of opened items
      const redirectParam = new URLSearchParams(this.props.location.search).get(
        "redirect"
      );
      if (redirectParam) this.props.history.push(redirectParam);
      else this.props.history.push("/navbar");
    }
    else{
      toast.error("Invalid Login");
    }
  }

  render() {
    const classes = this.props.classes;
    console.log("rendered", new Date());
    console.log("props", classes);
    return (
      
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>

            <div className={classes.flexRow}>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              {/* <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={this.handleChecked}
                      aria-label="login switch"
                    />
                  }
                  //label={this.state.onPremises ? "OnPremise" : "Login"}
                  name="onPremises"
                  label={"OnPremise"}
                />
              </FormGroup> */}
            </div>
         {this.state.loading && <Loading></Loading>}
         {!this.state.loading &&   (<form
              className={classes.form}
              // noValidate
              onSubmit={this.handleSubmit}
            >
              {(!this.state.onPremises) && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="clientCode"
                  label="Client Code"
                  name="clientCode"
                  autoComplete="clientCode"
                  autoFocus                  
                  value={this.state.databaseList.length==1?this.state.clientCode:""}  
                  disabled={this.state.databaseList.length==1}
                  onChange={this.handleChange}
                />
              )}
              {(this.state.onPremises||this.state.databaseList.length>1) && (
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Database
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="database"
                    value={this.state.database}                 
                    required
                    onChange={this.handleChange}
                  >
                    {this.state.databaseList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
             { this.state.userNameRequired && 
              (
                <>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="username"
                name="username"
                autoComplete="username"
                autoFocus
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={this.handleChange}
              />
              </>
              )
             }
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright data={this.state.copyrightInfo}/>
              </Box>
            </form>
         )} </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Login);
