// class ColorPicker extends React.Component {

//   render() {
//     return <CirclePicker />
//   }
// }

// export default ColorPicker

import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

class ColorPicker extends React.Component {
    constructor(props) {
        super(props);
       
        const selectedColor=props.color?props.color: "#2d16dd"
        this.state = {
            displayColorPicker: false,
            color:selectedColor,
            disableEdit:props.disableEdit?props.disableEdit:false
          };
    }
  

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    // this.setState({ color: color.rgb })
    this.setState({ color: color.hex })
    if(typeof this.props.onChange === "function")
        this.props.onChange(color.hex);
  };

  render() {

    const styles = reactCSS({
      'default': {
        color: {
          width: '17px',
          height: '17px',
          borderRadius: '26px',
        //   background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        background: this.state.color,
        },
        swatch: {
          borderRadius: '25px',
          background: '#fff',
          borderRadius: '25px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          top: '3px',
          position: 'relative',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
          
       {
         this.state.disableEdit && (
         <div style={ styles.swatch }>
          <div style={ styles.color } />
        </div>)
       }  

    {
         !this.state.disableEdit && (
         <div style={ styles.swatch } onClick={ this.handleClick }>
          <div style={ styles.color } />
        </div>)
       }    
        { this.state.displayColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
        </div> : null }

      </div>
    )
  }
}

export default ColorPicker