import React, { Component } from "react";
import * as dc from "dc";
import * as d3 from "d3";
import { ChartTemplate } from "./chartTemplate";

var title ="Yearly Fluctuation [P]";

const LineChartFunc = (divRef, ndx,config) => {
  
  const mycrossfilter = ndx;
  var groupKey = "gender";
  if(config)
  {
    groupKey = config.groupKey;
    title=config.title;
  }
  // const dimension = ndx.dimension(d=> d[compareKey2] > d[compareKey1] ? 'Loss' : 'Gain')
  // const group = dimension.group();
  
  const pElement  = divRef.parentElement.parentElement; 

  var width=pElement.clientWidth,
      height=pElement.clientHeight;

  const chartDimension = mycrossfilter.dimension((d) => {return d[groupKey];});
  const chartGroup =chartDimension.group().reduceCount(function(d) {return d.value;});;
  const all = ndx.groupAll();

  var runMin = +chartGroup
                .top(Infinity)
                .reduce(function(prev, current) { 
                        return (prev.value < current.value) ? prev : current  }).value;

  var runMax = +chartGroup
                .top(Infinity)
                .reduce(function(prev, current) { 
                          return (prev.value > current.value) ? prev : current  }).value;

  const chart = new dc.LineChart(divRef);
  
  const NullReplacer = "N.A.";
  

  chart
    .width(width-30)
    .height(height-60)
    .x(d3.scaleBand())
    .y(d3.scaleLinear().domain([0, runMax]))
    .xUnits(dc.units.ordinal)
    .brushOn(false)
    .renderDataPoints(true)
    .xAxisLabel(groupKey)
    // .yAxisLabel('Count')
    .dimension(chartDimension)
    .group(chartGroup);

  chart.yAxis().ticks(runMax);
  // chart
  //   .width(width-30)
  //   .height(height-60)
  //   .x(d3.scaleLinear().domain([0,20]))
  //   .curve(d3.curveCardinal)
  //   .renderArea(false)
  //   .brushOn(false)
  //   .renderDataPoints(true)
  //   .clipPadding(10)
  //   .yAxisLabel(groupKey)
  //   .dimension(groupDimension)
  //   .group(groupGroup);

  chart.render();

  return chart;
};
export const LineChart = (props) => (
  <ChartTemplate chartFunction={LineChartFunc} title={props.config.title}   config={props.config}/>
);

export default LineChart;
