import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FileParser from "./fileParser";
import lookUpService from "../../Services/lookupApi";
import { toast } from "react-toastify";

const styles = (theme) => ({
    root: {
        height: "100vh",
    },
});

const defaultImportTypes = [
    {
        name: "Contact",
        id: 4,//tempIds
        columns: [
            {
                name: 'Column Id',
                id: "colid"
            }
        ]
    }, {
        name: "User",
        id: 5,//tempIds
        columns: [
            {
                name: 'Column Id',
                id: "colid"
            }
        ]
    }, {
        name: "Multiple Entities",
        id: 100,

    }
];

const fixedKnowImportTypes = [{
    name: "Framework Lookups",
    id: 10,
}
]

const actionTypes = [
    {
        id: 1,
        name: 'Add New / Update'
    },
    {
        id: 2,
        name: 'Clear and Add'
    },
]

class ImportData extends React.Component {
    constructor(props) {
        super(props);

        const {
            match: { params },
        } = this.props
        const id = Number(params.entityId) || -1;
        const entityTypeId = Number(params.entityTypeId) || -1;
        const pid = Number(params.pentityId) || -1;
        const pentityTypeId = Number(params.pentityTypeId) || -1;

        this.state = {
            importTypes: [...defaultImportTypes],
            filename: "",
            fileData: "",
            columnsToMapByTypeId: {},
            sheetMapper: [],
            multiSheetsData:
            {
                sheets: [],
                columnsBySheetIndex: {}
            }
            ,//will contain sheets with columns
            sheetsDependency: [

            ],
            fixedKnownType: false,
            mappedJsonDataString: "",
            entityTypeId: entityTypeId,
            entityId: id,
            pEntityId: pid,
            pEntityTypeId: pentityTypeId,
            entitiesByEntityTypeId: {},
            selectedAction: 1,
            allowColumnSelectionToMap:true
        };
        this.handleFile = this.handleFile.bind(this);
        this.columnToMapChange = this.columnToMapChange.bind(this);
        this.handleMapperAdd = this.handleMapperAdd.bind(this);
        this.handleMapData = this.handleMapData.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSheetsDependencyChange = this.handleSheetsDependencyChange.bind(this);
        this.handleImportTypeChange = this.handleImportTypeChange.bind(this);
        this.handleImportEntityIdChange = this.handleImportEntityIdChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSelect(item, sheetObj) {

        sheetObj.colMapper.map(x => {
            x.isSelectedForCompare = false;
        })
        item.isSelectedForCompare = true
        this.setState({
            sheetMapper: [...this.state.sheetMapper]
        })
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async handleFile(e) {

        const file = e.target.files[0];
        const fileData = await file.arrayBuffer();
        //move to file parser later
        //reading only columns here

        const sheetInfo = FileParser.getAllColumns(fileData);
        //console.log(sheetInfo);

        let selectedImportTypeId=-1;
        let selectedImportEntityId=-1;
        if(this.state.fixedKnownType){
            if(this.props.ImportTableTemplate){
                selectedImportTypeId = 13 //table template typeid
                //await getColumnsForImportType(selectedImportTypeId,selectedImportEntityId);
                await this.updateEntitiesByEntityTypeId(selectedImportTypeId);
                const importObj= this.state.entitiesByEntityTypeId[selectedImportTypeId].find(x=>x.name ==this.props.ImportTableTemplate)
                if(importObj){
                    selectedImportEntityId= importObj.id;
                    const columnsToMap = await this.getColumnsForImportType(selectedImportTypeId, Number(selectedImportEntityId))
                }
            }else{
                selectedImportTypeId= this.state.entityTypeId
                selectedImportEntityId = this.state.entityId
            }
        }
        
        const sheetMapper = sheetInfo.sheets.map((sheetObj) => {
            const columns = sheetInfo.columnsBySheetIndex[sheetObj.sheetIndex];
            
            return {
                name: sheetObj.name,
                sheetIndex: sheetObj.sheetIndex,
                selectedImportType: selectedImportTypeId,
                selectedEntityId: selectedImportEntityId,
                colMapper: columns.map(x => {
                    return {
                        sheetColumn: x,
                        columnToMap: this.state.fixedKnownType ? x : "",
                        isSelectedForCompare: false
                    }
                }),
            }
        })

        let sheetsDependency = [];
        for (var i = 0; i < sheetInfo.sheets.length - 1; i++) {
            sheetsDependency.push(
                {
                    leftSheetIndex: sheetInfo.sheets[i].sheetIndex,
                    leftColumnName: "",
                    rightSheetIndex: sheetInfo.sheets[i + 1].sheetIndex,
                    rightColumnName: ""
                }
            )
        }
        this.setState({
            sheetMapper: sheetMapper,
            multiSheetsData: sheetInfo,
            sheetsDependency: sheetsDependency,
            filename: file.name,
            fileData: fileData,
        })

    }

    async handleSheetMappings(fileData) {

    }


    handleSheetsDependencyChange(event, obj) {
        obj[event.target.name] = event.target.value;
        this.setState({
            sheetsDependency: [...this.state.sheetsDependency]
        })
    }

    columnToMapChange(event, mapperObj) {
        mapperObj.columnToMap = event.target.value;
        this.setState({
            sheetMapper: [...this.state.sheetMapper]
        })
    }

    handleMapperAdd(e) {
        e.preventDefault();
        const newArray = [
            ...this.state.mapper,
            {
                sheetColumn: -1,
                columnToMap: ""
            }
        ];
        this.setState({
            mapper: [...newArray],
        });

    }
    async handleMapData(e) {
        e.preventDefault();

        let columnToCompareBySheetOrder = this.state.sheetMapper.map(sheetObj => {
            let compareColumn = sheetObj.colMapper.find(x => x.isSelectedForCompare);
            return compareColumn;
        });
        //for framework lookups ignore this validation
        if (this.state.entityTypeId != 10 && Array.isArray(columnToCompareBySheetOrder)) {
            if (columnToCompareBySheetOrder.filter(x => !x).length > 0) {
                //if something is null
                toast.error("Please select Column to compare");
                return;
            }
        }

        const workbook = FileParser.getWorkbook(this.state.fileData);
        const data = []
        //const readAllSheets = this.state.selectedImportType == "100" ? true : false;

        // workbook.SheetNames.map(sheetName => {

        // })
        this.state.sheetMapper.map(sheetObj => {
            const worksheet = workbook.Sheets[sheetObj.name];
            const jsonData = FileParser.sheetToJson(worksheet);
            var filtered = jsonData.map((obj) => {
                var finalObj = sheetObj.colMapper.reduce((p, mapperObj) => {
                    if (mapperObj.columnToMap && mapperObj.sheetColumn) {
                        p[mapperObj.columnToMap] = obj[mapperObj.sheetColumn]
                    }
                    return p;
                }, {})
                return finalObj
            });

            let compareColumn = sheetObj.colMapper.find(x => x.isSelectedForCompare);
            data.push({
                entityType: sheetObj.selectedImportType,
                entityId: sheetObj.selectedEntityId,
                parentEntityId: this.state.pEntityId,
                parentEntityTypeId: this.state.pEntityTypeId,
                columnToCompare: compareColumn ? compareColumn.columnToMap : "",
                dataToMap: filtered
            })
        }
        )

        const finalDataToSave = {
            data: data,
            selectedAction: this.state.selectedAction,
            fileName: this.state.filename,
            sheetsDependency: this.state.sheetsDependency
        }

        this.setState({
            mappedJsonDataString: JSON.stringify(finalDataToSave)
        })
        let resultId = await lookUpService.importDataForEntity({ dataJSON: JSON.stringify(finalDataToSave) });
        if (this.props.CallbackDataHandler) {
            this.props.CallbackDataHandler();
        }
       
    }




    async handleImportTypeChange(event, sheetObj) {
        sheetObj.selectedImportType = event.target.value;
        sheetObj.selectedEntityId = -1 //resetting
        await this.updateEntitiesByEntityTypeId(Number(sheetObj.selectedImportType));
        await this.updateColumns(sheetObj.selectedImportType, sheetObj.selectedEntityId, sheetObj)
    }
    async handleImportEntityIdChange(value, sheetObj) {
        sheetObj.selectedEntityId = value;
        await this.updateColumns(sheetObj.selectedImportType, sheetObj.selectedEntityId, sheetObj)
    }

    async updateColumns(entityTypeId, entityId, sheetObj) {
        const columnsToMap = await this.getColumnsForImportType(Number(entityTypeId), Number(entityId))
        sheetObj.colMapper = sheetObj.colMapper.map(x => {
            let mappedCol = columnsToMap.find(y => y.name?.toLocaleLowerCase() === x.sheetColumn.toLocaleLowerCase())
            return {
                ...x,
                columnToMap: mappedCol ? mappedCol.name : ""
            }
        })
        this.setState({
            sheetMapper: [...this.state.sheetMapper]
        })
    }



    async getColumnsForImportType(importTypeId, importEntityId) {
        if (importTypeId > -1 && importTypeId != 100) {
            const columnsToMapByTypeId = { ...this.state.columnsToMapByTypeId }
            const hashKey = `${importTypeId}_${importEntityId}`
            if (!columnsToMapByTypeId[hashKey]) {
                const params = {
                    customkey: "columnsForImport",
                    entityId: importEntityId,
                    entityTypeId: importTypeId,
                    pentityId: this.state.pEntityId,
                    pentityTypeId: this.state.pEntityTypeId
                }
                const list = await lookUpService.getCustomKeyProcedures(params);

                columnsToMapByTypeId[hashKey] = list;
                this.setState({
                    columnsToMapByTypeId: columnsToMapByTypeId
                })
            }
            return columnsToMapByTypeId[hashKey];
        }
        return []
    }

    async updateEntitiesByEntityTypeId(typeId) {
        if (typeId > 0) {
            const propName = `${typeId}`;

            if (!this.state.entitiesByEntityTypeId[propName]) {
                const data = await lookUpService.getItemsListForRegisterSelect({
                    typeId
                });
                this.setState({
                    entitiesByEntityTypeId: {
                        ...this.state.entitiesByEntityTypeId,
                        [`${propName}`]: [...data.list]
                    }
                })
            }
        }
    }

    getColumnsToMapForType(typeId, entityId) {
        const hashKey = `${typeId}_${entityId}`
        if (this.state.columnsToMapByTypeId[hashKey]) {
            return this.state.columnsToMapByTypeId[hashKey];
        }
        return []
    }

    getEntitiesToMapForType(typeId) {
        const hashKey = `${typeId}`
        if (this.state.entitiesByEntityTypeId[hashKey]) {
            return this.state.entitiesByEntityTypeId[hashKey];
        }
        return []
    }

    async componentDidMount() {


        let fixedKnownType = false;
        let allowColumnSelectionToMap = true
        if (fixedKnowImportTypes.find(x => x.id == this.state.entityTypeId)) {
            fixedKnownType = true;
            allowColumnSelectionToMap = false;
            // this.setState({
            //     importTypes: [...fixedKnowImportTypes],
            //     fixedKnownType: true,
            // })
        }
        else if(this.props.ImportTableTemplate){//when table template import is invoked
            fixedKnownType =true;
            
        }
        const eparams = {
            customkey: "importTypesForEntity",
            entityTypeId: this.state.entityTypeId,
            entityId: this.state.entityId,
            pentityTypeId: this.state.pEntityTypeId,
            pentityId: this.state.pEntityId
        }

        const list = await lookUpService.getCustomKeyProcedures(eparams);
        if (list) {
            this.setState({
                importTypes: [...list],
                fixedKnownType,
                allowColumnSelectionToMap
            })
        }


    }


    render() {

        const classes = this.props.classes;

        return (
            <>

                <div className="pageHeaderPanel">
                    <div className="page-header1">
                        <h5>Import Excel</h5>
                        <div className="btn-toolbar justify-content-end ">
                            <button className="btn  btn-icon btn-outline-primary btnAction" title="import" onClick={this.handleMapData}><i className="fas fa-file-import" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>

                <div className="bodyPanel">

                    <div className="row">
                        <div className="col-md-4">

                            <select className="form-control"
                                name="selectedAction"
                                key={this.state.selectedAction}
                                value={this.state.selectedAction} onChange={(e) => this.handleChange(e)}
                            >
                                {actionTypes.map(y =>
                                    <option value={y.id} key={y.name}>
                                        {y.name}
                                    </option>
                                )
                                }
                            </select>
                        </div>
                        <div className="col-md-4"> <input type="file" className="form-control" onChange={this.handleFile} /></div>
                        <div className="col-md-4 "> <strong>Filename:</strong> {this.state.filename}</div>


                    </div>
                    {
                        this.state.sheetMapper && this.state.sheetMapper.map(sheetObj => (
                            <>

                                <table className="table table-hover m-b-0 importTable">
                                    <thead>
                                        <tr>
                                            <th>SheetName:</th>
                                            <th>Select Import Type:</th>
                                            <th>Select Import Entity:</th>
                                            <th>File Column</th>
                                            <th>Mapped Column</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            sheetObj.colMapper.map((x, index) => {
                                                return (
                                                    <tr>
                                                        {
                                                            index == 0 && <>
                                                                <td rowSpan={sheetObj.colMapper ? sheetObj.colMapper.length : 1} >{sheetObj.name}</td>
                                                                <td rowSpan={sheetObj.colMapper ? sheetObj.colMapper.length : 1}>
                                                                    <select name="selectedImportType" className="form-control"
                                                                        value={sheetObj.selectedImportType} onChange={(e) => this.handleImportTypeChange(e, sheetObj)}
                                                                        disabled={this.state.fixedKnownType}
                                                                    >
                                                                        <option value="-1">Select</option>
                                                                        {this.state.importTypes.map(y =>
                                                                            <option value={y.id} key={y.id}>
                                                                                {y.name}
                                                                            </option>
                                                                        )
                                                                        }
                                                                    </select>
                                                                </td>
                                                                <td rowSpan={sheetObj.colMapper ? sheetObj.colMapper.length : 1}
                                                                >
                                                                    <select className="form-control"
                                                                        key={sheetObj.selectedEntityId}
                                                                        value={sheetObj.selectedEntityId} onChange={(e) => this.handleImportEntityIdChange(e.target.value, sheetObj)}
                                                                    >
                                                                        <option value="-1">Select</option>
                                                                        {this.getEntitiesToMapForType(sheetObj.selectedImportType).map(y =>
                                                                            <option value={y.id} key={y.name}>
                                                                                {y.name}
                                                                            </option>
                                                                        )
                                                                        }
                                                                    </select>
                                                                </td>
                                                            </>
                                                        }

                                                        <td>{x.sheetColumn}</td>

                                                        <td>
                                                            {/* <input value={x.columnToMap} onChange={(e) => this.columnToMapChange(e, x)} /> */}
                                                            <div className="input-group">
                                                                {!this.state.allowColumnSelectionToMap && <>
                                                                    {x.columnToMap}
                                                                </>}
                                                                {this.state.allowColumnSelectionToMap && <>
                                                                    <select className="form-control"
                                                                        key={sheetObj.selectedImportType}
                                                                        value={x.columnToMap} onChange={(e) => this.columnToMapChange(e, x)}
                                                                    >
                                                                        <option value="-1">Select</option>
                                                                        {this.getColumnsToMapForType(sheetObj.selectedImportType, sheetObj.selectedEntityId).map(y =>
                                                                            <option value={y.name} key={y.name}>
                                                                                {y.name}
                                                                            </option>
                                                                        )
                                                                        }
                                                                    </select>
                                                                </>
                                                                }

                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">
                                                                        <input
                                                                            type="radio"
                                                                            key={x.sheetColumn}
                                                                            value={x.sheetColumn}
                                                                            checked={x.isSelectedForCompare}
                                                                            name={sheetObj.sheetIndex}
                                                                            required
                                                                            onChange={() => {
                                                                                this.handleSelect(x, sheetObj);
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }



                                    </tbody>

                                </table>

                            </>
                        ))
                    }


                    {
                        this.state.sheetsDependency && this.state.sheetsDependency.length > 0 && (<>

                            <table className="table table-hover m-b-0 importTable">
                                <thead>
                                    <tr>
                                        <th> SheetName </th>
                                        <th>Column Name</th>
                                        <th>SheetName</th>
                                        <th>Column Name</th>
                                    </tr>
                                </thead>

                                {
                                    this.state.sheetsDependency.map(x => (
                                        <tbody>

                                            <tr>
                                                <td>
                                                    <select className="form-control"
                                                        name="leftSheetIndex"
                                                        value={x.leftSheetIndex} onChange={(e) => this.handleSheetsDependencyChange(e, x)}
                                                    >
                                                        <option value="-1">Select</option>
                                                        {this.state.multiSheetsData.sheets.map(y =>
                                                            <option value={y.sheetIndex} key={y.name}>
                                                                {y.name}
                                                            </option>
                                                        )
                                                        }
                                                    </select>
                                                </td>
                                                <td>
                                                    <select className="form-control"
                                                        name="leftColumnName"
                                                        value={x.leftColumnName} onChange={(e) => this.handleSheetsDependencyChange(e, x)}
                                                    >
                                                        <option value="-1">Select</option>
                                                        {this.state.multiSheetsData.columnsBySheetIndex[x.leftSheetIndex] &&
                                                            this.state.multiSheetsData.columnsBySheetIndex[x.leftSheetIndex].map(y =>
                                                                <option value={y} key={y}>
                                                                    {y}
                                                                </option>
                                                            )
                                                        }
                                                    </select>
                                                </td>
                                                <td>
                                                    <select className="form-control"
                                                        name="rightSheetIndex"
                                                        value={x.rightSheetIndex} onChange={(e) => this.handleSheetsDependencyChange(e, x)}
                                                    >
                                                        <option value="-1">Select</option>
                                                        {this.state.multiSheetsData.sheets.map(y =>
                                                            <option value={y.sheetIndex} key={y.name}>
                                                                {y.name}
                                                            </option>
                                                        )
                                                        }
                                                    </select>
                                                </td>
                                                <td>
                                                    <select className="form-control"
                                                        name="rightColumnName"
                                                        value={x.rightColumnName} onChange={(e) => this.handleSheetsDependencyChange(e, x)}
                                                    >
                                                        <option value="-1">Select</option>
                                                        {this.state.multiSheetsData.columnsBySheetIndex[x.rightSheetIndex] &&
                                                            this.state.multiSheetsData.columnsBySheetIndex[x.rightSheetIndex].map(y =>
                                                                <option value={y} key={y}>
                                                                    {y}
                                                                </option>
                                                            )
                                                        }
                                                    </select>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))
                                }
                            </table>
                        </>)
                    }

                    <div className="input-group justify-content-end">
                        <textarea className="form-control" value={this.state.mappedJsonDataString} hidden="true"></textarea>
                        <div className="input-group-append">
                            {/* <button  className="btn btn-outline-primary" onClick={this.handleMapData}><i className="fas fa-file-import" aria-hidden="true"></i></button> */}
                        </div>
                    </div>
                </div>
            </>
        )

    }
}

export default withStyles(styles)(ImportData);
