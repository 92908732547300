import React from "react";
import { withStyles } from "@material-ui/core/styles";
import NavBoard from "./../../components/NavBoard";

const styles = (theme) => ({
  root: {
    height: "100vh",
  },
});

class NavMain extends React.Component {
  constructor(props) {
    var navMainItems = JSON.parse(window.localStorage.getItem("navMenuItems")).navMainItems;
    super(props);
    this.state = {
      navItems: navMainItems
    };
  }

  render() {
    const classes = this.props.classes;
    return <NavBoard NavItems={this.state.navItems}></NavBoard>;
  }
}

export default withStyles(styles)(NavMain);
