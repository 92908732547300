import React, { useContext, useState } from 'react';
import { ListGroup, Dropdown, Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Bootstrap, FontAwesome, BootswatchSelect } from 'react-bootswatch-select';
import ChatList from "./ChatList";
import { ConfigContext } from "../../../../contexts/ConfigContext";
//import useAuth from '../../../../hooks/useAuth';

import avatar1 from '../../../../assets/images/user/avatar-1.jpg';
import avatar2 from '../../../../assets/images/user/avatar-2.jpg';
import avatar3 from '../../../../assets/images/user/avatar-3.jpg';
import avatar4 from '../../../../assets/images/user/avatar-4.jpg';

import IconButton from "@material-ui/core/IconButton";
import GridOnIcon from "@material-ui/icons/GridOn";
import ReorderIcon from "@material-ui/icons/Reorder";

const NavRight = () => {
    const configContext = useContext(ConfigContext);
    //const { logout } = useAuth();
    const { rtlLayout } = configContext.state;
    var pInfo = {
        DisplayName:global.DisplayName,
        ClientCode: global.ClientCode
    };
    const [listOpen, setListOpen] = useState(false);

    const [displayCards, setDisplayCards] = React.useState(global.displayCards);//true
    const handleLogout = async () => {
        try {

            if (typeof global.handleLogout == "function")
                global.handleLogout();
            //handleClose();
            //await logout();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <React.Fragment>
            <ListGroup as='ul' bsPrefix=' ' className="navbar-nav ml-auto">
                <ListGroup.Item as='li' bsPrefix=' '>                   
                    <IconButton
                        color={displayCards ? "inherit" : "default"}
                        //className={classes.displayCardButton}
                        onClick={(e) => {
                            setDisplayCards(true)
                            global.setDisplayCards(true);
                        }}
                    >
                        <GridOnIcon></GridOnIcon>
                    </IconButton>
                    <IconButton
                        color={!displayCards ? "inherit" : "default"}
                        //className={classes.displayCardButton}
                        onClick={(e) => {
                            setDisplayCards(false)
                            global.setDisplayCards(false);
                        }}
                    >
                        <ReorderIcon></ReorderIcon>
                    </IconButton>
                </ListGroup.Item>
                {/* <ListGroup.Item as='li' bsPrefix=' '>
                    <Dropdown alignRight={false} >
                        <Dropdown.Toggle as={Link} variant='link' to='#' id="dropdown-basic">
                            <i className="feather icon-bell icon" />
                            <span className="badge badge-pill badge-danger"><span /></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="notification notification-scroll">
                            <div className="noti-head">
                                <h6 className="d-inline-block m-b-0">Notifications</h6>
                                <div className="float-right">
                                    <Link to='#' className="m-r-10">mark as read</Link>
                                    <Link to='#'>clear all</Link>
                                </div>
                            </div>
                            <PerfectScrollbar>
                                <ListGroup as='ul' bsPrefix=' ' variant="flush" className="noti-body">
                                    <ListGroup.Item as='li' bsPrefix=' ' className="n-title">
                                        <p className="m-b-0">NEW</p>
                                    </ListGroup.Item>
                                    <ListGroup.Item as='li' bsPrefix=' ' className="notification">
                                        <Media>
                                            <img className="img-radius" src={avatar1} alt="Generic placeholder" />
                                            <Media.Body>
                                                <p>
                                                    <strong>{pInfo.DisplayName}</strong>
                                                    <span className="n-time text-muted">
                                                        <i className="icon feather icon-clock m-r-10" />30 min
                                                    </span>
                                                </p>
                                                <p>New ticket Added</p>
                                            </Media.Body>
                                        </Media>
                                    </ListGroup.Item>
                                    <ListGroup.Item as='li' bsPrefix=' ' className="n-title">
                                        <p className="m-b-0">EARLIER</p>
                                    </ListGroup.Item>
                                    <ListGroup.Item as='li' bsPrefix=' ' className="notification">
                                        <Media>
                                            <img className="img-radius" src={avatar2} alt="Generic placeholder" />
                                            <Media.Body>
                                                <p>
                                                    <strong>Joseph William</strong>
                                                    <span className="n-time text-muted">
                                                        <i className="icon feather icon-clock m-r-10" />30 min
                                                    </span>
                                                </p>
                                                <p>Purchase New Theme and make payment</p>
                                            </Media.Body>
                                        </Media>
                                    </ListGroup.Item>
                                    <ListGroup.Item as='li' bsPrefix=' ' className="notification">
                                        <Media>
                                            <img className="img-radius" src={avatar3} alt="Generic placeholder" />
                                            <Media.Body>
                                                <p>
                                                    <strong>Sara Soudein</strong>
                                                    <span className="n-time text-muted">
                                                        <i className="icon feather icon-clock m-r-10" />30 min
                                                    </span>
                                                </p>
                                                <p>currently login</p>
                                            </Media.Body>
                                        </Media>
                                    </ListGroup.Item>
                                    <ListGroup.Item as='li' bsPrefix=' ' className="notification">
                                        <Media>
                                            <img className="img-radius" src={avatar4} alt="Generic placeholder" />
                                            <Media.Body>
                                                <p>
                                                    <strong>Suzen</strong>
                                                    <span className="n-time text-muted">
                                                        <i className="icon feather icon-clock m-r-10" />yesterday
                                                    </span>
                                                </p>
                                                <p>Purchase New Theme and make payment</p>
                                            </Media.Body>
                                        </Media>
                                    </ListGroup.Item>
                                </ListGroup>
                            </PerfectScrollbar>
                            <div className="noti-footer">
                                <Link to='#'>show all</Link>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </ListGroup.Item>
                <ListGroup.Item as='li' bsPrefix=' '>
                    <Dropdown>
                        <Dropdown.Toggle as={Link} variant='link' to='#' className="displayChatbox" onClick={() => setListOpen(true)}>
                            <i className="icon feather icon-mail" />
                            <span className="badge bg-success"><span /></span>
                        </Dropdown.Toggle>
                    </Dropdown>
                </ListGroup.Item> */}
                <ListGroup.Item as='li' bsPrefix=' '>
                    <Dropdown alignRight={false} className="drp-user">
                        <Dropdown.Toggle as={Link} variant='link' to='#' id="dropdown-basic">
                            {pInfo && pInfo.DisplayName && pInfo.DisplayName.toString().toUpperCase()} <img src={avatar1} className="img-radius wid-40" alt="User Profile" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="profile-notification">
                            <div className="pro-head">
                                <img src={avatar1} className="img-radius" alt="User Profile" />
                                <span>{pInfo.DisplayName}</span>
                                <Link to='#' className="dud-logout" title="Logout" onClick={handleLogout}>
                                    <i className="feather icon-log-out" />
                                </Link>
                            </div>
                            <ListGroup as='ul' bsPrefix=' ' variant='flush' className="pro-body">
                                <ListGroup.Item as='li' bsPrefix=' '>
                                    <i className="feather icon-feather" />Theme:
                                </ListGroup.Item>
                                <ListGroup.Item as='li' bsPrefix=' '>
                                    <BootswatchSelect version={'4.4.1'} selectedThemeName="minty" className="form-control" />
                                </ListGroup.Item>
                                <ListGroup.Item as='li' bsPrefix=' '><Link to='#' className="dropdown-item"><i className="feather icon-package" />Client: {pInfo.ClientCode}</Link></ListGroup.Item>
                                {/* <ListGroup.Item as='li' bsPrefix=' '><Link to='#' className="dropdown-item"><i className="feather icon-settings" />Settings</Link></ListGroup.Item> */}
                                <ListGroup.Item as='li' bsPrefix=' '><Link to='/adminInstance/27/2' className="dropdown-item"><i className="feather icon-user" />Profile</Link></ListGroup.Item>
                                {/* <ListGroup.Item as='li' bsPrefix=' '><Link to='#' className="dropdown-item"><i className="feather icon-mail" />My Messages</Link></ListGroup.Item> */}
                                {/* <ListGroup.Item as='li' bsPrefix=' '><Link to='#' className="dropdown-item"><i className="feather icon-lock" />Lock Screen</Link></ListGroup.Item> */}
                                <ListGroup.Item as='li' bsPrefix=' '><Link to='#' className="dropdown-item" onClick={handleLogout}><i className="feather icon-log-out" />Logout</Link></ListGroup.Item>
                            </ListGroup>
                        </Dropdown.Menu>
                    </Dropdown>
                </ListGroup.Item>
            </ListGroup>

            <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />
        </React.Fragment>
    );
};

export default NavRight;
