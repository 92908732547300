import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./edit";

import lookUpService from "./../../../../Services/lookupApi";
import DynamicGrid from "../../../Common/DynamicGrid"
import Button from "@material-ui/core/Button";


/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */


const QueryGridComp = class extends React.Component {
  constructor(props) {
    super(props);

    let data;
    try {
      //data = JSON.parse(props.component.jsonData);
      data = {}; //set default
    } catch (e) {
      data = {}; //set default
    }



    this.handleChange = this.handleChange.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);

    let value = props.value ? props.value : props.submissionData;
    this.state = {
      value: value,
      jsonData: value ? value.jsonData : data,
      headers: [],
      data: [],
      showDialog: false
    };


  }

  async componentDidMount() {
    this.mounted = true;
    this.getData();

  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async getData() {
    if (!Boolean(this.props.component.entityId > 0)) {
      return
    }

    const params = {
      entityId: this.props.component.entityId || 62,
      entityTypeId: this.props.component.entityTypeId || 9,
      frameworkId: this.props.component.frameworkId || 4,
      pentityId: this.props.component.pentityId || 312,
      pentityTypeId: this.props.component.pentityTypeId || 3,
      customGridKey: this.props.component.customGridKey,
      apikey: this.props.component.key,
    }
    const fulldata = await lookUpService.getQueryGridData(params);

    if (fulldata && this.mounted) {

      this.setState(
        {
          headers: [...fulldata.headers],
          data: [...fulldata.data]
        }
      )
    }
  }

  setValue = (jsonDataToSave) => {

    this.setState(
      (prevState) => ({
        value: {
          jsonData: jsonDataToSave,
        },
      }),
      // () => this.props.onChange(null,this.state.value)
      () => this.props.onChange(this.state.value)
    );
  };



  handleChange(e) {
    this.setState({
      searchText: e.target.value
    })
  }

  handleRefresh(e) {
    e.preventDefault();
    
    // this.props.FORMIO_ROOT._submission.data.adminContainer.loggedInUserRole = "overriden";
    // this.props.FORMIO_ROOT._submission.data.auditreporting.executivesummary = "executivesummary";
    // this.props.FORMIO_ROOT.triggerRedraw()
    this.getData();
  }



  render() {
    //const data = this.state.jsonData;
    //const { displayCards } = this.props;
    const tableStyle = {
      "borderWidth": "1px",
      'borderColor': "#aaaaaa",
      'borderStyle': 'solid',
      fontSize: 18
    }

    return (
      <div>
        {/* <div
          style={{ display: "flex", margin: 20, justifyContent: "space-between" }}
        >
        </div> */}
        <div className="pageHeaderPanel">
          <div className="page-header1">
            <div class="btn-toolbar justify-content-end col-xs-12">
              <div class="btn  btn-icon btn-outline-primary btnAction" onClick={this.handleRefresh} title="Refresh">
                <i class="fas fa-refresh"></i>
              </div>
            </div>
          </div>
          {
            this.state.headers.length > 0 &&
            <DynamicGrid
              Headers={this.state.headers}
              Data={this.state.data}
              DisplayCard={false}
              ShowName={false}

            ></DynamicGrid>
          }



        </div>
        {/* <p>EntityId - {this.props.component.entityId}</p>
        <p>EntityTypeId - {this.props.component.entityTypeId}</p>
        <p>FrameworkId - {this.props.component.frameworkId}</p>
        <p>ParentEntityId - {this.props.component.pentityId}</p>
        <p>ParentEntityTypeId - {this.props.component.pentityTypeId}</p> */}
      </div >
    );
  }
};

export default class QueryGrid extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "14QueryGrid",
      icon: "square",
      group: "basic",
      documentation: "",
      weight: -10,
      schema: QueryGrid.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "queryGrid",
      label: "Query Grid",
      logic: [
        {
          "name": "on Load",
          "trigger": {
            "type": "javascript",
            "javascript": "result= true;"
          },
          "actions": [
            {
              "name": "populateIds",
              "type": "mergeComponentSchema",
              //              "schemaDefinition": "schema = { myWidgetURI:data[data.apiKeyToPick] }"
              "schemaDefinition": "schema = {frameworkId:data.frameworkId, entityId:data.entityId,entityTypeId:data.entityTypeId,pentityId:data.pentityId, pentityTypeId:data.pentityTypeId }"
            }
          ]
        }
      ]
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    //console.log('this.dataForSetting',this.dataForSetting)
    // if (this.shouldSetValue) {
    //   //this.setValue(this.dataForSetting);
    //   this.updateValue(this.dataForSetting);
    // }

    return ReactDOM.render(
      <QueryGridComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        contextData={this._data}
        FORMIO_ROOT={this.root}
      />,
      element
    );
  }

  setValue(data) {
    console.log("Called wit data-", data);
    //hacked to rerender the react component by adding new prop //temp fix
    //hacked to rerender the react component by adding new prop submissionData//temp fix
    //TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO
    if (data && this.element) {
      ReactDOM.render(
        <QueryGridComp
          component={this.component} // These are the component settings if you want to use them to render the component.
          value={this.dataValue} // updatedvalue of the component.
          onChange={this.updateValue} // The onChange event to call when the value changes.
          submissionData={data}
          contextData={this._data}
          FORMIO_ROOT={this.root}
        />,
        this.element
      );
    }
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
