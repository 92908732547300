import React, { Component } from 'react';
import lookUpService from "./../../../Services/lookupApi";
import { Link } from "react-router-dom";
import { VerticalAlignTop } from '@material-ui/icons';


class SearchResults extends Component {
    constructor(props) {
        super(props);

        this.state = {
            entityTypes: [],
            entities: [

            ],
            selectedEntitypes: [

            ]
        }
        this.handleEntityTypeSelection = this.handleEntityTypeSelection.bind(this);
        this.handleNavigation = this.handleNavigation.bind(this);
    }
    componentDidMount() {
        const { searchText } = this.props.searchData
        this.getData(searchText);

    }
    componentDidUpdate(prevProps) {
        const { searchText } = this.props.searchData
        if (prevProps.searchData.searchText !== searchText) {
            //window.location.pathname
            this.getData(searchText);
        }

    }
    async getData(searchText) {
        const searchParams = {
            searchText: searchText,
            urlString: window.location.pathname
        }
        const data = await lookUpService.getMainSearchResults(searchParams);
        let entityTypessSelected = data.entityTypes
            .filter(x => x.selected)
            .map(x => x.entitytypeId_name)
        this.setState({
            entities: [...data.entities],
            entityTypes: [...data.entityTypes],
            selectedEntitypes: [...entityTypessSelected]
        })

    }

    handleNavigation(url) {
        if (typeof this.props.searchData.closeHandler === "function")
            this.props.searchData.closeHandler();
        this.props.history.push(url)
    }

    handleEntityTypeSelection(item) {

        item.selected = item.selected ? !item.selected : true;
        const selectedTypeId = item.entitytypeId_name;
        let currentEntities = this.state.selectedEntitypes;
        if (item.selected) {
            currentEntities.push(selectedTypeId)
        }
        else {
            currentEntities = currentEntities.filter(x => x !== selectedTypeId)
        }

        this.setState({
            entityTypes: [...this.state.entityTypes],
            selectedEntitypes: currentEntities
        });
    }

    render() {


        //const dataToLoad = value;
        return (

            <div className="App" key={(new Date()).getUTCMilliseconds()}>
               
               <div className='searchResult'>

                <h2>Results for  {this.props.searchData.searchText}</h2>
                {/* <div onClick={()=>this.handleNavigation('/editor')}> Editor</div> */}
                <ul className='resultEntity'>
                    {/* <ul> */}
                    {
                        this.state.entityTypes.length > 0 && this.state.entityTypes.map(x =>
                            <li key={x.entitytypeId_name}>
                                <>
                                    <input type='checkbox'
                                        checked={x.selected}
                                        onChange={() => {
                                            this.handleEntityTypeSelection(x);
                                        }}
                                    />
                                    {x.name}  ({x.count})</>
                            </li>
                        )
                    }
                    {/* </ul> */}
                  
                    </ul>
                    <h3>Entities</h3>  
                </div>
                   

                    <div key={(new Date()).getUTCMilliseconds()} className="table-responsive searchResultTable">

                    <table className="table table-sm table-bordered">
                        <thead>
                            <tr>
                                <th align="left">S.no</th>
                                <th align="left">Entity Type</th>
                                <th align="left">Reference</th>
                                <th align="left" className='nameResultTH'>Name</th>
                                <th align="left">Field Name</th>
                                <th align="left" className='nameResultTH'>Field Details</th>
                                <th align="left">Parent Type</th>
                                <th align="left">Path</th>
                            </tr>
                        </thead>
                        <tbody key={new Date()}>
                            {

                                this.state.entities
                                    .filter(x => this.state.selectedEntitypes.indexOf(x.entitytypeId_name) > -1)
                                    .map(x =>

                                        <tr key={`${this.props.searchData.searchText}_${x.entityname}`}>
                                            <td align="left">
                                                {x.sno}
                                            </td>
                                            <td align="left">
                                                {x.entitytypename}
                                            </td>
                                            <td align="left">
                                                {x.reference}
                                            </td>
                                            <td align="left">
                                                {/* <Link to={x.entity_navurl ? x.entity_navurl : '/'}> {x.name}</Link> */}
                                                {/* open url opens in new window. else in same window */}
                                                {x.entity_openurl && <a href={x.entity_openurl} target='_blank'>{x.entityname}</a>}
                                                {!x.entity_openurl && <div class="btn-link" onClick={() => this.handleNavigation(x.entity_navurl)}> {x.entityname}</div>
                                                }
                                            </td>

                                            <td align="left">
                                                {x.fieldname}
                                            </td>

                                            <td align="left" class='nameResultTD'>
                                                {x.fieldvalue}
                                            </td>
                                            <td align="left">
                                                {x.parentname}
                                            </td>
                                            <td align="left">
                                                {x.entity_path}
                                            </td>

                                        </tr>
                                    )
                            }
                        </tbody>
                    </table>
                    </div>
               
            </div>
        );
    }
}

export default SearchResults;