import React from "react";


import DBService from "../../Services/dexieForm";
import Service from "../../Services";


import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import Builder from "../RenderBuilder/index";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  root: {
    // padding:10
  },
});

class AdminUniverse extends React.Component {
  constructor(props) {
    super(props);
    console.log("props-", this.props);
    this.state = { components: [], display: "form", id: -100, name: "", createNew:true, schemaForDB:null }; //default

    this.handleChange = this.handleChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDisplayChange =this.handleDisplayChange.bind(this);
    
    
    this.handleOnUISchemaChange = this.handleOnUISchemaChange.bind(this);
    this.handleOnUIDisplayChange = this.handleOnUIDisplayChange.bind(this);
    this.handleJsonChange = this.handleJsonChange.bind(this);
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    console.log("id", params.id);

    //DBService.initialize();
    let item;
    if(params.id>-1)    
      item = await Service.getFormMetaList();
    console.log("item-", item);
    if (item && item.length>0) {
      this.setState({
        components: item[0].meta.components,
        display: item[0].meta.display,
        name: item[0].name,
        id: item[0].id,
        createNew:false
      });
    }
  }

  handleChange(schema) {
    console.log(schema);
    this.setState({ components: schema.components, display: schema.display });
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }
  handleDisplayChange(event) {
    this.setState({ display: event.target.value });
  }

  handleOnUISchemaChange(builder) {
    const {schema,dbSchema}= builder
    this.setState({
      components: schema.components,
      display: schema.display,
      meth: JSON.stringify(schema),
      schemaForDB:dbSchema
    });
  }

  handleOnUIDisplayChange(display) {
    this.setState({ display: display });
  }

  handleJsonChange(event) {
    let newJson = event.target.value;
    if (newJson) {
      let schema = JSON.parse(newJson);
      this.setState({
        components: schema.components,
        display: schema.display,
        meth: JSON.stringify(schema),        
      });
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    let params = {
      formJson: JSON.stringify({
        components: this.state.components,
        display: this.state.display,
      }),  
      schemaForDB:this.state.schemaForDB,    
      id: this.state.id,
    };
    
    //create new
    let resultId = await Service().saveAdminUniverse(params);
  }

  render() {
    const {classes} = this.props;
    return (
      <Grid
        container
        component="main"
        justify="center"
        component={Paper}
        className={classes.root}
      >
        {/* <CssBaseline />         */}
        <Grid item elevation={6} square style={{ flexGrow: 1 }}>
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Admin Universe
            </Typography>            
      <form onSubmit={this.handleSubmit}>       
        <div>
        <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="meth"
                label="meth"
                name="meth"
                autoComplete="meth"
                autoFocus
                value={this.state.meth}
                onChange={this.handleJsonChange}
                rows="10"
                multiline
              />
         <Builder
                Components={this.state.components}
                Display={this.state.display}
                OnChange={this.handleOnUISchemaChange}
                OnDisplayChange={this.handleOnUIDisplayChange}
              ></Builder>
          {/* <input type="submit" value="Submit Form" /> */}          
          <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Save
              </Button>
        </div>        
      </form>
      </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AdminUniverse);


