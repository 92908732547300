import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import NavLeft from "./NavLeft";
import NavRight from "./NavRight";

import { ConfigContext } from "../../../contexts/ConfigContext";
import logo from '../../../assets/appLogo.png';
import * as actionType from "../../../store/actions";

const NavBar = () => {
    const [moreToggle, setMoreToggle] = useState(false);
    const configContext = useContext(ConfigContext);
    const { collapseMenu, headerBackColor, headerFixedLayout, layout, subLayout } = configContext.state;
    const { dispatch } = configContext;

    let headerClass = ['navbar', 'pcoded-header', 'navbar-expand-lg', headerBackColor];
    if (headerFixedLayout && layout === 'vertical') {
        headerClass = [...headerClass, 'headerpos-fixed'];
    }

    let toggleClass = ['mobile-menu'];
    if (collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }

    const navToggleHandler = () => {
        dispatch({type: actionType.COLLAPSE_MENU})
    };

    let moreClass = ['mob-toggler'];
    let collapseClass = ['collapse navbar-collapse'];
    if (moreToggle) {
        moreClass = [...moreClass, 'on'];
        collapseClass = [...collapseClass, 'd-block']
    }
    const logoStyle = {
        width: '40%',
        height:"40%"
      };

    let navBar = (
        <React.Fragment>
            <div className="m-header">
                <div className={toggleClass.join(' ')} id="mobile-collapse" onClick={navToggleHandler}><span/></div>
                <Link to={`${global.defaultHomeUrl}`} className="b-brand">
                    
                <img id="main-logo" src={logo} alt="Home"  className='logo'/>
                <img id="main-logo" src={logo} alt="Home"  className='logo-thumb'/>
                    
                </Link>
                <div className={moreClass.join(' ')} onClick={(e) => setMoreToggle(!moreToggle)}>
                    <i className="feather icon-more-vertical"/>
                </div>
            </div>
            <div className={collapseClass.join(' ')}>
                <NavLeft/>
                <NavRight/>
            </div>
        </React.Fragment>
    );

    if (layout === 'horizontal' && subLayout === 'horizontal-2') {
        navBar = (
            <div className="container">
                {navBar}
            </div>
        );
    }

    return (
        <React.Fragment>
            <header className="navbar pcoded-header navbar-expand-lg header-info headerpos-fixed">
                {navBar}
            </header>
        </React.Fragment>
    );
};

export default NavBar;
