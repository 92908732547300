
import React from 'react';

export default class TextReplacePreview extends React.Component {
    render() {

        return <span><b>{this.props.textKey}
        </b></span>
    }
}
