import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./edit";

import lookUpService from "./../../../../Services/lookupApi";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Modal from './../../../Common/Modal';

import TablePagination from "@material-ui/core/TablePagination";
const jsonString = {
  values: [
    {
      name: "High",
      color: "red",
    },
    {
      name: "low",
      color: "green",
    },
  ],
};

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const LinkGridComp = class extends React.Component {
  constructor(props) {
    super(props);

    let data;
    try {
      //data = JSON.parse(props.component.jsonData);
      data = { items:[] }; //set default
    } catch (e) {
      data = { items:[] }; //set default
    }

    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    let value=props.submissionData ? props.submissionData : props.value;
    this.state = {
      value:value,
      jsonData:value?value.jsonData: data,
      searchText:"",
      columns:props.component.columns,
      showDialog:false
    };
  }

  setValue = (jsonDataToSave) => {
    
    this.setState(
      (prevState) => ({
        value: {
          jsonData: jsonDataToSave,
        },
      }),
      // () => this.props.onChange(null,this.state.value)
      () => this.props.onChange(this.state.value)
    );
  };

 
 
  handleSelect(item) {
    item.selected = item.selected ? !item.selected : true;
  }

  

  handleChange(e){
    this.setState({
      searchText:e.target.value
    })  
  }

 async handleSearch(e){
    e.preventDefault();      
    
    const searchParams={
      searchText:this.state.searchText,
      frameworkId:2,//this.props.component.cframeworkId,
      entityId:3,//this.props.component.entityId,
      entityTypeId:4,//this.props.component.entityTypeId,
      pentityId:5,//this.props.component.pentityId,
      pentityTypeId:6,//this.props.component.pentityTypeId,

    }
      const list=await lookUpService.getUsers(searchParams);
      let jsonData=this.state.jsonData;
      jsonData.items=list
      this.setState({
        jsonData,
      })  
  }

 

  render() {
    const data = this.state.jsonData;

    return (
      <div>
         
        
          <table>
            <thead>
              <tr>
              {/* <th>Select</th> */}
                {
                  this.state.columns && this.state.columns.filter(x=>x.visible).map((col, i)=>(
                     <th key={i}> {col.name} </th>
                  ))
                }
                
                <th> Actions </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.items &&
                data.items.map((item, index) => (
                  <tr key={index}>
                    {/* <td>
                      {" "}
                      <input                        
                        type="checkbox"
                        key={item.id}
                        checked={item.selected}
                        onChange={() => {
                          this.handleSelect(item);
                        }}
                      />{" "}
                    </td>                     */}
                    {
                       this.state.columns && this.state.columns.filter(x=>x.visible).map((col, i)=>(
                        <td key={i}> {item[col.dbfield]} </td>
                     ))
                    }
                    
                    <td> 
                    <Button variant="contained"  color="primary">
            WIP
          </Button>

                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        
     </div>
    );
  }
};

export default class LinkGrid extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "9 Table/Template",
      icon: "square",
      group: "basic",
      documentation: "",
      weight: -10,
      schema: LinkGrid.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "tableTemplate",
      label: "Table / Template"     
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    //console.log('this.dataForSetting',this.dataForSetting)
    // if (this.shouldSetValue) {
    //   //this.setValue(this.dataForSetting);
    //   this.updateValue(this.dataForSetting);
    // }
    return ReactDOM.render(
      <LinkGridComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }

  setValue(data) {
    console.log("Called wit data-", data);
    //hacked to rerender the react component by adding new prop //temp fix
    //hacked to rerender the react component by adding new prop submissionData//temp fix
    //TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO
    if (data && this.element) {
      ReactDOM.render(
        <LinkGridComp
          component={this.component} // These are the component settings if you want to use them to render the component.
          value={this.dataValue} // updatedvalue of the component.
          onChange={this.updateValue} // The onChange event to call when the value changes.
          submissionData={data}
        />,
        this.element
      );
    }
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
