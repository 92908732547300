import React from "react";
import * as dc from "dc";
import { scaleLinear } from "d3";
import * as d3 from "d3";
import { ChartTemplate } from "./chartTemplate";

var title ="Data";


var numFormat = d3.format(".2f");
const NumberChartFunc = (divRef, ndx,config,layout) => { 
  
  var groupKey = "gender";
  if(config)
  {
    groupKey = config.groupKey;
    title=config.title;
  }

  const chartDimension = ndx.dimension((d) => {return +(isNaN(d[groupKey])?0:d[groupKey]);}); 
  var summaryGroup = chartDimension.groupAll().reduce(
    function (p, v) {
        ++p.count;
        p.total += +(isNaN(v[groupKey])?0:v[groupKey]);
        p.avg = Math.round(p.total / p.number);
        return p;
    },
    function (p, v) {
        --p.count;
        p.total -= +(isNaN(v[groupKey])?0:v[groupKey]);
        p.avg = (p.number == 0) ? 0 : Math.round(p.total / p.number);
        return p;
    },
    function () {
        return {count: 0, total: 0, avg: 0}
    });
  //create a table container node and append to parent div as dc.datatable only creates thead and tbody elements
  var nContainer = document.createElement("div");
  nContainer.className = "container";
  nContainer.style.backgroundColor =  config.bgColorCode?config.bgColorCode:"white";
  nContainer.style.color=config.textColorCode?config.textColorCode:"black"; 
  nContainer.style.fontSize=config.fontSize?config.fontSize:"50px";
  // nContainer.style.textAlign="center";
  nContainer.style.height="100%";
  nContainer.style.width="calc(100%-5px)";
  divRef.appendChild(nContainer);

  //use table container as target to render datatable 
  var chart = dc.numberDisplay(nContainer);

  const pElement = divRef.parentElement.parentElement;

  var width = pElement.clientWidth,
    height = pElement.clientHeight;

  //prepare columns to represent keys, label will be used as colHeader
  var valAccessor = function(d) {
      return d.value.count;
  };
  
  if(config.aggregateType.toLowerCase()==='count'){
    valAccessor = function(d) {
        return d.count;
    };
  }
  else if(config.aggregateType.toLowerCase()==='sum'){
    valAccessor =function (d) { return d.total;};
  }
  else if(config.aggregateType.toLowerCase()==='avg'){
    valAccessor =function (d) { return d.avg;};
  }
  var numFormat = config.numberFormat?config.numberFormat:"d";
  chart
    .width(width)
    .height(height)
    .formatNumber(d3.format(numFormat))
    .valueAccessor(valAccessor)
    .group(summaryGroup);

  chart.render();
  return chart;

};

export const NumberChart = (props) => (
    <ChartTemplate chartFunction={NumberChartFunc} title={props.config.title} config={props.config} layout={props.layout}/>  
);

export default NumberChart;
