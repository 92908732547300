import baseEditForm from "formiojs/components/_classes/component/Component.form";



export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            // You can ignore existing fields.
            key: "placeholder",
            ignore: true,
          },         
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected EntityId",
            weight: 12,
            key: "entityId", // This will be available as component.myCustomSetting
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected EntityTypeId",
            weight: 12,
            key: "entityTypeId", // This will be available as component.myCustomSetting
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected FrameworkId",
            weight: 12,
            key: "frameworkId", // This will be available as component.cframeworkId
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected RegisterId",
            weight: 12,
            key: "registerId", // This will be available as component.stepItemId
          }         
        ],
      },

      {
        key: "data",
        components: [],
      },
      {
        key: "validation",
        components: [],
      },
      {
        key: "api",
        components: [],
      },
      {
        key: "conditional",
        components: [],
      },
      {
        key: "logic",
        components: [],
      },
    ],
    ...extend
  );
};
