import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
class BChartConfig extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleDataSourceSelection = this.handleDataSourceSelection.bind(this);
  }

  handleChange(val) { }

  handleTextChange(event) {
    let value = event.target.value;

    // this.setState({
    //     [event.target.name]: value,
    // });
    this.updateParent({
      [event.target.name]: value,
    });
  }

  updateParent(modifiedObj) {
    if (this.props.onConfigChange)
      this.props.onConfigChange(modifiedObj, this.props.id)
  }

  handleDataSourceSelection(event) {

    let objToModify = {
      entityId: -1,
      entityTypeId: -1,
      groupKey: ''
    }

    let value = event.target.value;
    const { dataSources } = this.props.metaData;
    const selectedDataSource = dataSources.find(x => x.entityId == value);

    if (selectedDataSource) {
      objToModify.entityId = selectedDataSource.entityId;
      objToModify.entityTypeId = selectedDataSource.entityTypeId;
    }
    this.updateParent(objToModify);
  }

  render() {
    const { title, groupKey, entityTypeId, entityId,
      labelAngle, labelMargin_x, labelMargin_y, chartType, aggregateType } = this.props.chartConfig;
    const {
      groupKeyObj,
      dataSources,
      aggregateFunctions
    } = this.props.metaData;


    let groupKeys = [];
    if (groupKeyObj[`${entityTypeId}_${entityId}`]) {
      groupKeys = groupKeyObj[`${entityTypeId}_${entityId}`];
    }
    const groupKeyColumnData = groupKeys.find(x => x.StepItemKey === groupKey);
    const knownDataTypes = ["number", "date"];

    let columnDatatype = groupKeyColumnData ? groupKeyColumnData.StepitemType : '';
    if (knownDataTypes.indexOf(columnDatatype) == -1) {
      columnDatatype = "default";
    }


    const aggreagetFunctionsToShow = aggregateFunctions[chartType][columnDatatype] || [];
    return (
      <>
        {/* Pie chart config - {config.chartType} */}
        <div className="dashboardForm">
          <div className="form-group">
            Title:{" "}
            <input
              style={{
                width: "100%", background: "#ffffff"
              }}
              variant="outlined"
              margin="normal"
              id="title"
              label="Bar chart title"
              name="title"
              autoComplete="title"
              className="form-control"
              autoFocus
              value={title}
              onChange={this.handleTextChange}
            />
          </div>

          <div className="row form-group">

            <div className="col-md-4">
              Data Sources:
              <select className="form-control"
                value={entityId} onChange={this.handleDataSourceSelection}
              >
                <option value="-1">Select</option>
                {dataSources.map(x =>
                  <option value={x.entityId} key={x.entityId}>
                    {x.displayName}
                  </option>
                )
                }
              </select>
            </div>

            <div className="col-md-4">
              Group Key:
              <select className="form-control"
                name="groupKey"
                value={groupKey} onChange={this.handleTextChange}
              >
                {groupKeys.map(x =>
                  <option value={x.StepItemKey} key={x.StepItemKey}>
                    {x.StepItemName}
                  </option>
                )
                }
              </select>
            </div>

            <div className="col-md-4">
              Aggregate by:
              <select className="form-control"
                name="aggregateType"
                value={aggregateType} onChange={this.handleTextChange}
              >
                <option value="-1">Select</option>
                {aggreagetFunctionsToShow.map(x =>
                  <option value={x.code} key={x.code}>
                    {x.name}
                  </option>
                )
                }
              </select>
            </div>
          </div>

          <div className="form-group">

            <div className="row">
              <div className="col-md-4" >
                Label Angle:
                <input
                  variant="outlined"
                  margin="normal"
                  id="labelAngle"
                  label="labelAngle"
                  className="form-control"
                  name="labelAngle"
                  autoComplete="labelAngle"
                  autoFocus
                  value={labelAngle}
                  onChange={this.handleTextChange}
                />
              </div>

              <div className="col-md-4" >
                Label Margin_x:
                <input
                  variant="outlined"
                  margin="normal"
                  id="labelMargin_x"
                  label="labelMargin_x"
                  name="labelMargin_x"
                  className="form-control"
                  autoComplete="labelMargin_x"
                  autoFocus
                  value={labelMargin_x}
                  onChange={this.handleTextChange}
                />
              </div>
              <div className="col-md-4" >
                labelMargin_y:
                <input
                  variant="outlined"
                  margin="normal"
                  className="form-control"
                  id="labelMargin_y"
                  label="label Margin_y"
                  name="labelMargin_y"
                  autoComplete="labelMargin_y"
                  autoFocus
                  value={labelMargin_y}
                  onChange={this.handleTextChange}
                />
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default BChartConfig;
