import React from "react";
//import DBService from "../../../Services/dexieForm";
import Service from "../../../Services";
import { Link } from "react-router-dom";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CustomList from "../../Common/List";
import { ENTITY_TYPES } from "../../../config/constant";

const styles = (theme) => ({
  table: {
    minWidth: 650,
  },
  cell: {
    fontSize: 12,
  },
  alignItems: {
    display: "flex",
    //width: 600,
    //"justify-content": "space-between",
    "padding-bottom": 20,
    flexDirection: "row",
    //flexWrap: "nowrap"
    justifyContent: "space-between",
  },
  formDiv: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
});

class AdminFormsList extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = this.props;
    this.state = {
      builderFormsList: [],
      entityTypeId: Number(params.entityTypeId),
      page: 0,
      rowsPerPage: 5,
      headers: []
    }; //default

    this.handleClick = this.handleClick.bind(this);
    this.handleClickNew = this.handleClickNew.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowsPerPageChange = this.handleRowsPerPageChange.bind(this);
  }

  //implement this to get fresh data when url param changed
  componentDidUpdate(prevProps) {

    const { match: { params: { entityTypeId } } } = this.props
    if (prevProps.match.params.entityTypeId !== entityTypeId) {
      this.setState({
        builderFormsList: [],
        entityTypeId: Number(entityTypeId),
        page: 0,
        rowsPerPage: 5,
        headers: []
      },
        () => { this.getData(0, this.state.rowsPerPage) }
      ); //default

    }
  }
  async handlePageChange(event, newPage) {
    await this.getData(newPage, this.state.rowsPerPage)
  }

  async handleRowsPerPageChange(event) {
    const noOfRows = parseInt(event.target.value, 10);
    //setRowsPerPage(parseInt(event.target.value, 10));
    //setPage(0);
    await this.getData(0, noOfRows);
  }

  async getData(page, rowsPerPage, searchText) {
    //DBService.initialize();
    let {data:list,headers=[]} = await Service().getEntitiesListByEntityTypeId({
      entityTypeId: this.state.entityTypeId,
    });

    if (Array.isArray(list))
      list = list.map((x) => {
        return {
          ...x,
          id: x.entityid,
          path: `/adminInstance/${this.state.entityTypeId}/${x.entityid}`,
          handleClick: (e) => {
            e.preventDefault();
            this.props.history.push(`/adminInstance/${this.state.entityTypeId}/${x.entityid}`);
          },
        };
      });

    
    if (this.state.entityTypeId === 14) {
      headers = [
        {
          colId: 'frameworkname',
          colName: 'Framework'
        }, {
          colId: 'workflowparametername',
          colName: 'workflow Parameter Name'
        },
        {
          colId: 'status',
          colName: 'status'
        }
      ]
    }

    this.setState({ builderFormsList: list || [], headers: headers, page: page, rowsPerPage: rowsPerPage });
  }

  async componentDidMount() {
    await this.getData(0, this.state.rowsPerPage);
  }

  handleClick(item) {
    console.log("clicked Item - ", item);
    this.props.history.push(`/adminInstance/${this.state.entityTypeId}/${item.id}`);
  }
  handleClickNew(e) {
    e.preventDefault()
    this.props.history.push(`/adminInstance/${this.state.entityTypeId}/-1`);
  }

  render() {
    const list = this.state.builderFormsList;
    const { classes, displayCards } = this.props;
    const ENTITYINFO = ENTITY_TYPES[this.state.entityTypeId] || {};

    return (
      <div className="pageHeaderPanel">
        <div className="page-header1">
          <h5 key={ENTITYINFO.name}>{ENTITYINFO.name} List</h5>
          <div className="btn-toolbar justify-content-end ">
            <div className="btn-toolbar justify-content-end ">
              <Link className="btn  btn-icon btn-outline-secondary btnAction"
                to={`/navbar`} title="Back">
                <i className="fas fa-arrow-left"></i>
              </Link>&nbsp;
              <Link  className="btn  btn-icon btn-outline-primary btnAction" title="Add"
              to={`/adminInstance/${this.state.entityTypeId}/-1`}
              >
                <i className="fas fa-plus"></i>
              </Link>
            </div>
          </div>
        </div>
        <CustomList Headers={this.state.headers} Data={list} DisplayCard={displayCards}
          Pagination={{
            count: list.length,//this.state.totalRecordsCount,
            page: this.state.page,
            rowsPerPage: this.state.rowsPerPage,
            handleChangePage: this.handlePageChange,
            handleChangeRowsPerPage: this.handleRowsPerPageChange
          }}
        ></CustomList>
        {list.length == 0 &&
          <div className="tablePanel-body noPanelBody">
            <h2>No Admin Forms</h2>
          </div>
        }
      </div>
    );
  }
}

export default withStyles(styles)(AdminFormsList);
