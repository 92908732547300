import baseEditForm from "formiojs/components/_classes/component/Component.form";

const defaultVal1 = {
  assigned: [
    {
      name: "Harry",
    },
    {
      name: "Kate",
    },
  ],
  unassigned:[
    {
      name: "Macarena",
    },
    {
      name: "James",
    },
    {
      name: "Pote",
    },
    {
      name: "Bigil",
    },
  ]
};

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            // You can ignore existing fields.
            key: "placeholder",
            ignore: true,
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "My Custom Setting",
            weight: 12,
            key: "myCustomSetting", // This will be available as component.myCustomSetting
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "JSON Values",
            weight: 12,
            key: "jsonData", // This will be available as component.myCustomSetting
            defaultValue: JSON.stringify(defaultVal1),
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "select",
            input: true,
            label: "My Select",
            weight: 12,
            key: "myselect", // This will be available as component.myCustomSetting
            data: {
              values: ["val1", "val2"],
            },
          },
        ],
      },
      {
        key: "data",
        components: [],
      },
      {
        key: "validation",
        components: [],
      },
      {
        key: "api",
        components: [],
      },
      {
        key: "conditional",
        components: [],
      },
      {
        key: "logic",
        components: [],
      },
    ],
    ...extend
  );
};
