import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./customTreeSelection.settingsForm";
import CustomTreeComp from "../../../Common/CustomTreeView"

export default class customTreeSelection extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Tree Based Mapper",
      icon: "indent",
      group: "basic",
      documentation: "",
      weight: -10,
      schema: customTreeSelection.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "customTreeSelection",
      label: "Tree Based Mapper",
      logic: [
        {
          "name": "on Load",
          "trigger": {
            "type": "javascript",
            "javascript": "result= true;"
          },
          "actions": [
            {
              "name": "populateIds",
              "type": "mergeComponentSchema",
//              "schemaDefinition": "schema = { myWidgetURI:data[data.apiKeyToPick] }"
                "schemaDefinition": "schema = {frameworkId:data.frameworkId, entityId:data.entityId,entityTypeId:data.entityTypeId,pentityId:data.pentityId, pentityTypeId:data.pentityTypeId }"
            },
            {
              "name": "populateMetaData",
              "type": "customAction",
              "customAction": "data['+component.key+']=value"                      
            }
          ]
        }
      ]
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <CustomTreeComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        contextData={this._data}
      />,
      element
    );
  }
  setValue(data) {
    //hacked to rerender the react component by adding new prop //temp fix
    //hacked to rerender the react component by adding new prop submissionData//temp fix
    //TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO
    if (data && this.element) {    
      ReactDOM.render(
        <CustomTreeComp
          component={this.Component} // These are the component settings if you want to use them to render the component.
          value={this.dataValue} // updatedvalue of the component.
          onChange={this.updateValue} // The onChange event to call when the value changes.
          submissionData={data}
        />,
        this.element
      );
    }
  }
  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
