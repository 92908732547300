import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            // You can ignore existing fields.
            key: "placeholder",
            ignore: true,
          },
          // {
          //   // Or add your own. The syntax is form.io component definitions.
          //   type: "textfield",
          //   input: true,
          //   label: "Button Name",
          //   weight: 12,
          //   key: "buttonName", // This will be available as component.myCustomSetting
          // },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Custom Grid Key",
            weight: 12,
            key: "customGridKey", // This will be available as component.myCustomSetting
          },
          {
            type: "textfield",
            input: true,
            label: "Select Button Label",
            weight: 12,
            defaultValue:"Select",
            key: "selectLabel",
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textarea",
            input: true,
            label: "Column Mapping Json",
            weight: 12,
            key: "mappingJson", // This will be available as component.myCustomSetting
            rows:10
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected EntityId",
            weight: 12,
            key: "entityId", // This will be available as component.myCustomSetting
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected EntityTypeId",
            weight: 12,
            key: "entityTypeId", // This will be available as component.myCustomSetting
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected FrameworkId",
            weight: 12,
            key: "frameworkId", // This will be available as component.cframeworkId
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected Parent EntityId",
            weight: 12,
            key: "pentityId", // This will be available as component.entityId
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected Parent EntityTypeId",
            weight: 12,
            key: "pentityTypeId",
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected Link Id",
            weight: 12,
            key: "linkId",
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected link FrameworkId",
            weight: 12,
            key: "linkFrameworkId",
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected linkApiKey",
            weight: 12,
            key: "linkApiKey",
          },
          {
            type: "checkbox",
            input: true,
            label: "Allow Radio button Selection",
            weight: 12,
            defaultValue:true,
            key: "AllowRadioSelection", // This will be available as component
          }, 
          {
            type: "checkbox",
            input: true,
            label: "Allow Checkbox Selection",
            weight: 12,
            defaultValue:false,
            key: "AllowCheckboxSelection", // This will be available as component
          },  
          // {
          //   // Or add your own. The syntax is form.io component definitions.
          //   type: "textfield",
          //   input: true,
          //   label: "Join multiselection with",
          //   weight: 12,
          //   defaultValue:",",
          //   key: "joinMultiSelectionWith",
          // },
          {
            "label": "Column Mapping",
            "labelPosition": "top",
            "description": "",
            "tooltip": "",
            "disableAddingRemovingRows": false,
            "conditionalAddButton": "",
            "reorder": false,
            "addAnother": "",
            "addAnotherPosition": "bottom",
            "defaultOpen": false,
            "layoutFixed": false,
            "enableRowGroups": false,
            "initEmpty": false,
            "customClass": "",
            "tabindex": "",
            "hidden": false,
            "hideLabel": false,
            "autofocus": false,
            "disabled": false,
            "tableView": false,
            "modalEdit": false,
            "defaultValue": [],
            "persistent": true,
            "protected": false,
            "dbIndex": false,
            "encrypted": false,
            "redrawOn": "",
            "clearOnHide": true,
            "customDefaultValue": "",
            "calculateValue": "",
            "calculateServer": false,
            "allowCalculateOverride": false,
            "validateOn": "change",
            "validate": {
              "required": false,
              "minLength": "",
              "maxLength": "",
              "customMessage": "",
              "custom": "",
              "customPrivate": false,
              "json": "",
              "strictDateValidation": false,
              "multiple": false,
              "unique": false
            },
            "unique": false,
            "errorLabel": "",
            "key": "gridColumnMapping",
            "tags": [],
            "properties": {},
            "conditional": { "show": null, "when": null, "eq": "", "json": "" },
            "customConditional": "",
            "logic": [],
            "attributes": {},
            "overlay": {
              "style": "",
              "page": "",
              "left": "",
              "top": "",
              "width": "",
              "height": ""
            },
            "type": "datagrid",
            "input": true,
            "placeholder": "",
            "prefix": "",
            "suffix": "",
            "multiple": false,
            "refreshOn": "",
            "widget": null,
            "showCharCount": false,
            "showWordCount": false,
            "allowMultipleMasks": false,
            "tree": true,
            "components": [
              {
                "label": "Grid Column",
                "labelPosition": "top",
                "placeholder": "",
                "description": "",
                "tooltip": "",
                "prefix": "",
                "suffix": "",
                "widget": { "type": "input" },
                "inputMask": "",
                "allowMultipleMasks": false,
                "customClass": "",
                "tabindex": "",
                "autocomplete": "",
                "hidden": false,
                "hideLabel": false,
                "showWordCount": false,
                "showCharCount": false,
                "mask": false,
                "autofocus": false,
                "spellcheck": true,
                "disabled": false,
                "tableView": true,
                "modalEdit": false,
                "multiple": false,
                "persistent": true,
                "inputFormat": "plain",
                "protected": false,
                "dbIndex": false,
                "case": "",
                "encrypted": false,
                "redrawOn": "",
                "clearOnHide": true,
                "customDefaultValue": "",
                "calculateValue": "",
                "calculateServer": false,
                "allowCalculateOverride": false,
                "validateOn": "change",
                "validate": {
                  "required": false,
                  "pattern": "",
                  "customMessage": "",
                  "custom": "",
                  "customPrivate": false,
                  "json": "",
                  "minLength": "",
                  "maxLength": "",
                  "strictDateValidation": false,
                  "multiple": false,
                  "unique": false
                },
                "unique": false,
                "errorLabel": "",
                "key": "gridColumn",
                "tags": [],
                "properties": {},
                "conditional": { "show": null, "when": null, "eq": "", "json": "" },
                "customConditional": "",
                "logic": [],
                "attributes": {},
                "overlay": {
                  "style": "",
                  "page": "",
                  "left": "",
                  "top": "",
                  "width": "",
                  "height": ""
                },
                "type": "textfield",
                "input": true,
                "refreshOn": "",
                "inputType": "text",
                "id": "exwzka0000000000",
                "defaultValue": ""
              },
              {
                "label": "FormIo Path To Map",
                "labelPosition": "top",
                "placeholder": "",
                "description": "",
                "tooltip": "",
                "prefix": "",
                "suffix": "",
                "widget": { "type": "input" },
                "inputMask": "",
                "allowMultipleMasks": false,
                "customClass": "",
                "tabindex": "",
                "autocomplete": "",
                "hidden": false,
                "hideLabel": false,
                "showWordCount": false,
                "showCharCount": false,
                "mask": false,
                "autofocus": false,
                "spellcheck": true,
                "disabled": false,
                "tableView": true,
                "modalEdit": false,
                "multiple": false,
                "persistent": true,
                "inputFormat": "plain",
                "protected": false,
                "dbIndex": false,
                "case": "",
                "encrypted": false,
                "redrawOn": "",
                "clearOnHide": true,
                "customDefaultValue": "",
                "calculateValue": "",
                "calculateServer": false,
                "allowCalculateOverride": false,
                "validateOn": "change",
                "validate": {
                  "required": false,
                  "pattern": "",
                  "customMessage": "",
                  "custom": "",
                  "customPrivate": false,
                  "json": "",
                  "minLength": "",
                  "maxLength": "",
                  "strictDateValidation": false,
                  "multiple": false,
                  "unique": false
                },
                "unique": false,
                "errorLabel": "",
                "key": "mappingPath",
                "tags": [],
                "properties": {},
                "conditional": { "show": null, "when": null, "eq": "", "json": "" },
                "customConditional": "",
                "logic": [],
                "attributes": {},
                "overlay": {
                  "style": "",
                  "page": "",
                  "left": "",
                  "top": "",
                  "width": "",
                  "height": ""
                },
                "type": "textfield",
                "input": true,
                "refreshOn": "",
                "inputType": "text",
                "id": "euwbtw000000",
                "defaultValue": ""
              },
              {
                // Or add your own. The syntax is form.io component definitions.
                type: "textfield",
                input: true,
                label: "Join multiselection with",
                weight: 12,
                defaultValue:",",
                key: "joinMultiSelectionWith",
              },
            ]
          }   
         
        ],
      },

      {
        key: "data",
        components: [],
      },
      {
        key: "validation",
        components: [],
      },
      {
        key: "api",
        components: [],
      },
      {
        key: "conditional",
        components: [],
      },
      {
        key: "logic",
        components: [],
      },
    ],
    ...extend
  );
};
