import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./edit";

//import {browserHistory} from "react-router";

import lookUpService from "./../../../../Services/lookupApi";
import Button from "@material-ui/core/Button";
import Modal from './../../../Common/Modal';
import { thresholdFreedmanDiaconis } from "d3";


/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */

const MessageTypes = {
  WARNING: 1,
  INFORMATION: 2
}


const WorkflowInstanceComp = class extends React.Component {
  constructor(props) {
    super(props);

    let data;
    try {
      //data = JSON.parse(props.component.jsonData);
      data = {}; //set default
    } catch (e) {
      data = {}; //set default
    }



    this.handleChange = this.handleChange.bind(this);
    this.handleDialog = this.handleDialog.bind(this);
    this.handleWorkflow = this.handleWorkflow.bind(this);

    let value = props.value ? props.value : props.submissionData;
    this.state = {
      value: value,
      jsonData: value ? value.jsonData : data,
      currentState: {
        name: '',
        state: 0
      },
      transitions: [
      ],
      selectedTransition: {
        msg: '',
        name: '',
        messageType: MessageTypes.WARNING
      },
      showDialog: false
    };


  }

  async componentDidMount() {


    //this.setValue({ a: 1 })
    // if (!Boolean((Number(this.props.component.registerId) > 0)))
    //   return

    const params = {
      entityId: this.props.component.entityId || 2,
      entityTypeId: this.props.component.entityTypeId || 3,
      frameworkId: this.props.component.frameworkId || 5,
      registerId: this.props.component.registerId || 6
    }
    const workflowData = await lookUpService.getEntityWorkflowData(params);
    if (workflowData) {
      this.setState(
        {
          currentState: workflowData.currentState,
          transitions: workflowData.transitions,
        }
      )
    }

  }


  setValue = (jsonDataToSave) => {

    this.setState(
      (prevState) => ({
        value: {
          jsonData: jsonDataToSave,
        },
      }),
      // () => this.props.onChange(null,this.state.value)
      () => this.props.onChange(this.state.value)
    );
  };



  handleChange(e) {
    this.setState({
      searchText: e.target.value
    })
  }

  handleDialog(openState) {
    this.setState({ showDialog: openState })
  }

  handleWorkflow(workflowObj) {
    this.setState({
      selectedTransition: {
        ...this.state.selectedTransition,
        name: workflowObj.name
      }
    })
    this.handleDialog(true)
  }

  render() {
    const data = this.state.jsonData;

    const tableStyle = {
      "borderWidth": "1px",
      'borderColor': "#aaaaaa",
      'borderStyle': 'solid',
      fontSize: 18
    }

    return (
      <div>
        <div
          style={{ display: "flex", margin: 20, justifyContent: "space-between" }}
        >
          <div className="m-b-10"><h5>
            {"Workflow"}
          </h5>
            Current state : <h6>{this.state.currentState.name}</h6>
          </div>

        </div>
        <div>
          {
            this.state.transitions.map(x => {
              //return <Button color="primary" variant="contained" type="submit">
              return <Button key={x.name} color="primary" variant="contained" onClick={() => { this.handleWorkflow(x) }} >
                handleWorkflow
                {x.name}
              </Button>
            })
          }
          {this.state.showDialog && (<Modal closeModal={() => this.handleDialog(false)}>
            <div>
              <h3> Message for button {this.state.selectedTransition.name} </h3>
              {
                this.state.selectedTransition.messageType === MessageTypes.INFORMATION && (
                  <Button color="primary" variant="contained" type="submit" value={this.state.selectedTransition.name} >
                    OK
                  </Button>
                )
              }
              {this.state.selectedTransition.messageType !== MessageTypes.INFORMATION && (
                <>
                  <Button color="primary" variant="contained" type="submit" value={this.state.selectedTransition.name} >
                    Approve
                  </Button>

                  <Button color="primary" variant="contained"
                   value={this.state.selectedTransition.name} 
                   onClick={()=>{this.handleDialog(false)}}
                   >
                    Reject
                  </Button></>
              )
              }

            </div>

          </Modal>)}

        </div>
        <p>EntityId - {this.props.component.entityId}</p>
        <p>EntityTypeId - {this.props.component.entityTypeId}</p>
        <p>FrameworkId - {this.props.component.frameworkId}</p>
        <p>registerId - {this.props.component.registerId}</p>




      </div>
    );
  }
};


export default class WorkflowInstance extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "9WorkflowInstance",
      icon: "square",
      group: "basic",
      documentation: "",
      weight: -10,
      schema: WorkflowInstance.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "workflowInstance",
      label: "Workflow Instance",
      logic: [
        {
          "name": "on Load",
          "trigger": {
            "type": "javascript",
            "javascript": "result= true;"
          },
          "actions": [
            {
              "name": "populateIds",
              "type": "mergeComponentSchema",
              //              "schemaDefinition": "schema = { myWidgetURI:data[data.apiKeyToPick] }"
              "schemaDefinition": "schema = { entityId:data.entityId, entityTypeId:data.entityTypeId, frameworkId:data.frameworkId, registerId:data.pentityTypeId }"
            }
          ]
        }
      ]
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    //console.log('this.dataForSetting',this.dataForSetting)
    // if (this.shouldSetValue) {
    //   //this.setValue(this.dataForSetting);
    //   this.updateValue(this.dataForSetting);
    // }
    return ReactDOM.render(
      <WorkflowInstanceComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }

  setValue(data) {
    console.log("Called wit data-", data);
    //hacked to rerender the react component by adding new prop //temp fix
    //hacked to rerender the react component by adding new prop submissionData//temp fix
    //TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO
    if (data && this.element) {
      ReactDOM.render(
        <WorkflowInstanceComp
          component={this.component} // These are the component settings if you want to use them to render the component.
          value={this.dataValue} // updatedvalue of the component.
          onChange={this.updateValue} // The onChange event to call when the value changes.
          submissionData={data}
        />,
        this.element
      );
    }
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
