import React from "react";
//import ReactDOM from 'react-dom';
import RenderForm from "./../../RenderForm";
import DBService from "../../../Services/dexieForm";
import { Link } from "react-router-dom";


class SubmissionForm extends React.Component {
  constructor(props) {
    super(props);
    console.log("props-", this.props);

    const {
      match: { params },
    } = this.props;

    this.submissionDataTemp = {};
    this.state = {
      components: [],
      display:"form",
      submission: this.submissionDataTemp,
      id: Number(params.id),
      metaId: params.metaId,
      registerId: params.registerId,
      name: "form Name",
    }; //default

    this.handleChange = this.handleChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    DBService.initialize();
   
    const item = await DBService.getFramework(this.state.metaId);
    console.log("item-", item);
    if (item) {
      const meth=JSON.parse(item.meth)
      this.setState({
        components: meth.components,
        display: meth.display,
      });
    } else {
      //not a valid methFormId
      console.log("not a valid methFormId");
      this.props.history.replace(`/buildList`);
      return;
    }
    if (this.state.id > 0) {
      const submissionData = await DBService.getRegisterInstanceData(this.state.id);
      console.log("submissionData-", submissionData);
      if (submissionData) {
        this.submissionDataTemp = {
          data: submissionData.data,
        };


        this.setState({
          submission: this.submissionDataTemp,
          name: submissionData.name,
        });
      } else {
        //not a valid formId
        console.log("not a valid formId");
        this.props.history.replace(`formList/${this.state.metaId}`);
        return;
      }
    }
  }

  handleChange(submission) {
    if (submission.changed) {
      console.log(submission);
      //--------------------------------------->//
      //todo this is workaround as setstate is causing infinite rerender for submission
      //. submission has to move to state later
      this.submissionDataTemp = submission;
      //const submissionData = submission.data;
      //this.setState({ submission: submission });
    }
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();
    console.log("A form was submitted: " + JSON.stringify(this.state));

    DBService.initialize();

    const id = this.state.id;

    if (id === -100) {
      //add
      const newId = await DBService.addRegisterInstanceData({
        name: this.state.name,
        data: this.submissionDataTemp.data,
        metaId: this.state.metaId,
        registerId: this.state.registerId
      });
      console.log("newId", newId);
      //this.props.history.push(`/build/${newId}`);
      this.props.history.push(`/registerInstance/${this.state.registerId}`);
    } else {
      //update
      const updated = await DBService.updateRegisterInstanceData(id, {
        name: this.state.name,
        data: this.submissionDataTemp.data,
      });
      console.log("updated", updated);
    }
  }

  render() {
    console.log("rendered", new Date());
    return (
      <div className="dynamicForm">
        <form onSubmit={this.handleSubmit}>
          <div className="formMain">
            {/* <label>
              Form Name:
              <input
                type="text"
                value={this.state.name}
                placeholder="Name"
                required
                onChange={this.handleNameChange}
              />
            </label> */}
            <input type="submit" value="Save" />
            <Link to={`/registerInstance/${this.state.registerId}`}>Back to Instance List</Link>
          </div>
          <div>
          <RenderForm Components={this.state.components} Display={this.state.display} Submission={this.state.submission}
                OnChange={this.handleChange}
                ></RenderForm>
          </div>
        </form>
      </div>
    );
  }
}

export default SubmissionForm;
