import React, { Component } from "react";
import lookUpService from "./../../../Services/lookupApi";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Modal from '../Modal'

const ContactListComp = class extends React.Component {
    constructor(props) {
        super(props);

        let data;
        try {
            //data = JSON.parse(props.component.jsonData);
            data = { unassigned: [], assigned: [] }; //set default
        } catch (e) {
            data = { unassigned: [], assigned: [] }; //set default
        }

        this.handleAssign = this.handleAssign.bind(this);
        this.handleUnAssign = this.handleUnAssign.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleRole = this.handleRole.bind(this)
        this.handleNotify = this.handleNotify.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        let value = props.value ? props.value : props.submissionData;
        this.state = {
            value: value,
            jsonData: value ? value.jsonData : data,
            searchText: "",
            columns: props.component.columns,
            showDialog: false,
            roles: [{ name: "Developer", id: 1 }, { name: "AppUser", id: 2 }, { name: "Select", id: -1 }]
        };
    }

    setValue = (jsonDataToSave) => {

        this.setState(
            (prevState) => ({
                value: {
                    jsonData: jsonDataToSave,
                },
            }),
            // () => this.props.onChange(null,this.state.value)
            () => {
                let dataToSave = {
                    jsonData: {
                        source: 'fromUI',//to know whether contact data porperly loaded or not
                        assigned: this.state.jsonData.assigned
                    }
                }
                this.props.onChange(dataToSave)
            }
        );
    };

    async componentDidMount() {
        if (this.props.component.entityTypeId > -1) {

            const lookUps = await lookUpService.getFrameworkRoleList({
                entityId: this.props.component.pentityId,
                entityTypeId: this.props.component.entityTypeId
            });

            if (!this.props.value) {//formio maintains the value irrespective of comp rendered
                //get and bind the data only when the initial value is not present
                //initial value will be populated when the component rerenders on tab changes

                const searchParams = {
                    searchText: this.state.searchText,
                    frameworkId: this.props.component.frameworkId || -1,
                    entityId: this.props.component.entityId || -1,
                    entityTypeId: this.props.component.entityTypeId || -1,
                    pentityId: this.props.component.pentityId || -1,
                    pentityTypeId: this.props.component.pentityTypeId || -1,
                    isSearch: 0
                }

                const list = await lookUpService.getContactsForEntityWithoutPermissions(searchParams);
                let jsonData = this.state.jsonData;
                //set the assigned here
                jsonData.assigned = list.map(x => {
                    x.dbSaved = true
                    return x;
                })

                this.setState({
                    roles: lookUps,
                    jsonData
                })
                this.setValue(jsonData);
            }
            else {
                this.setState({
                    roles: lookUps
                })
            }


        }
    }

    handleAssign(e) {
        e.preventDefault()
        console.log(this.refs);
        var data = this.state.jsonData;
        var selected = data.unassigned.filter((x) => x.selected)
        data.unassigned = data.unassigned.filter((x) => !x.selected);
        selected = selected.map(x => {
            delete x.selected;
            x.AssignedNow = true;//to keep track of what items are added newly
            return x;
        });

        let jsonDataToSave = {
            assigned: [...data.assigned, ...selected],
            unassigned: [...data.unassigned]
        }
        this.setState({
            jsonData: jsonDataToSave
        });
        this.setValue(jsonDataToSave);
    }

    handleUnAssign(e) {
        e.preventDefault();
        console.log(this.refs);

        var data = this.state.jsonData;
        if (!data.unassigned) {//adding not to break- need to optimize here
            data.unassigned = []
        }
        var selected = data.assigned.filter((x) => x.selected);
        data.assigned = data.assigned.filter((x) => !x.selected);
        selected = selected.map(x => { delete x.selected; return x; });
        let jsonDataToSave = {
            unassigned: [...data.unassigned, ...selected],
            assigned: [...data.assigned],
        }

        this.setState({
            jsonData: jsonDataToSave
        });
        this.setValue(jsonDataToSave);
    }
    handleSelect(item) {
        item.selected = item.selected ? !item.selected : true;
    }
    handleRole(item, e) {
        e.preventDefault();
        item.role = e.target.value
        //var data = this.state.jsonData;

        this.setState({
            jsonData: { ...this.state.jsonData }
        });
        this.setValue(this.state.jsonData);

    }

    handleDelete(item, e) {
        e.preventDefault();

        var data = this.state.jsonData;
        if (!data.unassigned) {//adding not to break- need to optimize here
            data.unassigned = []
        }

        data.assigned = data.assigned.filter((x) => x.id !== item.id);

        let jsonDataToSave = {
            unassigned: [...data.unassigned, item],
            assigned: data.assigned,
        }

        this.setState({
            jsonData: jsonDataToSave
        });
        this.setValue(jsonDataToSave);
    }

    handleNotify(item) {
        item.notify = item.notify ? !item.notify : true;
        this.setState({
            jsonData: { ...this.state.jsonData }
        });
        this.setValue(this.state.jsonData);
    }

    handleChange(e) {
        this.setState({
            searchText: e.target.value
        })
    }

    async handleSearch(e) {
        e.preventDefault();

        const searchParams = {
            searchText: this.state.searchText,
            frameworkId: this.props.component.frameworkId || -1,
            entityId: this.props.component.entityId || -1,
            entityTypeId: this.props.component.entityTypeId || -1,
            pentityId: this.props.component.pentityId || -1,
            pentityTypeId: this.props.component.pentityTypeId || -1,
            isSearch: 1
        }

        const list = await lookUpService.getContactsForEntityWithoutPermissions(searchParams);
        let jsonData = this.state.jsonData;
        let unAssignedDBSavedRecords = []
        if (jsonData.unassigned && Array.isArray(jsonData.unassigned))
            unAssignedDBSavedRecords = jsonData.unassigned.filter(x => x.dbSaved)
        jsonData.unassigned = [...unAssignedDBSavedRecords, ...list.map(x => { return x; })]
        this.setState({
            jsonData,
        })
    }

    handleDialog(openState) {
        this.setState({ showDialog: openState })
    }

    render() {
        const data = this.state.jsonData;
        const disableComponent = this.props.component.disabled;
        const tableStyle = {
            "borderWidth": "1px",
            'borderColor': "#aaaaaa",
            'borderStyle': 'solid',
            fontSize: 18
        }

        return (
            <div>
                <div className="btn-toolbar justify-content-end">
                    <div className="dt-buttons btn-group float-right">
                        <Button onClick={() =>
                            this.handleDialog(true)
                        }
                            disabled={disableComponent}
                            className="btn btn-icon btn-outline-secondary btnAction pull-right" title="Link Contact">
                            <i className="fa fa-id-card" aria-hidden="true"></i>
                        </Button>
                    </div>
                </div>
                {/* <p>EntityId - {this.props.component.entityId}</p>
            <p>EntityTypeId - {this.props.component.entityTypeId}</p>
            <p>frameworkId - {this.props.component.frameworkId}</p> */}
                <div className="table-responsive">
                    <table className="table table-hover m-b-0">
                        <thead>
                            <tr>
                                <th >Actions</th>
                                <th > Name </th>
                                <th > Role </th>
                                <th > Notify </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data &&
                                data.assigned &&
                                data.assigned.map((item, i) => (
                                    <tr key={i}>
                                        <td >
                                            {/* {" "}
                        <input                        
                          type="checkbox"
                          key={item.id}
                          checked={item.selected}
                          onChange={() => {
                            this.handleSelect(item);
                          }}
                        />{" "} */}
                                            <button
                                                disabled={disableComponent}
                                                className="btn btn-icon btn-outline-secondary btnAction" onClick={(e) => this.handleDelete(item, e)}>
                                                <i className="fa fa-trash" aria-hidden="true"

                                                ></i>
                                            </button>
                                        </td>
                                        <td > {item.name} </td>
                                        <td >
                                            <select
                                                disabled={disableComponent}
                                                className="form-control" value={item.role} onChange={(e) => {
                                                    this.handleRole(item, e);
                                                }}>
                                                {this.state.roles.map(x =>
                                                    <option value={x.id} key={x.id}>
                                                        {x.name}
                                                    </option>
                                                )
                                                }
                                            </select>
                                        </td>
                                        <td >
                                            <input
                                                disabled={disableComponent}
                                                type="checkbox"
                                                key={item.id}
                                                checked={item.notify}
                                                onChange={() => {
                                                    this.handleNotify(item);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                {this.state.showDialog && (<Modal closeModal={() => this.handleDialog(false)}>
                    <div>
                        <div className="">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="input-group mb-3">
                                        <input id="standard-basic" type="text" value={this.state.searchText} onChange={this.handleChange}
                                            onKeyDownCapture={(e) => { if (e.key === "Enter") { this.handleSearch(e); } }}
                                            className="form-control" placeholder={this.state.searchText} aria-label={this.state.searchText} aria-describedby="basic-addon2" />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" onClick={this.handleSearch} type="button"><i className="fa fa-search" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {data &&

                                <div className="row">
                                    <div className="col-md-5">
                                        <table className="table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th >Available</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data &&
                                                    data.unassigned &&
                                                    data.unassigned.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                <input
                                                                    key={`unassigned ${i}`}
                                                                    type="checkbox"
                                                                    key={item.id}
                                                                    onChange={() => {
                                                                        this.handleSelect(item);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td > {item.name} </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-2 align-self-start text-center arrowLeftRight"><br /><br />
                                        <input type="button" onClick={this.handleAssign} value=">" /><br /><br />
                                        <input type="button" onClick={this.handleUnAssign} value="<" />
                                    </div>
                                    <div className="col-md-5">
                                        <table className="table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th>Assigned</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data &&
                                                    data.assigned &&
                                                    data.assigned.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                {" "}
                                                                <input
                                                                    type="checkbox"
                                                                    key={item.id}
                                                                    checked={item.selected}
                                                                    onChange={() => {
                                                                        this.handleSelect(item);
                                                                    }}
                                                                />{" "}
                                                            </td>
                                                            <td > {item.name} </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }

                        </div>

                    </div>

                </Modal>)}
            </div>
        );
    }
};

export default ContactListComp