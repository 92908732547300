import React from 'react';
import ReactDOM from 'react-dom';

import ProductPreview from './Custom/ProductPreview'
import TextReplacePreview from './Custom/TextReplacePreview'
import LinkReplacePreview from './Custom/LinkReplacePreview'
//import ClassicEditor from 'ckeditor5-custom-build';

//import ClassicEditor from './../../../../../public/libs/ckeditor/ckeditor'

//We can configure the list of toolbar items needed here
// Editor configuration.
const defaultConfig = {
    toolbar: {
        items: [
            'Alignment',
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'imageUpload',
            'blockQuote',
            'insertTable',
            'mediaEmbed',
            'undo',
            'redo',
            'sourceEditing',
            'Code',
            'CodeBlock',
            //'reactPlugin'
        ]
    },
    language: 'en',
    image: {
        toolbar: [
            'imageTextAlternative',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side'
        ]
    },
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells'
        ]
    },
    // The configuration of the Products plugin. It specifies a function that will allow
    // the editor to render a React <ProductPreview> component inside a product widget.
    products: {
        productRenderer: (id, domElement, additionalData) => {
            //console.log(this.props)
            //const product = this.props.products.find(product => product.id === id);
            let product = {
                id
            }
            if (additionalData) {
                product = {
                    ...product,
                    ...additionalData
                }
            }

            ReactDOM.render(
                <ProductPreview id={id} {...product} />,
                domElement
            );
        }
    },
    custom:{
		textReplaceRenderer: (textKey, domElement) => {
			//const product = this.props.products.find(product => product.id === id);
			
			ReactDOM.render(
				<TextReplacePreview textKey={textKey} />,
				domElement
			);
		},
        linkReplaceRenderer:(props, domElement) => {
			
			ReactDOM.render(
				<LinkReplacePreview textKey={props.textKey} linkKey={props.linkKey} />,
				domElement
			);
		}
	}
};

export default defaultConfig;
