import React, { Component } from "react";
import Service from "../../../Services";
import Button from "@material-ui/core/Button";

import Modal from './../Modal';
import TextField from "@material-ui/core/TextField";
import ContactListComp from "./../ContactList"

const SaveView = class extends React.Component {
    constructor(props) {
        super(props);


        this.handleDialog = this.handleDialog.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleViewType= this.handleViewType.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);        
        this.state = {
            showDialog: props.showDialog,
            name:this.props.viewName,
            viewType:1,
            dragId:-1,          
        };

    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.viewName !== this.state.name) {
          this.setState({ name: nextProps.viewName });
        }
      }



    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleViewType(e) {

        this.setState({
            viewType:e.target.checked? 1:2
        })
    }

 
    async handleDialog(openState) {
        this.setState({ showDialog: openState })
    }

    async handleSubmit(event) {

        const params = {
            viewName: this.state.name,
            EntityTypeId: this.props.entityParams.entityTypeId,
            EntityId: this.props.entityParams.id,
            ParentEntityTypeId: this.props.entityParams.pentityTypeId,
            ParentEntityId: this.props.entityParams.pid,
            viewId:this.props.entityParams.viewId,
            viewType: this.state.viewType,
            filtersData:{
                matchCondition:this.props.filtersData.matchCondition,
                filters: this.props.filtersData.filters,
                currentUser: this.props.filtersData.currentUser,
                topRecords: this.props.filtersData.topRecords,
                orderByColumn: this.props.filtersData.orderByColumn,
                sortBy: this.props.filtersData.sortBy,
            },
            columns: this.props.columns.map((x) => {
                return {
                    colName: x.colName,
                    colId: x.colId,
                    isSelected: x.isSelected,
                    orderid: x.orderid
                }
            })
        }
        console.log(params);
        let resultId = await Service().saveCustomView({dataJSON:JSON.stringify(params)});
        this.handleDialog(false)
    }

    render() {


        return (
            <div>
                <div className="btn  btn-icon btn-outline-primary btnAction" title="Save View">
                    <div  onClick={() => this.handleDialog(true)} ><i className="fa fa-eye" ></i></div>                   
                </div>
                {this.state.showDialog && (<Modal closeModal={() => this.handleDialog(false)} SaveData={this.handleSubmit} ShowSaveButton="true">
                    <div className="row input-group mb-3 input-group-sm">
                        <div className="col-sm-6 form-row">
                            <input type="text" className="form-control"
                                required
                                id="name"
                                placeholder="Register Name"
                                name="name"
                                autoComplete="name"
                                autoFocus
                                value={this.state.name}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label class = "checkbox-inline">
                                <input type = "checkbox" checked={this.state.viewType===1?true:false}
                                                                    onChange={this.handleViewType} /> Generic View
                            </label>  
                        </div>
                        {/* <div className="col-sm-2">                         
                            <input type="button" onClick={this.handleSubmit} type="button" className="btn btn-outline-secondary" value="Save" />
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-sm-12">                            
                            <ContactListComp component={{columns:[]}}
                            onChange={()=>{}}
                            ></ContactListComp>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-sm-12">                            
                            <h5>Column Chooser</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="container">                          
                            {Array.isArray(this.props.columns) &&
                                this.props.columns
									.sort((a, b) => a.orderid - b.orderid)
									.map(col => {
                                    return <div draggable={true}
		                            onDragOver={(ev) => ev.preventDefault()}
		                            onDragStart={this.handleDrag}
		                            onDrop={this.handleDrop}
		                            id={col.colId} className="border-top"
		                            >
										<div className="row input-group input-group-sm">
	                                         <label className = "checkbox-inline">
	                                            <input type = "checkbox" key={`${col.colId}-${col.isSelected}`}
                                                disabled={true}
	                                                        checked={col.isSelected}
	                                                         />  {col.orderid} - {col.colName}
                                                            
	                                        </label>                                          
	                                    </div> 

									</div>   
                                })

                            }              
                        </div>
                    </div> */}

                   

                </Modal>)}

            </div>
        );
    }
};

export default SaveView