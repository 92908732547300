import baseEditForm from "formiojs/components/_classes/component/Component.form";


export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            // You can ignore existing fields.
            key: "placeholder",
            ignore: true,
          },
          {
            type: "checkbox",
            input: true,
            label: "Hide Single box Color Select",
            key: "hideSingleSelectColor",
            defaultValue: true,
          }
        ],
      },

      {
        key: "data",
        components: [],
      },
      {
        key: "validation",
        components: [],
      },
      {
        key: "api",
        components: [],
      },
      {
        key: "conditional",
        components: [],
      },
      {
        key: "logic",
        components: [],
      },
    ],
    ...extend
  );
};
