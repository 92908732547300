import React from 'react';
import './modal.css';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";

const Modal = (props) => {
  const { closeModal,ShowSaveButton,SaveData,title,footerActions } = props;


  return (
    <div className="overlay" >
        <div className="content w-75 mw-100"> 
          <div className="row">
              <div className="modal-title col-md-8"><h5>{props.title}</h5></div>
            <div className="col-md-4">
                <div className="dt-buttons  float-right">
                  <Button  onClick={SaveData} hidden={!ShowSaveButton} type="button" className="btn  btn-icon btn-outline-primary btnAction">
                    <i className="fas fa-save" aria-hidden="true"></i>
                  </Button>
                  <Button  onClick={closeModal} type="button" className="btn  btn-icon btn-outline-secondary btnAction">
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </Button>
                </div>
            </div>
          </div>
          <div className="row">
              <div className="col-md-12 modal-body ">
                {props.children}
              </div>
            </div>
          {props.footerActions!=null&&props.footerActions.length>0 && <div className="row">
            <div class="col-md-12">
              <div class="">                          
                {props.footerActions.map((action, idx) => (
                  <div className={action.className} onClick={action.callBack} title={action.title?action.title:action.Name}>{action.Name}</div>
                ))}
              </div>
            </div>
          </div> }
        </div>
    </div>
  );
};


export default Modal;