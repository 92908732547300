import React from "react";
import * as dc from "dc";
import { scaleLinear } from "d3";
import * as d3 from "d3";
import { ChartTemplate } from "./chartTemplate";
import helperFunctions from "./Helper";

var title = "Bar Chart";

function remove_empty_bins(source_group) {
  return {
    all: function () {
      return source_group.all().filter(function (d) {
        return d.value != 0;
      });
    }
  };
}
function combine_groups(groups) {
  return {
    all: function () {
      var parts = Object.keys(groups).map(function (gk) {
        return groups[gk].all().map(function (kv) {
          return { key: [gk, kv.key], value: kv.value };
        })
      });
      return Array.prototype.concat.apply([], parts);
    }
  };
}

function combine_groups_old() {
  var groups = Array.prototype.slice.call(arguments);
  return {
    all: function () {
      var alls = groups.map(function (g) { return g.all(); });
      var gm = {};
      alls.forEach(function (a, i) {
        a.forEach(function (b) {
          if (!gm[b.key]) {
            gm[b.key] = new Array(groups.length);
            for (var j = 0; j < groups.length; ++j)
              gm[b.key][j] = 0;
          }
          gm[b.key][i] = b.value;
        });
      });
      var ret = [];
      for (var k in gm)
        ret.push({ key: k, value: gm[k] });
      return ret;
    }
  };
}

var numFormat = d3.format(".0f");
const barChartFunc = (divRef, ndx, config, layout) => {

  const mycrossfilter = ndx;
  var xLabel = "Category";
  var yLabel = config.aggregateType || "Count";
  var groupKey = "gender";
  if (config) {
    groupKey = config.groupKey;
    title = config.title;
  }
  xLabel = groupKey.toUpperCase();
  yLabel = yLabel.toUpperCase();

  const chartDimension = ndx.dimension((d) => { return d[groupKey] ? d[groupKey] : "N.A."; });
  //var chartGroup = chartDimension.group().reduceCount((d) => { return d[groupKey] ? d[groupKey] : "N.A."; });

  var chartGroup = chartDimension.group().reduce(
    /* callback for when data is added to the current filter results */
    function (p, v) {
      var xAxisLookup = [];//**need to know what this does
      helperFunctions.getColumnValueForDimensionGroup(config, "x", xLabel, v, xAxisLookup, p);
      helperFunctions.updateAggregateValues(p, v, config, true);
      return p;
    },
    /* callback for when data is removed from the current filter results */
    function (p, v) {
      helperFunctions.updateAggregateValues(p, v, config, false);
      return p;
    },
    /* initialize p */
    function () {
      return helperFunctions.intializeValues();
    });


  var stackKey = config.stackKey ? config.stackKey : groupKey;

  var stackGroup = ndx.dimension((d) => { return d[stackKey] ? d[stackKey] : "N.A."; }).group().reduceCount((d) => { return d[stackKey] ? d[stackKey] : "N.A."; });


  var chartGroup_filtered_group = remove_empty_bins(chartGroup);
  var stackGroup_filtered_group = remove_empty_bins(stackGroup);


  var combined = combine_groups({ main: chartGroup_filtered_group, stack: stackGroup_filtered_group });

  var chart = dc.barChart(divRef);

  var uniqueKeys = chartGroup.top(Infinity).map(function (d) { return d.key; });

  const pElement = divRef.parentElement.parentElement;

  var width = pElement.clientWidth,
    height = pElement.clientHeight;

  pElement.addEventListener('resize', (event) =>
    console.log(event.detail)
  );

  var legendFunc;
  if (config.legendPosition == "horizondal") {
    height = height * 0.6;
    width = width * 0.9;
    legendFunc = dc.legend().horizontal(true).x(50).y(height + 50); //horizontal legends
  }
  else {
    width = width * 0.6;
    height = height * 0.8;
    legendFunc = dc.legend().x(width + 10).y(10).itemHeight(13).gap(5); //vertical legends  

  }
  var aggregateFunctionKeyForValues = config.aggregateType ? config.aggregateType.replace(config.aggregateType[0], config.aggregateType[0].toUpperCase()) : "Count"
  
  //Status  - Closed (Green - #FF5733), In-Progress (Yellow - #FFE173), Open (Amber - #FFBF00), Overdue (Red -#F9584B)
  const NullReplacer = "N.A.";
  var colorScale= d3.scaleOrdinal()
  .domain(["Closed","In-progress","Open","Overdue"])
  .range(["#79DE79","#FFE68F","#FFBF00","#FB6962"])

  chart
    .width(width)
    .height(height)
    .margins({ top: 10, left: 30, right: 0, bottom: 80 })
    .dimension(chartDimension)
    .group(chartGroup, groupKey, function (d) {
      console.log(d);
      return d.value[aggregateFunctionKeyForValues]

    })
    .x(d3.scaleOrdinal().domain(uniqueKeys))
    .x(d3.scaleBand())
    //.data(chartGroup)
    .xUnits(dc.units.ordinal)
    .keyAccessor(function (d) { return d.key; })
    .gap(20)
    // .stack(combined,stackKey, function(d) { return d.value; }) 
    //.colors(d3.scaleOrdinal().domain(uniqueKeys).range(d3.schemeSet3))
    .colors(colorScale)
    .colorAccessor(function (d) { return d.key; })
    .legend(legendFunc)
    .brushOn(false)
    .renderLabel(true)
    .xAxisLabel(xLabel)
    .yAxisLabel(yLabel)
    
    .title(function (d) {
      return d.key + ":" + numFormat(d.value[aggregateFunctionKeyForValues]) + "\n";
    }
    )
    .elasticX(true);
  // .xAxis().ticks(2);


  chart.legendables = function () {
    return chartGroup.all().sort((a, b) => a.key - b.key)
      .map(function (kv) {
        return {
          chart: chart,
          name: kv.key,
          color: chart.colors()(kv.key)
        };
      }
      )
  };
  var labelAngle = 0, labelMargin_x = "1em", labelMargin_y = "1em";

  if (config.labelAngle) labelAngle = config.labelAngle;
  if (config.labelMargin_x) labelMargin_x = config.labelMargin_x;
  if (config.labelMargin_y) labelMargin_y = config.labelMargin_y;

  chart.on("postRender", function (chart) {
    chart.select('.axis.x')
      .attr("text-anchor", "end")
      .selectAll("text")
      .attr("transform", "rotate(" + labelAngle + ")")
      .attr("dx", labelMargin_x)
      .attr("dy", labelMargin_y);
  });


  chart.render();
  return chart;
};
export const BChart = (props) => (
  <ChartTemplate chartFunction={barChartFunc} title={props.config.title} config={props.config} layout={props.layout} />
);

export default BChart;
