import React from "react";
//import ReactDOM from 'react-dom';
import { Form } from "react-formio";
import DBService from "../../../Services/dexieForm";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";

import Paper from "@material-ui/core/Paper";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import RenderForm from "./../../RenderForm";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 600,
    //padding:10
    width: "100%",
    flexGrow: 1,
  },
  root: {
    flexGrow: 1,
    margin: "auto",
    padding: 20,
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "space-between",
    width: "100%",
  },
  container: {
    display: "flex",
    flexGrow: 2,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    //border: "1px solid red",
  },
  item: {
    flexGrow: 1,
  },
  button: {
    marginLeft: 30,
  },
});

class UniverseForm extends React.Component {
  constructor(props) {
    super(props);
    console.log("props-", this.props);

    const {
      match: { params },
    } = this.props;

    this.submissionDataTemp = {};
    this.state = {
      components: [],
      submission: this.submissionDataTemp,
      id: Number(params.id),
      metaId: 1,
      name: "",
    }; //default

    this.handleChange = this.handleChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    DBService.initialize();

    const item = await DBService.getFormMeta(this.state.metaId);
    console.log("item-", item);
    if (item) {
      this.setState({
        components: item.meta.components,
      });
    } else {
      //not a valid methFormId
      console.log("not a valid methFormId");
      alert("Not yet defined");
      this.props.history.replace(`/universeList`);
      return;
    }
    if (this.state.id > 0) {
      const submissionData = await DBService.getFormData(this.state.id);
      console.log("submissionData-", submissionData);
      if (submissionData) {
        this.submissionDataTemp = {
          data: submissionData.data,
        };

        this.setState({
          submission: this.submissionDataTemp,
          name: submissionData.name,
        });
      } else {
        //not a valid formId
        console.log("not a valid formId");
        this.props.history.replace(`universeList/${this.state.metaId}`);
        return;
      }
    }
  }

  handleChange(submission) {
    if (submission.changed) {
      console.log(submission);
      //--------------------------------------->//
      //todo this is workaround as setstate is causing infinite rerender for submission
      //. submission has to move to state later
      this.submissionDataTemp = submission;
      //const submissionData = submission.data;
      //this.setState({ submission: submission });
    }
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();
    console.log("A form was submitted: " + JSON.stringify(this.state));

    DBService.initialize();

    const id = this.state.id;

    if (id === -100) {
      //add
      const newId = await DBService.addFormData({
        name: this.state.name,
        data: this.submissionDataTemp.data,
        metaId: this.state.metaId,
      });
      console.log("newId", newId);
      //this.props.history.push(`/build/${newId}`);
      this.props.history.push(`/universeList`);
    } else {
      //update
      const updated = await DBService.updateFormData(id, {
        name: this.state.name,
        data: this.submissionDataTemp.data,
      });
      console.log("updated", updated);
    }
  }

  render() {
    console.log("rendered", new Date());
    const { classes } = this.props;

    return (
      <Grid
        container
        component="main"
        //justify="center"
        component={Paper}
        className={classes.root}
      >
        {/* <CssBaseline />         */}
        <Grid elevation={6} square>
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Universe
            </Typography>
            <form onSubmit={this.handleSubmit} className={classes.formControl}>
              <div className={classes.item}>
                <div className={classes.container}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    //fullWidth
                    id="name"
                    label="name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    value={this.state.name}
                    onChange={this.handleNameChange}
                  />
                  {/* <input type="submit" value="Save Form" /> */}
                  <div>
                    <Link to={`/universeList`}> Universe List</Link>
                    <Button
                      type="submit"
                      //fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
              <div>
                <RenderForm Components={this.state.components} Submission={this.state.submission}
                OnChange={this.handleChange}
                ></RenderForm>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(UniverseForm);
