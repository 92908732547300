import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ColorPicker from "./../ColorPicker"
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: "auto",
        padding: 20,
        width: "97%",
        justifyContent: "space-between",
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        verticalAlign: "center",
        color: theme.palette.text.secondary,
        backgroundColor: "#349feb",
        height: 80,
    },
    item2: {
        padding: theme.spacing(1),
        textAlign: "center",
        verticalAlign: "center",
        color: theme.palette.text.secondary,
        margin: 10,
        //backgroundColor: "#11ff33",
        //height: 80,
    },
    viewButtonLabel: { textTransform: "none", color: "white" },
    table: {
        minWidth: 650,
    },
    cell: {
        fontSize: 12,
    },
    formDiv: {
        margin: theme.spacing(4, 4),
        display: "flex",
        flexDirection: "column",
    },
}));

export default function GridColors(props
) {
    // {
    //     Rows: rows = 2,
    //     Columns: columns = 2
    // }=props
    const classes = useStyles();
    const { Rows, Columns, Data, onChange } = props;;
    let squares = [...Array(Number(props.Rows))].map(x => Array(Number(props.Columns)).fill({}))

    //const [grids, setGrids] = useState([...Array(Number(props.Rows))].map(x => Array(Number(props.Columns)).fill({})))
    const [grids, setGrids] = useState([...Data])
    const [gridItem, setGridItem] = useState(null);

    useEffect(() => {

        let data = [...Data];
        let rows = Number(props.Rows);
        let columns = Number(props.Columns)
        if (rows > 0 && columns > 0 &&
            (data.length != rows ||
                data[0].length != columns
            )) {
            data = [...Array(Number(props.Rows))].map((x, index) => {
                return [...Array(Number(props.Columns)).fill({}).map((y, yindex) => {
                    return {
                        color: 'yellow',
                        index: `${index}_${yindex}`
                    }
                }
                )]
            }
            )
        }
        setGrids(data);
    }, [props.Rows, props.Columns, props.Data])


    const handleGridItemColorChange = (item, color) => {
        //setGridItem(item)
        item.color = color;
        setGrids([...grids]);
        if (typeof onChange == 'function') {
            onChange([...grids])
        }
    }

    const handleGridItemSelect = (item, e) => {
        //setGridItem(item)
        item.selected = e.target.checked;
        setGrids([...grids]);
    }


    const handleColorChange = (color) => {
        grids.map(row => {
            row.map(col => {
                if (col.selected) {
                    col.color = color;
                    delete col.selected;
                }
            })
        })
        //setGrids(grids)
        if (typeof onChange == 'function') {
            onChange([...grids])
        }
    }


    return (
        <div className={classes.root}>
            <Grid container spacing={3} justify="flex-start" key={Rows + '-' + Columns}>
                {grids.map((r, i) => {

                    return <p>
                        {/* <input type="checkbox" /> */}
                        {r.map((c, j) => {
                            return <div key={`${i}_${j}_${c.color}}`} style={{ border: `1px solid red`, backgroundColor: 'white' }}
                            // onClick={(e) => { e.preventDefault(); handleGridItemSelect(c) }}
                            >
                                {i + "-" + j}
                                <input type="checkbox" checked={c.selected} onChange={(e) => { handleGridItemSelect(c, e) }} />
                                <ColorPicker key={`c_${i}_${j}_${c.color}}`} color={c.color} onChange={(colorSelected) => handleGridItemColorChange(c, colorSelected)}></ColorPicker>
                            </div>
                        })}
                    </p>
                })

                }
            </Grid>
            <ColorPicker color="blue" onChange={handleColorChange}></ColorPicker>
        </div>
    );
}
