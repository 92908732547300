import React, { Component } from "react";
import * as dc from "dc";
import * as d3 from "d3";
import { ChartTemplate } from "./chartTemplate";

var title = "Yearly Fluctuation [P]";

const PieChartFunc = (divRef, ndx, config) => {

  const mycrossfilter = ndx;
  var groupKey = "gender";
  if (config) {
    groupKey = config.groupKey;
    title = config.title;
  }
  // const dimension = ndx.dimension(d=> d[compareKey2] > d[compareKey1] ? 'Loss' : 'Gain')
  // const group = dimension.group();

  const groupDimension = mycrossfilter.dimension((d) => { return d[groupKey]; });
  const groupGroup = groupDimension.group();
  const all = ndx.groupAll();

  const chart = new dc.PieChart(divRef);
  const pElement = divRef.parentElement.parentElement;
  //taking the least of width/heaight * 0.8
  const sizeToSet = (pElement.clientWidth > pElement.clientHeight ? pElement.clientHeight : pElement.clientWidth) * 0.8;
  const width = sizeToSet, height = sizeToSet;
  const diameter = sizeToSet;
  const NullReplacer = "N.A.";

  chart.width(width).height(height).radius(diameter * 0.45);

  //High (Red - #F9584B), Medium (Yellow - #FFE173), Low (Green - #FF5733)

  var colorScale= d3.scaleOrdinal()
    .domain(["Low","Medium","High"])
    .range(["#79DE79","#FFE68F","#FB6962"])
  var color = d3.scaleOrdinal(d3.schemeSet3);

  chart
    .dimension(groupDimension)
    .group(groupGroup)
    // .colors(d3.scale.category20b())//.colorAccessor(d => d.key)
    //.colors(d3.schemeCategory10)
    //.colorAccessor(function (d, i){return i;})
    // .colors(["#008e15", "#3fe256", "#eef200", "#f19d00", "#d7191c"])
    //.colors(d3.scaleOrdinal().domain([9, 8, 7, 6, 5, 4, 3, 2, 1]).range(d3.schemeSet3))
    //High (Red), Medium (Yellow), Low (Green
    //Closed (Green), In-Progress (Yellow), Open (Amber), Overdue (Red)
    
    //.colors(d3.scaleOrdinal().domain(uniqueKeys).range(d3.schemeSet3))
    .colors(colorScale)
    .colorAccessor(function(d){ 
     // return colorScale.domain().indexOf(d.key); 
      return d.key
    }
      )
    // .colorAccessor(function (d) { 
    //   //console.log(d);
    //   //return "green";
    //   return colorScale(d.key)
    //  })
    .label(function (d) {
      var label = d.key ? d.key : NullReplacer;
      if (all.value()) {
        label += ' (' + d.value + ')';
      }
      return label;
    })
    .renderLabel(true)
    .transitionDuration(500);

  if (!config.hideLegend) {
    chart
      .legend(dc.legend()
        .x((diameter * 1.1))
        .y(50)
        .gap(10)
        //.autoItemWidth(true)
        .legendText(function (legend) { return legend.name ? legend.name : NullReplacer; })
      );

    chart.render();
  }
  return chart;
};
export const PieChart = (props) => (
  <ChartTemplate chartFunction={PieChartFunc} title={props.config.title} config={props.config} />
);

export default PieChart;
