import React, { useContext, useEffect, useRef } from 'react';

import Navigation from "./Navigation";
import NavBar from "./NavBar";
import Breadcrumb from "./Breadcrumb";
import Configuration from "./Configuration";

import useWindowSize from "../../hooks/useWindowSize";
import useOutsideClick from "../../hooks/useOutsideClick";
import { ConfigContext } from "../../contexts/ConfigContext";
import * as actionType from "../../store/actions";
import SearchResults from "../../components/Common/SearchResults"
import CustomBreadCrumb from "../../components/Common/CustomBreadCrumb"

const AdminLayout = ({ children }) => {
    const formLogin = false;
    const windowSize = useWindowSize();
    const ref = useRef();
    const configContext = useContext(ConfigContext);
    const auth = children.props.isAuthenticated;
    const { collapseMenu, layout, subLayout, headerFixedLayout, showSearchResults, searchText } = configContext.state;
    const { dispatch } = configContext;


    useOutsideClick(ref, () => {
        if (collapseMenu) {
            dispatch({ type: actionType.COLLAPSE_MENU });
        }
    });

    useEffect(() => {
        if (windowSize.width > 992 && windowSize.width <= 1024 && layout !== 'horizontal') {
            dispatch({ type: actionType.COLLAPSE_MENU });
        }

        if (layout === 'horizontal' && windowSize.width < 992) {
            dispatch({ type: actionType.CHANGE_LAYOUT, layout: 'vertical' });
        }
    }, [dispatch, layout, windowSize]);

    const mobileOutClickHandler = () => {
        if (windowSize.width < 992 && collapseMenu) {
            dispatch({ type: actionType.COLLAPSE_MENU });
        }
    };

    let mainClass = ['pcoded-wrapper'];
    if (layout === 'horizontal' && subLayout === 'horizontal-2') {
        mainClass = [...mainClass, 'container'];
    }

    let common = (
        <React.Fragment>
            <Navigation />
            <NavBar />
        </React.Fragment>
    );

    if (windowSize.width < 992) {
        let outSideClass = ['nav-outside'];
        if (collapseMenu) {
            outSideClass = [...outSideClass, 'mob-backdrop'];
        }
        if (headerFixedLayout) {
            outSideClass = [...outSideClass, 'mob-fixed'];
        }

        common = (
            <div className={outSideClass.join(' ')} ref={ref}>
                {common}
            </div>
        );
    }

    return (
        <>
            {!formLogin &&
                (<React.Fragment>
                    {common}
                    <div className={configContext.state.layout=="vertical"?"pcoded-main-container":"pcoded-main-container verticalMenu"} onClick={() => mobileOutClickHandler}>
                        <div className={mainClass.join(' ')}>
                            <div className="pcoded-content">
                                <div className="pcoded-inner-content">
                                    {showSearchResults &&
                                        <div>
                                            {/* <h1>Search Results Placeholders</h1> */}
                                            <SearchResults
                                                searchData={
                                                    {
                                                        searchText: searchText,
                                                        closeHandler: () => {
                                                            dispatch({ type: actionType.SEARCH_CLOSED });
                                                        }
                                                    }
                                                }
                                                {
                                                ...children.props
                                                }
                                            ></SearchResults>
                                        </div>}
                                    <Breadcrumb />

                                    <CustomBreadCrumb locationUrl={window.location.pathname}
                                        {
                                        ...children.props
                                        }
                                    ></CustomBreadCrumb>

                                    {
                                        !showSearchResults &&
                                        <>
                                            {children}
                                        </>
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                    <Configuration />
                </React.Fragment >)
            }
            {
                formLogin && (
                    <>
                        {children}
                        <Configuration />
                    </>
                )
            }
        </>
    );
};

export default AdminLayout;
