import { Form } from 'react-formio'
import { downloadFile, uploadFile } from '../../Services/fileManager'
class FileService {
    async uploadFile(storage, file, fileName, dir, evt, url, options, fileKey) {
        console.log(storage, file, fileName, dir, evt, url, options, "upload file");
        
    return new Promise((resolve, reject) => {
        console.log(storage, file, fileName, dir, evt, url, options, fileKey)
        const formJSON = {
            [fileKey]: file,
            name: fileName,
            dir
        }
        var fd = new FormData()

        for (const key in formJSON) {
            fd.append(key, formJSON[key])
        }

        uploadFile(url,fd)
            .then((res) => {
                if(res.status==200){
                    var resData = res.data.Result;
                    if(res.data.IsCompleted){
                     resData = JSON.parse(resData);   
                    }
                    return resolve({
                        storage: 'url',
                        name:file.name,
                        url: (res.data.url ? res.data.url : url + "&file=" + resData.fileUniqueName),
                        size: file.size,
                        type: file.type,
                        data: resData
                    })
                }
                else{
                    var errMessage = (res.data&&res.data.Message)?res.data.Message : JSON.stringify(res.data);
                    if(!errMessage || errMessage.length==0) errMessage = "Invalid Response";
                    reject(errMessage);
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);            
            })
            
        })
    
    }

    async deleteFile(fileInfo) {
        console.log(fileInfo)
    }

    async downloadFile(fileInfo, options) {
        console.log(fileInfo);
        var fileExt = ""; 
        var fileTypeList=["doc","docx","xlsx","docx","csv","pdf"];
        fileExt = fileInfo.originalName.slice((fileInfo.originalName.lastIndexOf(".") - 1 >>> 0) + 2);
        if(fileTypeList.indexOf(fileExt.toLowerCase())>-1){
            window.open("/renderfile?title="+encodeURIComponent(fileInfo.originalName)+"&url="+encodeURIComponent(fileInfo.url));
        }
        else{
            return new Promise((resolve, reject) => {
            downloadFile(fileInfo.url)
                .then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]))
                    var fileLink = document.createElement('a')
    
                    fileLink.href = fileURL
                    fileLink.setAttribute('download', fileInfo.originalName)
                    document.body.appendChild(fileLink)
    
                    fileLink.click()
                    fileLink.remove()
                    resolve()
                })
                .catch((err) => reject(err));
            });
        }
    }
}

export default FileService;