
import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField";
import ColorPicker from "./../../../Common/ColorPicker"
class NumberChartConfig extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleDataSourceSelection = this.handleDataSourceSelection.bind(this);
    }

    handleChange(val) {

    }

    handleTextChange(event) {

        let value = event.target.value;
        let propName = event.target.name

        if (event.target.type == "checkbox") {
            value = event.target.checked;
        }
        // this.setState({
        //     [event.target.name]: value,
        // });
        this.updateParent({
            [propName]: value
        })
    }

    updateParent(modifiedObj) {
        if (this.props.onConfigChange)
            this.props.onConfigChange(modifiedObj, this.props.id)
    }

    handleDataSourceSelection(event) {

        let objToModify = {
            entityId: -1,
            entityTypeId: -1,
            groupKey: ''
        }

        let value = event.target.value;
        const { dataSources } = this.props.metaData;
        const selectedDataSource = dataSources.find(x => x.entityId == value);

        if (selectedDataSource) {
            objToModify.entityId = selectedDataSource.entityId;
            objToModify.entityTypeId = selectedDataSource.entityTypeId;
        }
        this.updateParent(objToModify);
    }
    handleColorChange(targetName,colorCode){
        this.updateParent({
            [targetName]: colorCode
        })
    };
    render() {
        let { title, groupKey,summaryType,bgColorCode,textColorCode,fontSize,numberFormat, entityTypeId, entityId} = this.props.chartConfig;

        const {
            groupKeyObj,
            dataSources
        } = this.props.metaData;

        let groupKeys = [];
        if (groupKeyObj[`${entityTypeId}_${entityId}`]) {
            groupKeys = groupKeyObj[`${entityTypeId}_${entityId}`];
        }
        let summaryTypes= [
            { name: "Count", value :"count" },
            // { name: "Total", value: "sum" },
            // { name: "Average", value: "avg" }
          ];
        return (
            <>
            <div className="dashboardForm">
                <div className="form-group">
                    Title:  <input
                        className='form-control'    
                        variant="outlined"
                        margin="normal"
                        id="title"
                        label="Select chart title"
                        name="title"
                        autoComplete="title"
                        autoFocus
                        value={title}
                        onChange={this.handleTextChange}
                    />
                </div>
                <div className='row form-group'>
                    <div className="col-md-3">
                        Data Sources:
                        <select className="form-control"
                            value={entityId} onChange={this.handleDataSourceSelection}
                        >
                            <option value="-1">Select</option>
                            {dataSources.map(x =>
                                <option value={x.entityId} key={x.entityId}>
                                    {x.displayName}
                                </option>
                            )
                            }
                        </select>
                    </div>
                    <div className="col-md-3">
                        Group key:
                        <select className="form-control"
                            name="groupKey"
                            value={groupKey} onChange={this.handleTextChange}
                        >
                            {groupKeys.map(x =>
                                <option value={x.StepItemKey} key={x.StepItemKey}>
                                    {x.StepItemName}
                                </option>
                            )
                            }
                        </select>
                    </div>
                    <div className="col-md-3">
                        Summary Type:
                        <select className="form-control"
                            name="summaryType"
                            value={summaryType} onChange={this.handleTextChange}
                        >
                            {summaryTypes.map(x =>
                                <option value={x.value} key={x.name}>
                                    {x.name}
                                </option>
                            )
                            }
                        </select>
                    </div>
                </div>
                <div className='row form-group'>                   
                    <div className="col-md-2">
                        Background Color:
                        <div class="input-group">
                            <input type='text' className="form-control"
                                name="bgColorCode"
                                value={bgColorCode} onChange={this.handleTextChange}
                            />                        
                            <ColorPicker  key="bgColorCode" name="bgColorCode" color={bgColorCode} onChange={(newCode)=>{this.handleColorChange("bgColorCode",newCode);}}></ColorPicker>
                        </div>
                    </div>
                    <div className="col-md-2">
                        Font Color:
                        <div class="input-group">
                            <input type='text' className="form-control"
                                name="textColorCode"
                                value={textColorCode} onChange={this.handleTextChange}
                            />
                            <ColorPicker  key="textColorCode"name="textColorCode" color={textColorCode} onChange={(newCode)=>{this.handleColorChange("textColorCode",newCode);}}></ColorPicker>
                        </div>
                    </div>
                    <div className="col-md-2">
                        Font Size(with Units):
                        <input type='text' className="form-control"
                            name="fontSize"
                            value={fontSize} onChange={this.handleTextChange}
                        />
                    </div>                    
                    <div className="col-md-2">
                        Format Code:
                        <input type='text' className="form-control"
                            name="numberFormat"
                            value={numberFormat} onChange={this.handleTextChange}
                        />
                    </div>
                </div>

              </div>
            </>)
    }
}

export default NumberChartConfig;