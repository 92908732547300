import React from "react";
import * as dc from "dc";
import { scaleLinear } from "d3";
import * as d3 from "d3";
import { ChartTemplate } from "./chartTemplate";

var title ="Data";

const SelectChartWithSummaryFunc = (divRef, ndx,config,layout) => { 
  
  var groupKey = "gender";
  if(config)
  {
    groupKey = config.groupKey;
    title=config.title;
  }

  const chartDimension = ndx.dimension((d) => {return d[groupKey]?d[groupKey]:"N.A.";}); 
  var summaryGroup = chartDimension.group().reduce(
    function (p, v) {
        ++p.number;
        // p.total += +v.Speed;
        // p.avg = Math.round(p.total / p.number);
        return p;
    },
    function (p, v) {
        --p.number;
        // p.total -= +v.Speed;
        // p.avg = (p.number == 0) ? 0 : Math.round(p.total / p.number);
        return p;
    },
    function () {
        return {number: 0, total: 0, avg: 0}
    });
  //create a table container node and append to parent div as dc.datatable only creates thead and tbody elements
  var tContainer = document.createElement("table");
  tContainer.className = "table table-sm table-bordered table-hover";
  divRef.appendChild(tContainer);

  //use table container as target to render datatable 
  var chart = dc.dataTable(tContainer);

  const pElement = divRef.parentElement.parentElement;

  var width = pElement.clientWidth,
    height = pElement.clientHeight;

  //prepare columns to represent keys, label will be used as colHeader
  var columns = [];
  if(config.groupKey){
    columns.push(function (d) { return d.key;});
  }
  if(config.aggregateType.toLowerCase()==='count'){
    columns.push(function (d) { return d.value.number;});
  }
  else if(config.aggregateType.toLowerCase()==='sum'){
    columns.push(function (d) { return d.value.total;});
  }
  else if(config.aggregateType.toLowerCase()==='avg'){
    columns.push(function (d) { return d.value.avg;});
  }
  var chartCurrFilters=[];  
  chart
    .width(width)
    .height(height)
    // .dimension(chartDimension)
    .showSections(false)
    .dimension(summaryGroup)
    .columns(columns)
    .sortBy(function (d) { return d.key })
    .order(d3.ascending.bind(null));

    chart.on('pretransition', function (table) {
      table
        .selectAll('tr.dc-table-row')
        .on('click',function(evnt,data){
          var currFilter = data.key;
          if(chartCurrFilters.indexOf(currFilter)===-1)
            chartCurrFilters.push(currFilter);
          else
            chartCurrFilters = chartCurrFilters.filter(k => k != currFilter);
          
          if(chartCurrFilters.length==0) 
            chartDimension.filter(null);
          else  
            chartDimension.filterFunction(function(d) {
              return chartCurrFilters.indexOf(d) !== -1;
            });
          
            dc.redrawAll();
        });        
        //ref:https://jsfiddle.net/gordonwoodhull/aofbu59y/62/
        table.selectAll('tr.dc-table-row')
          .classed('table-active', d => chartCurrFilters.indexOf(d.key) !== -1);
    });

  chart.render();
  return chart;

};

export const SelectChartWithSummary = (props) => (
    <ChartTemplate chartFunction={SelectChartWithSummaryFunc} title={props.config.title} config={props.config} layout={props.layout}/>  
);

export default SelectChartWithSummary;
