import React from "react";
import * as dc from "dc";
import { scaleLinear } from "d3";
import * as d3 from "d3";
import crossfilter from "crossfilter2";
import { ChartTemplate } from "./chartTemplate";

var title = "Data";

const groupAddReducer = (p, v) => {
  ++p.count;
  return p;
};
const groupRemoveRudcer = (p, v) => {
  --p.count;
  return p;
};

const groupInitalizer = () => {
  return {
    count: 0
  };
};

var numFormat = d3.format(".2f");


const ensure_group_bins = function (source_group, refData) { // (source_group, bins...}
  //var bins = Array.prototype.slice.call(arguments, 1);
  return {
    all: function () {
      var result = source_group.all().slice(0); // copy original results (we mustn't modify them)              
      refData.forEach(function (d, idx) {
        if (result.filter(function (r) { return r.key[0] == d.xLabel && r.key[1] == d.yLabel; }).length == 0) {
          //result.push({key:[d.xLabel,d.yLabel], value:0});
          result.splice(idx, 0, { key: [d.xLabel, d.yLabel], value: 0 });
        }
        // else{
        //   result.push(result.filter(function(r){ return r.key[0] == d.xLabel && r.key[1] == d.yLabel;})[0]);
        // }
      });
      return result;
    }
  };
};


const heatMapChartFunc = (divRef, ndx, config, layout) => {

  var dimKeys = ["cons", "like"];

  var objData = [
    { cons: 1, like: 1 },
    { cons: 2, like: 1 },
    { cons: 1, like: 1 },
    { cons: 2, like: 2 },
    { cons: 4, like: 3 },
    { cons: 1, like: 4 },
    { cons: 3, like: 2 }
  ];

  var refData = {
    "cons":
    {
      1: "insignificant",
      2: "minor",
      3: "moderate",
      4: "severe",
      5: "catastrophic"
    },
    "like": {
      1: "rare",
      2: "unlikely",
      3: "possible",
      4: "likely",
      5: "almost certain"
    }
  };
  const pElement = divRef.parentElement.parentElement;

  var width = pElement.clientWidth,
    height = pElement.clientHeight;

  const xAxis = config['xKey'];
  const yAxis = config['yKey'];

  const xAxisName = xAxis + "_Name", yAxisName = yAxis + "_Name";

  const heatmapDimension = ndx.dimension(function (d) {
    return [d[xAxisName], d[yAxisName]];
  });
  // const heatmapGroup = heatmapDimension
  //     .group()
  //     .reduce(groupAddReducer, groupRemoveRudcer, groupInitalizer);
  const heatmapGroup = heatmapDimension.group().reduceCount(function (d) {
    return d.value;
  });

  const showZeroVals = false,
    alwaysRenderBubble = false;

  var refArr = [];
  var xKeys = Object.keys(config[xAxis]).filter(key => config[xAxis][key] != '--Select--'), //Object.keys(config[xAxis]),
    yKeys = Object.keys(config[yAxis]).filter(key => config[yAxis][key] != '--Select--');//Object.keys(config[yAxis]);
  //console.log(xKeys,yKeys);

  //const chartDimension = ndx.dimension((d) => {return d[groupKey]?d[groupKey]:"N.A.";});

  xKeys.forEach((xkey, xidx) => {
    yKeys.forEach((ykey, yidx) => {
      var count = ndx.all(Infinity)
        .filter((obj) => {
          return (
            (obj[xAxis] == config[xAxis][xkey] || obj[xAxisName] == config[xAxis][xkey]) &&
            (obj[yAxis] == config[yAxis][ykey] || obj[yAxisName] == config[yAxis][ykey])
          )
        }).length;
      var rObj = {
        value: count,
        xLabel: config[xAxis][xkey],
        yLabel: config[yAxis][ykey]
      };
      rObj[xAxis] = xkey;
      rObj[yAxis] = ykey;

      refArr.push(rObj);
    });
  });


  const getColor = function (xKey, yKey, d) {
    console.log(d);
    var xVal = xKey, yVal = yKey;

    if (isNaN(xVal)) xVal = Object.values(config[xAxis]).indexOf(xKey);
    if (isNaN(yKey)) yVal = Object.values(config[yAxis]).indexOf(yKey);

    if (xVal > 10) xVal = xKeys.indexOf(xKey.toString()) + 1;
    if (yVal > 10) yVal = yKeys.indexOf(yKey.toString()) + 1;
    var level1 = xKeys.length < yKeys.length ? xKeys.length : yKeys.length;
    var level2 = xKeys.length + yKeys.length;
    //color codes can be modified here
    if (xVal + yVal <= level1 - 1) return "#50C878";
    else if (xVal + yVal >= level2 - 2) return "coral";
    else return "#ffed6f";
  }
  const populateParentScale = function (d) {
    var bbox = this.getBBox(),
      cbbox = this.parentNode.getBBox(),
      scale = Math.min(cbbox.width / bbox.width, cbbox.height / bbox.height);
    d.scale = scale;
  }

  const filledGroup = ensure_group_bins(heatmapGroup, refArr);

  //console.log(refArr);

  var heatMapChart = dc.heatMap(divRef);


  var chartFilter = crossfilter(refArr);

  var runDim = chartFilter.dimension(function (d) {

    var xPoint = parseInt(d[xAxis]);
    var yPoint = parseInt(d[yAxis]);

    return [xPoint, yPoint, d];
  }),
    runGroup = runDim.group().reduceSum((d) => { return +d.value; });

  heatMapChart
    .margins({ top: 5, left: (width * 0.1), right: 3, bottom: (height * 0.1) })
    .width(width - 40)
    .height(height - 60)
    // .xAxisLabel(xAxis)
    // .yAxisLabel(yAxis)
    .dimension(heatmapDimension)
    .group(filledGroup)
    .keyAccessor(function (kv) {
      //debugger;
      //return kv.key[0];
      return +Object.values(config[xAxis]).indexOf(kv.key[0]);
    })
    .valueAccessor(function (kv) {
      // return kv.key[1];    
      return +Object.values(config[xAxis]).indexOf(kv.key[1]);
    })
    //.rowsLabel(function(d) { return d.xLabel; })
    .colsLabel(function (xVal) {
      return Object.values(config[xAxis])[xVal] || 0;
    })
    .rowsLabel(function (yval) {
      return Object.values(config[yAxis])[yval];
    })
    .colorAccessor(function (d) {
      return getColor(d.key[0], d.key[1], d)
    })
    .title(function (d) {
      var val = xAxis + ":   " + d.key[0] + "\n" +
        yAxis + ":  " + d.key[1] + "\n" +
        "Count:  " + (d.value) + "\n";
      //+"[ResArea:"+d.key[0]*d.key[1]+"]";
      return val;
      //return d.value == 0 ? "": val;

    });

  heatMapChart.on('renderlet.heatMap', function (_chart) {

    var positions = [];
    _chart.selectAll(".heat-box").each(function (d, i) {
      positions.push({
        width: d3.select(this).attr("width"),
        height: d3.select(this).attr("height"),
        x: d3.select(this).attr("x"),
        y: d3.select(this).attr("y")
      });
    })
      .style("fill", function (d) {
        return getColor(d.key[0], d.key[1], d);
      });

    //_chart.selectAll('g.rows.axis').attr('width',150);

    _chart.selectAll('g.rows.axis>text')
      .attr('transform', function (d) {
        var coord = this.getBBox();
        var x = coord.x + (coord.width / 2),
          y = coord.y + (coord.height / 2);
        return "rotate(-20 " + x + " " + y + ")"
      });

    _chart.selectAll('g.cols.axis>text')
      .attr('transform', function (d) {
        var coord = this.getBBox();
        var x = coord.x + (coord.width / 2),
          y = coord.y + (coord.height / 2) - 5;
        return "rotate(-20 " + x + " " + y + ")"
      });

    const chartBoxes = _chart.selectAll('g.box-group');
    chartBoxes
      .append("circle")
      .attr("cx", function (d, i) { return parseInt(positions[i].x) + parseInt(positions[i].width / 2); })
      .attr("cy", function (d, i) { return parseInt(positions[i].y) + parseInt(positions[i].height / 2); })
      .attr("r", function (d) {
        var cRadius = 15;
        var pRectangle = this.parentNode.getClientRects()[0]
        if (pRectangle)
          cRadius = (pRectangle.width < pRectangle.height ? pRectangle.width : pRectangle.height) * 0.4;
        return cRadius;
      })
      .style("fill", function (d) {
        if (d.value > 0 || alwaysRenderBubble)
          return "lightpink";
        else
          return getColor(d.key[0], d.key[1], d);
      });



    chartBoxes.append('text')
      .attr('x', function (d, i) { return parseInt(positions[i].x) + parseInt(positions[i].width / 2) })
      .attr('y', function (d, i) { return parseInt(positions[i].y) + parseInt(positions[i].height / 2) })
      .text(function (d) {
        //console.log(d);
        var val = (d.value.count || d.value);
        if (showZeroVals || alwaysRenderBubble)
          return val;
        else
          return (val > 0 ? val : "");
      })
      .attr('dx', '-2')
      .attr('dy', '4')
      .attr('fill', "black")
      .attr("text-anchor", "middle")
      .style("font-size", "1px")
      .each(populateParentScale)
      .style("font-size", function (d) { return d.scale / 3 + "px"; });


  });

  // heatMapChart.colorCalculator(function(d, i) {
  //   return d.value.color === null ?
  //     '#ccc' : heatMap.colors()(d.value.color);
  // });   

  heatMapChart.render();

  return heatMapChart;
};
export const HeatMapChart = (props) => (
  <ChartTemplate chartFunction={heatMapChartFunc} title={props.config.title} config={props.config} layout={props.layout} />
);

export default HeatMapChart;
