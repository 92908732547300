import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Grid, Row, Col } from 'react-flexbox-grid'
import { BubbleChart } from "./bubbleChart";
import { GainOrLossChart } from "./gainOrLessChart";
import { QuarterChart } from "./quarterChart";
import { DayOfWeekChart } from "./dayOfWeekChart";
import { FluctuationChart } from "./fluctuationChart";
import { MoveChart } from "./moveChart";
import { DataTable } from "./nasdaqTable";
import { PieChart } from "./PChart";
import { BarChart } from "./Barchart";

import { BChart } from "./BChart";
import { SelectChart } from "./SelectChart";
import { SelectChartWithSummary } from "./SelectChartWithSummary";
import { NumberChart } from "./NumberChart";
import { TableChart } from "./TableChart";
import { HeatMapChart } from "./HeatMap";
import { LineChart } from "./linechart";

import { DataContext } from "./DataContext";
import { css } from 'glamor';

import Card from "@material-ui/core/Card";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { withSize, SizeMe } from "react-sizeme";
import { Responsive, WidthProvider } from "react-grid-layout";
import useResizeObserver from "use-resize-observer";
import { useParams } from 'react-router-dom';
const ref = React.createRef();
const originalItems = ["a", "b", "c", "d"];
const initialLayouts = {
    lg: [
        { i: "a", x: 0, y: 0, w: 6, h: 6 },
        { i: "b", x: 9, y: 0, w: 3, h: 6 },
        { i: "c", x: 6, y: 0, w: 3, h: 6 },
        { i: "d", x: 0, y: 6, w: 12, h: 10 }
    ]
};

const LayoutBreakpointKeys = {
    lg: 'lg',
    md: 'md',
    sm: 'sm',
    xs: 'xs',
    xxs: 'xxs'
};

function getChartByType(chartType) {

    var chartObj = BubbleChart;

    if (chartType.toString().toLowerCase() == "bubblechart")
        chartObj = BubbleChart;
    else if (chartType.toString().toLowerCase() == "quarterchart")
        chartObj = QuarterChart;
    else if (chartType.toString().toLowerCase() == "gainorlosschart")
        chartObj = GainOrLossChart;
    else if (chartType.toString().toLowerCase() == "movechart")
        chartObj = MoveChart;
    else if (chartType.toString().toLowerCase() == "piechart")
        chartObj = PieChart;
    else if (chartType.toString().toLowerCase() == "barchart")
        chartObj = BarChart;
    else if (chartType.toString().toLowerCase() == "bchart")
        chartObj = BChart;
    else if (chartType.toString().toLowerCase() == "table")
        chartObj = TableChart;
    else if (chartType.toString().toLowerCase() == "select")
        chartObj = SelectChart;
    else if (chartType.toString().toLowerCase() == "selectwithsummary")
        chartObj = SelectChartWithSummary;    
    else if (chartType.toString().toLowerCase() == "numberchart")
        chartObj = NumberChart;             
    else if (chartType.toString().toLowerCase() == "heatmap")
        chartObj = HeatMapChart;
    else if (chartType.toString().toLowerCase() == "linechart")
        chartObj = LineChart;
    return chartObj;
}

const getDashboardConfig = async (dashId) => {
    const url = "/dashboardConfigs.json";
    const response = await fetch(url);
    let dashConfigs = await response.json();
    if (!dashId || dashId < 1) dashId = 0;
    return dashConfigs[dashId];
}

function getDashBoardComponentList(dashId) {
    // return getDashboardConfig(dashId).then((dashboardConfig) => {


    //     var dashOptions = {};
    //     var itemKeys = Object.keys(dashboardConfig).filter((key => key.toLowerCase() !== "title"));
    //     dashOptions.title = dashboardConfig.title || "Dashboard";
    //     //var layouts = { lg: [] };
    //     (itemKeys).forEach(key => {
    //         dashOptions[key] = {
    //             "chart": getChartByType(dashboardConfig[key].chartType),
    //             "props": dashboardConfig[key].config,
    //             "layout": dashboardConfig[key].layout || { "x": 0, "y": 0, "w": 6, "h": 6 }
    //         };
    //         dashboardConfig[key].layout = { "x": 6, "y": 12, "w": 6, "h": 6 };
    //         var cLayout = dashboardConfig[key].layout || { "x": 0, "y": 0, "w": 6, "h": 6 };
    //         cLayout.i = key;
    //         layouts.lg.push(cLayout);
    //     });
    //     return { "items": itemKeys, "layouts": layouts, "dashOptions": dashOptions };
    // })
};

function getDashBoardComponentListFromConfig(dashboardConfig, EditWidgetHandler) {

    var dashOptions = {};
    const Objectkeys = ["a", "b", "c", "d", "e", "f", "g", "h"];

    // const keysToOgnore=["title","selectedwidgettypeid","datasources"]
    // var itemKeys = Object.keys(dashboardConfig).filter((key =>keysToOgnore.indexOf(key.toLowerCase())===-1 ));
    // dashOptions.title = dashboardConfig.title || "Dashboard";
    //var layouts = { lg: [] };

    var itemKeys = dashboardConfig.widgets.map((x, i) => {
        const key = Objectkeys[i];

        //adding edit handler
        x.config.edithandler = () => {
            if (typeof EditWidgetHandler == "function") {
                EditWidgetHandler(i)
            }
        }

        dashOptions[key] = {
            "chart": getChartByType(x.chartType),
            "props": x.config,

            //"layout": x.layout || { "x": 0, "y": 0, "w": 6, "h": 6 }
        };
        // var cLayout ={ "x": 3, "y": 0, "w": 6, "h": 6 }; // dashOptions[key].layout// || { "x": 0, "y": 0, "w": 6, "h": 6 };
        // cLayout.i = key;
        // layouts.lg.push(cLayout);
        return key;
    });
    return { "items": itemKeys, "dashOptions": dashOptions };
}

const ResponsiveGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles({
    root: {
        width: "90%",
        height: "80%",
        display: "flex",
        flexDirection: "column"
    },
    header: {
        display: "flex",
        alignItems: "center",
        padding: "0.5rem"
    },
    spacer: {
        flexGrow: 1
    },
    body: {
        padding: "0.5rem",
        flexGrow: 1
    }
});


function Widget({ id, onRemoveItem, Item, config }) {
    const classes = useStyles();
    const { ref, width, height } = useResizeObserver(HTMLDivElement);
    const configToRender = {
        ...config,
        chartKey: id
    }
    return (
        <div ref={ref} key={id} className="svg-container">
            <Item config={configToRender} />
            {/* {width}x{height}  */}
        </div>

    );
}

const style = css({
    padding: '1rem',
    marginTop: '2rem'
})

function Dashboard(props) {

    //using useParams hook as this is a functional componet
    const params = { id: 2 }// useParams()
    //you can find all params from here
    //console.log(params);  

    //var dashboardConfig=getDashboardConfig(params.id);
    let dashBoardComponentList;//= getDashBoardComponentList(params.id);
    const [dashParam, setDashParam] = useState(null);
    const [dashboardConfig, setDashboardConfig] = useState(null);
    const [stateKey, updateState] = React.useState((new Date()).getUTCMilliseconds());
    const [layoutKey, setLayoutKey] = React.useState((new Date()).getUTCMilliseconds());
    //const forceUpdate = React.useCallback(() => updateState((new Date()).getUTCMilliseconds()), []);
    const forceUpdate = () => {
        //modifying the dependency list of items of useeffect to retrigger
        updateState((new Date()).getUTCMilliseconds())
    }

    const dashboardUniqueKey = () => {
        return "dash";
        if (props && props.currentContext) {
            if (props.currentContext.pentityTypeId) {
                //when in entity context
                const ctx = props.currentContext;
                const uniqueKey = `${ctx.pentityTypeId}_${ctx.pentityId}_${ctx.entityTypeId}_${ctx.entityId}_${ctx.dashApiKey}`;
                return uniqueKey;
            }
            else {
                //dash pages/ landing pages
                const ctx = props.currentContext;
                const location = window.location;
                const uniqueKey = `${location.pathname}_${ctx.dashApiKey}`;
                return uniqueKey;
            }
        }
        return "layouts";
    }

    const [layouts, setLayouts] = useState({}
    );
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (items.length == 0)
            return;
        //required to set the default layout when new item added
        //let newLayout = getFromLS(dashboardUniqueKey()) || {};
        let newLayout = props.DashBoardConfig.layoutInfo || {};

        Object.keys(LayoutBreakpointKeys).map(layoutKey => {

            const newLayoutArray = items.map((x, i) => {

                if (!newLayout[layoutKey] || !newLayout[layoutKey][i]) {
                    return { x: 0, y: i, w: 3, h: 2 }
                }
                return newLayout[layoutKey][i];
            });
            newLayout[layoutKey] = [...newLayoutArray];

        })
        setLayouts(newLayout)
    }, [items])

    //console.log("fullRenderLayoutwith-", layouts);
    useEffect(() => {
        setDashParam(params.id);
        const asyncWrapper = async () => {
            try {
                if (props.DashBoardConfig) {
                    dashBoardComponentList = getDashBoardComponentListFromConfig(props.DashBoardConfig, props.EditWidgetHandler);
                }
                else
                    dashBoardComponentList = await getDashBoardComponentList(params.id);
                setItems(dashBoardComponentList.items);
                //setLayouts(dashBoardComponentList.layouts);
                setDashboardConfig(dashBoardComponentList.dashOptions);
                //    setTimeout(() => {
                //      setCurrent(current + 1);
                //   }, 5000);
            } catch (e) {
                console.log("async error:" + e);
            }
        }
        asyncWrapper();
    }, [params.id, dashParam, stateKey]);

    const onLayoutChange = (currentItemLayout, allLayouts) => {
        saveToLS(dashboardUniqueKey(), allLayouts);
        setLayouts(allLayouts);
        if (typeof props.onResize == 'function')
            props.onResize(allLayouts)
    };
    const onLayoutSave = () => {
        saveToLS(dashboardUniqueKey(), layouts);
    };
    const onRemoveItem = (itemId) => {
        setItems(items.filter((i) => i !== itemId));
    };
    const onAddItem = (itemId) => {
        setItems([...items, itemId]);
    };

    const getLayoutKey = (width) => {
        //{{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        if (width > 1200)
            return LayoutBreakpointKeys.lg;
        else if (width > 996)
            return LayoutBreakpointKeys.md;
        else if (width > 768)
            return LayoutBreakpointKeys.sm;
        else if (width > 480)
            return LayoutBreakpointKeys.xs;
        //else if (width > 0)
        return LayoutBreakpointKeys.xxs;

    }
    return (
        // React compares dom change based on key
        <div key={stateKey}   class="pageHeaderPanel">
            
  <div class="page-header1">
    <h5>{props.title ? props.title : props.currentContext.title}</h5>
    <div class="btn-toolbar justify-content-end">
      <div class="dt-buttons btn-group float-right">
      <div class="btn  btn-icon btn-outline-secondary btnAction" title="Refresh" onClick={(e) => { e.preventDefault(); forceUpdate() }}>
                            <i class="fa fa-refresh"></i>
                        </div>&nbsp;
      </div>
    </div>
  </div>

            


            {items.length > 0 && Object.keys(layouts).length > 0 && <SizeMe monitorHeight
                refreshMode={"debounce"}
                refreshRate={60} >
                {({ size }) => (
                    <div>
                        {
                            size.width > 0 && (
                                <div ref={ref}>
                                    {dashboardConfig && <DataContext
                                        dashId={params.id}
                                        DashBoardConfig={props.DashBoardConfig}
                                        DashBoardCurrentContext={props.currentContext}
                                    >
                                        <ResponsiveGridLayout
                                            isDraggable
                                            isRearrangeable
                                            isResizable
                                            // preventCollision={true}
                                            className="layout"
                                            layouts={layouts}
                                            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                                            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                                            // rowHeight={30}
                                            // width={size.width}
                                            // verticalCompact={true}
                                            // compactType='vertical'
                                            onLayoutChange={onLayoutChange}
                                        >
                                            {items.map((key, i) => {
                                                const currentBreakPointKey = getLayoutKey(size.width);
                                                const itemLayout = layouts[currentBreakPointKey][i] || { w: 3, h: 2, x: 0, y: 0 };
                                                const chartLayoutKey = `${currentBreakPointKey}_${itemLayout.x}_${itemLayout.y}_${itemLayout.w}_${itemLayout.h}`;

                                                return (
                                                    <div
                                                        key={`${key}_${chartLayoutKey}`}
                                                        className="widget d3-wrapper"
                                                        data-grid={itemLayout}
                                                        style={{
                                                            border: "1px solid grey",
                                                        }}
                                                    >

                                                        {/* {props.EditWidgetHandler && (
                                                            <button className="btn  btn-icon btn-outline-primary btnAction" aria-label="Edit" title="Edit" onClick={(e) => { e.preventDefault(); props.EditWidgetHandler(i) }}>
                                                                <i className="fa fa-edit"></i>
                                                            </button>

                                                        )} */}
                                                        {/* width- {size.width}
                                                        currentBreakPointKey- {currentBreakPointKey} */}
                                                        <Widget id={`${key}_${chartLayoutKey}`} key={`${key}_${chartLayoutKey}`}
                                                            onRemoveItem={onRemoveItem}
                                                            Item={dashboardConfig[key].chart}
                                                            config={dashboardConfig[key].props}
                                                        />
                                                    </div>)
                                            })}
                                        </ResponsiveGridLayout>
                                    </DataContext>
                                    }
                                </div>
                            )
                        }
                        {/* <div>
                {parseInt(size.width)}w &nbsp;&nbsp; {parseInt(size.height)}h
            </div>
            <div>Count:{items.length}<br/></div> */}
                    </div>
                )}
            </SizeMe>
            }
            {/* <DataContext>
                <Row>
                    <Col md={12} >
                        <BubbleChart />
                    </Col>
                </Row>
                <Row>
                    <Col md={7}>
                        <Row>
                            <Col md={12}>
                                <MoveChart />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <GainOrLossChart />
                            </Col>
                            <Col md={6}>
                                <FluctuationChart />
                            </Col>

                            <Col md={6}>
                                <QuarterChart />
                            </Col>
                            <Col md={6}>
                                <DayOfWeekChart />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={5} style={{overflowY:'scroll', maxHeight:'70vh', width:'100%'}}>
                        <DataTable />
                    </Col>
                </Row>
        </DataContext>  */}
        </div >
    )
}

function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
        try {
            ls = JSON.parse(global.localStorage.getItem("dashLayoutKey")) || {};
        } catch (e) { }
    }
    return ls[key];
}

function saveToLS(key, value) {
    if (global.localStorage) {
        let ls = {};

        try {
            ls = JSON.parse(global.localStorage.getItem("dashLayoutKey")) || {};
        } catch (e) { }

        const objToUpdate = {
            ...ls,
            [key]: value
        }
        global.localStorage.setItem(
            "dashLayoutKey",
            JSON.stringify(objToUpdate)
        );
    }
}


export default Dashboard;

