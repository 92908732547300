import React, { Component } from "react";
import Service from "../../../Services";
import Button from "@material-ui/core/Button";

import Modal from './../Modal';
import TextField from "@material-ui/core/TextField";

const Matches = {
    ANY: -100,
    ALL: -200
}

const Filters = class extends React.Component {
    constructor(props) {
        super(props);


        this.handleDialog = this.handleDialog.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCurrentUser = this.handleCurrentUser.bind(this);
        this.handleColumnChange = this.handleColumnChange.bind(this)
        this.handleConditionChange = this.handleConditionChange.bind(this)
        this.renderFilterItems = this.renderFilterItems.bind(this);
        this.handleAddFilterItem = this.handleAddFilterItem.bind(this);
        this.handleRemoveFilterItem = this.handleRemoveFilterItem.bind(this);
        this.handleResetFilters = this.handleResetFilters.bind(this);
        //let columns=Array.isArray(this.props.filtersData.columns)?this.props.filtersData.columns:[]



        this.state = {
            showDialog: props.showDialog,
            dragId: -1,
            conditions: [{ name: "Select", id: -1 }, { name: "Contains", id: 1 }, { name: "Does not contain", id: 2 }, { name: "Starts With", id: 3 }],
            matches: [{ name: "All", id: -200 }, { name: "Any", id: -100 },],
            matchCondition: -200,
            currentUser: false,
            topRecords: 'ALL',
            filters: [{
                columnId: -99,
                colDataType: '',
                colKey: '',
                conditionId: -99,
                items: [
                ]
            }],
            orderByColumn: props.filtersData.orderByColumn || 'name',
            sortBy: props.filtersData.sortBy || 'desc'
        };

    }


    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleCurrentUser(e) {

        this.setState({
            currentUser: e.target.checked ? true : false
        })
    }


    async handleDialog(openState, applyFilter) {
        if (typeof this.props.filterHandler === "function" && !openState && applyFilter) {
            //call the handler only when the dialog is closed
            this.props.filterHandler({
                ...this.props.filtersData,
                filters: this.state.filters,
                currentUser: this.state.currentUser ? this.state.currentUser : false,
                matchCondition: this.state.matchCondition,
                topRecords: this.state.topRecords,
                orderByColumn: this.state.orderByColumn,
                sortBy: this.state.sortBy
            })
        }
        this.setState({ showDialog: openState })
    }

    handleResetFilters() {
        this.setState({
            conditions: [{ name: "Select", id: -1 }, { name: "Contains", id: 1 }, { name: "Does not contain", id: 2 }, { name: "Starts With", id: 3 }],
            matches: [{ name: "All", id: -200 }, { name: "Any", id: -100 },],
            matchCondition: -200,
            currentUser: false,
            topRecords: 'ALL',
            filters: [{
                columnId: -99,
                colDataType: '',
                colKey: '',
                conditionId: -99,
                items: [
                ]
            }],
            orderByColumn: this.props.filtersData.orderByColumn || 'name',
            sortBy: this.props.filtersData.sortBy || 'desc'
        })
        if (typeof this.props.filterResetHandler == "function")
            this.props.filterResetHandler();
    }

    renderFilterItems(items) {

        const columns = Array.isArray(this.props.filtersData.columns) ? this.props.filtersData.columns : []
        const conditionsByDataType = this.props.filtersData.conditionsByDataType;
        const lookupByColId = this.props.filtersData.lookupByColId;

        return items.map((x, itemIndex) => {

            const renderChildren = (x.columnId === "-100" || x.columnId === "-200") ? true : false;
            return (<div>

                {

                    (<div className=" form-group">

                        <div className="">  Field:</div>
                        <div className="input-group ">
                        <div className="closeIcon" title="Remove Filter Condition"
                                    onClick={() => this.handleRemoveFilterItem(items, itemIndex)}>
                                    <i className="fa fa-trash"></i>
                                </div>
                        <div className="row filterGroup">
                        
                            <div className="col-md-6">
                            <select class="selectpicker form-control" value={x.columnId} onChange={
                                (e) => { this.handleColumnChange(x, e) }
                            }>
                                {columns.map(y =>
                                    <option value={y.colId} key={y.colId}>
                                        {y.colName}
                                    </option>
                                )
                                }
                            </select></div>
                            
                            {!renderChildren && (<>


                                {conditionsByDataType && Array.isArray(conditionsByDataType[x.colDataType]) &&
                                    <>


                                        {/* <span >  Condition:</span> */}
                                        <div className="col-md-6">
                                        <select class="form-control" value={x.conditionId} onChange={
                                            (e) => { this.handleConditionChange(x, e) }
                                        }>
                                            {conditionsByDataType[x.colDataType].map(x =>
                                                <option value={x.lookUpValue} key={x.lookUpValue}>
                                                    {x.lookUpName}
                                                </option>
                                            )
                                            }
                                        </select>
                                        </div>

                                    </>

                                }
                                

                                {x.noOfValuesRequired > 0 && (
                                    <>


                                        {
                                            ((x.colDataType !== "datetime" && x.colDataType !== "select")
                                                //|| (x.colDataType === "datetime" && x.daysRequired)
                                            )
                                            && (

                                                <div className="col-md-12">
                                                <>
                                                    <input className="form-control" value={x.value1} onChange={(e) => {
                                                        x.value1 = e.target.value
                                                        this.setState({
                                                            ...this.state
                                                        })
                                                    }} />
                                                </>
                                                </div>
                                            )
                                        }


                                        {
                                            x.colDataType === "datetime" && (
                                                <>

                                                    {x.currentDateRequired === 1 &&
                                                        <>
                                                            <div className="col-md-3">
                                                            <label class="checkbox-inline">
                                                                <input type="checkbox" checked={x.currentDateSelected1}
                                                                    onChange={(e) => {
                                                                        x.currentDateSelected1 = e.target.checked
                                                                        this.setState({
                                                                            ...this.state
                                                                        })
                                                                    }} /> Current Date
                                                            </label>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="col-md-3">
                                                    {!x.currentDateSelected1 && !x.daysRequired &&
                                                        
                                                        <input
                                                            id="date"
                                                            label="Value1"
                                                            type="date"
                                                            className="form-control"
                                                            //defaultValue={new Date()}
                                                            //className={classes.textField}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            value={x.value1} onChange={(e) => {
                                                                x.value1 = e.target.value
                                                                this.setState({
                                                                    ...this.state
                                                                })
                                                                
                                                            }}
                                                        />}
                                                        </div>

                                                             
                                                    {x.daysRequired &&
                                                    <div className="col-md-12"> 
                                                        <>
                                                            <input className="form-control" value={x.value1} onChange={(e) => {
                                                                x.value1 = e.target.value
                                                                this.setState({
                                                                    ...this.state
                                                                })
                                                            }} />
                                                        </>
                                                        </div> 
                                                    }
                                                            
                                                </>
                                            )
                                        }

                                        {
                                            x.colDataType === "select" && Array.isArray(lookupByColId[x.columnId]) && (
                                                    <div className="col-md-12">
                                                        <select class="selectpicker form-control" value={x.value1} onChange={
                                                            (e) => {
                                                                x.value1 = e.target.value
                                                                this.setState({
                                                                    ...this.state
                                                                })
                                                            }

                                                        }>
                                                            {
                                                                !x.value1 && <option value={""} key={'empty'}>
                                                                    {""}
                                                                </option>
                                                            }
                                                            {lookupByColId[x.columnId].map(y =>
                                                                <option value={y.lookUpValue} key={y.lookUpValue}>
                                                                    {y.lookUpName}
                                                                </option>
                                                            )
                                                            }
                                                        </select>
                                                    </div>

                                            )
                                        }




                                    </>
                                )
                                }

                                {x.noOfValuesRequired > 1 && (
                                    <>

                                        {
                                            x.colDataType !== "datetime" && (
                                                <>
                                                    {/* <span> Value2: </span> */}

                                                    <input className="form-control" value={x.value2} onChange={(e) => {
                                                        x.value2 = e.target.value
                                                        this.setState({
                                                            ...this.state
                                                        })

                                                    }} />
                                                </>
                                            )
                                        }

                                        {
                                            x.colDataType === "datetime" && (

                                                <>
                                                <div className="col-md-3">
                                                    {x.currentDateRequired === 1 &&
                                                        <>

                                                            <label class="checkbox-inline">
                                                                <input type="checkbox" checked={x.currentDateSelected2}
                                                                    onChange={(e) => {
                                                                        x.currentDateSelected2 = e.target.checked
                                                                        this.setState({
                                                                            ...this.state
                                                                        })
                                                                    }} /> Current Date
                                                            </label>
                                                        </>
                                                    }
                                                    </div>
                                                    <div className="col-md-3">
                                                    {!x.currentDateSelected2 && !x.daysRequired &&
                                                        <input
                                                            id="date"
                                                            label="Value2"
                                                            type="date"
                                                            className="form-control"
                                                            //defaultValue={new Date()}
                                                            //className={classes.textField}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            value={x.value2} onChange={(e) => {
                                                                x.value2 = e.target.value
                                                                this.setState({
                                                                    ...this.state
                                                                })

                                                            }}
                                                        />}
                                                        </div>

                                                </>

                                            )
                                        }

                                    </>
                                )
                                }

                            </>)}
                            </div>
                            {'  '}
                            {
                                renderChildren &&
                                <div className="btn btn-icon btn-outline-primary btnAction" title="Add Filter Condition"
                                    onClick={() => this.handleAddFilterItem(x.items)}>
                                    <i class="fas fa-plus"></i>
                                </div>
                            }{'  '}

                                
                        </div>
                    </div>
                    )
                }
                {
                    renderChildren && Array.isArray(x.items) &&

                    <div style={{ marginLeft: 20 }}>
                        {
                            this.renderFilterItems(x.items)
                        }
                    </div>


                }
            </div>)


        })

    }

    handleAddFilterItem(parent) {

        parent.push({
            columnId: -99,
            colDataType: '',
            colKey: '',
            conditionId: -99,
            value1: '',
            value2: '',
            items: []
        })
        this.setState({
            filters: [...this.state.filters]
        })
    }

    handleRemoveFilterItem(parent, itemIndex) {

        //try using filter to remove without mutating the array
        //parent = parent.filter((x, i) => itemIndex !== i)
        //TODO mutating array here
        parent = parent.splice(itemIndex, 1);
        this.setState({
            filters: [...this.state.filters]
        })
    }

    handleConditionChange(item, e) {
        item.conditionId = e.target.value
        item.noOfValuesRequired = 0 //reset on change
        item.daysRequired = 0
        item.currentDateRequired = 0
        item.value1 = ''
        item.value2 = ''
        const conditionsByDataType = this.props.filtersData.conditionsByDataType[item.colDataType]
        let conditionSelected;
        if (Array.isArray(conditionsByDataType)) {
            conditionSelected = conditionsByDataType.find(c => c.lookUpValue === Number(item.conditionId));
        }
        item.noOfValuesRequired = conditionSelected ? conditionSelected.noOfValuesRequired : 0
        item.daysRequired = conditionSelected ? conditionSelected.daysRequired : 0
        item.currentDateRequired = conditionSelected ? conditionSelected.currentDateRequired : 0

        this.setState({
            filters: [...this.state.filters]
        })
    }

    handleColumnChange(item, e) {
        item.conditionId = -99
        item.noOfValuesRequired = 0 //reset on change
        item.daysRequired = 0
        item.currentDateRequired = 0
        item.value1 = ''
        item.value2 = ''
        item.columnId = e.target.value
        const colSelected = this.props.filtersData.columns.find(c => c.colId === Number(item.columnId))
        item.colDataType = colSelected ? colSelected.colDataType : ''
        item.colKey = colSelected ? colSelected.colKey : ''
        if (item.columnId === "-100" || item.columnId === "-200") {
            if (item.items.length === 0)
                item.items = [{
                    columnId: -99,
                    colDataType: '',
                    colKey: '',
                    conditionId: -99,
                    value1: '',
                    value2: '',
                    items: []
                }]
        }
        this.setState({
            filters: [...this.state.filters]
        })
    }

    async componentWillReceiveProps(newProps) {


        if (newProps.filtersData.orderByColumn !== this.state.orderByColumn ||
            newProps.filtersData.sortBy !== this.state.sortBy
        ) {

            this.setState(
                {
                    orderByColumn: newProps.filtersData.orderByColumn,
                    sortBy: newProps.filtersData.sortBy,
                }
            );
            return true;
        }
        return false;
    }

    render() {


        const columns = Array.isArray(this.props.filtersData.columns) ? this.props.filtersData.columns : []
        //const filterButtonName = typeof this.props.filtersData.filterName ? this.props.filtersData.filterName : 'Filters'
        return (
            <div>
                <div className="btn  btn-icon btn-outline-primary btnAction" title="Create Filters">
                    <div onClick={(e) => { e.preventDefault(); this.handleDialog(true, true) }} ><i className="fa fa-filter" ></i></div>
                </div>
                {this.state.showDialog && (<Modal closeModal={(e) => { e.preventDefault(); this.handleDialog(false, false) }}
                    // SaveData={(e)=>{e.preventDefault();  this.handleDialog(false,true)}} ShowSaveButton="true"
                    footerActions={[
                        { Name: "Apply",  callBack: (e) => { e.preventDefault(); this.handleDialog(false, true) }, className: "btn btn-primary pull-right", title: "Apply the filters" }
                        , { Name: "Reset", callBack: (e) => { e.preventDefault(); this.handleResetFilters() }, className: "btn btn-danger pull-left", title: "Reset" }
                    ]}>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="pull-left">
                                <h4>Filters</h4>
                            </div>
                            <div className="pull-right">
                                <label class="checkbox-inline" title="Filter based on logged-in user">
                                    <input type="checkbox" checked={this.state.currentUser} onChange={this.handleCurrentUser} /> Current User
                                </label>
                            </div>
                        </div>
                    </div>


                    {/* <div className="container">
                        <div className="form-group col-md-2"> */}

            <div className="form-group">
            <span className="form-label">Match:</span>
                    <div className="input-group">
                        
                        <select class="form-control" value={this.state.matchCondition} name="matchCondition" onChange={
                            this.handleChange
                        }>
                            {this.state.matches.map(x =>
                                <option value={x.id} key={x.id}>
                                    {x.name}
                                </option>
                            )
                            }
                        </select>
                        <div className="input-group-append">
                        <button className="btn btn-icon btn-outline-primary" onClick={() => this.handleAddFilterItem(this.state.filters)} title="Add Filter Condition">
                            <i class="fas fa-plus"></i>
                        </button>
                        </div>
                    </div>
                    </div>

                    
                    <div>
                        {this.renderFilterItems(this.state.filters)}
                    </div>
                    <br />




                    <table className="table table-hover m-b-0">
                        <thead>
                            <tr>
                                <th title="Number of records">Top: </th>
                                <th title="Field to use for sorting results">Order by:</th>
                                <th title="Sort type [ascending/descending]">Sort Type:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input class="form-control " value={this.state.topRecords} name="topRecords" onChange={this.handleChange} /></td>
                                <td><select class="form-control" value={this.state.orderByColumn} name="orderByColumn" onChange={this.handleChange}>
                                    {columns.map(y =>
                                        <option value={y.colKey} key={y.colId}>
                                            {y.colName}
                                        </option>
                                    )
                                    }
                                </select></td>
                                <td><select class="form-control" value={this.state.sortBy} name="sortBy" onChange={this.handleChange}>

                                    <option value={"asc"} key={"asc"}>
                                        {"Ascending"}
                                    </option>
                                    <option value={"desc"} key={"desc"}>
                                        {"Descending"}
                                    </option>
                                </select></td>
                            </tr>
                        </tbody>
                    </table>





                </Modal>)}

            </div>
        );
    }
};

export default Filters