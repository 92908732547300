import React, { Component } from 'react';
import lookUpService from "../../../Services/lookupApi";



class CustomBreadCrumb extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navItems: [],
        }

        this.handleNavigation = this.handleNavigation.bind(this);
    }
    componentDidMount() {
        const locationUrl = this.props.locationUrl
        this.getData(locationUrl);

    }
    componentDidUpdate(prevProps) {
        const locationUrl = this.props.locationUrl
        if (prevProps.locationUrl !== locationUrl) {
            //window.location.pathname
            this.getData(locationUrl);
        }

    }
    async getData(locationUrl) {
        const searchParams = {
            urlString: locationUrl,
            //urlString: window.location.pathname
        }
        const data = await lookUpService.getBreadcrumbResults(searchParams);
        this.setState({
            navItems: data.list,

        })

    }

    handleNavigation(url) {

        this.props.history.push(url)
    }


    render() {

        const showBreadCrumb = global.userRoleType != 2;//do not show for light user
        //const dataToLoad = value;
        return (
            <>
                {showBreadCrumb &&
                    <div className="App">
                        {/* <h2>Results for  {this.props.locationUrl}</h2> */}
                        {/* <div onClick={()=>this.handleNavigation('/editor')}> Editor</div> */}

                        {/* <ul> */}
                        {
                            this.state.navItems.length > 0 && <ol class="customBreadCrumb">
                                {this.state.navItems.map(x =>
                                    <li >
                                        <a href={x.name_navurl}>{x.name}</a>
                                    </li>)
                                }
                            </ol>
                        }
                        {/* </ul> */}



                    </div>}
            </>
        );
    }
}

export default CustomBreadCrumb;