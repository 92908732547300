import React from "react";
import DBService from "../../Services/dexieForm";
import { Link } from "react-router-dom";

class FormList extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = this.props;

    this.state = { formsList: [] , metaId:params.metaId}; //default

    this.handleClick = this.handleClick.bind(this);
    this.handleNewClick = this.handleNewClick.bind(this);
  }

  async componentDidMount() {
    DBService.initialize();
    let list = await DBService.getFormListByMetaId(this.state.metaId);
    this.setState({ formsList: list });
  }

  handleNewClick(event) {
    event.preventDefault();
    
    this.props.history.replace(`/form/${this.state.metaId}/-100`);
  }

  handleClick(item) {
    console.log("clicked Item - ", item);
    this.props.history.replace(`/form/${this.state.metaId}/${item.id}`);
  }

  render() {
    const list = this.state.formsList;
    return (
      <div className="dynamicForm">
        <div>
          <div className="alignItems">
            <span><b>Forms List</b></span>
            {/* <Link to={`/form/${this.state.metaId}/-100`}>Submit New Form</Link> */}
            <a onClick={this.handleNewClick}>Submit New Form</a>
          </div>
        </div>
        {list.length > 0 && (
          <ul>
            {list.map((item) => (
              <li key={item.id}>
                <a onClick={(e) =>{ 
                  e.preventDefault();
                  this.handleClick(item)
                }}
                  >{item.name}</a>
              </li>
            ))}
          </ul>
        )}
        {list.length == 0 && 
        <div className="tablePanel-body noPanelBody">
          <h2>No Submitted forms</h2>
        </div>
        }
      </div>
    
    );
  }
}

export default FormList;
