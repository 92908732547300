import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./edit";

import lookUpService from "./../../../../Services/lookupApi";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Modal from './../../../Common/Modal';

import TablePagination from "@material-ui/core/TablePagination";
const jsonString = {
  values: [
    {
      name: "High",
      color: "red",
    },
    {
      name: "low",
      color: "green",
    },
  ],
};

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const ContactListComp = class extends React.Component {
  constructor(props) {
    super(props);

    let data;
    try {
      //data = JSON.parse(props.component.jsonData);
      data = { unassigned: [], assigned: [] }; //set default
    } catch (e) {
      data = { unassigned: [], assigned: [] }; //set default
    }

    this.handleAssign = this.handleAssign.bind(this);
    this.handleUnAssign = this.handleUnAssign.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDialog = this.handleDialog.bind(this);
    this.handlePermission = this.handlePermission.bind(this);
    let value = props.submissionData ? props.submissionData : props.value;
    this.state = {
      value: value,
      jsonData: value ? (value.jsonData ? value.jsonData : data) : data,
      searchText: "",
      columns: props.component.columns,
      features: props.component.features,
      showDialog: false
    };
  }

  setValue = (jsonDataToSave) => {

    this.setState(
      (prevState) => ({
        value: {
          jsonData: jsonDataToSave,
        },
      }),
      () => {
        let dataToSave = {
          jsonData: {
            assigned: this.state.jsonData.assigned
          }
        }
        this.props.onChange(dataToSave)
      }
    );
  };

  handleAssign() {
    console.log(this.refs);
    var data = this.state.jsonData;
    var selected = data.unassigned.filter((x) => x.selected)
    data.unassigned = data.unassigned.filter((x) => !x.selected);
    selected = selected.map(x => { delete x.selected; return x; });

    let jsonDataToSave = {
      assigned: [...data.assigned, ...selected],
      unassigned: data.unassigned
    }
    this.setState({
      jsonData: jsonDataToSave
    });
    this.setValue(jsonDataToSave);
  }

  handleUnAssign() {
    console.log(this.refs);

    var data = this.state.jsonData;
    var selected = data.assigned.filter((x) => x.selected);
    data.assigned = data.assigned.filter((x) => !x.selected);
    selected = selected.map(x => { delete x.selected; return x; });
    let jsonDataToSave = {
      unassigned: [...data.unassigned, ...selected],
      assigned: data.assigned,
    }

    this.setState({
      jsonData: jsonDataToSave
    });
    this.setValue(jsonDataToSave);
  }
  handleSelect(item) {
    item.selected = item.selected ? !item.selected : true;
  }

  handlePermission(item, feature, index) {

    const items = [...this.state.jsonData.assigned];
    const itemToUpdate = items[index];
    itemToUpdate[feature] = itemToUpdate[feature] ? !itemToUpdate[feature] : true;

    const jsonDataToSave = {
      ...this.state.jsonData,
      assigned: items,
    }

    this.setState({
      jsonData: jsonDataToSave
    });
    this.setValue(jsonDataToSave);
    //    item[feature] = item[feature] ? !item[feature] : true;
  }

  handleChange(e) {
    this.setState({
      searchText: e.target.value
    })
  }

  async handleSearch(e) {
    e.preventDefault();

    const searchParams = {
      searchText: this.state.searchText,
      frameworkId: 2,//this.props.component.cframeworkId,
      entityId: 3,//this.props.component.entityId,
      entityTypeId: 4,//this.props.component.entityTypeId,
      pentityId: 5,//this.props.component.pentityId,
      pentityTypeId: 6,//this.props.component.pentityTypeId,

    }
    const list = await lookUpService.getUsers(searchParams);
    let jsonData = this.state.jsonData;
    jsonData.unassigned = list
    this.setState({
      jsonData,
    })
  }

  handleDialog(openState) {
    this.setState({ showDialog: openState })
  }

  render() {
    const data = this.state.jsonData;

    return (
      <div>
        <div className="dt-buttons btn-group float-right">
          <Button onClick={() =>
            this.handleDialog(true)
          } className="btn  btn-icon btn-outline-primary btnAction pull-right" title="Assign Permissions">
            <i className="fa fa-users"></i>
          </Button>
        </div>
        {false && //logging
          <div>
            <p>EntityId - {this.props.component.entityId}</p>
            <p>entityTypeId - {this.props.component.entityTypeId}</p>
            <p>pEntityId - {this.props.component.pentityId}</p>
            <p>pentityTypeId - {this.props.component.pentityTypeId}</p>
            <p>cframeworkId - {this.props.component.cframeworkId}</p>
          </div>
        }
        <div className="table-responsive">
          <table className="table table-condensed">
            <thead>
              <tr>
                {/* <th>Select</th> */}
                {
                  this.state.columns && this.state.columns.filter(x => x.visible).map((col, i) => (
                    <th key={i} scope="col"> {col.name} </th>
                  ))
                }
                {
                  this.state.features && this.state.features.filter(x => x.visible).map((col, i) => (
                    <th key={`f-${i}`} scope="col"> {col.name} </th>
                  ))
                }
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.assigned &&
                data.assigned.map((item, index) => (
                  <tr key={index}>
                    {/* <td>
                      {" "}
                      <input                        
                        type="checkbox"
                        key={item.id}
                        checked={item.selected}
                        onChange={() => {
                          this.handleSelect(item);
                        }}
                      />{" "}
                    </td>                     */}
                    {
                      this.state.columns && this.state.columns.filter(x => x.visible).map((col, i) => (
                        <td key={i} scope="col"> {item[col.dbfield]} </td>
                      ))
                    }
                    {
                      this.state.features && this.state.features.filter(x => x.visible).map((col, i) => (
                        <td key={`f-${i}`}>
                          {/* {item[col.dbfield]}  */}
                          <input
                            type="checkbox"
                            key={`f-${i}-${col.dbfield}`}
                            checked={item[col.dbfield]}
                            onChange={(e) => {
                              console.log(e.target.checked)
                              this.handlePermission(item, col.dbfield, index);
                            }}
                          />
                        </td>

                      ))
                    }
                    <td>
                      <Button variant="contained" className="btn btn-icon btn-outline-secondary btnAction">
                        <i className="fa fa-trash"></i>
                      </Button>

                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {this.state.showDialog && (<Modal closeModal={() => this.handleDialog(false)}>
          <div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="input-group mb-3">
                    <input id="standard-basic" type="text" value={this.state.searchText} onChange={this.handleChange} label="Search"
                      className="form-control" placeholder={this.state.searchText} aria-label={this.state.searchText} aria-describedby="basic-addon2" />
                    <div className="input-group-append">
                      <button className="btn btn-outline-primary" onClick={this.handleSearch} type="button"><i className="fa fa-search" aria-hidden="true"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-5">

                  <table className="table table-condensed">
                    <thead >
                      <tr>
                        <th scope="col">Select</th>
                        {
                          this.state.columns && this.state.columns.filter(x => x.visible).map((col, i) => (
                            <th key={i} scope="col"> {col.name} </th>
                          ))
                        }

                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.unassigned &&
                        data.unassigned.map((item, i) => (
                          <tr key={i}>
                            <td>
                              <input
                                key={`unassigned ${i}`}
                                type="checkbox"
                                key={item.id}
                                onChange={() => {
                                  this.handleSelect(item);
                                }}
                              />
                            </td>
                            {
                              this.state.columns && this.state.columns.filter(x => x.visible).map((col, i) => (
                                <td key={i}> {item[col.dbfield]} </td>
                              ))
                            }

                          </tr>
                        ))}
                    </tbody>
                  </table>

                 
                </div>

              <div className="col-md-2 align-self-start text-center arrowLeftRight"><br /><br />
                <input type="button" onClick={this.handleUnAssign} value="<" /><br /><br />
                <input type="button" onClick={this.handleAssign} value=">" />
              </div>
                
                <div className="table-responsive  col-sm-5">

                  <table className="table table-condensed">
                    <thead>
                      <tr>
                        <th>Select</th>
                        {
                          this.state.columns && this.state.columns.filter(x => x.visible).map((col, i) => (
                            <th key={i}> {col.name} </th>
                          ))
                        }
                        {
                          this.state.features && this.state.features.filter(x => x.visible).map((col, i) => (
                            <th key={`f-${i}`}> {col.name} </th>
                          ))
                        }

                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.assigned &&
                        data.assigned.map((item, index) => (
                          <tr key={index}>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                key={item.id}
                                checked={item.selected}
                                onChange={() => {
                                  this.handleSelect(item);
                                }}
                              />{" "}
                            </td>
                            {
                              this.state.columns && this.state.columns.filter(x => x.visible).map((col, i) => (
                                <td key={i}> {item[col.dbfield]} </td>
                              ))
                            }
                            {
                              this.state.features && this.state.features.filter(x => x.visible).map((col, i) => (
                                <td key={`f-${i}`}>
                                  {/* {item[col.dbfield]}  */}
                                  <input
                                    type="checkbox"
                                    key={`f-${i}-${col.dbfield}`}
                                    checked={item[col.dbfield]}
                                    onChange={() => {
                                      this.handlePermission(item, col.dbfield, index);
                                    }}
                                  />
                                </td>

                              ))
                            }
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

              <div className="col-md-12">
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={111}
                  rowsPerPage={5}
                  page={1}
                //onChangePage={this.handleChangePage}
                //onChangeRowsPerPage={pagination.handleChangeRowsPerPage}
                />
              </div>

              </div>
            
          </div>

        </Modal>)}
      </div>
    );
  }
};

export default class ContactList extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "6ContactPermissionList",
      icon: "square",
      group: "basic",
      documentation: "",
      weight: -10,
      schema: ContactList.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "contactPermissionList",
      label: "Permission List",
      logic: [
        {
          "name": "on Load",
          "trigger": {
            "type": "javascript",
            "javascript": "result= true;"
          },
          "actions": [
            {
              "name": "populateIds",
              "type": "mergeComponentSchema",
              //              "schemaDefinition": "schema = { myWidgetURI:data[data.apiKeyToPick] }"
              "schemaDefinition": `schema = { entityId:data.entityId
                                              ,entityTypeId:data.entityTypeId
                                              ,pentityId:data.pentityId
                                              ,pentityTypeId:data.pentityTypeId
                                              , cframeworkId:data.cframeworkId }`
            }
          ]
        }
      ]
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    //console.log('this.dataForSetting',this.dataForSetting)
    // if (this.shouldSetValue) {
    //   //this.setValue(this.dataForSetting);
    //   this.updateValue(this.dataForSetting);
    // }
    return ReactDOM.render(
      <ContactListComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }

  setValue(data) {
    console.log("Called wit data-", data);
    //hacked to rerender the react component by adding new prop //temp fix
    //hacked to rerender the react component by adding new prop submissionData//temp fix
    //TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO
    if (data && this.element) {
      ReactDOM.render(
        <ContactListComp
          component={this.component} // These are the component settings if you want to use them to render the component.
          value={this.dataValue} // updatedvalue of the component.
          onChange={this.updateValue} // The onChange event to call when the value changes.
          submissionData={data}
        />,
        this.element
      );
    }
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
