import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./edit";

//import {browserHistory} from "react-router";

import lookUpService from "./../../../../Services/lookupApi";
import Button from "@material-ui/core/Button";
import Modal from './../../../Common/Modal';
import { Tabs, Tab } from 'react-bootstrap'
import EntityInstanceForm from "../../../EntityInstance";

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */




const EntityTabComp = class extends React.Component {
  constructor(props) {
    super(props);

    let data;
    try {
      //data = JSON.parse(props.component.jsonData);
      data = {}; //set default
    } catch (e) {
      data = {}; //set default
    }



    this.handleChange = this.handleChange.bind(this);
    this.handleDialog = this.handleDialog.bind(this);
    this.handleTab = this.handleTab.bind(this);

    let value = props.value ? props.value : props.submissionData;
    this.state = {
      value: value,
      jsonData: value ? value.jsonData : data,
      activeTabId: 2,
      tabs: [{
        id: 1,
        name: 'Tab A',
        entityId: 1,
        entityTypeId: 2,

      },
      {
        id: 2,
        name: 'Tab B'
      },
      {
        id: 3,
        name: 'Tab C'
      }
      ],
      selectedEntityToView:{},
      showDialog: false
    };


  }

  async componentDidMount() {

    if(!Boolean(this.props.component.entityId>0))
    {
      return
    }
    const params = {
      entityId: this.props.component.entityId || 2,
      entityTypeId: this.props.component.entityTypeId || 3,
      frameworkId: this.props.component.frameworkId || 5,
      pentityId: this.props.component.pentityId || 7,
      pentityTypeId: this.props.component.pentityTypeId || 6,
      multiEntityKey: this.props.component.multiEntityKey
    }
    const tabData = await lookUpService.getTabEntityDetails(params);
    let activeTabData
    if(Array.isArray(tabData.tabs))
    {
      activeTabData=tabData.tabs[0];
    }
    if (tabData) {
      this.setState(
        {
          tabs: tabData.tabs,
          activeTabId: activeTabData? activeTabData.id:'',
          selectedEntityToView:{
            entityTypeId:activeTabData? activeTabData.entityTypeId:-1,
            entityId:activeTabData? activeTabData.entityId:-1,
            pentityTypeId:activeTabData? activeTabData.pentityTypeId:-1,
            pentityId:activeTabData? activeTabData.pentityId:-1,
          }
        }
      )
    }

  }


  setValue = (jsonDataToSave) => {

    this.setState(
      (prevState) => ({
        value: {
          jsonData: jsonDataToSave,
        },
      }),
      // () => this.props.onChange(null,this.state.value)
      () => this.props.onChange(this.state.value)
    );
  };



  handleChange(e) {
    this.setState({
      searchText: e.target.value
    })
  }

  handleDialog(openState) {
    this.setState({ showDialog: openState })
  }

  handleTab(tabId) {

    const activeTabData=this.state.tabs.find(x=>x.id== tabId);

    if(activeTabData){
      this.setState({
        activeTabId: tabId,
        selectedEntityToView:{
          entityTypeId:activeTabData? activeTabData.entityTypeId:-1,
          entityId:activeTabData? activeTabData.entityId:-1,
          pentityTypeId:activeTabData? activeTabData.pentityTypeId:-1,
          pentityId:activeTabData? activeTabData.pentityId:-1,
        }
      })
    }
   

  }

  render() {
    const data = this.state.jsonData;

    const tableStyle = {
      "borderWidth": "1px",
      'borderColor': "#aaaaaa",
      'borderStyle': 'solid',
      fontSize: 18
    }

    return (
      <div>
        {/* <div
          style={{ display: "flex", margin: 20, justifyContent: "space-between" }}
        >
          <div className="m-b-10"><h5>
            {"Entity Tabs"}
          </h5>

          </div>

        </div> */}
        <div>
          <Tabs
            id="controlled-tab-example"
            activeKey={this.state.activeTabId}
            onSelect={(k) => this.handleTab(k)}
            className="mb-3"
          >
            {this.state.tabs.map(x => {
              return <Tab eventKey={x.id} title={x.name}>
                {/* <p>{x.name}</p> */}
              </Tab>


            })
            }
          </Tabs>
          {/* Need to find the best way to pass as url params */}
          {this.state.selectedEntityToView.entityId > 0 &&
            <EntityInstanceForm key={this.state.selectedEntityToView.entityId} match={{
              params: {
                entityId: this.state.selectedEntityToView.entityId,
                entityTypeId: this.state.selectedEntityToView.entityTypeId,
                pentityTypeId: this.state.selectedEntityToView.pentityTypeId,
                pentityId: this.state.selectedEntityToView.pentityId
              }
            }} 
            location={{
              key:(new Date()).getUTCMilliseconds()
            }}
            renderType="nestedForm"
            >

            </EntityInstanceForm>
          }



          {this.state.showDialog && (<Modal closeModal={() => this.handleDialog(false)}>
            <div>
              <h3> Message for button {this.state.selectedTransition.name} </h3>
              {

              }


            </div>

          </Modal>)}

        </div>
        {/* <p>EntityId - {this.props.component.entityId}</p>
        <p>EntityTypeId - {this.props.component.entityTypeId}</p>
        <p>FrameworkId - {this.props.component.frameworkId}</p>
        <p>ParentEntityId - {this.props.component.pentityId}</p>
        <p>ParentEntityTypeId - {this.props.component.pentityTypeId}</p> */}
      </div >
    );
  }
};


export default class EntityTab extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "1EntityTab",
      icon: "square",
      group: "basic",
      documentation: "",
      weight: -10,
      schema: EntityTab.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "entityTab",
      label: "Entity Tab",
      logic: [
        {
          "name": "on Load",
          "trigger": {
            "type": "javascript",
            "javascript": "result= true;"
          },
          "actions": [
            {
              "name": "populateIds",
              "type": "mergeComponentSchema",
              //              "schemaDefinition": "schema = { myWidgetURI:data[data.apiKeyToPick] }"
              "schemaDefinition": `schema = { entityId:data.entityId
                                              ,entityTypeId:data.entityTypeId
                                              ,pentityId:data.pentityId
                                              ,pentityTypeId:data.pentityTypeId
                                              ,frameworkId:data.frameworkId }`
            }
          ]
        }
      ]
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    //console.log('this.dataForSetting',this.dataForSetting)
    // if (this.shouldSetValue) {
    //   //this.setValue(this.dataForSetting);
    //   this.updateValue(this.dataForSetting);
    // }
    return ReactDOM.render(
      <EntityTabComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }

  setValue(data) {
    console.log("Called wit data-", data);
    //hacked to rerender the react component by adding new prop //temp fix
    //hacked to rerender the react component by adding new prop submissionData//temp fix
    //TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO
    if (data && this.element) {
      ReactDOM.render(
        <EntityTabComp
          component={this.component} // These are the component settings if you want to use them to render the component.
          value={this.dataValue} // updatedvalue of the component.
          onChange={this.updateValue} // The onChange event to call when the value changes.
          submissionData={data}
        />,
        this.element
      );
    }
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
