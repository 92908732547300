import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
//import CKEditor from '@ckeditor/ckeditor5-react';
import defaultEditorConfig from './EditorConfig';
import ClassicEditor from 'ckeditor5-custom-build';

//import CustomEditor from "./EditorConfig"

//import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';

//below property config also works
// ClassicEditor.defaultConfig = {
//     ...ClassicEditor.defaultConfig,
//     //plugins: [ SourceEditing ],
//     toolbar: {
//         items: [
//             ...ClassicEditor.defaultConfig.toolbar.items,
//             { name: 'document', items: ['Source', '-', 'Save', 'NewPage', 'ExportPdf', 'Preview', 'Print', '-', 'Templates'] },
//             { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
//         ]
//     }
// }

// ClassicEditor
//     .create( document.querySelector( '#editor' ), {
//         plugins: [ SourceEditing ],
//         toolbar: [ 'sourceEditing', ]
//     } )
//     .then( )
//     .catch( );

class App extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

    }

    handleChange(event, editor) {
        //updating the modified value only on blur
        const data = editor.getData();
        if (this.props.onChange && typeof this.props.onChange === "function") {
            this.props.onChange(data);
        }
    }

    render() {
        let value = this.props.value ? this.props.value : this.props.submissionData;
        const dataToLoad = value;
        const editorConfig = this.props.EditorConfig ? this.props.EditorConfig : defaultEditorConfig



        return (

            <div>
                {/* <h2>Using CKEditor 5 build in React</h2> */}
                <CKEditor

                    editor={ClassicEditor}
                    data={dataToLoad}
                    config={defaultEditorConfig}

                    onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        //console.log( 'Editor is ready to use!', editor );
                        if(typeof this.props.OnEditorReady ==="function")
                        {
                            this.props.OnEditorReady(editor);
                        }

                    }}
                    onChange={(event, editor) => {
                        //const data = editor.getData();
                        //console.log( { event, editor, data } );
                    }}
                    onBlur={(event, editor) => {
                        //console.log( 'Blur.', editor );
                        this.handleChange(event, editor);
                    }}
                    onFocus={(event, editor) => {
                        //console.log( 'Focus.', editor );
                    }}
                />
            </div>
        );
    }
}

export default App;
