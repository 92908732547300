import React, { Component } from "react";
import ReactDOM from 'react-dom';
import * as dc from "dc";
import * as d3 from "d3";
import { ChartTemplate } from "./chartTemplate";

import { Bar } from "@nivo/bar";

//class BarChartFunc extends React.Component{
const BarChartFunc = (divRef, ndx,config) => {

  // constructor(props) {
  //   super(props);
  // }

  const filterAll=()=>{

  }

  const render=()=>{
    
    const langdata =  [
      {
        id: "java",
        label: "java",
        value: 506,
        color: "hsl(58, 70%, 50%)"
      },
      {
        id: "elixir",
        label: "elixir",
        value: 271,
        color: "hsl(194, 70%, 50%)"
      },
      {
        id: "python",
        label: "python",
        value: 111,
        color: "hsl(56, 70%, 50%)"
      },
      {
        id: "lisp",
        label: "lisp",
        value: 158,
        color: "hsl(19, 70%, 50%)"
      },
      {
        id: "rust",
        label: "rust",
        value: 315,
        color: "hsl(233, 70%, 50%)"
      }
    ];
    var groupKey = config.groupKey;
    //var data=langdata;//ndx.dimension((d) => {return d[groupKey];}).top(Infinity) ;
    

    var data = langdata;//this.props.source;

    return (<div>
          <h1>Sample Bar</h1>
          {/* <Bar
            data={data}
            width= {750}
            height= {400}
            margin= {{ top: 60, right: 80, bottom: 60, left: 80 }}
            indexBy= {"id"}
            keys={["value"]}
            padding= {0.2}
            labelSkipWidth= {16}
            labelSkipHeight= {16}
            valueScale={{ type: 'linear' }}
            colors={{ scheme: 'nivo' }}    
            groupMode={"grouped"}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10
              }
            ]}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                translateY: 50,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000"
                    }
                  }
                ]
              }
            ]}
          /> */}
        </div>);

  }

  return {render:render};
}

const chartWrapperFunc = (divRef, ndx,config) =>{
  return BarChartFunc(divRef,ndx, config);
}

export const BarChart = (props) => (
  <ChartTemplate chartFunction={chartWrapperFunc} title={props.config.title}   config={props.config}/>
);

export default BarChart;
