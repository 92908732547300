import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./CustomViewerWidget.settingsForm";

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const CustomViewerWidgetComp = class extends Component {
  constructor(props) {
    super(props);
    this.state = {      
      value: true,
      myWidgetURI: props.component.myWidgetURI,
      myWidgetWidth:props.component.myWidgetWidth,
      myWidgetHeight:props.component.myWidgetHeight
    };
  }
  setValue = () => {
    this.setState(
      (prevState) => ({
        value: !prevState.value,
        myWidgetURI: "",

      })
      //() => this.props.onChange(null, this.state.value)
    );
  };

  render() {
    return (
        <div width={this.state.myWidgetWidth} height={this.state.myWidgetHeight} 
          dangerouslySetInnerHTML={{
            __html:
              "<iframe src='" +
              (this.state ? this.state.myWidgetURI : this.props.myWidgetURI) +
              "' width='"+ (this.state ? this.state.myWidgetWidth: this.props.myWidgetWidth )+
              "' height='"+(this.state ? this.state.myWidgetHeight: this.props.myWidgetHeight )+"' />"
          }}
        />
    );
  }
};

export default class CustomViewerWidget extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "AG CustomViewer",
      icon: "square",
      group: "basic",
      documentation: "",
      weight: -10,
      schema: CustomViewerWidget.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "customViewerWidgetComp",
      label: "Report",
      logic: [
        {
          "name": "on ReportUrl",
          "trigger": {
            "type": "javascript",
            "javascript": "result= true;"
          },
          "actions": [
            {
              "name": "populateUrl",
              "type": "mergeComponentSchema",
//              "schemaDefinition": "schema = { myWidgetURI:data[data.apiKeyToPick] }"
                "schemaDefinition": "schema = { myWidgetURI:data.reportUrl }"
            }
          ]
        }
      ]
      
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <CustomViewerWidgetComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
