const XLSX = require("xlsx");

const fileParser = {

    getWorkbook: (data) => {
        const workbook = XLSX.read(data);//read columns in first row
        return workbook
    },
    getWorkbookForHeader: (data) => {
        const workbook = XLSX.read(data, { sheetRows: 2 });//read columns in first row
        return workbook
    },
    sheetToJson: (worksheet) => {
        return XLSX.utils.sheet_to_json(worksheet);
    },
    getAllColumns: (data) => {
        const workbook = XLSX.read(data);
        var sheet_name_list = workbook.SheetNames;
        const sheetInfo = {
            sheets: [],
            columnsBySheetIndex: {}
        }
        for (var sheetIndex = 0; sheetIndex < sheet_name_list.length; sheetIndex++) {
            const sheetObj = {
                name: sheet_name_list[sheetIndex],
                sheetIndex: sheetIndex
            }
            let columns = [];
            var worksheet = workbook.Sheets[sheet_name_list[sheetIndex]];
            for (let key in worksheet) {
                let regEx = new RegExp("^\(\\w\)\(1\){1}$");
                if (regEx.test(key) == true) {
                    columns.push(worksheet[key].v);
                }
            }
            sheetInfo.columnsBySheetIndex[sheetIndex] = columns;
            sheetInfo.sheets.push(sheetObj)
        }
        return sheetInfo;
    }
}
export default fileParser