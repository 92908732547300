import React from "react";


import Service from "../../../Services";
import lookUpService from "../../../Services/lookupApi";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";

import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Builder from "../../RenderBuilder";
import { ENTITY_TYPES } from "../../../config/constant";
import DashbaordWrapper from "../../Common/Dashboards";
import { searchComponentsByType } from "../../../Utils/formData"
import { Tabs, Tab } from 'react-bootstrap'
import TableConfiguration from "./TableConfiguration";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    //padding:10
  },

  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  root: {
    // padding:10
  },
});



class BuilderForm extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = props;
    console.log("id", params.id);

    this.state = {
      typeId: Number(params.typeId),
      id: Number(params.id),
      components: [],
      display: "form",
      createMode: true,
      schemaForDB: null,
      name: "",
      description: "",
      apikey: "",
      isDashboardEntity: Number(params.typeId) == 23,
      finalDashboardConfig: null,
      activeTabId: 1,
      tabs: [
        {
          id: 1,
          name: 'General'
        },
        {
          id: 2,
          name: 'Table Configuration'
        },
      ],
      tableConfiguration: {},
      isTableTemplateEntity: Number(params.typeId) == 13,
      tableTemplateColumns: []
    }; //default

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnUISchemaChange = this.handleOnUISchemaChange.bind(this);
    this.handleOnUIDisplayChange = this.handleOnUIDisplayChange.bind(this);
    this.handleJsonChange = this.handleJsonChange.bind(this);
    this.handleupdateFormIoValue = this.handleupdateFormIoValue.bind(this);
    this.handlTableConfigData = this.handlTableConfigData.bind(this);
    this.TableConfigCallBackFn = null;
  }

  async componentDidMount() {
    let item = null;

    let tableTemplateColumns = [];
    //if (this.state.id > 0)
    item = await Service().getEntitiesDetailByEntityTypeId(this.state.typeId, this.state.id);
    if (item) {

      if (this.state.isTableTemplateEntity && this.state.id > 0) {
        const params = {
          customkey: "columnsForImport",
          entityId: this.state.id,
          entityTypeId: this.state.typeId,
          pentityId: -1,
          pentityTypeId: -1
        }
        const list = await lookUpService.getCustomKeyProcedures(params);
        tableTemplateColumns = list;

      }
      let finalDashboardConfig = null;
      if (item.formJSON && item.formJSON.finalDashboardConfig) {
        finalDashboardConfig = item.formJSON.finalDashboardConfig;
      } else {//nneded for migration from old formio to standalone
        if (item.formJSON && Array.isArray(item.formJSON.components)) {
          const existingCustomComps = searchComponentsByType(item.formJSON.components, "dashboardSelect");
          if (existingCustomComps && Array.isArray(existingCustomComps) && existingCustomComps.length > 0) {
            finalDashboardConfig = existingCustomComps[0].defaultValue
          }

        }

      }

      this.setState({
        createMode: false,
        components: item.formJSON ? item.formJSON.components : null,
        display: item.formJSON ? item.formJSON.display : null,
        finalDashboardConfig: finalDashboardConfig,
        typeId: this.state.typeId,
        id: this.state.id,
        name: item.name,
        description: item.description,
        apikey: item.apikey,
        apiKeyRequired: item.apiKeyRequired,
        tableTemplateColumns: [...tableTemplateColumns]
      });
    }
  }

  handleTab(tabId) {

    const activeTabData = this.state.tabs.find(x => x.id == tabId);

    if (activeTabData) {
      this.setState({
        activeTabId: tabId
      })
    }


  }

  handleChange(event) {
    if (event.target.name == 'name' && this.state.id < 0) {
      //for new filling apikey (reference key) from name
      this.setState({
        apikey: event.target.value.replace(/ /g, '')
      })
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleJsonChange(event) {
    let newJson = event.target.value;
    if (newJson) {
      let schema = JSON.parse(newJson);
      this.setState({
        components: schema.components,
        display: schema.display,
        meth: JSON.stringify(schema),
      });
    }
  }

  handleOnUISchemaChange(builder) {
    const { schema, dbSchema } = builder
    this.setState({
      components: schema.components,
      display: schema.display,
      meth: JSON.stringify(schema),
      schemaForDB: dbSchema
    });
  }

  handleOnUIDisplayChange(display) {
    this.setState({ display: display });
  }

  async handleSubmit(event) {
    event.preventDefault();

    let additionalData = {}
    if (typeof this.TableConfigCallBackFn === "function") {
      additionalData.tableConfigData = this.TableConfigCallBackFn()
    }

    let params = {
      formJson: JSON.stringify({
        components: this.state.components,
        display: this.state.display,
        finalDashboardConfig: this.state.finalDashboardConfig
      }),
      schemaForDB: this.state.schemaForDB,
      typeId: this.state.typeId,
      id: this.state.id,
      name: this.state.name,
      description: this.state.description,
      apikey: this.state.apikey,
      additionalData: additionalData
    };
    //if (this.state.createMode) {
    //create new
    let resultId = await Service().saveCustomFormBuilder(params);
    if (resultId > 0) {
      this.setState({
        id:Number(resultId)
      })
      this.props.history.push(`/customBuilder/${this.state.typeId}/${resultId}`);
    }

    // } else {
    //   //save
    // }
  }
  handlTableConfigData(callBackFn) {
    this.TableConfigCallBackFn = callBackFn;
  }

  handleupdateFormIoValue(newData) {
    this.setState({
      finalDashboardConfig: { ...newData }
    })

  }

  render() {
    const { classes } = this.props;
    const showSchemaData = false;
    const EntityInfo = ENTITY_TYPES[this.state.typeId] || {};
    const apiKeyRequied = this.state.apiKeyRequired == 1 ? true : false
    return (



      <div
        container
        component="main"
        justify="center"
      >
        {/* <CssBaseline />         */}

        <div className="page-header1 marb10">
          <h5 >{EntityInfo.name} Builder </h5>
          <div className="btn-toolbar justify-content-end">
            <div className="btn-group float-right">
              {/* <input type="submit" value="Save" /> */}

              <Link className="btn  btn-icon btn-outline-secondary btnAction"
                to={`/customBuilderList/${this.state.typeId}`} Tooltip="Back">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
          </div>
        </div>

        <div className="formio-form">



          <Grid item elevation={6} square style={{ flexGrow: 1 }}>
            <div>

              <form

                className={classes.form}
                // noValidate
                onSubmit={this.handleSubmit}
              >
                <div className="form-group">
                  Name
                  <input
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    autoComplete="name"
                    className="form-control"
                    autoFocus
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  Description
                  <textarea
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="description"
                    label="Description"
                    name="description"
                    autoComplete="description"
                    className="form-control"
                    autoFocus
                    value={this.state.description}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  Reference Key
                  <input
                    variant="outlined"
                    margin="normal"
                    required={apiKeyRequied}
                    fullWidth
                    id="apikey"
                    label="Reference Key"
                    name="apikey"
                    autoComplete="apikey"
                    className="form-control"
                    autoFocus
                    value={this.state.apikey}
                    onChange={this.handleChange}
                  />
                </div>
                {
                  this.state.isDashboardEntity
                  && (this.state.finalDashboardConfig || this.state.id < 0)
                  && <div style={{ minHeight: 500 }}>
                    <DashbaordWrapper updateFormIoValue={this.handleupdateFormIoValue}
                      configValue={this.state.finalDashboardConfig}
                      currentContext={{
                        entityId: this.state.id,// this.props.component.entityId,
                        entityTypeId: this.state.typeId,// this.props.component.entityTypeId,
                        pentityId: -1,// this.props.component.pentityId,
                        pentityTypeId: -1,// this.props.component.pentityTypeId,
                        dashApiKey: "dashApiKey"// this.props.component.key
                      }
                      }
                      isFormIoBuilderRendered={true}
                    ></DashbaordWrapper>
                  </div>
                }


                {
                  !this.state.isDashboardEntity &&
                  <>

                    {//13- table templates
                      this.state.isTableTemplateEntity &&
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={this.state.activeTabId}
                        onSelect={(k) => this.handleTab(k)}
                        className="mb-3"
                      >
                        {this.state.tabs.map(x => {
                          return <Tab eventKey={x.id} title={x.name}>
                            {/* <p>{x.name}</p> */}
                          </Tab>


                        })
                        }
                      </Tabs>
                    }
                    {
                      this.state.activeTabId == 1 &&
                      <Builder
                        Components={this.state.components}
                        Display={this.state.display}
                        OnChange={this.handleOnUISchemaChange}
                        OnDisplayChange={this.handleOnUIDisplayChange}
                      ></Builder>
                    }
                    {
                      this.state.activeTabId == 2 &&
                      <div>
                        {this.state.tableTemplateColumns.length > 0 &&
                          <TableConfiguration
                            Columns={this.state.tableTemplateColumns}
                            onChange={this.handlTableConfigData}
                          ></TableConfiguration>
                        }
                      </div>
                    }
                  </>

                }

                {showSchemaData && !this.state.isDashboardEntity &&
                  <>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="meth"
                      label="meth"
                      name="meth"
                      autoComplete="meth"
                      autoFocus
                      value={this.state.meth}
                      onChange={this.handleJsonChange}
                      rows="10"
                      multiline
                    />
                  </>
                }
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Save
                </Button>
              </form>
            </div>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BuilderForm);
