import React from "react";
//import "./styles.css";
import * as services from "./../../Services/auth";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import sideImage from "./../../assets/default.png";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Loading from "./../../components/Common/Loading"

function Copyright(props) {
  var copyrightInfo = (props && props.data) ? props.data : {};
  if(copyrightInfo && copyrightInfo.comp_name)
  {
    return ( <Typography variant="body2" color="textSecondary" align="center">
           {"Copyright © "}
            <Link color="inherit" href={copyrightInfo.comp_url||''}>
              {copyrightInfo.comp_name}
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
            </Typography>);
   
  }
  else{
    return (<Typography/>)
  };
}

const styles = (theme) => ({
  // table: {
  //   minWidth: 650,
  // },
  // cell:{
  //   fontSize:12
  // }
  root: {
    height: "100vh",
    // maxHeight: "calc(100vh - 64px)",
    maxHeight: "calc(100vh - 0px)",
  },
  image: {
    backgroundImage: `url(${sideImage})`,
    backgroundRepeat: "no-repeat",
    
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  flexRow: {
    //margin: theme.spacing(8, 4),
    display: "flex",
    //flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      username: "",
      password: "",
      clientCode: "",
      onPremises: false,
      database: "",
      authType:"custom",
      databaseList: [
        { name: "Dev", id: 1 },
        { name: "QA", id: 2 },
        { name: "UAT", id: 3 },
      ],
      authTypeList:["Custom"],
      copyrightInfo:{}
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
  }

  async componentDidMount() {
      let config = await services.getLoginConfig();
      window.localStorage.removeItem("envSettings");      
      window.localStorage.removeItem("clientCode");         
      window.localStorage.removeItem("displayName");
      if(config)
      {
        var dnsCode = window.location.host.split(".")[0];
        var dnsSpecific = config.Connections.filter(data => (data.client && data.client.toLowerCase() === dnsCode.toLowerCase()));
        //if DNS Specific DB list available, use the filtered list else use full list
        if(dnsSpecific.length>0){
          config.Connections = dnsSpecific;
        }

        this.setState({
          databaseList:config.Connections,
          onPremises:Boolean(config.onPremise),
          loading:false,
          copyrightInfo:(config.Settings&&config.Settings.copyright?config.Settings.copyright:{})
        })

        var defaultCfg = this.state.databaseList[0];
        var authType = (defaultCfg.authTypes && Array.isArray(defaultCfg.authTypes) && defaultCfg.authTypes.length>0) ? defaultCfg.authTypes[0] : ["Custom"];
        if(this.state.databaseList.length===1)
        {
          if(defaultCfg.authTypes)
          {
            if(defaultCfg.authTypes.length>1)
            {
              this.setState({
                database:defaultCfg.name,
                clientCode:defaultCfg.name,
                authTypeList: defaultCfg.authTypes
              });
            }
            else if(defaultCfg.authTypes.length ===1)
            {              
                if(authType.toLowerCase() ==="windows")
                {
                  let userInfo = await services.getCurrWinUserInfo();
                  if(userInfo && userInfo.user)
                  {
                    this.setState({
                      database:defaultCfg.name,
                      clientCode:defaultCfg.name,
                      authTypeList: defaultCfg.authTypes,
                      authType:authType,
                      username:userInfo.user,
                      password:"W1nD3faultP@ss"
                    });                     
                  }
                }
                else
                {

                  this.setState({
                    database:defaultCfg.name,
                    clientCode:defaultCfg.name,
                    authTypeList: defaultCfg.authTypes,
                    authType: defaultCfg.authTypes[0]
                  });
                }
            }
          }
          else
          {
            this.setState({database:defaultCfg.name,clientCode:defaultCfg.name,onPremises:false});            
          }
          
        }
        else if(this.state.databaseList.length > 1)
        {                          
          if(authType.toLowerCase() === "windows")
          {
            let userInfo = await services.getCurrWinUserInfo();
            if(userInfo && userInfo.user)
            {
              this.setState({
                database:defaultCfg.name,
                authTypeList: defaultCfg.authTypes,
                authType:authType,
                username:userInfo.user,
                password:"W1nD3faultP@ss"
              });                     
            }
          }
          else
          {
            this.setState({
              database:defaultCfg.name,
              authTypeList: defaultCfg.authTypes,
              authType: authType
            });
          }            
          
        }
        if(config.Settings)
          window.localStorage.setItem("envSettings",JSON.stringify(config.Settings));          
          if(config.Settings)
          {
            document.title=config.Settings.appTitle?config.Settings.appTitle:'Simplifying..';
            this.setState({
              loginText:config.Settings.loginText?config.Settings.loginText : "Sign in",
              loginBGImage:config.Settings.loginBGImage
            });
          }
          
      }

  }

  async handleChange(event) 
  {     
    //event.preventDefault();
    const targetName = event.currentTarget.name, targetVal = event.currentTarget.value;

    this.setState({
      [targetName]: targetVal,
    });

    if(targetName.toLowerCase() === "authtype" && targetVal.toLowerCase() ==="windows")
    {
      let userInfo = await services.getCurrWinUserInfo();
      if(userInfo && userInfo.user){
          this.setState({username:userInfo.user,authType:targetVal,password:"W1nD3faultP@ss"});
      }
    }
    else if(targetName.toLowerCase() === "database" && this.state.databaseList.find((item) => item.id === targetVal).authTypes)
    {
      var currCfg = this.state.databaseList.find((item) => item.id === targetVal);
      var authType = currCfg.authTypes[0];
      this.setState({database:targetVal, authTypeList: currCfg.authTypes, authType:authType});

      if(authType.toLowerCase() ==="windows")
      {
        let userInfo = await services.getCurrWinUserInfo();
        if(userInfo && userInfo.user)
        {
          this.setState({username:userInfo.user,password:"W1nD3faultP@ss"});         
        }
      }      
    }

  }

  handleChecked(event) {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    console.log("fromState", this.state);
    const data = {
      username: this.state.username,
      clientCode: this.state.clientCode,
      password: this.state.password,
      database:this.state.database,
      authtype:this.state.authType
    };
    this.setState({loading:true});
    const result = await services.Login(data);
    this.setState({loading:false});
    if(result && result.token){
      this.props.userHasAuthenticated(true);
      localStorage.setItem("formActivePages", JSON.stringify({}))//clear track of opened items
      const redirectParam = new URLSearchParams(this.props.location.search).get(
        "redirect"
      );
      if (redirectParam && redirectParam>1) this.props.history.push(redirectParam);
      else if(result.defaultUrl)
      this.props.history.push(result.defaultUrl);
      else this.props.history.push("/navbar");
      
      window.location.reload(true);//need to comeup with better mechanism to fire layout change event      

    }
    else{
      toast.error("Invalid Login");
    }
  }

  render() {
    const classes = this.props.classes;
    console.log("rendered", new Date());
    console.log("props", classes);
    return (
      // <div className="dynamicForm">
      //   <form onSubmit={this.handleSubmit}>
      //     <div className="formMain">
      //       <h2>Login Form WIP</h2>
      //       <input type="submit" value="Login now" />
      //     </div>
      //   </form>
      // </div>
      <Grid container component="main" className={classes.root} style={{backgroundImage: `url(${this.state.loginBGImage})`,backgroundSize: "cover"}}>
        <CssBaseline />
        <div className="container">
        <div item xs={12} sm={8} md={5} className="form-signin animate__animated animate__zoomIn" component={Paper} elevation={6} square>
          <div>


            <div className={classes.flexRow}>
              <h5>{this.state.loginText}</h5>
              {/* <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={this.handleChecked}
                      aria-label="login switch"
                    />
                  }
                  //label={this.state.onPremises ? "OnPremise" : "Login"}
                  name="onPremises"
                  label={"OnPremise"}
                />
              </FormGroup> */}
            </div>
         {this.state.loading && <Loading></Loading>}
         {!this.state.loading &&   (<form
              className={classes.form}
              // noValidate
              onSubmit={this.handleSubmit}
            >
              {(!this.state.onPremises||this.state.databaseList.length==1) && (
                <div className="input-group">
                <span className="input-group-text">
                 <span className="feather icon-server"></span>
               </span>
                <input
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="clientCode"
                  label="Client Code"
                  name="clientCode"
                  autoComplete="clientCode"
                  autoFocus                  
                  value={this.state.databaseList.length==1?this.state.clientCode:""}  
                  disabled={this.state.databaseList.length==1}
                  onChange={this.handleChange}
                  className="form-control"
                />
                </div>
              )}
              {(this.state.onPremises||this.state.databaseList.length>1) && (
                
                
                  <div className="input-group" hidden={this.state.databaseList.length <= 1} >
                     <span className="input-group-text">
                      <span className="feather icon-server"></span>
                    </span>
                    <select className="form-control" name="database"
                      value={this.state.database}
                      required
                      onChange={this.handleChange}>
                      {this.state.databaseList.map((item) => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                      ))}
                    </select>
                   
                  </div>
              )}
                <div className="input-group">
                <span className="input-group-text">
                    <span className="feather icon-user"></span>
                  </span>
                  <input
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={this.state.username}
                    id="username"
                    placeholder="User Name"
                    label="username"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    onChange={this.handleChange}
                    disabled={this.state.authType.toLocaleLowerCase()=="windows"}
                    className="form-control"
                  />
                  
                </div>
              
                <div className="input-group">
                <span className="input-group-text">
                    <span className="feather icon-lock"></span>
                  </span>
                  <input
                    variant="outlined"
                    margin="normal"
                    // required={this.state.authType.toLowerCase()!="windows"}
                    fullWidth
                    name="password"
                    label="Password"
                    placeholder="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={this.handleChange}                    
                    disabled={this.state.authType.toLocaleLowerCase()=="windows"}
                    value={this.state.password}
                    className="form-control"
                  />
                  
                </div>
{/* 
                {(this.state.database.length>0 
                    && this.state.authTypeList.length>0) && (                 */}
                
                    <div className="input-group" hidden={this.state.authTypeList.length <= 1} >
                      <span className="input-group-text">
                        <span className="feather icon-shield"></span>
                      </span>
                      <select className="form-control" name="authType"
                        value={this.state.authType.toLowerCase()}
                        required
                        onChange={this.handleChange}>
                        {this.state.authTypeList.map((authType) => (
                          <option key={authType.toLowerCase()}
                                  value={authType.toLowerCase()}>{authType.toLowerCase()}</option>
                        ))}                        
                      </select>
                    
                    </div>
                {/* )} */}

                <div className="form-group">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="btn btn-primary"
                  >
                   <span className="feather icon-log-in"></span>
                  </Button>
                </div>         
              
                <div className="form-group"><br/>
                 {/*  
                  <div className=" pull-left forgotPassword" > <a href="#">Forgot password?</a></div>

                  <div className="form-group text-right signUp">
                  <span><a href="#">Sign Up </a></span>
                </div>*/}
                </div> 
                <div  className="clearfix"></div>



              <Box mt={2}>
                <Copyright data={this.state.copyrightInfo}/>
              </Box>
            </form>
         )} </div>
        </div>
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(Login);
