import React from "react";
//import DBService from "../../../Services/dexieForm";
import Service from "../../../Services";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
//import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";
import CustomList from "../../Common/List";
import { Link } from "react-router-dom";
import { Dashboard } from "@material-ui/icons";
import { ENTITY_TYPES } from "../../../config/constant";
const styles = (theme) => ({
  table: {
    minWidth: 650,
  },
  cell: {
    fontSize: 12,
  },
  alignItems: {
    display: "flex",
    //width: 600,
    //"justify-content": "space-between",
    "padding-bottom": 20,
    flexDirection: "row",
    //flexWrap: "nowrap"
    justifyContent: "space-between",
  },
  formDiv: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
  },
});



class CustomFormsList extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = this.props;
    
    this.state = { builderFormsList: [], page: 0, rowsPerPage: 5,typeId:Number(params.typeId) }; //default

    this.handleClick = this.handleClick.bind(this);
    this.handleClickNew = this.handleClickNew.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowsPerPageChange = this.handleRowsPerPageChange.bind(this);
  }

  async componentDidMount() {


    await this.getData(0, this.state.rowsPerPage);
  }

  async handlePageChange(event, newPage) {
    await this.getData(newPage, this.state.rowsPerPage);
  }

  async handleRowsPerPageChange(event) {
    const noOfRows = parseInt(event.target.value, 10);
    //setRowsPerPage(parseInt(event.target.value, 10));
    //setPage(0);
    await this.getData(0, noOfRows);
  }

  async getData(page, rowsPerPage, searchText) {
    //DBService.initialize();
    let list = await Service().getCustomFormBuilderListByTypeId(this.state.typeId); //pass one to get the existing
    if (list) {
      list = list.map((x) => {
        return {
          name: x.name,
          path: `/template/${this.state.typeId}/${x.id}`,
          handleClick: () => {
            this.props.history.push(`/template/${this.state.typeId}/${x.id}`);
          },
        };
      });

      this.setState({
        builderFormsList: list,
        page: page,
        rowsPerPage: rowsPerPage,
      });
    }
  }

  handleClick(item) {    
    this.props.history.push(`/template/${this.state.typeId}/${item.id}`);
  }
  handleClickNew(item) {
    this.props.history.push(`/template/${this.state.typeId}/-100`);
  }

  render() {
    const list = this.state.builderFormsList;
    const { classes, displayCards } = this.props;
    const EntityInfo= ENTITY_TYPES[this.state.typeId]||{};
    return (
      <div className="pageHeaderPanel">
        <div className="page-header1">
             <h5>{EntityInfo.name} List</h5> 
            <div className="btn-toolbar justify-content-end">
             <div className="dt-buttons btn-group float-right">
              {/* <input type="submit" value="Save" /> */}
              <a className="btn  btn-icon btn-outline-secondary btnAction" href="/navbar"
                Tooltip="Back">
                <i className="fas fa-arrow-left"></i>
              </a>&nbsp;
            </div>
            </div>
            {/* <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleClickNew}
            >
              Add
            </Button> */}
        </div>
        {list.length > 0 && <CustomList
          Data={list}
          DisplayCard={displayCards}
          Pagination={{
            count: list.length,//this.state.totalRecordsCount,
            page: this.state.page,
            rowsPerPage: this.state.rowsPerPage,
            handleChangePage: this.handlePageChange,
            handleChangeRowsPerPage: this.handleRowsPerPageChange,
          }}
        ></CustomList>}
        {list.length == 0 &&
          <div className="tablePanel-body noPanelBody">
            <h2>No builder Forms</h2>
          </div>
        }
      </div>
    );
  }
}

export default withStyles(styles)(CustomFormsList);
