import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function AuthenticatedRoute({ component: C, appProps, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        appProps.isAuthenticated ? (
          <Layout {...props} {...appProps}>
            <C {...props} {...appProps} />
          </Layout>
        ) : (
          <Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`} />
        )
      }
    />
  );
}
