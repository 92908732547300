import axios from "axios";



export async function Login(payload) {
  const url = "/auth/login";
  try {
    const data = {      
      username: payload.username,
      password: Buffer.from(payload.password).toString('base64'),      
      clientCode:payload.clientCode,
      authtype:payload.authtype?payload.authtype:"custom"
    };
    // if(payload.clientCode)
    //   data.clientCode= payload.clientCode
  if(payload.database)      
    data.clientCode= payload.database;
    
    //for login calls : enabled cookie retention to share with other apps 
    const response = await axios.post(url, data,{     
      credentials: 'include'
    });
    //console.log(response.data);   

    window.localStorage.setItem("token", response.data.token);         
    window.localStorage.setItem("clientCode",payload.clientCode?payload.clientCode:payload.database);     
    window.localStorage.setItem("displayName",payload.username);
    window.localStorage.setItem("loginType", response.data.loginType); 
    window.localStorage.setItem("userRoleType", response.data.userRoleType);
    window.localStorage.setItem("defaultHomeUrl", response.data.defaultUrl);
    global.DisplayName = window.localStorage.getItem("displayName");
    global.ClientCode = window.localStorage.getItem("clientCode");
    window.localStorage.setItem("navMenuItems", JSON.stringify(response.data.menuItems));     
    return response.data;
  } catch (e) {
    console.log(e);
  }
}

export async function formLogin(payload) {
  const url = "/auth/formlogin";
  try {
    const data = {      
      // username: payload.username,
      // password: payload.password,      
      clientCode:payload.clientCode
    };
    
  if(payload.database)      
    data.clientCode= payload.database;
    
    //for login calls : enabled cookie retention to share with other apps 
    const response = await axios.post(url, data,{     
      credentials: 'include'
    });
    //console.log(response.data);   

    window.localStorage.setItem("token", response.data.token);         
    window.localStorage.setItem("clientCode",payload.clientCode?payload.clientCode:payload.database);     
    window.localStorage.setItem("displayName",payload.username);
    window.localStorage.setItem("loginType", response.data.loginType); 
    window.localStorage.setItem("userRoleType", response.data.userRoleType);
    global.DisplayName = window.localStorage.getItem("displayName");
    global.ClientCode = window.localStorage.getItem("clientCode");
    
    window.localStorage.setItem("navMenuItems", JSON.stringify(response.data.menuItems));     
    return response.data;
  } catch (e) {
    console.log(e);
  }
}

export async function getCurrWinUserInfo() {
  const url = "/auth/getcurrwinuserinfo";
  const apiBase = process.env.REACT_APP_API_ROOT ? process.env.REACT_APP_API_ROOT : "http://localhost:5000/api";
  try {    
    const instance = axios.create({
        withCredentials: true,
        baseURL: apiBase
      });    
    let response = await instance.get(url);
    return response.data;
  } catch (e) {
    console.log(e);
  }
}

export async function getLoginConfig(payload) {
  const url = "/auth/config";
  try {
   
    const response = await axios.get(url);
    return response.data;
  } catch (e) {
    console.log(e);
  }
}
