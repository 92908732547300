import React from "react";
import DBService from "../../../Services/dexieForm";
import { Link } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
//import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography"

const styles = (theme) => ({
  table: {
    minWidth: 650,
  },
  cell:{
    fontSize:12
  },
  alignItems: {
    display: "flex",
    //width: 600,
    //"justify-content": "space-between",
    "padding-bottom": 20,
    flexDirection:"row",
    //flexWrap: "nowrap"
    "justifyContent": "space-between"
  },
  formDiv:{
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column", 
  }
});

class RegisterInstanceList extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;

    this.state = { builderFormsList: [], registerId:params.id }; //default

    this.handleClick = this.handleClick.bind(this);
    this.handleClickNew = this.handleClickNew.bind(this);
  }

  async componentDidMount() {
    DBService.initialize();
    let list = await DBService.getRegisterInstanceListByRegisterId(this.state.registerId);
    this.setState({ builderFormsList: list });
  }

  handleClick(item) {
    console.log("clicked Item - ", item);
    this.props.history.push(`/registerInstance/${item.registerId}/${item.metaId}/${item.id}`);
  }
  async handleClickNew(item) {
   
    const registerItem=await DBService.getUniverse(this.state.registerId);
    this.props.history.push(`/registerInstance/${this.state.registerId}/${registerItem.frameworkId}/-100`);
  }

  render() {
    const list = this.state.builderFormsList;
    const {classes} = this.props;
    return (
      <div className="pageHeaderPanel">
        <div className="page-header1">
          <h5>Register Instance List</h5>
            {/* <Link to="/framework/-100">Add New</Link> */}
            <Button
                type="submit"
                
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.handleClickNew}
              >
                Add
              </Button>
          </div>
        {list.length > 0 && (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell><b>Name</b></TableCell>
                  <TableCell align="right"><b>Actions</b></TableCell>                 
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                    
                      <Button onClick={() => this.handleClick(item)}>{item.name}</Button>
                       
                    </TableCell>
                    
                    <TableCell className={classes.cell} align="right">
                    <Link to={`/registerInstance/${item.registerId}/${item.metaId}/${item.id}`}>Edit</Link>
                    </TableCell>
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {list.length == 0 &&
        <div className="tablePanel-body noPanelBody">
           <h2>No  forms</h2>
        </div>
        }
      </div>
    );
  }
}

export default withStyles(styles)(RegisterInstanceList);
