import React from "react";
//import DBService from "../../../Services/dexieForm";
import Service from "../../../Services";
import { Link } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
//import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  table: {
    minWidth: 650,
  },
  cell: {
    fontSize: 12,
  },
  alignItems: {
    display: "flex",
    //width: 600,
    //"justify-content": "space-between",
    "padding-bottom": 20,
    flexDirection: "row",
    //flexWrap: "nowrap"
    justifyContent: "space-between",
  },
  formDiv: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
  },
});

class FrameworkList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { builderFormsList: [] }; //default

    this.handleClick = this.handleClick.bind(this);
    this.handleClickNew = this.handleClickNew.bind(this);
  }

  async componentDidMount() {
    //DBService.initialize();
    let list = await Service().getFrameworkList();
    this.setState({ builderFormsList: list });
    
    // var result = await Service.Graphql(`{
    //   Helper {
    //           getFrameworkList{
    //             Name,
    //             FrameworkFile,
    //             FrameworkID
    //           }
    //       }      
    //     }`);
    // console.log(result);
  }

  handleClick(item) {
    console.log("clicked Item - ", item);
    this.props.history.push(`/framework/${item.id}`);
  }
  handleClickNew(item) {
    this.props.history.push(`/framework/-1`);
  }

  render() {
    const list = this.state.builderFormsList;
    const { classes } = this.props;
    return (
      <div className="pageHeaderPanel">
        <div className="page-header1">
            <h5> Framework List</h5>
            <div className="btn-toolbar justify-content-end ">
            {/* <Link to="/framework/-100">Add New</Link> */}
            <a  className="btn  btn-icon btn-outline-secondary btnAction"
                to={`/navbar`} Tooltip="Back">
                <i className="fas fa-arrow-left"></i>
            </a>&nbsp;
            <a
              type="submit"
              variant="contained"
              color="primary" 
              className="btn  btn-icon btn-outline-primary btnAction"
              onClick={this.handleClickNew}
            >
              <i className="fas fa-plus"></i>
            </a>
          
          </div>
        </div>
        {list.length > 0 && (
          <div className="tablePanel-body  paneltopTable">
          <div component={Paper} className="table-responsive fixtableWindow">



            <table  className="table table-hover m-b-0" aria-label="simple table">
              <thead className="headerGrid">
                <tr className="table-primary">
                  <th>Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item) => (
                  <tr key={item.id}>
                    <td>
                    <Link href="" style={{ textTransform: 'none' }} onClick={() => this.handleClick(item)}>{item.name}</Link>
                    </td>
                    <td className={classes.cell} >
                    <button className="btn  btn-icon btn-outline-primary btnAction"> <Link to={`framework/${item.id}`}><i class="fas fa-pen" aria-hidden="true"></i></Link> </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          </div>
        )}
        {list.length == 0 &&
          <div className="tablePanel-body noPanelBody">
            <h2>No Frameworks</h2>
          </div>
        }
      </div>
    );
  }
}

export default withStyles(styles)(FrameworkList);
