
import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField";
import lookUpService from "./../../../../Services/lookupApi";

class HeatMapChartConfig extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleDataSourceSelection = this.handleDataSourceSelection.bind(this);
        this.handleColumnSelection = this.handleColumnSelection.bind(this);
    }

    handleChange(val) {

    }

    handleTextChange(event) {

        let value = event.target.value;

        // this.setState({
        //     [event.target.name]: value,
        // });
        this.updateParent({
            [event.target.name]: value
        })
    }

    updateParent(modifiedObj) {
        if (this.props.onConfigChange)
            this.props.onConfigChange(modifiedObj, this.props.id)
    }

    handleDataSourceSelection(event) {

        let objToModify = {
            entityId: -1,
            entityTypeId: -1,
            groupKey: ''
        }

        let value = event.target.value;
        const { dataSources } = this.props.metaData;
        const selectedDataSource = dataSources.find(x => x.entityId == value);

        if (selectedDataSource) {
            objToModify.entityId = selectedDataSource.entityId;
            objToModify.entityTypeId = selectedDataSource.entityTypeId;
        }
        this.updateParent(objToModify);
    }

    async handleColumnSelection(groupKeys, event) {
        let value = event.target.value;

        const stepItemObj = groupKeys.find(x => x.StepItemKey === value);
        let stepItemLookUpsObj = await this.getLookUpValues(stepItemObj);

        this.updateParent({
            [event.target.name]: value,
            [value]: {
                ...stepItemLookUpsObj
            }
        })
    }

    async getLookUpValues(stepItemObj) {
        const { entityTypeId, entityId } = this.props.chartConfig
        if (entityId > -1 && entityTypeId > -1 && stepItemObj?.StepItemid > -1) {

            const list = await lookUpService.getLookupValueBystepitemId({
                entityId: entityId,
                entityTypeId: entityTypeId,
                stepItemId: stepItemObj.StepItemid
            });
            // console.log(list);
            if (Array.isArray(list)) {
                //convert array to object of key and values
                return list.reduce((p, c) => {
                    //if(c.id>)
                    p[c.id] = c.name;
                    return p;
                }, {});
            }
        }

        return {};
    }

    render() {

        const { title, xKey, yKey, entityTypeId, entityId } = this.props.chartConfig
        const {
            groupKeyObj,
            dataSources
        } = this.props.metaData;

        let groupKeys = [];
        if (groupKeyObj[`${entityTypeId}_${entityId}`]) {
            groupKeys = groupKeyObj[`${entityTypeId}_${entityId}`];
        }
        return (
            <>
                <div className="dashboardForm">
                    <div className="form-group">
                        {/* Pie chart config - {config.chartType} */}
                        Title:  <input
                           className='form-control'
                            variant="outlined"
                            margin="normal"
                            id="title"
                            label="Heat Map title"
                            name="title"
                            autoComplete="title"
                            autoFocus
                            value={title}
                            onChange={this.handleTextChange}
                        />
                    </div>
                    <div className='row form-group'>
                        <div className="col-md-4">
                            Data Sources:
                            <select className="form-control"
                                value={entityId} onChange={this.handleDataSourceSelection}
                            >
                                <option value="-1">Select</option>
                                {dataSources.map(x =>
                                    <option value={x.entityId} key={x.entityId}>
                                        {x.displayName}
                                    </option>
                                )
                                }
                            </select>
                        </div>
                    
                        <div className="col-md-4">
                            X key:
                            <select className="form-control"
                                name="xKey"
                                value={xKey} onChange={(e) => this.handleColumnSelection(groupKeys, e)}
                            >
                                {groupKeys.map(x =>
                                    <option value={x.StepItemKey} key={x.StepItemKey}>
                                        {x.StepItemName}
                                    </option>
                                )
                                }
                            </select>
                        </div>
                        <div className="col-md-4">
                            Y key:
                            <select className="form-control"
                                name="yKey"
                                value={yKey} onChange={(e) => this.handleColumnSelection(groupKeys, e)}
                            >
                                {groupKeys.map(x =>
                                    <option value={x.StepItemKey} key={x.StepItemKey}>
                                        {x.StepItemName}
                                    </option>
                                )
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </>)
    }
}

export default HeatMapChartConfig;