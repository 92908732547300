import { Utils } from "formiojs";
import ApiService from "../Services/api"

const lodashObj = require("lodash/fp/object");
const hoek = require("@hapi/hoek");

export function dataToSave(components, submission) {
  //const ShcemaText = JSON.stringify(flattenedComponents, null, 4);
  const flattenedComponents = getFlattenedComponents(components);

  var data = Object.keys(flattenedComponents).reduce((obj, key) => {
    //console.log(`key-${key} - ${lodashObj.get(submission,key)}`);
    //obj[key]=lodashObj.get(submission,key);
    obj[key] = hoek.reach(submission, key);
    return obj;
  }, {});
  return data;
}

export function compareData(components, beforeChange, latestObj) {

  const flattenedComponents = getFlattenedComponents(components);
  let modified = false;
  var ControlTypesToCompare = ["textfield"];
  Object.keys(flattenedComponents).map((key) => {

    if (ControlTypesToCompare.indexOf(flattenedComponents[key].type) > -1) {
      let oldVal = hoek.reach(beforeChange, key);
      let newVal = hoek.reach(latestObj, key);
      if (!oldVal)//assign default values for formio when not present
        oldVal = "";
      if (!newVal)
        newVal = "";
      if (oldVal != newVal)
        modified = true;
    }

  });
  return modified;
}

export function getFlattenedComponents(components) {
  let flattenedComponents = Utils.flattenComponents(components, false);
  let KeysWithoutPeriod = Object.keys(flattenedComponents).filter(
    (x) => {

      return (x.indexOf(".") === -1 || flattenedComponents[x].type === 'container')
    }
  );
  console.log("Before", Object.keys(flattenedComponents).length);
  if (Array.isArray(KeysWithoutPeriod)) {
    KeysWithoutPeriod.forEach((keyToDelete) => {
      delete flattenedComponents[keyToDelete];
    });
    console.log("After", Object.keys(flattenedComponents).length);
  }
  return flattenedComponents;
}
function translateObjValueByKey(srcObj, key, opType) {
  for (var i in srcObj) {
    if (!srcObj.hasOwnProperty(i)) continue;
    if (i == key) {
      //srcObj[key] = (opType == 'json' ? JSON.parse(srcObj[key]) : JSON.stringify(srcObj[key]));
      if(opType == 'json')
      {
        if(typeof srcObj[key] != 'object'){
          srcObj[key] = JSON.parse(srcObj[key]);
        }
      }
      else{
        if(typeof srcObj[key] == 'object'){
          srcObj[key] = JSON.stringify(srcObj[key]);
        }
      }
    }
    else if (typeof srcObj[i] == 'object') {
      translateObjValueByKey(srcObj[i], key, opType);
    }
  }
  return srcObj;
}

export function searchComponentsByType(components, typeName) {
  return Utils.searchComponents(components, { type: typeName });
}

export function transformFieldValuesByType(components, data, fieldType, opType) {  
  let fileFieldsData = searchComponentsByType(components, fieldType);
  fileFieldsData.map((fileComp, idx) => {
    var fldKey = fileComp.key;
    var updChildObj = translateObjValueByKey(data, fldKey, opType);
  });
}
export async function calculateItems(meth, entityParams, attributeKey) {
  try {

    let result = [];

    console.log("keys", Object.keys(meth));
    const attributeElements = getAttributeKeyElements(
      meth.components,
      meth.columns,
      attributeKey
    );
    console.log("attributeElementsLength", attributeElements.length);
    if (attributeElements.length > 0) {
      console.log("elemen", attributeElements);
      for (let i = 0; i < attributeElements.length; i++) {
        const selectElement = attributeElements[i];

        const customKey = selectElement.attributes[attributeKey]

        const params = {
          elementKey: customKey,
          frameworkId: entityParams.frameworkId,
          entityId: entityParams.entityId,
          entityTypeId: entityParams.entityTypeId,
          pentityId: entityParams.pentityId,
          pentityTypeId: entityParams.pentityTypeId,
        }

        const value = await ApiService.getCalculatedValueForEntity(params);
        result.push({
          key: selectElement.key,
          value: value
        })

      }
      console.log("elemenLen", attributeElements.length);
    }
    return result
  } catch (e) {
    console.log(e);
  }
}

///finds deep elements with attribute key
export function getAttributeKeyElements(
  components = [],
  columns = [],
  attributeKey = ""
) {
  var elements = [];
  if (Array.isArray(components)) {
    const items = components.filter(
      (item) => item.attributes && item.attributes[attributeKey]
    );

    console.log(components.map((item) => item.key))
    elements.push(...items);

    components.map((item) => {
      const nestedItems = getAttributeKeyElements(
        item.components,
        item.columns,
        attributeKey
      );
      elements.push(...nestedItems);
    });
  }


  if (Array.isArray(columns)) {
    const items = columns.filter(
      (item) => item.attributes && item.attributes[attributeKey]
    );

    console.log(columns.map((item) => item.key))
    elements.push(...items);

    columns.map((item) => {
      const nestedItems = getAttributeKeyElements(
        item.components,
        item.columns,
        attributeKey
      );
      elements.push(...nestedItems);
    });
  }

  return elements;
}

export function bindCalculatedItems(obj, calculatedItems) {

  if (!Array.isArray(calculatedItems) || typeof obj !== 'object') {
    return null;
  }

  let isModified = false;
  calculatedItems.map((item) => {
    const { key = null, value = "" } = item;
    const propLevelObj = getPropLevelObject(obj, key)

    if (propLevelObj) {
      isModified = true;
      propLevelObj[key] = value //replacing the value here
    }
  })
  if (isModified)
    return obj;
  return null;
}

export function getPropLevelObject(obj, key) {

  if (!obj)//added the null check to fix the errors
    return null
  if (typeof obj === "object" && obj.hasOwnProperty(key)) {
    return obj;
  }
  for (let i = 0; i < Object.keys(obj).length; i++) {
    const propName = Object.keys(obj)[i]
    if (typeof obj[propName] === 'object') {
      let levelObj = getPropLevelObject(obj[propName], key)
      if (levelObj != null) {
        return levelObj;
      }
    }
  }
  return null

}

export function setElementToFocus(apikey) {
  localStorage.setItem("elementToFocus", apikey)
}

export function getElementToFocus() {
  return localStorage.getItem("elementToFocus");
}