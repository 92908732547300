import React, { Component } from "react";
import GridColors from "../GridColors";


class ColorGridComp extends Component {
    constructor(props) {
        super(props);

        let value = props.value ? props.value : props.submissionData;
        if (!value) {
            value = {}
        }
        this.state = {
            rows: value.rows || 3,
            columns: value.columns || 3,
            data: value.data || []
        };
        this.handleClickNew = this.handleClickNew.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDataChange = this.handleDataChange.bind(this);
    }

    async componentDidMount() {

    }
    setValue = () => {
        let dataToSave = {
            rows: this.state.rows,
            columns: this.state.columns,
            data: this.state.data
        }
        this.props.onChange(dataToSave)
    };

    handleClickNew(item) {

    }

    handleDataChange(coldata) {
        this.setState({ data: [...coldata] }, this.setValue)
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        }, this.setValue)
    }
    render() {
        const { hideSingleSelectColor } = this.props.component

        return (
            <div className="pageHeaderPanel">
                <div className="page-header1">
                    <h1>Grids</h1>
                    <div className="row input-group mb-3 input-group-sm">
                        <div className="col-md-6 form-row">
                            <input type="text" className="form-control"
                                required
                                id="rows"
                                placeholder="Rows"
                                name="rows"
                                autoComplete="3"
                                autoFocus
                                value={this.state.rows}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-md-6 form-row">
                            <input type="text" className="form-control"
                                required
                                id="columns"
                                placeholder="Columns"
                                name="columns"
                                autoComplete="3"
                                autoFocus
                                value={this.state.columns}
                                onChange={this.handleChange}
                            />
                        </div>

                    </div>
                    <div>
                        <GridColors onChange={this.handleDataChange}
                            Rows={this.state.rows}
                            Columns={this.state.columns}
                            Data={this.state.data}
                        ></GridColors>
                    </div>
                </div>
            </div>
        );
    }
}
export default ColorGridComp