import React from "react";

import DBService from "../../../Services/dexieForm";
import Service from "../../../Services/api";

import { withStyles } from "@material-ui/core/styles";
//import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";

import Paper from "@material-ui/core/Paper";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    //minWidth: 120,
    //padding:10
  },

  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
  },
  button: {
     marginTop:50
  },
});

class RegisterList extends React.Component {
  constructor(props) {
    super(props);

    this.state = { name: "", frameworkId: "", id: -100, frameworkList:[] }; //default

    this.handleChange = this.handleChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    console.log("id", params.id);
    DBService.initialize();
    
    const item = await DBService.getUniverse(params.id);
    if (item) {
      this.setState({
        frameworkId: item.frameworkId,
        name: item.name,
        id: params.id,
      });
    }

    //const frameworkList=await DBService.getFrameworkList();
    const frameworkList=await Service.getFrameworkList()
    console.log('List>>>',frameworkList);
    this.setState({
      frameworkList: frameworkList
    });

  }

  handleChange(event) {
    
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    console.log("A form was submitted: " + JSON.stringify(this.state));
    DBService.initialize();

    const id = this.state.id;

    if (id == -100) {
      //add
      const newId = await DBService.addUniverse({
        name: this.state.name,
        frameworkId: this.state.frameworkId,
      });
      console.log("newId", newId);
      //this.props.history.push(`/build/${newId}`);
      this.props.history.push(`/registerList`);
    } else {
      //update
      const updated = await DBService.updateUniverse(id, {
        name: this.state.name,
        frameworkId: this.state.frameworkId,
      });
      console.log("updated", updated);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        component="main"
        justify="center"
        component={Paper}
        className={classes.root}
      >
        {/* <CssBaseline />         */}
        <Grid item elevation={6} square>
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Register
            </Typography>
            <form
              className={classes.form}
              // noValidate
              onSubmit={this.handleSubmit}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="name"
                name="name"
                autoComplete="name"
                autoFocus
                value={this.state.name}
                onChange={this.handleChange}
              />
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="demo-simple-select-label">Framework</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="frameworkId"
                  value={this.state.frameworkId}
                  required
                  onChange={this.handleChange}
                >
                  {
                    this.state.frameworkList.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))
                  }
                  
                </Select>
              </FormControl>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Save
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(RegisterList);
