import logo from "./logo.svg";
import React, { useContext, useState, useEffect } from "react";
import * as actionType from "./store/actions";
import "./App.css";
import { ToastContainer, } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JWT from "./Utils/jwt";
import lookupApi from "./Services/lookupApi"

import {
  Prompt,
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
} from "react-router-dom";

import { Detector } from "react-detect-offline";
import Builder from "./components/Builder";
import BuilderList from "./components/BuilderList";
import MyForm from "./components/Form";
import FormList from "./components/FormList";

//import './assets/bootstrap/bootstrap.min.css';
import './assets/bootstrap/minty_bootstrap.min.css';
import 'formiojs/dist/formio.full.min.css';
import Routes from "./routes";
import Modal from './components/Common/Modal'
import { ConfigContext } from "./contexts/ConfigContext";

function NewApp(props) {
  console.log("NewApp called");
  const isValid = JWT.verify();
  const configContext = useContext(ConfigContext);
  const [isAuthenticated, userHasAuthenticated] = useState(isValid);
  const [displayCards, setDisplayCards] = React.useState(false);//true
  const [status, setStatusBase] = React.useState("");
  const [showDialog, setshowDialog] = React.useState(false);
  const [nextLocation, setnextLocation] = React.useState('');
  const [confirmedNavigation, setconfirmedNavigation] = React.useState(false);

  const setStatus = (msg) => setStatusBase({ msg, date: new Date() });

  useEffect(() => {
    onLoad();
  }, []);
  useEffect(() => {
    if (confirmedNavigation) {
      setshowDialog(false);
      props.history.push(nextLocation)
    }
  }, [confirmedNavigation]);

  async function onLoad() {
    try {
      var envSettings = window.localStorage.getItem("envSettings");
      if(envSettings){
        envSettings = JSON.parse(envSettings);
        document.title=envSettings.appTitle?envSettings.appTitle:'Simplifying..';
      }
      //await login
      const isValid = JWT.verify();
      userHasAuthenticated(isValid);

      if (isValid) {
        const { dispatch } = configContext;
        const userData = await lookupApi.getUserPreferences();
        if (userData?.data?.MenuViewType)
          //todo pull layout from db and call the dispatch
          dispatch({ type: actionType.CHANGE_LAYOUT, layout: userData.data.MenuViewType.toLowerCase() });
      }

    } catch (e) {
      alert(e);
      if (e !== "No current user") {
        alert(e);
      }
    }
  }
  async function handleTheme() {

  }

  global.handleTheme = handleTheme;
  global.DisplayName = window.localStorage.getItem("displayName");
  global.ClientCode = window.localStorage.getItem("clientCode");
  global.userRoleType = window.localStorage.getItem("userRoleType");
  global.defaultHomeUrl = window.localStorage.getItem("defaultHomeUrl");

  async function handleLogout() {
    //await signOut()
    window.localStorage.setItem("token", null);
    window.localStorage.setItem("navMenuItems", JSON.stringify({ "items": [], "navMainItems": [] }));
    userHasAuthenticated(false);
    if (props.location.pathname && props.location.pathname.indexOf("login") > -1) {
      console.log(`logoutHandler called multiple times`)
      return;
    }
    props.history.push(`/login?redirect=${props.location.pathname}${props.location.search}`);

  }
  global.handleLogout = handleLogout;
  global.setDisplayCards = setDisplayCards;
  global.displayCards = displayCards;// can be derived from local storage
  
  //const { when } = props;
  //const when = props.location.pathname.indexOf(`entity/`) > 0;
  const { blockNavigation } = configContext.state;
  const when = props.location.pathname.indexOf(`entity/`) > 0 && blockNavigation
  const handleBlockedNavigation = newLocation => {
    const unblockedRoutes = ["/login"]
    if (newLocation.pathname === props.location.pathname)
      return true
    if (unblockedRoutes.find(x => newLocation.pathname.indexOf(x) > -1))
      return true;
    if (confirmedNavigation) {
      //setnextLocation('')
      setconfirmedNavigation(false);//reset
      return true
    }
    //setconfirmedNavigation(false);//reset
    setnextLocation(newLocation)
    setshowDialog(true)
    return false
  };

  const handleConfirmedNavigation = () => {
    setconfirmedNavigation(true);
  }
  return (
    <div>
      <Prompt when={when}
        message={handleBlockedNavigation}
      />
      <Routes
        appProps={{
          isAuthenticated,
          userHasAuthenticated,
          handleLogout,
          displayCards,
          setDisplayCards,
          userRoleType:global.userRoleType,
          isLightUser:global.userRoleType ==2,
          defaultHomeUrl: global.defaultHomeUrl
        }}
      />
      {/* {status ? <Snackbar key={status.date} status={status.msg} /> : "Nope"} */}
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showDialog && (<Modal closeModal={() => setshowDialog(false)} title="Warning"
        footerActions={[{ Name: "Confirm", className: "btn btn-secondary mr-3", callBack: () => { handleConfirmedNavigation() } },
        { Name: "Cancel", className: "btn btn-primary", callBack: () => { setshowDialog(false) } }
        ]}>
        <p>This action will lose any changes made. Are you sure?</p>

      </Modal>)
      }
    </div>
  );
}

function App() {
  console.log("App called");
  return (
    <Router>
      <div>
        <div>
          <Detector
            render={({ online }) => (
              <div className={online ? "normal" : "warning"}>
                You are currently {online ? "online" : "offline"}
              </div>
            )}
          />
        </div>
        <nav>
          <ul>
            {/* <li>
              <Link to="/form">My Form</Link>
            </li>
            <li>
              <Link to="/build/-100">New Form Build</Link>
            </li> */}
            <li>
              <Link to="/buildList">Form Builder List</Link>
            </li>
          </ul>
        </nav>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/build/:id" component={Builder} />
          <Route path="/buildList" component={BuilderList}></Route>
          <Route path="/form/:metaId/:id" component={MyForm} />
          <Route path="/formList/:metaId" component={FormList} />
          <Route path="/" component={BuilderList} />
        </Switch>
      </div>
    </Router>
  );
}

//export default App
export default withRouter(NewApp);
