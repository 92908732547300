import React, { Component } from "react";
import apiService from "./../../../Services/api";
import Modal from '../Modal'
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
const DynamicTableComp = class extends React.Component {
  constructor(props) {
    super(props);

    let value = props.value ? props.value : props.submissionData;

    this.state = {
      value: value,
      jsonData: value ? value.jsonData : {}
      //columns: props.component.columns,
      //entityId: this.props.component.entityId,
      //entityTypeId: this.props.component.entityTypeId,
      //componentId: this.props.component.id
    };
    this.state = {
      value: props.value
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  static getDerivedStateFromProps(props, state) {

    let ctxData = {};
    if (props.component) {
      ctxData.entityId = props.component.entityId;
      ctxData.entityTypeId = props.component.entityTypeId;
      ctxData.apikey = props.component.key;
      ctxData.frameworkId = props.component.frameworkId;
      ctxData.parentEntityId = props.component.pentityId;
      ctxData.parentEntitytypeid = props.component.pentityTypeId;
    }
    console.log("DynamicTable loading with ctxData: ", ctxData);
    return { contextData: ctxData };
  }

  async componentDidMount() {
    if (this.props.component && Boolean(this.props.component.entityId > 0)) {
      await this.getData()
    }
  }
  async getData() {
    if (this.props.component && !Boolean(this.props.component.entityId > 0))
      return
    const getParams = this.state.contextData;

    let jsonData = await apiService.getDynamicTableData(getParams);

    // let jsonData = {
    //     ...this.state.jsonData,
    //     unassigned: list.data.filter(x => x.isselected === 0),//.map(x => { x.role = -1; return x; })
    //     assigned: list.data.filter(x => x.isselected === 1)
    // };

    this.setState({
      jsonData,
    })

  }

  async saveData() {
    if (this.props.component && !Boolean(this.props.component.entityId > 0))
      return

    const contextData = this.state.contextData;
    const tableData = this.state.jsonData;

    let jsonData = await apiService.saveDynamicTableData(contextData, tableData);

    // let jsonData = {
    //     ...this.state.jsonData,
    //     unassigned: list.data.filter(x => x.isselected === 0),//.map(x => { x.role = -1; return x; })
    //     assigned: list.data.filter(x => x.isselected === 1)
    // };

    await this.getData()

  }
  renameJsonKey(json, oldkey, newkey) {
    return Object.keys(json).reduce((s, item) =>
      item == oldkey ? ({ ...s, [newkey]: json[oldkey] }) : ({ ...s, [item]: json[item] }), {})
  };
  setValue = () => {
    this.setState(
      prevState => ({ value: !prevState.value }),
      () => this.props.onChange(null, this.state.value)
    );
  };
  async handleDialog(openState, saveData) {
    if (!openState) {
      //if save clicked, post data to server and refresh
      if (saveData == true) {
        await this.saveData();
      }
      else {
        //if cancelled, refresh data from server and discard changes
        await this.getData();
      }
    }

    this.setState({ showDialog: openState })
  }
  handleValueChange(idx, colName, e) {
    var data = this.state.jsonData;
    data.tData[idx][colName] = e.target.value;
    this.setState({ jsonData: {
      ...this.state.jsonData,
      tData:[...data.tData]
    } });
  }

  handleDelete(item, indexToRemove) {

    this.setState({
      jsonData: {
        ...this.state.jsonData,
        tData: [...this.state.jsonData.tData.filter((x, i) => indexToRemove !== i)]
      }
    })
  }

  handleColumnNameChange(oldName, e) {
    var data = this.state.jsonData;
    var newName = e.target.value;

    for (var i = 0; i < data.cfgData.length; i++) {
      if (data.cfgData[i].title == oldName) {
        data.cfgData[i].title = newName;
      }
    }

    for (var i = 0; i < data.tData.length; i++) {
      data.tData[i] = this.renameJsonKey(data.tData[i], oldName, newName);
    }

    var colList = [];
    if (data && data.cfgData && Array.isArray(data.cfgData)) {
      colList = data.cfgData.map((col) => col.title)
    }
    else if (data && data.tData && Array.isArray(data.tData)) {
      colList = Object.keys(data.tData[0]);//typeof this.props.component.columns ? this.props.component.columns : []
    }
    this.setState({ jsonData: data, value: colList });
  }

  handleAddNewColumn() {
    var data = this.state.jsonData;
    var newName = "NewField";
    var colList = [];
    if (data && data.cfgData && Array.isArray(data.cfgData)) {
      colList = data.cfgData.map((col) => col.title)
    }
    else if (data && data.tData && Array.isArray(data.tData)) {
      colList = Object.keys(data.tData[0]);//typeof this.props.component.columns ? this.props.component.columns : []
    }
    if (data.cfgData == null) data.cfgData = [];
    if (data.tData == null) data.tData = [];
    for (var counter = 1; counter < 100; counter++) {
      if (colList.indexOf(newName) >= 0) {
        newName = newName + "_" + counter;
      }
      else {
        data.cfgData.push({ colid: -1 * counter, title: newName, type: "string" });
        for (var i = 0; i < data.tData.length; i++) {
          data.tData[i][newName] = '';
        }
        break;
      }
    }
    this.setState({ jsonData: data, value: colList });
  }

  handleAddNewRow() {
    var data = this.state.jsonData;
    var newRow = {};
    var colList = [];
    if (data.tData == null) data.tData = [];
    if (data && data.cfgData && Array.isArray(data.cfgData)) {
      colList = data.cfgData.map((col) => col.title)
    }
    else if (data && data.tData && Array.isArray(data.tData)) {
      colList = Object.keys(data.tData[0]);//typeof this.props.component.columns ? this.props.component.columns : []
    }
    var newRowCount = data.tData.filter((r) => { return r.id < 0 }).length;
    for (var idx = 0; idx < colList.length; idx++) {
      if (colList[idx] === 'id') {
        newRow[colList[idx]] = -1 * (newRowCount > 0 ? newRowCount + 1 : 1);
      }
      else {
        newRow[colList[idx]] = '';
      }
    }
    data.tData.push(newRow);
    this.setState({ jsonData: data });
  }

  render() {
    var refCols = ['id', 'frameworkid', 'pentityid', 'pentitytypeid', 'entityid', 'entitytypeid', 'contextkey', 'rowid'];
    var data = this.state.jsonData;
    const disableComponent = this.props.component.disabled;

    var columns = [];
    if (data && data.cfgData && Array.isArray(data.cfgData)) {
      columns = data.cfgData.map((col) => col.title)
    }
    else if (data && data.tData && Array.isArray(data.tData)) {
      columns = Object.keys(data.tData[0]);//typeof this.props.component.columns ? this.props.component.columns : []
    }
    return (
      <div>
        <div className="btn-toolbar justify-content-end">
          <div className="dt-buttons btn-group float-right">
            <button disabled={disableComponent} onClick={(e) => { e.preventDefault(); this.handleDialog(true, false) }} className="btn btn-icon btn-outline-secondary btnAction pull-right" title="Manage Data">
              <i className="fa fa-id-card" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        {this.state.showDialog && (<Modal closeModal={() => this.handleDialog(false, false)}
          SaveData={(e) => { e.preventDefault(); this.handleDialog(false, true) }} ShowSaveButton={columns.length > 0}>
          <div>
            <Tabs defaultActiveKey="config">
              <Tab eventKey="config" title="Configuration">
                <h5>Fields</h5>
                <div className="dt-buttons btn-group float-right">
                  <a onClick={() =>
                    this.handleAddNewColumn()
                  } className="btn  btn-icon btn-outline-primary btnAction" title="Add New Column">
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </a>
                </div>
                <ul>
                  {columns.map(name => (
                    refCols.indexOf(name) < 0 && <li key={name}>
                      <input type="text" defaultValue={name} onBlur={(e) => this.handleColumnNameChange(name, e)} />
                    </li>
                  ))}
                </ul>
              </Tab>
              <Tab eventKey="data" title="Table Data">
                <div class="dt-buttons btn-group float-left"><h5>Data</h5></div>
                <div className="dt-buttons btn-group float-right">
                  {columns.length > 0 && <a onClick={() =>
                    this.handleAddNewRow()
                  } className="btn  btn-icon btn-outline-primary btnAction" title="Add New Record">
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </a>}
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      {
                        columns.map(fldName => {
                          return refCols.indexOf(fldName) < 0 && <><th >{fldName}</th></>
                        })
                      }
                      <th>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.tData &&
                      data.tData.map((item, i) => (
                        <tr key={i}>
                          {
                            columns.map(fldName => {
                              return refCols.indexOf(fldName) < 0 && <>
                                <td><input type="text" key={`${i}_item[fldName]`} value={item[fldName]} onChange={(e) => this.handleValueChange(i, fldName, e)} /></td>
                              </>
                            })
                          }
                          {
                            <td>
                            <button className="btn btn-icon btn-outline-danger btnAction pull-right" title="Delete" onClick={(e) => {e.preventDefault(); this.handleDelete(item, i)}}> <span className="fa fa-trash"></span> </button>
                            </td>
                          }
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Tab>
            </Tabs>
          </div>
        </Modal>)}
        {columns.length > 0 && <div class="container col-md-12">
          <table className="table">
            <thead>
              <tr>
                {
                  columns.map(fldName => {
                    return refCols.indexOf(fldName) < 0 && <><th >{fldName}</th></>
                  })
                }
              </tr>
            </thead>
            <tbody>
              {data &&
                data.tData &&
                data.tData.map((item, i) => (
                  <tr key={i}>
                    {
                      columns.map(fldName => {
                        return refCols.indexOf(fldName) < 0 && <>
                          <td >{item[fldName]}</td>
                        </>
                      })
                    }
                  </tr>
                ))}
            </tbody>
          </table>
        </div>}
      </div>
    );
  }
};


export default DynamicTableComp;