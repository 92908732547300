import React from "react";
import PropTypes from 'prop-types'
import clsx from "clsx";
import {fade, makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import GridOnIcon from "@material-ui/icons/GridOn";
import ReorderIcon from "@material-ui/icons/Reorder";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import { BorderColor } from "@material-ui/icons";
import { Bootstrap, FontAwesome, BootswatchSelect } from 'react-bootswatch-select';
import logo from '../../assets/appLogo.png';
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
//import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
//import IconButton from "@material-ui/core/IconButton";
//import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import TreeView from "../Common/TreeView"

const RawHTML = ({ children, tag = 'div', nl2br = true, ...rest }) =>
    React.createElement(tag, {
        dangerouslySetInnerHTML: {
            __html: nl2br
                ? children && children.replace(/\n/g, '<br />')
                : children,
        },
        ...rest,
    });
RawHTML.propTypes = {
      children: PropTypes.string,
      nl2br: PropTypes.bool,
      tag: PropTypes.string,
  };

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,    
    display: "flex"
  },
  menuButton: {
   // marginRight: theme.spacing(2),
    marginRight: 36
  },
  title: {
    flexGrow: 1,
  },
  displayCardButton: {
    padding: "3px",
  },
  // badge: {
  //   backgroundColor: "red",
  // },
  ".MuiBadge-colorSecondary": {
    //  color: "grey",
    // backgrodColor: "grey",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    }
  },
  logoStyle:{
    width: '120px'
  }

}));

global.runOffline = window.localStorage.getItem("runOffline") === "true"; //read it from session
export default function MenuAppBar(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const auth = props.children.props.isAuthenticated;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [offline, setOffline] = React.useState(global.runOffline);

  const accountOpen = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    //setAuth(event.target.checked);
    setOffline(event.target.checked);
    global.runOffline = event.target.checked;
    window.localStorage.setItem("runOffline", event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    props.children.props.history.push("/login");
  };
  const handleRoot = () => {
    props.children.props.history.push("/navbar");
  };
  const handleLogOut = () => {
    setAnchorEl(null);
    props.children.props.handleLogout();
  };

  const handleMenuOption = (targetURI) => {
    if(targetURI && targetURI.length>0)
      props.children.props.history.push(targetURI);
  };
  

  const leftMenuOptions=[ {"type":"button","label":"Universe","className":"fa fa-globe","navURL":"/adminInstanceList/2"},
  {"type":"button","label":"Register","className":"fa fa-list","navURL":"/adminInstanceList/3"},
  {"type":"button","label":"Action","className":"fa fa-file-text","navURL":""},
  {"type":"button","label":"View","className":"fa fa-eye","navURL":""},
  {"type":"button","label":"Shortcut","className":"fa fa-link","navURL":""},
  {"type":"button","label":"Dashboard","className":"	fa fa-bar-chart","navURL":""},
  {"type":"button","label":"Report","className":"fa fa-list-alt","navURL":""},
  {"type":"button","label":"Framework","className":"fa fa-code","navURL":"/frameworkList"},
  {"type":"divider","label":"Configuration","className":"fa fa-code"},
  {"type":"button","label":"Admin","className":"fa fa-gears","navURL":"/navAdmin"},
  {"type":"button","label":"Settings","className":"fa fa-server","navURL":""}
];

  console.log("props- ", props);
  return (
    <div>
      <Bootstrap version={'4.4.1'} />
      <FontAwesome version={'4.7.0'} />
      <div className={classes.root}>
        <CssBaseline />
        {/* <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={offline}
                onChange={handleChange}
                aria-label="login switch"
              />
            }
            label={offline ? "Offline" : "Online"}
            checked={offline}
          />
        </FormGroup> */}
        {auth && (<AppBar position="static"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
        >
          <Toolbar>
          {auth && ( <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open
              })}
            >
              <MenuIcon />
            </IconButton>
          )}
            <Typography
              variant="h6"
              className={classes.title}
              onClick={handleRoot}
            >
              <div backgroundcolor="white">
               <img id="main-logo" src={logo} alt="Home" />
               </div>
            </Typography>
            {!auth && (
              <Button
                color="inherit"
                onClick={() => {
                  handleLogin();
                }}
              >
                Login
              </Button>
            )}  

            {auth && (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            )}
            
            {auth && (
              <div class="btn pull-right">
                {/* <span className={classes.cardContainer}> */}
                  <IconButton
                    color={props.children.props.displayCards ? "inherit" : "default"}
                    className={classes.displayCardButton}
                    onClick={(e) => {
                      props.children.props.setDisplayCards(true);
                    }}
                  >
                    <GridOnIcon></GridOnIcon>
                  </IconButton>
                  <IconButton
                    color={!props.children.props.displayCards ? "inherit" : "default"}
                    className={classes.displayCardButton}
                    onClick={(e) => {
                      props.children.props.setDisplayCards(false);
                    }}
                  >
                    <ReorderIcon></ReorderIcon>
                  </IconButton>
                {/* </span> */}
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <Badge
                    color="secondary"
                    //className={classes['.MuiBadge-colorSecondary']}
                    overlap="circle"
                    badgeContent="Offline"
                    variant="dot"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <AccountCircle />
                  </Badge>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={accountOpen}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem>Theme:<BootswatchSelect version={'4.4.1'} selectedThemeName="minty" className="form-control"/></MenuItem>
                  <MenuItem onClick={handleLogOut}>Log out</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>)}
        {auth && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            {leftMenuOptions.map((option, index) => ( 
              (option.type=="button"
              ?<ListItem button key={option.label} onClick={() => {
                handleMenuOption(option.navURL);
              }}> 
                <ListItemIcon>
                  <RawHTML tag="i" className={option.className} title={option.label}></RawHTML>
                </ListItemIcon>
                <ListItemText primary={option.label} />
                </ListItem>              
              :<Divider />
              )
            ))}
          </List>
          <TreeView/>
        </Drawer>
        )}
        </div>
      <div>
      {props.children}
      </div>
    </div>
  );
}
