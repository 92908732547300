import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            // You can ignore existing fields.
            key: "placeholder",
            ignore: true
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "checkbox",
            input: true,
            label: "Hide Input Value",
            weight: 12,
            key: "hideInputValue" 
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "checkbox",
            input: true,
            label: "Hide Dropdown",
            weight: 12,
            key: "hideDropdown" 
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "My Custom Setting",
            weight: 12,
            key: "myCustomSetting" // This will be available as component.myCustomSetting
          }
          ,
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "JSON Values",
            weight: 12,
            key: "jsonData", // This will be available as component.myCustomSetting
            defaultValue: JSON.stringify({ "values":[ { "name":"High",   "color":"red" }, { "name":"low",   "color":"green" } ] })
          },
          {
            "label": "SelectValues",
            "disableAddingRemovingRows": false,    
            "reorder": false,
            "addAnother": "",
            "addAnotherPosition": "bottom",    
            "defaultValue": [{ name:"A1",color:"#ea1212"},{ name:"B1",color:"#b7e0b7"}],
            "customDefaultValue": "",
            "validateOn": "change",
            "validate": {
              "required": false,
              "minLength": "",
              "maxLength": "",
              "customMessage": "",
              "custom": "",
              "customPrivate": false,
              "json": "",
              "strictDateValidation": false,
              "multiple": false,
              "unique": false
            },   
            "key": "selectValues",    
            "type": "datagrid",
            "input": true,
            "placeholder": "",
            "tree": true,
            "components": [
              {
                "label": "Name",
                "widget": { "type": "input" },
                "inputMask": "",
                "allowMultipleMasks": false,
                "customClass": "",
                "tabindex": "",
                "autocomplete": "",
                "hidden": false,
                "hideLabel": false,
                "showWordCount": false,
                "showCharCount": false,
                "mask": false,
                "autofocus": false,
                "spellcheck": true,
                "disabled": false,
                "tableView": true,
                "modalEdit": false,
                "multiple": false,
                "persistent": true,
                "inputFormat": "plain",
                "protected": false,
                "dbIndex": false,
                "case": "",
                "encrypted": false,
                "redrawOn": "",
                "clearOnHide": true,
                "customDefaultValue": "",
                "calculateValue": "",
                "calculateServer": false,
                "allowCalculateOverride": false,
                "validateOn": "change",
                "validate": {
                  "required": false,
                  "pattern": "",
                  "customMessage": "",
                  "custom": "",
                  "customPrivate": false,
                  "json": "",
                  "minLength": "",
                  "maxLength": "",
                  "strictDateValidation": false,
                  "multiple": false,
                  "unique": false
                },
                "unique": false,
                "errorLabel": "",
                "key": "name",
                "tags": [],
                "properties": {},
                "conditional": { "show": null, "when": null, "eq": "", "json": "" },
                "customConditional": "",
                "logic": [],
                "attributes": {},
                "overlay": {
                  "style": "",
                  "page": "",
                  "left": "",
                  "top": "",
                  "width": "",
                  "height": ""
                },
                "type": "textfield",
                "input": true,
                "refreshOn": "",
                "inputType": "text",
                "id": "eol1zho000000",
                "defaultValue": ""
              },
              // {
              //   // Or add your own. The syntax is form.io component definitions.
              //   type: "checkbox",
              //   input: true,
              //   defaultValue:true,
              //   label: "Show Label",
              //   weight: 12,
              //   key: "showLabel" // This will be available as component.myCustomSetting
              // },
              {
                // Or add your own. The syntax is form.io component definitions.
                type: "colorPicker",
                input: true,
                label: "Pick Color",
                weight: 12,
                key: "color" // This will be available as component.myCustomSetting
              },              
              {
                "label": "Min Value",
                "labelPosition": "top",
                "placeholder": "",
                "description": "",
                "tooltip": "",
                "prefix": "",
                "suffix": "",
                "widget": { "type": "input" },
                "inputMask": "",
                "allowMultipleMasks": false,
                "customClass": "",
                "tabindex": "",
                "autocomplete": "",
                "hidden": false,
                "hideLabel": false,
                "showWordCount": false,
                "showCharCount": false,
                "mask": false,
                "autofocus": false,
                "spellcheck": true,
                "disabled": false,
                "tableView": true,
                "modalEdit": false,
                "multiple": false,
                "persistent": true,
                "inputFormat": "plain",
                "protected": false,
                "dbIndex": false,
                "case": "",
                "encrypted": false,
                "redrawOn": "",
                "clearOnHide": true,
                "customDefaultValue": "",
                "calculateValue": "",
                "calculateServer": false,
                "allowCalculateOverride": false,
                "validateOn": "change",
                "validate": {
                  "required": false,
                  "pattern": "",
                  "customMessage": "",
                  "custom": "",
                  "customPrivate": false,
                  "json": "",
                  "minLength": "",
                  "maxLength": "",
                  "strictDateValidation": false,
                  "multiple": false,
                  "unique": false
                },
                "unique": false,
                "errorLabel": "",
                "key": "minValue",
                "tags": [],
                "properties": {},
                "conditional": { "show": null, "when": null, "eq": "", "json": "" },
                "customConditional": "",
                "logic": [],
                "attributes": {},
                "overlay": {
                  "style": "",
                  "page": "",
                  "left": "",
                  "top": "",
                  "width": "",
                  "height": ""
                },
                "type": "textfield",
                "input": true,
                "refreshOn": "",
                "inputType": "text",
                "id": "e7iwjon00",
                "defaultValue": ""
              },
              {
                // Or add your own. The syntax is form.io component definitions.
                type: "textfield",
                input: true,
                label: "Max Value",
                weight: 12,
                key: "maxValue" // This will be available as component.myCustomSetting
              },
              // {
              //   // Or add your own. The syntax is form.io component definitions.
              //   type: "textfield",
              //   input: true,
              //   label: "Description",
              //   weight: 12,
              //   key: "description" // This will be available as component.myCustomSetting
              // },
            ],
            "id": "eu90zso"
          }
        ]
      },
      {
        key: "data",
        components: []
      },
      {
        key: "validation",
        components: []
      },
      {
        key: "api",
        components: []
      },
      {
        key: "conditional",
        components: []
      },
      {
        key: "logic",
        components: []
      }
    ],
    ...extend
  );
};
