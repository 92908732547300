import React from "react";

import DBService from "../../../Services/dexieForm";
import Service from "../../../Services";

import { withStyles } from "@material-ui/core/styles";


import Button from "@material-ui/core/Button";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";

import Paper from "@material-ui/core/Paper";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import Builder from "../../RenderBuilder";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    //padding:10
  },

  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  root: {
    // padding:10
  },
});

class BuilderForm extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = props;
    console.log("typeId", params.typeId);

    this.state = {
      entityName: "",
      typeId: Number(params.typeId)
    }; //default

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    let item = null;

    if (this.state.typeId > 0)
      item = await Service().getAdminEntityBuilder(this.state.typeId);

    if (item) {
      this.setState({
        entityName: item.entityName,

      });
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

 

  async handleSubmit(event) {
    event.preventDefault();
    let params = {         
      typeId: this.state.typeId,
      entityName:this.state.entityName
    };
    //if (this.state.createMode) {
    //create new
    let resultId = await Service().saveAdminFormEntityList(params);
    if (resultId > 0) this.props.history.push(`/adminbuild/${resultId}`);
    // } else {
    //   //save
    // }
  }

  render() {
    const { classes } = this.props;
    const showSchemaData = true;
    return (
      <div>
        {/* <CssBaseline />         */}

        <div className="pageHeaderPanel">
          <div className="page-header1">
          <h5 >Custom Form</h5>
          <div className="btn-toolbar justify-content-end">
              {/* <input type="submit" value="Save" /> */}
              <button className="btn btn-icon btn-outline-secondary btnAction" href="/adminforms"
                Tooltip="Back">
                <i className="fas fa-arrow-left"></i>
              </button>
              <button className="btn  btn-icon  btn-outline-primary btnAction"
              type="submit"
              // fullWidth
              variant="contained"
              color="primary"
            >
             <i className="fas fa-save"></i>
            </button>

          </div>
          </div>
        </div>

        <div className="tablePanel-body  paneltopTable">
          <div className="col-md-12">
          <form
            className={classes.form}
            // noValidate
            onSubmit={this.handleSubmit}
          >

            <div className="form-group">
           <label className="col-form-label">Form Name</label> 
            <input
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="entityName"
              label="form name"
              name="entityName"
              autoComplete="form name"
              autoFocus
              value={this.state.entityName}
              className="form-control"
              onChange={this.handleChange}
            />
            </div>

            
          </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BuilderForm);
