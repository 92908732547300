import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./edit";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

const jsonString = {
  values: [
    {
      name: "High",
      color: "red",
    },
    {
      name: "low",
      color: "green",
    },
  ],
};

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const AssignGridComp = class extends Component {
  constructor(props) {
    super(props);

    let data;
    try {
      data = JSON.parse(props.component.jsonData);
    } catch (e) {
      data = { values: [] }; //set default
    }

    this.handleAssign = this.handleAssign.bind(this);
    this.handleUnAssign = this.handleUnAssign.bind(this);
    this.handleSelect=this.handleSelect.bind(this);
    this.state = {
      value: props.value ? props.value : props.submissionData,
      jsonData: data,
    };
  }
 
  
  setValue = (event) => {
    console.log("Selected", event.target.value);
    this.setState(
      (prevState) => ({
        value: {
          selectVal: event.target.value,
        },
      }),
      // () => this.props.onChange(null,this.state.value)
      () => this.props.onChange(this.state.value)
    );
  };

  handleAssign() {
    console.log(this.refs);
   var data = this.state.jsonData;
   var selected=data.unassigned.filter(x=>x.selected)
   data.unassigned=data.unassigned.filter(x=>!x.selected)
   this.setState(
     {
       jsonData:{
         assigned:[...data.assigned,...selected],
         unassigned:data.unassigned
       }
     }
   )

  }

  handleUnAssign() {
    console.log(this.refs);

    var data = this.state.jsonData;
    var selected=data.assigned.filter(x=>x.selected)
    data.assigned=data.assigned.filter(x=>!x.selected)
    this.setState(
      {
        jsonData:{
          unassigned:[...data.unassigned,...selected],
          assigned:data.assigned
        }
      }
    )
    
  }
  handleSelect(item){

    item.selected=item.selected?!item.selected:true
  }

  render() {
    const data = this.state.jsonData;

    return (
      <div style={{ display:"flex", flexDirection:"row", justifyContent:"space-between", width:"90%"}}>
        <table>
          <thead>
            <tr>
            <th>Select</th>
            <th>Name</th>
            </tr>
          </thead>
        <tbody>
          {data &&
            data.assigned &&
            data.assigned.map((item,i) => (
              <tr  key={i}>
                <td> <input type="checkbox" onChange={()=>{ this.handleSelect(item)}} /> </td>
                <td> {item.name}</td>
              </tr>
            ))}
            </tbody>
        </table>
        <div>
        <input type="button" onClick={this.handleUnAssign} value=">"/>
        <input type="button" onClick={this.handleAssign}  value="<"/>
        </div>

        <table>
          <thead>
          <tr>
            <th>Select</th>
            <th>Name</th>
            </tr>
          </thead>
          <tbody>
          {data &&
            data.unassigned &&
            data.unassigned.map((item,i) => (
              <tr key={i}>
                <td> <input type="checkbox"   onChange={()=>{ this.handleSelect(item)}} /> </td>
                <td> {item.name}</td>
              </tr>
            ))}
            </tbody>
        </table>

      </div>
    );
  }
};

export default class AssignGrid extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "2AssignGrid",
      icon: "square",
      group: "basic",
      documentation: "",
      weight: -10,
      schema: AssignGrid.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "assigngrid",
      label: "Assign Grid",
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    //console.log('this.dataForSetting',this.dataForSetting)
    // if (this.shouldSetValue) {
    //   //this.setValue(this.dataForSetting);
    //   this.updateValue(this.dataForSetting);
    // }
    return ReactDOM.render(
      <AssignGridComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }

  setValue(data) {
    console.log("Called wit data-", data);
    //hacked to rerender the react component by adding new prop //temp fix
    //hacked to rerender the react component by adding new prop submissionData//temp fix
    //TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO
    if (data) {
      ReactDOM.render(
        <AssignGridComp
          component={this.component} // These are the component settings if you want to use them to render the component.
          value={this.dataValue} // updatedvalue of the component.
          onChange={this.updateValue} // The onChange event to call when the value changes.
          submissionData={data}
        />,
        this.element
      );
    }
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
