import axios from "axios";
function swtichToCurrent(cUrl){
  var cURI = new URL(cUrl);
  cURI.hostname =document.location.hostname;
  var newUrl = cURI.href; 
  return newUrl;
}
export function uploadFile(url,formData){
    console.log(formData);
    url = swtichToCurrent(url);
    return axios
    .post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },validateStatus: false
    })
    .catch((err) => {
      return err;
    });
}
export function downloadFile(url){
    console.log(url);
    url = swtichToCurrent(url);
    
    return axios
        .get(url, {
            responseType: 'arraybuffer',
            headers: {
                "Content-Type": "multipart/form-data",
            },validateStatus: false
        })
        .catch((err) => {
            return err;
        });
}

