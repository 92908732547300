import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FileViewer from "react-file-viewer";

const styles = (theme) => ({
    actionButton: {
      padding: "3px",
    },
    actionsContainer: {
      //float:'right'
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
    },
  });

const onError = e => {
    console.log(e, "error in file-viewer");
  };

class RenderFile extends React.Component{

    constructor(props) {
      super(props);
    }
    render() 
    {
      var searchParams = new URLSearchParams(this.props.location.search);
      var fileExt = searchParams.get("title").slice((searchParams.get("title").lastIndexOf(".") - 1 >>> 0) + 2);
      var URI= searchParams.get("url"),FileType=fileExt,FileTitle=searchParams.get("title");  
      const token = window.localStorage.getItem("token");
      //URI = URI+"&saveAs="+FileTitle+"&token="+token;

      //replace original host name with current hostname
      URI = URI.replace(new URL(URI).origin,window.location.origin);
      //add saveAs param for file download prompt with actual file name
      if(new URL(URI).search.length==0){
        URI = URI+"?saveAs="+FileTitle+"&token="+token;
      }      
      else{
        URI=URI+"&saveAs="+FileTitle+"&token="+token;
      }
      
        return(
            <div class="container" >
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Rendering: <i>{FileTitle}</i></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="btn-toolbar justify-content-end col-xs-12 breadcumBtn">
                      <a class="fa fa-download" title={"Download File : " + FileTitle} href={URI} download={FileTitle}></a>
                    </div>
                    <div class="col-sm-12" style={{ maxHeight: '60%',minHeight:'40%' }}>
                        <FileViewer fileType={FileType} filePath={URI} onError={onError} />
                    </div>
                </div>
            </div>
          );
    }

       
}

export default withStyles(styles)(RenderFile);
