
import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField";
class SelectWithSummaryChartConfig extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleDataSourceSelection = this.handleDataSourceSelection.bind(this);
    }

    handleChange(val) {

    }

    handleTextChange(event) {

        let value = event.target.value;
        let propName = event.target.name

        if (event.target.type == "checkbox") {
            value = event.target.checked;
        }
        // this.setState({
        //     [event.target.name]: value,
        // });
        this.updateParent({
            [propName]: value
        })
    }

    updateParent(modifiedObj) {
        if (this.props.onConfigChange)
            this.props.onConfigChange(modifiedObj, this.props.id)
    }

    handleDataSourceSelection(event) {

        let objToModify = {
            entityId: -1,
            entityTypeId: -1,
            groupKey: ''
        }

        let value = event.target.value;
        const { dataSources } = this.props.metaData;
        const selectedDataSource = dataSources.find(x => x.entityId == value);

        if (selectedDataSource) {
            objToModify.entityId = selectedDataSource.entityId;
            objToModify.entityTypeId = selectedDataSource.entityTypeId;
        }
        this.updateParent(objToModify);
    }

    render() {

        const { title, groupKey,summaryType, entityTypeId, entityId,
            multiSelect,
            showCounts
         } = this.props.chartConfig
        const {
            groupKeyObj,
            dataSources
        } = this.props.metaData;

        let groupKeys = [];
        if (groupKeyObj[`${entityTypeId}_${entityId}`]) {
            groupKeys = groupKeyObj[`${entityTypeId}_${entityId}`];
        }
        let summaryTypes= [
            { name: "Count", value :"count" },
            // { name: "Total", value: "sum" },
            // { name: "Average", value: "avg" }
          ];
        return (
            <>
            <div className="dashboardForm">
            <div className="form-group">
                Title:  <input
                    className='form-control'    
                    variant="outlined"
                    margin="normal"
                    id="title"
                    label="Select chart title"
                    name="title"
                    autoComplete="title"
                    autoFocus
                    value={title}
                    onChange={this.handleTextChange}
                />
             </div>

                    <div className='row form-group'>
                        <div className="col-md-3">
                            Data Sources:
                            <select className="form-control"
                                value={entityId} onChange={this.handleDataSourceSelection}
                            >
                                <option value="-1">Select</option>
                                {dataSources.map(x =>
                                    <option value={x.entityId} key={x.entityId}>
                                        {x.displayName}
                                    </option>
                                )
                                }
                            </select>
                        </div>
                        <div className="col-md-3">
                            Group key:
                            <select className="form-control"
                                name="groupKey"
                                value={groupKey} onChange={this.handleTextChange}
                            >
                                {groupKeys.map(x =>
                                    <option value={x.StepItemKey} key={x.StepItemKey}>
                                        {x.StepItemName}
                                    </option>
                                )
                                }
                            </select>
                        </div>
                        <div className="col-md-3">
                            Summary Type:
                            <select className="form-control"
                                name="summaryType"
                                value={summaryType} onChange={this.handleTextChange}
                            >
                                {summaryTypes.map(x =>
                                    <option value={x.value} key={x.name}>
                                        {x.name}
                                    </option>
                                )
                                }
                            </select>
                        </div>
                    </div>

              </div>
            </>)
    }
}

export default SelectWithSummaryChartConfig;