
import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField";
class LineChartConfig extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleDataSourceSelection = this.handleDataSourceSelection.bind(this);
        this.handleColumnChange = this.handleColumnChange.bind(this);
    }

    handleChange(val) {

    }

    handleTextChange(event) {

        let value = event.target.value;

        // this.setState({
        //     [event.target.name]: value,
        // });
        this.updateParent({
            [event.target.name]: value
        })
    }

    updateParent(modifiedObj) {
        if (this.props.onConfigChange)
            this.props.onConfigChange(modifiedObj, this.props.id)
    }

    handleColumnChange(event, item) {
        let {
            columns=[]
        } = this.props.chartConfig
        if (event.target.checked) {
            columns.push(item.StepItemKey)
        }
        else {
            columns = columns.filter(x => x != item.StepItemKey)
        }
        this.updateParent({
            columns: columns
        })
    }

    handleDataSourceSelection(event) {

        let objToModify = {
            entityId: -1,
            entityTypeId: -1,
            groupKey: ''
        }

        let value = event.target.value;
        const { dataSources } = this.props.metaData;
        const selectedDataSource = dataSources.find(x => x.entityId == value);

        if (selectedDataSource) {
            objToModify.entityId = selectedDataSource.entityId;
            objToModify.entityTypeId = selectedDataSource.entityTypeId;
        }
        this.updateParent(objToModify);
    }

    render() {

        const { title, groupKey, entityTypeId, entityId,
            columns = []
        } = this.props.chartConfig
        const {
            groupKeyObj,
            dataSources
        } = this.props.metaData;


        let groupKeys = [];
        if (groupKeyObj[`${entityTypeId}_${entityId}`]) {
            groupKeys = groupKeyObj[`${entityTypeId}_${entityId}`];
        }

        const ColumnsList = groupKeys.map(x => {

            return {
                ...x,
                selected: columns.indexOf(x.StepItemKey)>-1
            }
        })
        return (
            <>
            <div className="dashboardForm">
            <div className="form-group">
                {/* Table chart config - {config.chartType} */}
                Title:  <input
                    className='form-control'
                    variant="outlined"
                    margin="normal"
                    id="title"
                    label="Line chart title"
                    name="title"
                    autoComplete="title"
                    autoFocus
                    value={title}
                    onChange={this.handleTextChange}
                />
                </div>

                <div className='row form-group'>

                <div className="col-md-6">
                Data Sources:
                <select className="form-control"
                    value={entityId} onChange={this.handleDataSourceSelection}
                >
                    <option value="-1">Select</option>
                    {dataSources.map(x =>
                        <option value={x.entityId} key={x.entityId}>
                            {x.displayName}
                        </option>
                    )
                    }
                </select>
                </div>
                <div className="col-md-6">
                Group key:
                <select className="form-control"
                    name="groupKey"
                    value={groupKey} onChange={this.handleTextChange}
                >
                    {groupKeys.map(x =>
                        <option value={x.StepItemKey} key={x.StepItemKey}>
                            {x.StepItemName}
                        </option>
                    )
                    }
                </select>
                </div>

                </div>



                <div className="form-group">
                Columns:
                {ColumnsList.map(x =>
                    <>
                        <input type='checkbox' key={x.StepItemKey} checked={x.selected}
                            onChange={(e) => this.handleColumnChange(e, x)}
                        />
                        {x.StepItemName}
                    </>
                )
                }
                </div>
                </div>

            </>)
    }
}

export default LineChartConfig;