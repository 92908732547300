import React from "react";

class SignUp extends React.Component {
    constructor(props) {
      super(props);
      
      
      //this.handleSubmit = this.handleSubmit.bind(this);
    }
     
    async handleSubmit(event) {
      event.preventDefault();
      console.log("A form was submitted: " + JSON.stringify(this.state));
  
    }
  
    render() {
      console.log("rendered", new Date());
      return (
        <div className="dynamicForm">
          <form onSubmit={this.handleSubmit}>
            <div className="formMain">
                <h2>SignUp Form WIP</h2>
            </div>
          
          </form>
        </div>
      );
    }
  }
  
  export default SignUp;
  