import React, { Component } from "react";
import apiService from "../../../Services/api";
import Modal from '../Modal'
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "./index.css";
import data from "./data-tree.json";
import Loading from "../Loading"
 
const CustomTreeViewComp = class extends React.Component {
  constructor(props) {
    super(props);

    let value = props.value ? props.value : props.submissionData;
    
    this.state = {
        loading:true,
        showEdit:false,
        value: value,
        jsonData: value ? value.jsonData : {}
        //columns: props.component.columns,
        //entityId: this.props.component.entityId,
        //entityTypeId: this.props.component.entityTypeId,
        //componentId: this.props.component.id
    };
  }

  static getDerivedStateFromProps(props, state) {

    let ctxData ={};
    ctxData.entityId = props.component.entityId;
    ctxData.entityTypeId = props.component.entityTypeId;
    ctxData.apikey = props.component.key;
    ctxData.frameworkId = props.component.frameworkId;
    ctxData.pentityId = props.component.pentityId;
    ctxData.pentityTypeId = props.component.pentityTypeId;
    
    console.log("CustomTreeView loading with ctxData: ", ctxData);
    return {contextData: ctxData};
  }

  async componentDidMount() {
    if(Boolean(this.props.component.entityId > 0)){
      await this.getData(); 
    }
    else{
      this.setState({loading:false});
    }
  }


  async getData() {
    if (!Boolean(this.props.component.entityId > 0)){
      this.setState({loading:false});
      return;
    }
    const getParams = this.state.contextData;

    let serverData = await apiService.getCustomTreeViewData(getParams);
    let jsonData = serverData.treeData
    let selectedData=serverData.selectedData;
    let originalData= JSON.parse(JSON.stringify(serverData.selectedData));

    this.setState({
      loading:false,
      showEdit:false,
      jsonData:jsonData,
      selectedData:selectedData,
      originalData:originalData
    });
  }

  async saveData() {

    if (!Boolean(this.props.component.entityId > 0))
        return
    let originalData =  JSON.parse(JSON.stringify(this.state.originalData));
    let selectedData = JSON.parse(JSON.stringify(this.state.selectedData));

    for(var idx=0;idx<selectedData.length;idx+=1){      
      if(originalData.filter(origObj=>{return selectedData[idx].LinkId == origObj.LinkId;}).length==0){
        selectedData[idx].LinkedID = -1;
     }
    }
    originalData.forEach(origObj => {
      if(selectedData.filter(selObj=>{return selObj.LinkId == origObj.LinkId;}).length==0){
         origObj.LinkedID = -1 * origObj.LinkedID;
         selectedData.push(origObj);
      }
    });

    const contextData = this.state.contextData;
    const tableData = this.state.jsonData;
    
    this.setState({ loading:true, showEdit:false});
    var dataForsave = selectedData
                .filter((lnkData)=>{return lnkData.LinkedID <0})
                .map((lnkData)=>({ LinkId : lnkData.LinkId,
                          FromEntityId : lnkData.FromEntityId,
                          FromFrameworkId : lnkData.FromFrameworkId,
                          ToFrameWorkId : lnkData.ToFrameWorkId,
                          ToEntityid : lnkData.ToEntityid,
                          LinkedID : lnkData.LinkedID
                        })
                );
    let saveData = await apiService.saveCustomTreeViewData(contextData,dataForsave);

    let newData =  JSON.parse(JSON.stringify(this.state.selectedData));

    this.setState({ showEdit: false,originalData:newData }); 
    this.getData();
  }

  setValue = () => {
    this.setState(
      prevState => ({ value: !prevState.value }),
      () => this.props.onChange(null, this.state.value)
    );
  };

  async handleEdit(editEnabled) {   
    let resetData =  JSON.parse(JSON.stringify(this.state.originalData));
    this.setState({ showEdit: editEnabled,selectedData:resetData }) ;   
  }

  checkAllNestedParentNodes = (currNode,treeManager)=>{ 

    var selectedItems = this.state.selectedData;

    if(currNode._data.FromFrmEntID !=currNode._data.ToFrmEntID)
    {
      var tmpNode= treeManager.getNodeById(currNode._parent);         
      if(tmpNode && tmpNode._data.FromFrmEntID !=tmpNode._data.ToFrmEntID)    
      {  
        const nodeExists = selectedItems.some(selNode => selNode.LinkId === tmpNode._data.LinkId);  
        if(!tmpNode.checked)
        {         
          tmpNode.Name=tmpNode.label;
          tmpNode.checked=true;
          if(!nodeExists){
            selectedItems.push(tmpNode._data);
            this.state.selectedData=selectedItems;
          }
        }
        if(tmpNode._parent != undefined)
          return this.checkAllNestedParentNodes(tmpNode,treeManager);
      }
    }
    return;
  }

  checkAllNestedChildNodes = (currNode,treeManager,selectedItems)=>{ 


    let childIds = currNode._children;
    if(childIds && Array.isArray(childIds))
    {
      childIds.forEach(chlId => 
      {        
        var tmpNode= treeManager.getNodeById(chlId);  
        if(tmpNode)
        {
          tmpNode.Name=tmpNode.label;
          tmpNode.checked=currNode.checked;
          const nodeExists = selectedItems.some(selNode => selNode.LinkId === tmpNode._data.LinkId);
          if(currNode.checked && !nodeExists){
            selectedItems.push(tmpNode._data);
            this.state.selectedData=selectedItems;
          }
          if(!currNode.checked && nodeExists){
            var idxItem = selectedItems.map(item => item.LinkId).indexOf(tmpNode._data.LinkId);
            if(idxItem>=0) 
              selectedItems.splice(idxItem, 1);            
          }          
          // this.state.selectedData=selectedItems;
          if(tmpNode._children && Array.isArray(tmpNode._children)){
            return this.checkAllNestedChildNodes(tmpNode,treeManager,selectedItems);
          }
        }
        
      }); 
    }
    return selectedItems;
  }

  onChange = (currentNode, selectedNodes) => {
    if(!this.state.showEdit) {
      this.handleEdit(true);
      return;
    }
    const { selectionTree } = this.refs
    
    var selectedItems = this.state.selectedData;
    const nodeExists = selectedItems.some(selNode => selNode.LinkId === currentNode._data.LinkId);
    if(currentNode.checked)
    { 
      if(!nodeExists)
      {
        currentNode.Name=currentNode.label;
        selectedItems.push(currentNode._data);
      }
    }
    else
    {
      selectedItems = selectedItems.filter(function(item) {
            return item.LinkId !== currentNode._data.LinkId;
      });
    }
    this.state.selectedData=selectedItems;

    var selectedItems = this.state.selectedData;
    this.state.selectedData = this.checkAllNestedChildNodes(currentNode,selectionTree.treeManager,selectedItems);

    if(currentNode.checked){      
      this.checkAllNestedParentNodes(currentNode,selectionTree.treeManager);
    }

    //this.selectedTable.renderTable(selectedItems);
  };


  render() {
    var refCols=['id','frameworkid','pentityid','pentitytypeid','entityid','entitytypeid','contextkey','rowid']; 
    var data = this.state.jsonData;
    var selectedData = this.state.selectedData;    
    var disabledComp= this.props.component.disabled;
    var columns=[];
    if(selectedData && selectedData.length>0){
      columns = ["Name"]
    }
    var title="Tree Based Mapper";
    if(this.props.component.label)
    {
      title = this.props.component.label;
    }    
    return (
      <>
      {(this.props.component.entityId > 0) &&
      <div>
        <div className="btn-toolbar justify-content-end">
        {!this.state.loading && <div className="btn-group">
                {this.state.showEdit && <button onClick={() =>
                      this.saveData()
                  } className="btn  btn-icon btn-outline-primary btnAction" title="Save Data">
                      <i className="fa fa-save" aria-hidden="true"></i>
                  </button>}
                {this.state.showEdit && <button onClick={() =>
                      this.handleEdit(false)
                  } className="btn  btn-icon btn-outline-primary btnAction" title="Undo Edit">
                      <i className="fa fa-undo" aria-hidden="true"></i>
                  </button>}
                {!this.state.showEdit && <button disabled={disabledComp} onClick={() => {
                  if(!disabledComp)
                      this.handleEdit(true)
                }
                  } className="btn btn-icon btn-outline-primary btnAction" title="Edit Data">
                      <i className="fas fa-pen" aria-hidden="true"></i>
                  </button>}
              </div>}
          </div>
          {this.state.loading && <div class="col-md-12"><Loading></Loading></div>}
          {!this.state.loading && <div class="container col-md-12">            
          <div class="row">
              <div class="col-md-12" style={{height:"40vh",overflowY:"auto"}}>
                <DropdownTreeSelect
                  data={data}
                  onChange={this.onChange}
                  className="bootstrap-demo"
                  showDropdown="always"
                  showPartiallySelected="true"
                  keepOpenOnSelect="true"
                  keepTreeOnSearch="true"
                  keepChildrenOnSearch="true"                  
                  ref="selectionTree"
                />
              </div>     
            </div>
          </div>}
      </div>}
      </>
    );
  }
};



export default CustomTreeViewComp;