import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
 

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    // position:'absolute',
    // top:0,
    // left:0,
    height:'100%',
    zIndex:800,
    background: 'rgba(255,255,255,0.7)'
    
  },
}));

export default function Loading() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
      {/* <LinearProgress /> */}
    </div>
  );
}