
const Helper = {
    getColumnValueForDimensionGroup: function (config, axis, name, d, lookup, p) {
        p.x=d[config.groupKey]
        return;
        // var dateFormat = widgetDetailObj.isRegister ? "DD-MM-YYYY" : "YYYY-MM-DDTHH:mm:ss";
        // if (widgetDetailObj.ParametersObjByUIFieldName[axis].ColumnType == Self.__helperFunctions.getDataContext().gridColumnTypes.EntityFieldId) {
        //     if (widgetDetailObj.ParametersObjByUIFieldName[axis].DataType == "Date") {
        //         var object = Self.__helperFunctions.getDate((d.EntityField[name] == null || d.EntityField[name] == "") ? Self.Others : '' + d.EntityField[name], widgetDetailObj.ParametersObjByUIFieldName[axis].Date_Part, dateFormat);
        //         p.x = object[0].value;
        //         p.realValue = object[0].realValue;
        //     } else if (widgetDetailObj.ParametersObjByUIFieldName[axis].DataType == "Percentage") {
        //         var value = d.EntityField[name] != null ? math.ROUNDDECIMALS(d.EntityField[name] * 100,dataContext.constants.percentagePrecision) : d.EntityField[name];
        //         if (lookup != null) {
        //             p.x = Self.__helperFunctions.getRelValueFromLookupRange(lookup, value);
        //         } else {
        //             p.x = d.EntityField[name] == null ? Self.Others : '' +value;
        //         }
        //     } else if (widgetDetailObj.ParametersObjByUIFieldName[axis].DataType == "Check") {
        //         var val;
        //         if (d.EntityField[name] == null) {
        //             if (.utils.GetMethClientOption(widgetDetailObj.gridResponse.MethData, "DisableTriStateForCheckbox") != null && utils.GetMethClientOption(widgetDetailObj.gridResponse.MethData, "DisableTriStateForCheckbox") == "1") {
        //                 val = Self.No;
        //             } else {
        //                 val = Self.Others;
        //             }
        //         } else if (d.EntityField[name] == 1) {
        //             val = Self.Yes;
        //         } else {
        //             val = Self.No;
        //         }
        //         p.x = val;
        //     } else {
        //         if (lookup != null) {
        //             p.x = Self.__helperFunctions.getRelValueFromLookupRange(lookup, d.EntityField[name]);
        //         } else {
        //             p.x = d.EntityField[name] == null ? Self.Others : '' + d.EntityField[name];
        //         }

        //     }
        // } else {
        //     if (widgetDetailObj.ParametersObjByUIFieldName[axis].DataType == "Date") {
        //         var object = Self.__helperFunctions.getDate((d[name] === null || d[name] == "") ? Self.Others : '' + d[name], widgetDetailObj.ParametersObjByUIFieldName[axis].Date_Part,dateFormat);
        //         p.x = object[0].value;
        //         p.realValue = object[0].realValue;
        //     } else if (widgetDetailObj.ParametersObjByUIFieldName[axis].DataType == "Percentage") {
        //         var value = d[name] != null ? math.ROUNDDECIMALS(d[name] * 100,dataContext.constants.percentagePrecision) : d[name];
        //         if (lookup != null) {
        //             p.x = Self.__helperFunctions.getRelValueFromLookupRange(lookup, value);
        //         } else {
        //             p.x = d[name] == null ? Self.Others : '' + value;
        //         }
        //     } else if (widgetDetailObj.ParametersObjByUIFieldName[axis].DataType == "Check") {
        //         var val;
        //         if (d[name] == null) {
        //             if (utils.GetMethClientOption(widgetDetailObj.gridResponse.MethData, "DisableTriStateForCheckbox") != null && utils.GetMethClientOption(widgetDetailObj.gridResponse.MethData, "DisableTriStateForCheckbox") == "1") {
        //                 val = Self.No;
        //             } else {
        //                 val = Self.Others;
        //             }
        //         } else if (d[name] == 1) {
        //             val = Self.Yes;
        //         } else {
        //             val = Self.No;
        //         }
        //         p.x = val;
        //     } else {
        //         if (lookup != null) {
        //             p.x = Self.__helperFunctions.getRelValueFromLookupRange(lookup, d[name]);
        //         } else {
        //             p.x = d[name] == null ? Self.Others : '' + d[name];
        //         }
        //     }
        // }
    },
    updateAggregateValues: function (p, v, config, isAdd, withData) {
        var valueAxis = this.valueAxisFieldName(config, false);
        // if (valueAxis && widgetDetailObj.ParametersObjByUIFieldName.value.ColumnType == dataContext.gridColumnTypes.EntityFieldId) {
        //     p.value = v.EntityField[valueAxis];
        // } else {
            p.value = v[valueAxis];
        //}
        // if (valueAxis && widgetDetailObj.ParametersObjByUIFieldName.value.DataType == "Percentage") {
        //     p.value = math.ROUNDDECIMALS(p.value * 100, dataContext.constants.percentagePrecision);
        // }

        if (withData && !p.rawData)
            p.rawData = [];

        if (isAdd) {
            ++p.Count;
            p.UniqueID = v.UniqueID;
            if (withData && p.rawData) {
                p.rawData.push(v);
            }
            if (valueAxis != null && p.value != null && !isNaN(p.value)) {


                p.values[p.values.length] = p.value;
                if (config.aggregateType == 'sum')
                    p.Sum = p.Sum + Number(p.value);
                if (config.aggregateType == 'product')
                    p.Product = (p.value != 0) ? (p.Product != 0 ? p.Product * p.value : p.value) : p.Product;
                if (config.aggregateType == 'avg')
                    p.Sum = p.Sum + Number(p.value);
                p.Avg = p.Count != 0 ? p.Sum / p.Count : 0;
                if (config.aggregateType == 'min')
                    p.Min = (p.values != null && p.values.length > 0) ? Math.min.apply(Math, p.values) : 0;
                if (config.aggregateType == 'max')
                    p.Max = (p.values != null && p.values.length > 0) ? Math.max.apply(Math, p.values) : 0;
                if (config.aggregateType == 'stdDev')
                    p.StdDev = (p.values != null && p.values.length > 0) ? this.standardDeviation(p.values) : 0;

            }
        } else {
            --p.Count;
            if (withData && p.rawData) {
                p.rawData = p.rawData.filter(function (item) { return item.UniqueID != v.UniqueID; });
            }
            if (valueAxis != null && p.value != null && !isNaN(p.value)) {


                for (var count = 0; count < p.values.length; count++) {
                    if (p.values[count] == p.value) {
                        p.values.splice(count, 1);
                        break;
                    }
                }
                if (config.aggregateType == 'sum')
                    p.Sum = p.Sum - p.value;
                if (config.aggregateType == 'product')
                    p.Product = (p.value != 0) ? (p.Product / p.value == 1 ? 0 : p.Product / p.value) : p.Product;
                if (config.aggregateType == 'avg')
                    p.Sum = p.Sum - p.value;
                p.Avg = p.Count != 0 ? p.Sum / p.Count : 0;
                if (config.aggregateType == 'min')
                    p.Min = (p.values != null && p.values.length > 0) ? Math.min.apply(Math, p.values) : 0;
                if (config.aggregateType == 'max')
                    p.Max = (p.values != null && p.values.length > 0) ? Math.max.apply(Math, p.values) : 0;
                if (config.aggregateType == 'stdDev')
                    p.StdDev = (p.values != null && p.values.length > 0) ? this.standardDeviation(p.values) : 0;

            }
        }
    },
    intializeValues: function () {
        return {
            x: 0,
            y: 0,
            z: 0,
            Count: 0,
            Sum: 0,
            Product: 0,
            Avg: 0,
            Min: 0,
            Max: 0,
            StdDev: 0,
            values: [],
            Name: null,
            realValue: 0
        };
    },
    valueAxisFieldName: function (config, isParamType) {
        return config.groupKey;
        // var valueAxis = 'value';
        // if (widgetDetailObj.ParametersObjByUIFieldName[valueAxis] != null) {
        //     if (isParamType) {
        //         return widgetDetailObj.ParametersObjByUIFieldName[valueAxis].ParamTypeName;
        //     } else {
        //         return widgetDetailObj.ParametersObjByUIFieldName[valueAxis].FieldName;
        //     }
        // } else {
        //     return null;
        // }
    },
    standardDeviation: function (values) {
        var avg = this.average(values);
        var squareDiffs = values.map(function (value) {
            var diff = value - avg;
            var sqrDiff = diff * diff;
            return sqrDiff;
        });
        var avgSquareDiff = this.average(squareDiffs);
        var stdDev = Math.sqrt(avgSquareDiff);
        return stdDev;
    },

    average: function (data) {
        var sum = data.reduce(function (sum, value) {
            return sum + value;
        }, 0);

        var avg = sum / data.length;
        return avg;
    }
}

export default Helper