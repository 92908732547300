import React from "react";
import { withStyles } from "@material-ui/core/styles";
import NavBoard from "./../../components/NavBoard";
import Service from "../../Services";
import { ENTITY_TYPES } from "../../config/constant";
const styles = (theme) => ({
  root: {
    height: "100vh",
  },
});

const defaultItems = [
  // { name: "User Management", path: "/user" },
  // { name: "Role", path: "/role" },
  // {
  //   name: "User Group",
  //   path: "/group",
  // },
  // {
  //   name: "Manage Universe",
  //   path: "/adminUniverse",
  // },
  { name: "Manage Framework", path: "/frameworkList" },
  // {
  //   name: "Manage Register",
  //   path: "/register",
  // },
  // {
  //   name: "Permission Scheme",
  //   path: "/permission",
  // },
  {
    name: "Admin Forms",
    path: "/adminforms",
  },
  {
    name: "Custom Forms",
    path: "/customForms",
  },
  {
    name: "Config Forms",
    path: "/configForms",
  },
  {
    name: "Client Configuration",
    path: "/adminInstance/11/1",
  },
  {
    name: "Import Data",
    path: "/import",
  }
]

class NavMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: []
    };
  }


  async componentDidMount() {
    let finalList = [...defaultItems];
    let list = await Service().getEntityList(1);//pass one to get the existing
    if (list) {
      list = list.filter(x =>
        x.id != 11
      ).map(x => {
        x.path = `/adminInstanceList/${x.id}`
        return x;
      })
      finalList.push(...list);
    }

    this.setState({ navItems: finalList });

  }

  render() {
    const classes = this.props.classes;
    return <NavBoard NavItems={this.state.navItems}></NavBoard>;
  }
}

export default withStyles(styles)(NavMain);
