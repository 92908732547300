import baseEditForm from "formiojs/components/button/Button.form.js";
export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            // You can ignore existing fields.
            key: "placeholder",
            ignore: true
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Width",
            weight: 13,
            key: "myWidgetWidth", // This will be available as component.myWidgetURI
            defaultValue:"400px",
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Height",
            weight: 14,
            key: "myWidgetHeight", // This will be available as component.myWidgetURI
            defaultValue:"400px",
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Widget URI",
            weight: 15,
            key: "myWidgetURI", // This will be available as component.myWidgetURI
            defaultValue:"",           
          }
        ]
      },
      {
        key: "validation",
        components: []
      },
      {
        key: "api",
        components: []
      },
      {
        key: "conditional",
        components: []
      },
      {
        key: "logic",
        components: []
      }
    ],
    ...extend
  );
};
