import CheckMatrix from "./CheckMatrix";
import CustomSelect from "./CustomSelect";
import ColorSelect from "./ColorSelect";
import toggleCustomComp from "./Toggle";
import Colored from "./Colored";
import RangeColored from "./RangeColored"
import AssignGrid from "./AssignGrid";
import GridTypes from "./GridTypes";
import ColorPicker from "./ColorPicker";
import ContactList from "./ContactList"
import ReportWidget from "./CustomViewerWidget"
import ContactPermissionList from "./ContactPermissionList"
import LinkGrid from "./LinkGrid"
import EntityLinkGrid from "./EntityLinkGrid"
import TableTemplate from "./TableTemplate"
import WorkflowInstance from "./Workflow"
import Filters from "./Filters"
import ColumnValues from "./ColumnValues"
import CustomGrid from "./CustomGrid"
import DynamicTable from "./DynamicTable"
import customTreeSelection from "./CustomTreeSelection"
import QueryGrid from "./QueryGrid"
import EntityTab from "./EntityTab"
// import Chart from "./Chart"

 import CustomTextEditor from "./CustomTextEditor"
 import FrameworkTemplate from "./FrameworkTemplate"
 import DashboardSelect from "./Wrappers/DashboardSelect"
 import RegisterSelect from "./Wrappers/RegisterSelect"
 import NavButton from "./NavButton";
import SelectQueryGrid from "./SelectQueryGrid"
import ColorGrid from "./ColorGrid";
import FixedDate from "./FixedDate"

export default {
  dynamicTable:DynamicTable,
  customTreeSelection:customTreeSelection,
  checkmatrix: CheckMatrix,
  customselect: CustomSelect,
  colorselect:ColorSelect,
  toggleCustomComp,
  colored:Colored,
  assigngrid:AssignGrid,
  //gridTypes:GridTypes,
  colorPicker:ColorPicker,
  contactList:ContactList,
  customViewerWidgetComp:ReportWidget,
  contactPermissionList:ContactPermissionList,
  //linkGrid:LinkGrid,
  entityLinkGrid:EntityLinkGrid,
  tableTemplate:TableTemplate,
  filters:Filters,
  workflowInstance:WorkflowInstance,
  customGrid:CustomGrid,
  columnValues:ColumnValues,
  rangecolored:RangeColored,
  queryGrid:QueryGrid,
  entityTab:EntityTab,
  // chart:Chart,
  customTextEditor:CustomTextEditor,
  frameworkTemplate:FrameworkTemplate,
  dashboardSelect:DashboardSelect,
  registerSelect:RegisterSelect,
  navButton:NavButton,
  selectQueryGrid:SelectQueryGrid,
  colorGrid : ColorGrid,
  fixedDate :FixedDate
};
