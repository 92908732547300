import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./edit";

import lookUpService from "./../../../../Services/lookupApi";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Modal from './../../../Common/Modal';

const jsonString = {
  values: [
    {
      name: "High",
      color: "red",
    },
    {
      name: "low",
      color: "green",
    },
  ],
};

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const GridTypesComp = class extends React.Component {
  constructor(props) {
    super(props);

    let data;
    try {
      //data = JSON.parse(props.component.jsonData);
      data = { unassigned: [],assigned:[] }; //set default
    } catch (e) {
      data = { unassigned: [],assigned:[] }; //set default
    }

    this.handleAssign = this.handleAssign.bind(this);
    this.handleUnAssign = this.handleUnAssign.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDialog =this.handleDialog.bind(this);
    let value=props.value ? props.value : props.submissionData;
    this.state = {
      value:value,
      jsonData:value?value.jsonData: data,
      searchText:"",
      columns:props.component.columns,
      showDialog:false
    };
  }

  setValue = (jsonDataToSave) => {
    
    this.setState(
      (prevState) => ({
        value: {
          jsonData: jsonDataToSave,
        },
      }),
      // () => this.props.onChange(null,this.state.value)
      () => this.props.onChange(this.state.value)
    );
  };

  handleAssign() {
    console.log(this.refs);
    var data = this.state.jsonData;
    var selected = data.unassigned.filter((x) => x.selected)
    data.unassigned = data.unassigned.filter((x) => !x.selected);
    selected=selected.map(x=> { delete x.selected; return x;});

    let jsonDataToSave={
        assigned: [...data.assigned, ...selected],
        unassigned: data.unassigned
    }
    this.setState({
      jsonData:jsonDataToSave
    });
    this.setValue(jsonDataToSave);
  }

  handleUnAssign() {
    console.log(this.refs);

    var data = this.state.jsonData;
    var selected = data.assigned.filter((x) => x.selected);
    data.assigned = data.assigned.filter((x) => !x.selected);
    selected=selected.map(x=> { delete x.selected; return x;});
    let jsonDataToSave={
        unassigned: [...data.unassigned, ...selected],
        assigned: data.assigned,
      }
    
    this.setState({
      jsonData:jsonDataToSave
    });
    this.setValue(jsonDataToSave);
  }
  handleSelect(item) {
    item.selected = item.selected ? !item.selected : true;
  }

  handleChange(e){
    this.setState({
      searchText:e.target.value
    })  
  }

  handleSearch(e){
    e.preventDefault();      
      const list= lookUpService.getUsers(this.state.searchText);
      let jsonData=this.state.jsonData;
      jsonData.assigned=list
      this.setState({
        jsonData,
      })  
  }

  handleDialog(openState) {
    this.setState({showDialog:openState})
  }

  render() {
    const data = this.state.jsonData;

    return (
      <div>
          <Button variant="contained" onClick={() => 
            this.handleDialog(true)
            } color="primary">
            Open
          </Button>
          <table>
            <thead>
              <tr>
              <th>Select</th>
                {
                  this.state.columns && this.state.columns.filter(x=>x.visible).map((col, i)=>(
                     <th key={i}> {col.name} </th>
                  ))
                }
                
              </tr>
            </thead>
            <tbody>
              {data &&
                data.assigned &&
                data.assigned.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {" "}
                      <input                        
                        type="checkbox"
                        key={item.id}
                        checked={item.selected}
                        onChange={() => {
                          this.handleSelect(item);
                        }}
                      />{" "}
                    </td>                    
                    {
                       this.state.columns && this.state.columns.filter(x=>x.visible).map((col, i)=>(
                        <td key={i}> {item[col.dbfield]} </td>
                     ))
                    }
                  </tr>
                ))}
            </tbody>
          </table>
        
        { this.state.showDialog && (<Modal closeModal={() => this.handleDialog(false)}> 
        <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "90%",
        }}
      >
        <div>
          <TextField id="standard-basic" value={this.state.searchText} onChange={this.handleChange} label="Standard" />
          <Button variant="contained" onClick={this.handleSearch} color="primary">
            Search
          </Button>
          
          <table>
            <thead>
              <tr>
              <th>Select</th>
                {
                  this.state.columns && this.state.columns.filter(x=>x.visible).map((col, i)=>(
                     <th key={i}> {col.name} </th>
                  ))
                }
                
              </tr>
            </thead>
            <tbody>
              {data &&
                data.assigned &&
                data.assigned.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {" "}
                      <input                        
                        type="checkbox"
                        key={item.id}
                        checked={item.selected}
                        onChange={() => {
                          this.handleSelect(item);
                        }}
                      />{" "}
                    </td>                    
                    {
                       this.state.columns && this.state.columns.filter(x=>x.visible).map((col, i)=>(
                        <td key={i}> {item[col.dbfield]} </td>
                     ))
                    }
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div>
          <input type="button" onClick={this.handleUnAssign} value=">" />
          <input type="button" onClick={this.handleAssign} value="<" />
        </div>

        <table>
        <thead>
              <tr>
              <th>Select</th>
                {
                  this.state.columns && this.state.columns.filter(x=>x.visible).map((col, i)=>(
                     <th key={i}> {col.name} </th>
                  ))
                }
                
              </tr>
            </thead>
          <tbody>
            {data &&
              data.unassigned &&
              data.unassigned.map((item, i) => (
                <tr key={i}>
                  <td>                    
                    <input
                    key={`unassigned ${i}`}
                      type="checkbox"
                      key={item.id}
                      onChange={() => {
                        this.handleSelect(item);
                      }}
                    />
                  </td>
                  {
                       this.state.columns && this.state.columns.filter(x=>x.visible).map((col, i)=>(
                        <td key={i}> {item[col.dbfield]} </td>
                     ))
                    }
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      
          </Modal>)}
     </div>
    );
  }
};

export default class GridTypes extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "3GridTypes",
      icon: "square",
      group: "basic",
      documentation: "",
      weight: -10,
      schema: GridTypes.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "gridTypes",
      label: "Assign Grid",
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    //console.log('this.dataForSetting',this.dataForSetting)
    // if (this.shouldSetValue) {
    //   //this.setValue(this.dataForSetting);
    //   this.updateValue(this.dataForSetting);
    // }
    return ReactDOM.render(
      <GridTypesComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }

  setValue(data) {
    console.log("Called wit data-", data);
    //hacked to rerender the react component by adding new prop //temp fix
    //hacked to rerender the react component by adding new prop submissionData//temp fix
    //TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO
    if (data) {
      ReactDOM.render(
        <GridTypesComp
          component={this.component} // These are the component settings if you want to use them to render the component.
          value={this.dataValue} // updatedvalue of the component.
          onChange={this.updateValue} // The onChange event to call when the value changes.
          submissionData={data}
        />,
        this.element
      );
    }
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
