import React from "react";
import { FormBuilder, Components,Formio } from "react-formio";
import DBService from "../../Services/dexieForm";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from "@material-ui/core/styles";
// import formStyles from "../../../node_modules/formiojs/dist/formio.full";
import TextField from "@material-ui/core/TextField";

import components from "./Custom";
Components.setComponents(components);
console.log('baseUrl',Formio.getBaseUrl())
//needed to set baseUrl to go env against
Formio.setBaseUrl("http://localhost:5000")
console.log('baseUrl',Formio.getBaseUrl())
//Formio.setToken()

// Formio.Headers={
//   authorization:`bearer ${token}`
// }

var AddHeaders = {
  priority: 0,
  preRequest: function(requestArgs) {
    console.log("requestArgs",requestArgs.url)
    const token=window.localStorage.getItem("token");
    if(!requestArgs.opts.headers) requestArgs.opts.headers = {};
    requestArgs.opts.headers.authorization = `bearer ${token}`;
    
    return new Promise(function(resolve, reject){
      setTimeout(resolve, 1000);
    })
  }
}
//registering the plugin to add token
//todo move this to interceptors
Formio.registerPlugin(AddHeaders, 'addheaders');

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

class BuilderForm extends React.Component {
  constructor(props) {
    super(props);
    console.log("props-", this.props);
    this.state = { components: [], display: "form", id: -100, name: "", methJson:{} }; //default

    this.handleChange = this.handleChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDisplayChange =this.handleDisplayChange.bind(this);
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    console.log("id", params.id);

    DBService.initialize();
    const item = await DBService.getFormMeta(params.id);
    console.log("item-", item);
    if (item) {
      this.setState({
        components: item.meta.components,
        display: item.meta.display,
        name: item.name,
        id: params.id,
        methJson:JSON.stringify(item.meta)
      });
    }
  }

  handleChange(schema) {
    console.log(schema);
    this.setState({ components: schema.components, display: schema.display });
    this.state.methJson=JSON.stringify(schema)
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }
  handleDisplayChange(event) {
    this.setState({ display: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();
    console.log("A form was submitted: " + JSON.stringify(this.state));
    DBService.initialize();

    const id = this.state.id;

    if (id == -100) {
      //add
      const newId = await DBService.addFormMeta({
        name: this.state.name,
        meta: {
          components: this.state.components,
          display: this.state.display,
        },
      });
      console.log("newId", newId);
      //this.props.history.push(`/build/${newId}`);
      this.props.history.push(`/buildList`);
    } else {
      //update
      const updated = await DBService.updateFormMeta(id, {
        name: this.state.name,
        meta: {
          components: this.state.components,
          display: this.state.display,
        },
      });
      console.log("updated", updated);
    }
  }

  render() {
    const classes = this.props;
    return (
      <form onSubmit={this.handleSubmit}> 
        {/* <link
          rel="stylesheet"
          href="./../"
        /> */}
        <div className="formMain">
          <label>
            Form Name:
            <input
              type="text"
              value={this.state.name}
              placeholder="Name"
              required
              onChange={this.handleNameChange}
            />
          </label>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Display</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.display}
              onChange={this.handleDisplayChange}
            >
              <MenuItem value={"form"}>Form</MenuItem>
              <MenuItem value={"wizard"}>Wizard</MenuItem>
             
            </Select>
          </FormControl>
          <input type="submit" value="Submit Form" />
        </div>
        <FormBuilder
          form={{
            display: this.state.display,
            components: this.state.components,
          }}
          onChange={this.handleChange}
          // options={{
          //   builder: {
          //     customBasic: {
          //       title: 'Custom Components',
          //       default: true,
          //       weight: 0,
          //       components: {
          //         gridTypes: false,
          //         colorPicker:true
          //       }
          //     },
          //     advanced: false
          //   }
          // }}
        ></FormBuilder>
        <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="meth"
                label="meth"
                name="meth"
                autoComplete="meth"
                autoFocus
                value={this.state.methJson}                
                rows="10"
                multiline
              />
      </form>
    );
  }
}

 export default withStyles(styles)(BuilderForm);

let sampleComponet = [
  {
    input: true,
    tableView: true,
    inputType: "text",
    inputMask: "",
    label: "First Name",
    key: "firstName",
    placeholder: "Enter your first name",
    prefix: "",
    suffix: "",
    multiple: false,
    defaultValue: "",
    protected: false,
    unique: false,
    persistent: true,
    validate: {
      required: false,
      minLength: "",
      maxLength: "",
      pattern: "",
      custom: "",
      customPrivate: false,
    },
    conditional: {
      show: false,
      when: null,
      eq: "",
    },
    type: "textfield",
  },
];
