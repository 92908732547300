import baseEditForm from "formiojs/components/_classes/component/Component.form";


export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            // You can ignore existing fields.
            key: "placeholder",
            ignore: true,
          },
          {
            type: "textfield",
            input: true,
            label: "Button Text",
            key: "buttonText",
            defaultValue: "Add",
          },
          {
            type: "textfield",
            input: true,
            label: "Navigation Url",
            key: "navUrl",
            defaultValue: "/navbar",
          },{
            type: "checkbox",
            input: true,
            label: "Display in Entity Modal",
            key: "entityModal",
            
          }
        ],
      },

      {
        key: "data",
        components: [],
      },
      {
        key: "validation",
        components: [],
      },
      {
        key: "api",
        components: [],
      },
      {
        key: "conditional",
        components: [],
      },
      {
        key: "logic",
        components: [],
      },
    ],
    ...extend
  );
};
