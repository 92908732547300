import React from "react";
import { withStyles } from "@material-ui/core/styles";


const styles = (theme) => ({
    root: {
        height: "100vh",
    },
});



class TableConfiguration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableConfiguration: {
                orderByColumn: '',
                matchOnColumn: '',
                allowedColumnsToImport: [...this.props.Columns]
            }
        };
        this.handleOnSelectChange = this.handleOnSelectChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
    }

    //onChange
    handleOnSelectChange(event) {

        this.setState({
            tableConfiguration: {
                ...this.state.tableConfiguration,
                [event.target.name]: event.target.value
            }
        });

    }
    handleCheckbox(event, item) {

        item.isSelected = event.target.checked;
        this.setState({
            tableConfiguration: {
                ...this.state.tableConfiguration
            }
        });

    }

    componentDidMount() {


    }

    render() {
        const classes = this.props.classes;
        const columns = [...this.props.Columns];
        //temp hack to pass child state to parent
        //passing the callback to return the current tableConfiguration state
        if (typeof this.props.onChange === "function") {
            this.props.onChange(() => {
                return this.state.tableConfiguration
            })
        }


        return (
            <>

                <div>
                    Order by Column :
                    <select
                        name="orderByColumn"
                        value={this.state.tableConfiguration.orderByColumn} onChange={(e) => {
                            this.handleOnSelectChange(e)
                        }}
                    >
                        <option value="-1">Select</option>
                        {columns.map(x =>
                            <option key={`${x.id}-OrderBy`} value={x.id}>
                                {`${x.name}`}
                            </option>
                        )
                        }
                    </select>
                </div>
                <div>
                    Match On Column for Import :
                    <select
                        name="matchOnColumn"
                        value={this.state.tableConfiguration.matchOnColumn} onChange={(e) => {
                            this.handleOnSelectChange(e)
                        }}
                    >
                        <option value="-1">Select</option>
                        {columns.map(x =>
                            <option key={`${x.id}-Match`} value={x.id}>
                                {`${x.name}`}
                            </option>
                        )
                        }
                    </select>
                </div>
                <div>
                    Allowed Columns to Import:
                    {this.state.tableConfiguration.allowedColumnsToImport.map(col =>
                        <>
                        <div>
                            <input type="checkbox"
                                checked={col.isSelected}
                                //disabled={col.colId === "name" ? true : false}
                                onChange={(e) => {
                                    this.handleCheckbox(e, col);
                                }} />  {col.name}
                                </div>
                        </>
                    )
                    }
                </div>
            </>
        )

    }
}

export default withStyles(styles)(TableConfiguration);
