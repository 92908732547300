import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./edit";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";

import lookUpService from "./../../../../../Services/lookupApi";
import DynamicGrid from "../../../../Common/DynamicGrid"
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import Dashboard from "../../../../../components/DashBoard/DashBoard";
import EntityTypeList from "../../../../../views/EntityTypeList";

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */

const EntityTypeIds = {
  Dashboards: 23
}

const RegisterComp = class extends React.Component {
  constructor(props) {
    super(props);

    let data;
    try {
      //data = JSON.parse(props.component.jsonData);
      data = {}; //set default
    } catch (e) {
      data = {}; //set default
    }



    this.handleChange = this.handleChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);


    let value = props.value ? props.value : props.submissionData;
    let valueToLoad = props.component.defaultValue;
    this.state = {
      value: value,
      jsonData: value ? value.jsonData : data,
      showDialog: false,
      items: [],
      types: [
      ],
      selectedItemId: valueToLoad?.selectedItemId,
      selectedTypeId: valueToLoad?.selectedTypeId,
      displayname: valueToLoad?.displayname,
      //TODO temp dirty hack for the editor mode
      isEditMode: window.location.pathname.indexOf('/customBuilder/') > -1 ? true : false,
      renderAsNavLink: valueToLoad?.renderAsNavLink
    };
    this.handleOnTypeSelect = this.handleOnTypeSelect.bind(this);
    this.handleOnItemSelect = this.handleOnItemSelect.bind(this);
  }

  async componentDidMount() {
    this.mounted = true;
    this.getData();

  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleOnTypeSelect(e) {
    const selectedTypeId = e.target.value;
    this.setState({
      selectedTypeId: selectedTypeId
    }
    )

    const dataToSet = {
      //...this.props.component.defaultValue,
      selectedTypeId: selectedTypeId,
    }
    this.updateFormIoValue(dataToSet)
    //this.props.component.customSelectedItemId = col.id
    //this.props.onChange(selectedTypeId, { defaultValue: dataToSet })
    this.getItemsByTypeId(selectedTypeId)
  }

  updateFormIoValue(newData) {

    this.props.component.defaultValue = {
      ...this.props.component.defaultValue,
      ...newData
    }
  }

  handleOnItemSelect(e) {
    const selectedItemId = e.target.value;
    this.setState({
      selectedItemId: selectedItemId
    }
    )

    const dataToSet = {
      //...this.props.component.defaultValue,
      selectedTypeId: this.state.selectedTypeId,
      selectedItemId: selectedItemId
    }
    this.updateFormIoValue(dataToSet)
    //this.props.component.customSelectedItemId = col.id
    //this.props.onChange(selectedItemId, { defaultValue: dataToSet })
  }

  async getData() {

    const data = await lookUpService.getEntityTypelistForRegisterSelect();

    if (data && this.mounted) {

      this.setState(
        {
          types: [...data],
        }
      )
    }
    if (this.state.selectedTypeId > -1) {
      this.getItemsByTypeId(this.state.selectedTypeId)
    }
  }

  async getItemsByTypeId(typeId) {

    const data = await lookUpService.getItemsListForRegisterSelect({ typeId });

    if (data.list && this.mounted) {

      this.setState(
        {
          items: [...data.list],
        }
      )
    }
  }
  setValue = (dataToSave) => {

    this.setState(
      (prevState) => ({
        value: dataToSave,
      }),
      // () => this.props.onChange(null,this.state.value)
      () => this.props.onChange(this.state.value)
    );
  };



  handleChange(val) {

    this.setState({
      isEditMode: val
    })
  }

  handleTextChange(event) {

    let value = event.target.value;
    if (event.target.name == 'renderAsNavLink')
      value = event.target.checked;
    this.setState({
      [event.target.name]: value,
    });
    this.updateFormIoValue({
      [event.target.name]: value
    })
  }



  render() {
    //const data = this.state.jsonData;
    //const { displayCards } = this.props;
    const isEditMode = this.state.isEditMode;
    const showEditMode = true;
    const selectedItemObj = this.state.selectedItemId > -1 ? this.state.items.find(x => x.id == this.state.selectedItemId) : null
    const tableStyle = {
      "borderWidth": "1px",
      'borderColor': "#aaaaaa",
      'borderStyle': 'solid',
      fontSize: 18
    }

    return (
      <div>

        <div>
          <div class="btn-toolbar justify-content-end col-xs-12">

          </div>

          SelectedItem- {JSON.stringify(this.props.component.defaultValue)}
          {
            showEditMode && <>
              {!isEditMode && <IconButton aria-label="Edit" onClick={() => (this.handleChange(true))}>
                <EditIcon />
              </IconButton>}
              {isEditMode && <IconButton aria-label="Done" onClick={() => (this.handleChange(false))}>
                <DoneIcon />
              </IconButton>

              }
            </>
          }

          {
            isEditMode && <>

              {
                this.state.types.length > 0 &&
                <select className="form-control"
                  value={this.state.selectedTypeId} onChange={this.handleOnTypeSelect}>
                  <option value={-1} key={-1}>
                    {"Select "}
                  </option>
                  {this.state.types.map(x =>
                    <option value={x.id} key={x.id}>
                      {x.name}
                    </option>
                  )
                  }
                </select>
              }
              {
                // this.state.items.length > 0 &&
                <select className="form-control"
                  value={this.state.selectedItemId} onChange={this.handleOnItemSelect}>
                  <option value={-1} key={-1}>
                    {"Select "}
                  </option>
                  {this.state.items.map(x =>
                    <option value={x.id} key={x.id}>
                      {x.name}
                    </option>
                  )
                  }
                </select>
              }
              {
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="displayname"
                  label="Display name"
                  name="displayname"
                  autoComplete="displayname"
                  autoFocus
                  value={this.state.displayname}
                  onChange={this.handleTextChange}
                />
              }
              Display as Navigation Link
              <input
                type="checkbox"
                name="renderAsNavLink"
                checked={this.state.renderAsNavLink}
                onChange={this.handleTextChange
                }
              />
            </>
          }
          {
            !isEditMode && <div>
              Renders the view for selected Item
              {
                this.state.renderAsNavLink && <>
                  {
                    selectedItemObj && <a target="_blank" href={selectedItemObj.entityUrl}>{this.state.displayname}</a>
                  }
                </>
              }

              {this.state.selectedTypeId == EntityTypeIds.Dashboards && <>
                {
                  <Dashboard
                    match={{
                      params: {
                        id: 2,
                      }
                    }}
                  ></Dashboard>
                }
                {
                  // <EntityTypeList
                  //   match={{
                  //     params: {
                  //       entityTypeId: 3,
                  //       entityId: -1,
                  //       pentityTypeId: 2,
                  //       pentityId: 4
                  //     }
                  //   }}

                  // >
                  // </EntityTypeList>
                }
              </>}

            </div>
          }





        </div>

      </div >
    );
  }
};

export default class Register extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "15 Register Select",
      icon: "square",
      group: "basic",
      documentation: "",
      weight: -10,
      schema: Register.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "registerSelect",
      label: "Select Register",
      logic: [
        {
          "name": "on Load",
          "trigger": {
            "type": "javascript",
            "javascript": "result= true;"
          },
          "actions": [
            {
              "name": "populateIds",
              "type": "mergeComponentSchema",
              //              "schemaDefinition": "schema = { myWidgetURI:data[data.apiKeyToPick] }"
              "schemaDefinition": "schema = {frameworkId:data.frameworkId, entityId:data.entityId,entityTypeId:data.entityTypeId,pentityId:data.pentityId, pentityTypeId:data.pentityTypeId }"
            }
          ]
        }
      ]
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    //console.log('this.dataForSetting',this.dataForSetting)
    // if (this.shouldSetValue) {
    //   //this.setValue(this.dataForSetting);
    //   this.updateValue(this.dataForSetting);
    // }

    return ReactDOM.render(
      <RegisterComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        contextData={this._data}
      />,
      element
    );
  }

  setValue(data) {
    console.log("Called wit data-", data);
    //hacked to rerender the react component by adding new prop //temp fix
    //hacked to rerender the react component by adding new prop submissionData//temp fix
    //TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO
    if (data && this.element) {
      ReactDOM.render(
        <RegisterComp
          component={this.component} // These are the component settings if you want to use them to render the component.
          value={this.dataValue} // updatedvalue of the component.
          onChange={this.updateValue} // The onChange event to call when the value changes.
          submissionData={data}
          contextData={this._data}
        />,
        this.element
      );
    }
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
