import React, { Component } from "react";
import lookUpService from "./../../../Services/lookupApi";

import DashboardRender from "../../DashBoard/DashBoard";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import TextField from "@material-ui/core/TextField";
import PieChartConfig from "./PieChart.js";
import BChartConfig from "./BChart.js";
import SelectChartConfig from "./Select";
import SelectWithSummaryChartConfig from "./SelectWithSummary";
import NumberChartConfig from "./NumberChart";
import TableChartConfig from "./Table";
import LineChartConfig from "./LineChart";
import HeatMapChartConfig from "./HeatMapChart";
import Modal from './../Modal';
//import GridLayout from "./GridLayout";
import CustomFormsInstance from "./../../CustomForms/Instance"
const ChartDefaults = {
  PieChart: {},
  BChart: {
    labelAngle: "-45",
    labelMargin_x: "-1em",
    labelMargin_y: "0em",
  },
  SelectChart: {
    "multiSelect": true,
    "showCounts": true
  },
  TableChart: {
    columns: []
  },
  LineChart: {
    columns: []
  },
  HeatChart: {
    xKey: "",
    yKey: ""
  }
};
const WIDGET_TYPES = {
  Chart: 1,
  Dashboard: 2,
};

const WIDGET_MODAL_ACTIONS = {
  Addnew: 1,
  EditExisting: 2
}

class DashboardWrapper extends Component {
  constructor(props) {
    super(props);

    let SavedConfig = props.configValue && props.configValue.dashboardFinalConfig ? props.configValue.dashboardFinalConfig : {};

    let existingWidgets = [];
    if (SavedConfig.widgets) {
      existingWidgets = SavedConfig.widgets.map(x => x.config)
    }
    this.state = {
      isEditMode: false,
      chartTypes: [
        { name: "piechart", chartType: "piechart" },
        { name: "bchart", chartType: "bchart" },
        { name: "select", chartType: "select" },
        { name: "selectWithSummary", chartType: "selectWithSummary" },  
        { name: "numberChart", chartType: "numberChart" },      
        { name: "table", chartType: "table" },
        { name: "heatmap", chartType: "heatmap" },
        { name: "linechart", chartType: "linechart" },
      ],
      dashBoardtitle: SavedConfig.title || "",
      addCurrentContext: SavedConfig.addCurrentContext || true,
      dashboardFinalConfig: SavedConfig,
      layoutInfo: SavedConfig.layoutInfo,
      widgets: [...existingWidgets],
      entityTypes: [],
      entities: [],
      dataSources: SavedConfig.dataSources || [
        {
          entityTypeId: "",
          entityId: "",
          displayName: ''
        }
      ],
      dataSourceDependency: SavedConfig.dataSourceDependency || [],
      groupKeysByEntityID: {},
      entitiesByEntityTypeId: {},
      selectedWidgetTypeId: SavedConfig.selectedWidgetTypeId || WIDGET_TYPES.Dashboard,
      widgetTypes: [
        { id: WIDGET_TYPES.Chart, name: "Chart" },
        { id: WIDGET_TYPES.Dashboard, name: "Dashboard" },
      ],
      //showPreview: true,
      showPreviewDialog: false,
      aggregateFunctions: {
        "piechart": {
          "default": [
            { name: "Count", code: "count" },
          ],
          "date": [],
          "number": [
            { name: "Average", code: "avg" },
            { name: "Count", code: "count" },
            { name: "Sum", code: "sum" },
            { name: "Distinct Count", code: "distinctCount" }
          ]
        },
        "bchart": {
          "default": [
            { name: "Count", code: "count" },
          ],
          "date": [],
          "number": [
            { name: "Average", code: "avg" },
            { name: "Count", code: "count" },
            { name: "Sum", code: "sum" },
            { name: "Distinct Count", code: "distinctCount" }
          ]
        }

      },
      showWidgetItemAddEdit: false,
      widgetItemToAddEditIndex: -1,
      widgetItemToAddEditAction: WIDGET_MODAL_ACTIONS.Addnew
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleWidgetAdd = this.handleWidgetAdd.bind(this);
    this.handleWidgetDelete = this.handleWidgetDelete.bind(this);
    this.handleDataSourceAdd = this.handleDataSourceAdd.bind(this);
    this.handleDataSourceDelete = this.handleDataSourceDelete.bind(this);
    this.handleDataSourceDependencyAdd = this.handleDataSourceDependencyAdd.bind(this);
    this.handleDependencyDataSourceSelection = this.handleDependencyDataSourceSelection.bind(this);
    this.handleDependencyColumnChange = this.handleDependencyColumnChange.bind(this);
    this.handleChartTypeChange = this.handleChartTypeChange.bind(this);
    this.handleOnConfigChange = this.handleOnConfigChange.bind(this);
    this.getNewWidgetDefaults = this.getNewWidgetDefaults.bind(this);
    this.handleWidgetTypeChange = this.handleWidgetTypeChange.bind(this);
    this.handleWidgetPropChange = this.handleWidgetPropChange.bind(this);
    this.handlePreview = this.handlePreview.bind(this);
    this.handleLayoutChange = this.handleLayoutChange.bind(this);
    this.handleWidgetItemToAddEdit = this.handleWidgetItemToAddEdit.bind(this);
    this.handleWidgetItemToEdit = this.handleWidgetItemToEdit.bind(this);
  }

  async componentDidMount() {
    this.getData();
  }

  async getData() {
    const list = await lookUpService.getEntityTypelistForRegisterSelect();
    const typeId = 3;
    //const data = await lookUpService.getItemsListForRegisterSelect({ typeId });
    this.setState({
      entityTypes: [...list],
      //entities: [...data.list],
    });
    //todo optimize the calls
    this.state.dataSources.map(async (x) => {
      await this.getEntitiesByEntityTypeId(x.entityTypeId)
    })
    this.state.dataSources.map(async (x) => {
      if (x.entityTypeId != 23) {//skip for dashboards
        await this.getGroupKeys(x.entityId, x.entityTypeId)
      }
    })
  }

  async getEntitiesByEntityTypeId(typeId) {
    if (typeId > 0) {
      const propName = `${typeId}`;

      if (!this.state.entitiesByEntityTypeId[propName]) {
        const data = await lookUpService.getItemsListForRegisterSelect({
          typeId
        });
        this.setState({
          entitiesByEntityTypeId: {
            ...this.state.entitiesByEntityTypeId,
            [`${propName}`]: [...data.list]
          }
        })
      }
    }
  }

  async getGroupKeys(entityId, entityTypeId) {
    if (entityId > 0 && entityTypeId > 0) {
      const propName = `${entityTypeId}_${entityId}`;
      if (!this.state.groupKeysByEntityID[propName]) {
        const list = await lookUpService.getStepItemsbyEntity({
          entityId: entityId,
          entityTypeId: entityTypeId
        });
        this.setState({
          groupKeysByEntityID: {
            ...this.state.groupKeysByEntityID,
            [`${propName}`]: list
          }
        })
      }
    }

  }

  handleWidgetItemToEdit(index) {
    this.handleWidgetItemToAddEdit(true, WIDGET_MODAL_ACTIONS.EditExisting, index, null);
  }

  handleWidgetItemToAddEdit(show, widgetModalAction, editIndex, postModalActionSave) {

    if (show) {

      if (widgetModalAction === WIDGET_MODAL_ACTIONS.Addnew) {//addnew scenario
        const addIndex = this.state.widgets.length;
        const newArray = [
          ...this.state.widgets,
          this.getNewWidgetDefaults()
          ,
        ];

        this.setState({
          widgetItemToAddEditAction: WIDGET_MODAL_ACTIONS.Addnew,
          showWidgetItemAddEdit: true,
          widgets: [...newArray],
          widgetItemToAddEditIndex: addIndex,
        })
      }
      else if (widgetModalAction === WIDGET_MODAL_ACTIONS.EditExisting) {//editing scenario
        this.setState({
          widgetItemToAddEditAction: WIDGET_MODAL_ACTIONS.EditExisting,
          showWidgetItemAddEdit: true,
          widgetItemToAddEditIndex: editIndex,
          editingWidgetBeforeChanges:{...this.state.widgets[editIndex]}
        })
      }
    }
    else {//when closing
      if (this.state.widgetItemToAddEditAction == WIDGET_MODAL_ACTIONS.Addnew) {
        //when closing new add dialog
        if (postModalActionSave) {

          this.setState({
            showWidgetItemAddEdit: false,
            widgetItemToAddEditIndex: -1,
            isEditMode: true //setting this to true for immediate rerendering of the dashboard preview when the below state callback is called
            //need to better handle this without using isEditMode
          }, () => {
            //save the edited
            this.handleChange(false);
          })
        }
        else {//delete the new added
          this.setState({
            widgets: [...this.state.widgets.filter((x, i) => this.state.widgetItemToAddEditIndex !== i)],
            showWidgetItemAddEdit: false,
            widgetItemToAddEditIndex: -1
          })
        }
      }
      else if (this.state.widgetItemToAddEditAction == WIDGET_MODAL_ACTIONS.EditExisting) {
        //when closing edit dialog
        if (postModalActionSave) {

          this.setState({
            showWidgetItemAddEdit: false,
            widgetItemToAddEditIndex: -1,//just resetting the state
            isEditMode: true //setting this to true for immediate rerendering of the dashboard preview when the below state callback is called
            //need to better handle this without using isEditMode
          }, () => {
            //save the edited
            this.handleChange(false);
          })
        }
        else {//restore the unmodified

          const widgets= this.state.widgets;
          widgets[editIndex]={...this.state.editingWidgetBeforeChanges}
          this.setState({
            widgets: [...widgets],
            showWidgetItemAddEdit: false,
            widgetItemToAddEditIndex: -1
          })
        }
      }
    }
  }

  handlePreview(val) {



    if (!val) {//update config when going to renderMode
      const layoutInfo = this.getFromLS("dash");
      let finalConfig = {
        ...this.state.dashboardFinalConfig,
        layoutInfo: { ...layoutInfo }
      }
      this.setState({
        layoutInfo: { ...layoutInfo },
        dashboardFinalConfig: finalConfig,
        showPreviewDialog: val
      })
      this.props.updateFormIoValue({
        dashboardFinalConfig: finalConfig,
      });
    }
    else {
      this.setState({
        showPreviewDialog: val
      })
    }



  }

  handleLayoutChange(layoutInfo) {
    let finalConfig = {
      ...this.state.dashboardFinalConfig,
      layoutInfo: { ...layoutInfo }
    }
    this.setState({
      layoutInfo: { ...layoutInfo },
      dashboardFinalConfig: { ...finalConfig },
    })
    this.props.updateFormIoValue({//update state in custom form
      dashboardFinalConfig: finalConfig,
    });
  }

  getFromLS(key) {//thsi can be removed as direct handler is added in dashboard to pass the layout
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("dashLayoutKey")) || {};
      } catch (e) { }
    }
    return ls[key];
  }

  saveToLS(key, value) {
    if (global.localStorage) {
      let ls = {};

      try {
        ls = JSON.parse(global.localStorage.getItem("dashLayoutKey")) || {};
      } catch (e) { }

      const objToUpdate = {
        ...ls,
        [key]: value
      }
      global.localStorage.setItem(
        "dashLayoutKey",
        JSON.stringify(objToUpdate)
      );
    }
  }

  handleChange(val) {
    this.setState({
      isEditMode: val,
    });



    //const Objectkeys = ["a", "b", "c", "d", "e", "f", "g", "h"];
    let finalConfig = {
      title: this.state.dashBoardtitle,
      addCurrentContext: this.state.addCurrentContext,
      selectedWidgetTypeId: this.state.selectedWidgetTypeId,
      dataSources: this.state.dataSources,
      dataSourceDependency: this.state.dataSourceDependency,
      layoutInfo: this.state.layoutInfo,
      widgets: this.state.widgets.map((x, i) => {
        if (x.chartType) {
          return {
            //layout: { x: 0, y: 0, w: 3, h: 6 },
            chartType: x.chartType,
            config: {
              // title: x.title,
              // groupKey: x.groupKey,
              ...x,
            },
          };
        }
      })
    };
    //         "layout":{"x":0,"y":6,"w":3,"h":6},
    // "chartType":"piechart",
    // "config":{
    //         }


    if (!val) {//update config when going to renderMode
      this.setState({
        dashboardFinalConfig: finalConfig
      })
      this.props.updateFormIoValue({
        dashboardFinalConfig: finalConfig,
      });
    }
  }
  handleChartTypeChange(item, e) {
    item.chartType = e.target.value;
    this.setState({
      widgets: [...this.state.widgets],
    });
  }

  handleWidgetAdd(e) {
    e.preventDefault();
    const newArray = [
      ...this.state.widgets,
      this.getNewWidgetDefaults()
      ,
    ];
    this.setState({
      widgets: [...newArray],
    });
  }

  handleWidgetDelete(e, indexToRemove) {
    e.preventDefault()

    this.setState({
      widgets: [...this.state.widgets.filter((x, i) => indexToRemove !== i)]
    })

  }

  handleDataSourceAdd(e) {
    e.preventDefault();
    const newArray = [
      ...this.state.dataSources,
      {
        entityId: -1,
        entityTypeId: -1,
        displayName: ''
      }
      ,
    ];
    this.setState({
      dataSources: [...newArray],
    });

  }

  handleDataSourceDelete(e, indexToRemove) {
    e.preventDefault()

    this.setState({
      dataSources: [...this.state.dataSources.filter((x, i) => indexToRemove !== i)]
    })

  }

  handleDataSourceDependencyAdd(e) {
    e.preventDefault();
    const newArray = [
      ...this.state.dataSourceDependency,
      {
        lentityId: -1,
        lentityTypeId: -1,
        ldisplayName: '',
        lcolumnKey: '',
        rentityId: -1,
        rentityTypeId: -1,
        rdisplayName: '',
        rcolumnKey: '',
      }
      ,
    ];
    this.setState({
      dataSourceDependency: [...newArray],
    });

  }
  handleDependencyDataSourceSelection(event, item, left = true) {

    const prefixKey = left ? 'l' : 'r';
    const entityIdKey = [`${prefixKey}entityId`]
    const entityTypeIdKey = [`${prefixKey}entityTypeId`]

    // let objToModify = {
    //   [`${entityIdKey}`]: -1,
    //   [`${entityTypeIdKey}`]: -1,
    //   [`${prefixKey}columnKey`]: '',
    //   [`${prefixKey}displayName`]: ''
    // }

    let value = event.target.value;
    const { dataSources } = this.state;
    const selectedDataSource = dataSources.find(x => x.entityId == value);

    if (selectedDataSource) {
      item[`${entityIdKey}`] = selectedDataSource.entityId;
      item[`${entityTypeIdKey}`] = selectedDataSource.entityTypeId;
      item[`${prefixKey}displayName`] = selectedDataSource.displayName;
      item[`${prefixKey}columnKey`] = '';
    }


    this.setState({
      dataSourceDependency: [...this.state.dataSourceDependency],
    });
  }
  handleDependencyColumnChange(e, item, left = true) {
    const prefixKey = left ? 'l' : 'r';
    item[`${prefixKey}columnKey`] = e.target.value;
    this.setState({
      dataSourceDependency: [...this.state.dataSourceDependency],
    });
  }

  getNewWidgetDefaults() {

    // if (this.state.dataSources.length == 1) {

    // }
    return {
      title: "",
      chartType: -1,
      id: -1,
      groupKey: "",
      entityId: -1,
      entityTypeId: -1,
      hideLegend: false,
      aggregateType: this.state.aggregateFunctions["piechart"].default[0].code,
      ...ChartDefaults.PieChart,
      ...ChartDefaults.BChart,
      ...ChartDefaults.SelectChart,
      ...ChartDefaults.TableChart,
      ...ChartDefaults.LineChart,
      ...ChartDefaults.HeatChart
    }
  }

  handleTextChange(event) {


    if (event.target.type == "checkbox") {
      this.setState({
        [event.target.name]: event.target.checked,
      });
    }
    else {
      let value = event.target.value;
      this.setState({
        [event.target.name]: value,
      });
    }

  }
  handleWidgetPropChange(event, widgetObj) {

    if (event.target.type == "checkbox") {
      widgetObj[event.target.name] = event.target.checked
    }
    else {
      let value = event.target.value;
      widgetObj[event.target.name] = value
    }
    this.setState({
      widgets: [...this.state.widgets],
    });
  }

  handleWidgetTypeChange(event) {
    let type = event.target.value;
    this.setState({
      [event.target.name]: type,
    });
    if (type == WIDGET_TYPES.Chart) {
      //ony one in the case of chart
      if (this.state.widgets.length !== 1) {
        const newArray = [
          this.getNewWidgetDefaults()
        ];
        this.setState({
          widgets: [...newArray],
        })
      }
    }
    else if (type == WIDGET_TYPES.Dashboard) {

    }
  }

  handleOnConfigChange(modifiedObj, id) {
    //temp id is the index of the widget array
    let allWidgets = [...this.state.widgets];

    allWidgets[id] = {
      ...allWidgets[id],
      ...modifiedObj,
    };
    this.setState({
      widgets: [...allWidgets],
    });
  }

  async handleDataSourceChange(item, e) {
    //temp id is the index of the widget array


    item[e.target.name] = Number(e.target.value);
    if (e.target.name == 'entityTypeId') {
      item.displayName = ""; //when type changes reset the Id
      item.entityId = -1;
    }

    if (e.target.name == 'entityId') {
      const entitiesByTypeId = this.state.entitiesByEntityTypeId[item.entityTypeId]
      if (Array.isArray(entitiesByTypeId)) {
        let selectedItem = entitiesByTypeId.find(x => x.id == e.target.value);
        if (selectedItem)
          item.displayName = selectedItem.name;
      }
    }
    this.setState({
      dataSources: [...this.state.dataSources],
    });
    if (e.target.name == "entityTypeId") {//get the entitiesList
      await this.getEntitiesByEntityTypeId(item.entityTypeId)
    }
    if (item.entityTypeId != 23) {//skip for dashboards
      this.getGroupKeys(item.entityId, item.entityTypeId)
    }
  }


  render() {

    //show widget only when dashboard datatype is not selected
    const dashboardAsSourceDoesNotExist = this.state.dataSources.filter(x => x.entityTypeId != 23).length > 0;
    const selectedDashboardSources = this.state.dataSources.filter(x => x.entityTypeId == 23);
    const showWidgets = dashboardAsSourceDoesNotExist;

    const isFormIoBuilderRendered = this.props.isFormIoBuilderRendered ? this.props.isFormIoBuilderRendered : false;
    const showEditButtons = isFormIoBuilderRendered;
    const renderDashBoardFromConfig = (this.state.dashboardFinalConfig.title && !this.state.showPreviewDialog) ? true : false;
    const isEditMode = this.state.isEditMode;
    //do not show preview when dashboard is selected as source;
    //and not rendered in formiobuildview
    //remove preview as direct resize handler is added to track resize
    const showPreview = false && showEditButtons && showWidgets;

    const showDataSourceDependecyConfig = this.state.dataSources.length > 1 ? true : false;
    const getColumns = (entityTypeId, entityId) => {
      let groupKeys = [];

      if (this.state.groupKeysByEntityID[`${entityTypeId}_${entityId}`]) {
        groupKeys = this.state.groupKeysByEntityID[`${entityTypeId}_${entityId}`];
      }
      return groupKeys;
    }

    const MetaData = {
      groupKeyObj: this.state.groupKeysByEntityID,
      dataSources: this.state.dataSources,
      aggregateFunctions: this.state.aggregateFunctions
    };



    const WidgetRenderer = (x, indexNum) => (
      <div className="dashboardWidgetRow">

        {/* <p>widget {x.id}</p> */}
        <div className="dashboardForm">
          <div className="form-group">
            Chart Type:

            <div className="row">
              <div className="col-md-9">
                <select
                  className="form-control"
                  value={x.chartType}
                  onChange={(e) => {
                    this.handleChartTypeChange(x, e);
                  }}
                >
                  <option value={-1}>Select</option>
                  {this.state.chartTypes.map((x) => (
                    <option value={x.chartType} key={x.chartType}>
                      {x.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <input
                  type="checkbox"
                  name="hideLegend"
                  checked={x.hideLegend}
                  onChange={(e) => this.handleWidgetPropChange(e, x)
                  }
                />
                Hide Legend:
              </div>
              <div className="col-md-1">
                <button className="btn btn-icon btn-outline-danger btnAction pull-right" title="Delete Widget" onClick={(e) => this.handleWidgetDelete(e, indexNum)}> <span className="fa fa-trash"></span> </button>


              </div>
            </div>

          </div>

        </div>


        {x.chartType == "piechart" && (
          <PieChartConfig
            id={indexNum}
            chartConfig={x}
            onConfigChange={this.handleOnConfigChange}
            metaData={MetaData}
          ></PieChartConfig>
        )}
        {x.chartType === "bchart" && (
          <BChartConfig
            id={indexNum}
            chartConfig={x}
            onConfigChange={this.handleOnConfigChange}
            metaData={MetaData}
          ></BChartConfig>
        )}
        {x.chartType === "select" && (
          <SelectChartConfig
            id={indexNum}
            chartConfig={x}
            onConfigChange={this.handleOnConfigChange}
            metaData={MetaData}
          ></SelectChartConfig>
        )}
        {x.chartType === "selectWithSummary" && (
          <SelectWithSummaryChartConfig
            id={indexNum}
            chartConfig={x}
            onConfigChange={this.handleOnConfigChange}
            metaData={MetaData}
          ></SelectWithSummaryChartConfig>
        )}
        {x.chartType === "numberChart" && (
          <NumberChartConfig
            id={indexNum}
            chartConfig={x}
            onConfigChange={this.handleOnConfigChange}
            metaData={MetaData}
          ></NumberChartConfig>
        )}
        {x.chartType === "table" && (
          <TableChartConfig
            id={indexNum}
            chartConfig={x}
            onConfigChange={this.handleOnConfigChange}
            metaData={MetaData}
          ></TableChartConfig>
        )}
        {x.chartType === "linechart" && (
          <LineChartConfig
            id={indexNum}
            chartConfig={x}
            onConfigChange={this.handleOnConfigChange}
            metaData={MetaData}
          ></LineChartConfig>
        )}
        {x.chartType === "heatmap" && (
          <HeatMapChartConfig
            id={indexNum}
            chartConfig={x}
            onConfigChange={this.handleOnConfigChange}
            metaData={MetaData}
          ></HeatMapChartConfig>
        )

        }

      </div>
    )
    let currentContext = "";
    try {
      currentContext = JSON.stringify(this.props.currentContext)
    }
    catch (e) { }
    return (
      <div>
        <div className="dashboardContainer">
          {/* Current Context - {currentContext} */}
          <div class="btn-toolbar justify-content-end col-xs-12"></div>
          {/* Select Dashboard: */}
          {/* SelectedItem- {JSON.stringify(this.props.component.defaultValue)} */}
          {showEditButtons && <>
            {!isEditMode && (
              <>
                <button className="btn  btn-icon btn-outline-primary btnAction" aria-label="Edit" title="Configuration" onClick={(e) => { e.preventDefault(); this.handleChange(true) }}>
                  <i className="fas fa-cog" ></i>
                </button>
                {
                  showWidgets &&
                  <button className="btn  btn-icon btn-outline-primary btnAction" aria-label="Add" title="Configuration" onClick={(e) => { e.preventDefault(); this.handleWidgetItemToAddEdit(true, WIDGET_MODAL_ACTIONS.Addnew, undefined, undefined) }}>
                    <i className="fas fa-plus" ></i>
                  </button>
                }

              </>
            )}
            {isEditMode && (
              <div className="form-group">
                <button className="btn  btn-icon btn-primary pull-right btn-sm" aria-label="Done" title="Done" onClick={(e) => { e.preventDefault(); this.handleChange(false) }} >
                  Save
                </button>
              </div>
            )}
          </>}
          {isEditMode && (
            <>
              {
                <div className="form-group">
                  Dashboard title
                  <input
                    variant="outlined"
                    margin="normal"
                    id="dashBoardtitle"
                    label="DashBoard title"
                    className="form-control"
                    name="dashBoardtitle"
                    autoComplete="dashBoardtitle"
                    autoFocus
                    value={this.state.dashBoardtitle}
                    onChange={this.handleTextChange}
                  />
                </div>
              }

              <div className="form-group">
                <><input
                  type="checkbox"
                  name="addCurrentContext"
                  checked={this.state.addCurrentContext}
                  onChange={this.handleTextChange
                  }
                />Add Current Context
                </>
              </div>
              {/* <div>
                GridLayout
                <GridLayout items={this.state.dataSources} layouts={initialLayouts}></GridLayout>
              </div> */}
              <div className="page-header1" > Data sources: <button className=" btn btn-icon btn-outline-primary btnAction pull-right" onClick={this.handleDataSourceAdd}><span className="fa fa-plus"></span></button>
              </div>
              {
                this.state.dataSources.length > 0 &&
                this.state.dataSources.map((y, indexNum) => (
                  <>

                    <div className="dashboardForm">

                      <div className="row form-group">

                        <div className="col-md-6">
                          Data Source type:
                          <select
                            className="form-control"
                            name="entityTypeId"
                            value={y.entityTypeId}
                            onChange={(e) => this.handleDataSourceChange(y, e)}
                          >
                            <option value={-1} key={-1}>
                              {"Select"}
                            </option>
                            {this.state.entityTypes.map((x) => (
                              <option value={x.id} key={x.id}>
                                {x.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-6">
                          Data source:
                          <select
                            className="form-control"
                            name="entityId"
                            value={y.entityId}
                            onChange={(e) => this.handleDataSourceChange(y, e)}
                          >
                            <option value={-1} key={-1}>
                              {"Select"}
                            </option>
                            {Array.isArray(this.state.entitiesByEntityTypeId[y.entityTypeId])
                              && this.state.entitiesByEntityTypeId[y.entityTypeId].map((x) => (
                                <option value={x.id} key={x.id}>
                                  {x.name}
                                </option>
                              ))}
                          </select>

                        </div>

                        <span className="widgetRemove">
                          <button className="btn btn-icon btn-outline-danger btnAction" title="Delete Data source" onClick={(e) => this.handleDataSourceDelete(e, indexNum)}> <span className="fa fa-trash"></span> </button>

                        </span>

                      </div>
                    </div>
                  </>))

              }

              {
                showDataSourceDependecyConfig &&
                <>
                  <div className="page-header1" > Data source Dependecies:
                    <button className=" btn btn-icon btn-outline-primary btnAction pull-right" onClick={this.handleDataSourceDependencyAdd}><span className="fa fa-plus"></span></button>
                  </div>
                  <>
                    {
                      this.state.dataSourceDependency.length > 0 &&
                      this.state.dataSourceDependency.map((y, indexNum) => (
                        <>
                          <div className='row form-group'>
                            <div className="col-md-3">
                              L -Data Sources:
                              <select className="form-control"
                                value={y.lentityId} onChange={(e) => this.handleDependencyDataSourceSelection(e, y, true)}
                              >
                                <option value="-1">Select</option>
                                {this.state.dataSources.map(x =>
                                  <option value={x.entityId} key={x.entityId}>
                                    {x.displayName}
                                  </option>
                                )
                                }
                              </select>
                            </div>
                            <div className="col-md-3">
                              L- Column:
                              <select className="form-control"
                                name="lcolumnKey"
                                value={y.lcolumnKey} onChange={(e) => this.handleDependencyColumnChange(e, y, true)}
                              >
                                <option value="-1">Select</option>
                                {
                                  getColumns(y.lentityTypeId, y.lentityId,).map(x =>
                                    <option value={x.StepItemKey} key={x.StepItemKey}>
                                      {x.StepItemName}
                                    </option>
                                  )
                                }
                              </select>
                            </div>

                            <div className="col-md-3">
                              R- Data Sources:
                              <select className="form-control"
                                value={y.rentityId} onChange={(e) => this.handleDependencyDataSourceSelection(e, y, false)}
                              >
                                <option value="-1">Select</option>
                                {this.state.dataSources.map(x =>
                                  <option value={x.entityId} key={x.entityId}>
                                    {x.displayName}
                                  </option>
                                )
                                }
                              </select>
                            </div>
                            <div className="col-md-3">
                              R - Column:
                              <select className="form-control"
                                name="rcolumnKey"
                                value={y.rcolumnKey} onChange={(e) => this.handleDependencyColumnChange(e, y, false)}
                              >
                                <option value="-1">Select</option>
                                {
                                  getColumns(y.rentityTypeId, y.rentityId,).map(x =>
                                    <option value={x.StepItemKey} key={x.StepItemKey}>
                                      {x.StepItemName}
                                    </option>
                                  )
                                }
                              </select>
                            </div>

                          </div>
                        </>))
                    }
                  </>
                </>
              }


              {
                showWidgets && (
                  <>
                    <div className="form-group" >
                      Widget Types:
                      <select
                        className="form-control"
                        name="selectedWidgetTypeId"
                        value={this.state.selectedWidgetTypeId}
                        onChange={this.handleWidgetTypeChange}
                      >
                        {this.state.widgetTypes.map((x) => (
                          <option value={x.id} key={x.id}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {this.state.selectedWidgetTypeId == WIDGET_TYPES.Dashboard && (
                      <>
                        <div className="page-header1" > Add Widget:  <button className=" btn btn-icon btn-outline-primary btnAction pull-right" onClick={this.handleWidgetAdd}>   <span className="fa fa-plus"></span></button> </div>
                      </>
                    )}
                    {this.state.widgets.length > 0 &&
                      this.state.widgets.map((x, indexNum) => {
                        return WidgetRenderer(x, indexNum)
                      }
                      )}
                  </>

                )
              }
            </>
          )}
          {!isEditMode && <>
            {/* Renders the view for selected Item */}

            {
              showPreview && (
                <button className="btn  btn-icon btn-outline-primary btnAction" aria-label="Preview" title="Preview" onClick={(e) => { e.preventDefault(); this.handlePreview(true) }}>
                  <i className="fa fa-eye"></i>
                </button>

              )
            }
            {
              this.state.showPreviewDialog && (
                <Modal closeModal={(e) => { e.preventDefault(); this.handlePreview(false) }}>
                  popup
                  <DashboardRender
                    DashBoardConfig={this.state.dashboardFinalConfig}
                    currentContext={this.props.currentContext}>
                  </DashboardRender>
                </Modal>

              )
            }
            {
              renderDashBoardFromConfig && (
                <>
                  {/* Length- {selectedDashboardSources.length} */}
                  {dashboardAsSourceDoesNotExist && (
                    <DashboardRender key={this.state.dashboardFinalConfig}
                      DashBoardConfig={this.state.dashboardFinalConfig}
                      currentContext={this.props.currentContext}
                      title={this.props.currentContext.title}
                      onResize={this.handleLayoutChange}
                      EditWidgetHandler={this.handleWidgetItemToEdit}
                    >
                    </DashboardRender>
                  )}
                  {!dashboardAsSourceDoesNotExist && selectedDashboardSources.length > 0 && (
                    <CustomFormsInstance match={{
                      params: {
                        typeId: 23,
                        id: selectedDashboardSources[0].entityId,
                      }
                    }}
                      renderReadOnly={true}
                    >
                    </CustomFormsInstance>
                  )}
                </>

              )
            }
            {
              this.state.showWidgetItemAddEdit && (
                <Modal closeModal={(e) => { e.preventDefault(); this.handleWidgetItemToAddEdit(false, this.state.widgetItemToAddEditAction, this.state.widgetItemToAddEditIndex, false) }}
                  footerActions={[
                    { Name: "Save", callBack: (e) => { e.preventDefault(); this.handleWidgetItemToAddEdit(false, this.state.widgetItemToAddEditAction, this.state.widgetItemToAddEditIndex, true) }, className: "btn btn-primary pull-right", title: "Save" }
                    , { Name: "Cancel", callBack: (e) => { e.preventDefault(); this.handleWidgetItemToAddEdit(false, this.state.widgetItemToAddEditAction, this.state.widgetItemToAddEditIndex, false) }, className: "btn btn-danger pull-left", title: "Cancel" }
                  ]}
                >
                  {WidgetRenderer(this.state.widgets[this.state.widgetItemToAddEditIndex], this.state.widgetItemToAddEditIndex)}
                </Modal>

              )
            }


          </>

          }
        </div>
      </div >
    );
  }
}

export default DashboardWrapper;
