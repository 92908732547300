import React from "react";

import DBService from "../../../Services/dexieForm";
import Service from "../../../Services";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";

import Paper from "@material-ui/core/Paper";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import Builder from "../../RenderBuilder";
import { ENTITY_TYPES } from "../../../config/constant";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    //padding:10
  },

  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  root: {
    // padding:10
  },
});

class BuilderForm extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = props;
    console.log("id", params.id);

    this.state = {
      entityTypeId: "",
      entityName: "",
      entityList: [],
      id: Number(params.id),
      components: [],
      display: "form",
      createMode: true,
      schemaForDB: null
    }; //default

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnUISchemaChange = this.handleOnUISchemaChange.bind(this);
    this.handleOnUIDisplayChange = this.handleOnUIDisplayChange.bind(this);
    this.handleJsonChange = this.handleJsonChange.bind(this);
  }

  async componentDidMount() {
    let item = null;

    if (this.state.id > 0)
      item = await Service().getAdminEntityBuilder(this.state.id);

    if (item) {
      this.setState({
        createMode: false,
        components: item.formJSON.components,
        display: item.formJSON.display,
        entityName: item.entityName,
        entityTypeId: this.state.id,
      });
    } else {
      let list = await Service().getEntityList(0); //pass zero to get the ones which are yet to create
      this.setState({ entityList: list, createMode: true, id: -100 });
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleJsonChange(event) {
    let newJson = event.target.value;
    if (newJson) {
      let schema = JSON.parse(newJson);
      this.setState({
        components: schema.components,
        display: schema.display,
        meth: JSON.stringify(schema),
      });
    }
  }

  handleOnUISchemaChange(builder) {
    const { schema, dbSchema } = builder
    this.setState({
      components: schema.components,
      display: schema.display,
      meth: JSON.stringify(schema),
      schemaForDB: dbSchema
    });
  }

  handleOnUIDisplayChange(display) {
    this.setState({ display: display });
  }

  async handleSubmit(event) {
    event.preventDefault();
    let params = {
      formJSON: JSON.stringify({
        components: this.state.components,
        display: this.state.display,
      }),
      schemaForDB: this.state.schemaForDB,
      entityTypeId: this.state.entityTypeId,
      id: this.state.id,
    };
    //if (this.state.createMode) {
    //create new
    let resultId = await Service().saveAdminFormEntityList(params);
    if (resultId > 0) this.props.history.push(`/adminbuild/${resultId}`);
    // } else {
    //   //save
    // }
  }

  render() {
    const { classes } = this.props;
    const showSchemaData = true;
    const EntityInfo= ENTITY_TYPES[this.state.entityTypeId]||{};
    return (
      <div className="pageHeaderPanel">
        {/* <CssBaseline />         */}
        
          <div className="page-header1">
            <div className="btn-toolbar justify-content-end">
             <div className="dt-buttons btn-group float-right">
              {/* <input type="submit" value="Save" /> */}
              <a className="btn  btn-icon btn-outline-secondary btnAction" href="/adminforms"
                Tooltip="Back">
                <i className="fas fa-arrow-left"></i>
              </a>&nbsp;
            </div>
            </div>
            </div>


          <div className="tablePanel-body  paneltopTable">
        <div className="col-md-12">

            {!this.state.createMode && (
              <h5 component="h1" variant="h5">
                Admin Form: {EntityInfo.name}
              </h5>
            )}
            <form
              className={classes.form}
              // noValidate
              onSubmit={this.handleSubmit}
            >
              {this.state.createMode && (
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Entity</InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    //value={display}
                    name="entityTypeId"
                    value={this.state.entityTypeId}
                    onChange={this.handleChange}
                  >
                    {this.state.entityList.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <Builder
                Components={this.state.components}
                Display={this.state.display}
                OnChange={this.handleOnUISchemaChange}
                OnDisplayChange={this.handleOnUIDisplayChange}
              ></Builder>
              {showSchemaData &&
                <>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="meth"
                    label="meth"
                    name="meth"
                    autoComplete="meth"
                    autoFocus
                    value={this.state.meth}
                    onChange={this.handleJsonChange}
                    rows="10"
                    multiline
                  />
                </>
              }
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Save
              </Button>
            </form>
            </div>
            </div>
      </div>
    );
  }
}

export default withStyles(styles)(BuilderForm);
