import baseEditForm from "formiojs/components/_classes/component/Component.form";

const defaultVal1 = {
  assigned: [
    {
      name: "Harry",
    },
    {
      name: "Kate",
    },
  ],
  unassigned:[
    {
      name: "Macarena",
    },
    {
      name: "James",
    },
    {
      name: "Pote",
    },
    {
      name: "Bigil",
    },
  ]
};

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            // You can ignore existing fields.
            key: "placeholder",
            ignore: true,
          },          
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "JSON Values",
            weight: 12,
            key: "jsonData", // This will be available as component.myCustomSetting
            defaultValue: JSON.stringify(defaultVal1),
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "select",
            input: true,
            label: "Entity",
            weight: 12,
            key: "gridEntity", // This will be available as component.myCustomSetting
            data: {
              values: ["User", "Role"],
            },
          },
          {
            "label": "Columns",
            "labelPosition": "top",
            "description": "",
            "tooltip": "",
            "disableAddingRemovingRows": false,
            "conditionalAddButton": "",
            "reorder": false,
            "addAnother": "",
            "addAnotherPosition": "bottom",
            "defaultOpen": false,
            "layoutFixed": false,
            "enableRowGroups": false,
            "initEmpty": false,
            "customClass": "",
            "tabindex": "",
            "hidden": false,
            "hideLabel": false,
            "autofocus": false,
            "disabled": false,
            "tableView": false,
            "modalEdit": false,
            "defaultValue": [{ name:"Name",dbfield:"name", visible:true},{ name:"id",dbfield:"id", visible:false}
            ,{ name:"Description",dbfield:"description", visible:true}],
            "persistent": true,
            "protected": false,
            "dbIndex": false,
            "encrypted": false,
            "redrawOn": "",
            "clearOnHide": true,
            "customDefaultValue": "",
            "calculateValue": "",
            "calculateServer": false,
            "allowCalculateOverride": false,
            "validateOn": "change",
            "validate": {
              "required": false,
              "minLength": "",
              "maxLength": "",
              "customMessage": "",
              "custom": "",
              "customPrivate": false,
              "json": "",
              "strictDateValidation": false,
              "multiple": false,
              "unique": false
            },
            "unique": false,
            "errorLabel": "",
            "key": "columns",
            "tags": [],
            "properties": {},
            "conditional": { "show": null, "when": null, "eq": "", "json": "" },
            "customConditional": "",
            "logic": [],
            "attributes": {},
            "overlay": {
              "style": "",
              "page": "",
              "left": "",
              "top": "",
              "width": "",
              "height": ""
            },
            "type": "datagrid",
            "input": true,
            "placeholder": "",
            "prefix": "",
            "suffix": "",
            "multiple": false,
            "refreshOn": "",
            "widget": null,
            "showCharCount": false,
            "showWordCount": false,
            "allowMultipleMasks": false,
            "tree": true,
            "components": [
              {
                "label": "Display Name",
                "labelPosition": "top",
                "placeholder": "",
                "description": "",
                "tooltip": "",
                "prefix": "",
                "suffix": "",
                "widget": { "type": "input" },
                "inputMask": "",
                "allowMultipleMasks": false,
                "customClass": "",
                "tabindex": "",
                "autocomplete": "",
                "hidden": false,
                "hideLabel": false,
                "showWordCount": false,
                "showCharCount": false,
                "mask": false,
                "autofocus": false,
                "spellcheck": true,
                "disabled": false,
                "tableView": true,
                "modalEdit": false,
                "multiple": false,
                "persistent": true,
                "inputFormat": "plain",
                "protected": false,
                "dbIndex": false,
                "case": "",
                "encrypted": false,
                "redrawOn": "",
                "clearOnHide": true,
                "customDefaultValue": "",
                "calculateValue": "",
                "calculateServer": false,
                "allowCalculateOverride": false,
                "validateOn": "change",
                "validate": {
                  "required": false,
                  "pattern": "",
                  "customMessage": "",
                  "custom": "",
                  "customPrivate": false,
                  "json": "",
                  "minLength": "",
                  "maxLength": "",
                  "strictDateValidation": false,
                  "multiple": false,
                  "unique": false
                },
                "unique": false,
                "errorLabel": "",
                "key": "name",
                "tags": [],
                "properties": {},
                "conditional": { "show": null, "when": null, "eq": "", "json": "" },
                "customConditional": "",
                "logic": [],
                "attributes": {},
                "overlay": {
                  "style": "",
                  "page": "",
                  "left": "",
                  "top": "",
                  "width": "",
                  "height": ""
                },
                "type": "textfield",
                "input": true,
                "refreshOn": "",
                "inputType": "text",
                "id": "exwzka0000000000",
                "defaultValue": ""
              },
              {
                "label": "Value Field",
                "labelPosition": "top",
                "placeholder": "",
                "description": "",
                "tooltip": "",
                "prefix": "",
                "suffix": "",
                "widget": { "type": "input" },
                "inputMask": "",
                "allowMultipleMasks": false,
                "customClass": "",
                "tabindex": "",
                "autocomplete": "",
                "hidden": false,
                "hideLabel": false,
                "showWordCount": false,
                "showCharCount": false,
                "mask": false,
                "autofocus": false,
                "spellcheck": true,
                "disabled": true,
                "tableView": true,
                "modalEdit": false,
                "multiple": false,
                "persistent": true,
                "inputFormat": "plain",
                "protected": false,
                "dbIndex": false,
                "case": "",
                "encrypted": false,
                "redrawOn": "",
                "clearOnHide": true,
                "customDefaultValue": "",
                "calculateValue": "",
                "calculateServer": false,
                "allowCalculateOverride": false,
                "validateOn": "change",
                "validate": {
                  "required": false,
                  "pattern": "",
                  "customMessage": "",
                  "custom": "",
                  "customPrivate": false,
                  "json": "",
                  "minLength": "",
                  "maxLength": "",
                  "strictDateValidation": false,
                  "multiple": false,
                  "unique": false
                },
                "unique": false,
                "errorLabel": "",
                "key": "dbfield",
                "tags": [],
                "properties": {},
                "conditional": { "show": null, "when": null, "eq": "", "json": "" },
                "customConditional": "",
                "logic": [],
                "attributes": {},
                "overlay": {
                  "style": "",
                  "page": "",
                  "left": "",
                  "top": "",
                  "width": "",
                  "height": ""
                },
                "type": "textfield",
                "input": true,
                "refreshOn": "",
                "inputType": "text",
                "id": "euwbtw000000",
                "defaultValue": ""
              },
              {
                "label": "Visible",
                "description": "",
                "tooltip": "",
                "shortcut": "",
                "inputType": "checkbox",
                "customClass": "",
                "tabindex": "",
                "hidden": false,
                "hideLabel": false,
                "autofocus": false,
                "disabled": false,
                "tableView": false,
                "modalEdit": false,
                "persistent": true,
                "protected": false,
                "dbIndex": false,
                "encrypted": false,
                "redrawOn": "",
                "clearOnHide": true,
                "customDefaultValue": "",
                "calculateValue": "",
                "calculateServer": false,
                "allowCalculateOverride": false,
                "validate": {
                  "required": false,
                  "customMessage": "",
                  "custom": "",
                  "customPrivate": false,
                  "json": "",
                  "strictDateValidation": false,
                  "multiple": false,
                  "unique": false
                },
                "errorLabel": "",
                "key": "visible",
                "tags": [],
                "properties": {},
                "conditional": { "show": null, "when": null, "eq": "", "json": "" },
                "customConditional": "",
                "logic": [],
                "attributes": {},
                "overlay": {
                  "style": "",
                  "page": "",
                  "left": "",
                  "top": "",
                  "width": "",
                  "height": ""
                },
                "type": "checkbox",
                "name": "",
                "value": "",
                "input": true,
                "placeholder": "",
                "prefix": "",
                "suffix": "",
                "multiple": false,
                "unique": false,
                "refreshOn": "",
                "labelPosition": "right",
                "widget": null,
                "validateOn": "change",
                "showCharCount": false,
                "showWordCount": false,
                "allowMultipleMasks": false,
                "dataGridLabel": true,
                "id": "eknnlxk00",
                "defaultValue": false
              }
            ],
            "id": "etoibyp"
          }
        ],
      },
      {
        key: "data",
        components: [],
      },
      {
        key: "validation",
        components: [],
      },
      {
        key: "api",
        components: [],
      },
      {
        key: "conditional",
        components: [],
      },
      {
        key: "logic",
        components: [],
      },
    ],
    ...extend
  );
};
