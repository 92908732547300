import Base from "formiojs/components/_classes/component/Component";
import ReactDOMServer from "react-dom/server";
//import ReactDOM from "react-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

export default class CustomSelect extends Base {
  constructor(component, options, data) {
    super(component, options, data);
    this.isBuilderMode = this.isBuilderMode.bind(this);
    this.List = CustomSelect.List;
    this.List.length=0;
    this.List.push({
      inputval: "select",
      color: "red",
      active: false,
    });
    // this.state = {
    //   inputval: "select",
    //   color: "red",
    // };
    this.state = {
      CustomList: this.List,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleonAdd = this.handleonAdd.bind(this);
  }

  isBuilderMode() {
    const { element: pEl, id, root } = this.parent;
    console.log("preview", this.options.preview);
    console.log("builderMode", this.builderMode);
    if (this.builderMode) {
      return true;
    }

    if (id !== root.id) {
      return false;
    }

    return !pEl || pEl.getAttribute("ref") === "preview";
  }

  handleColorChange(event, item) {
    item.color = event.target.value;
    // this.setState({ color: event.target.value });
  }

  handleInputChange(event, item) {
    item.inputval = event.target.value;
    //this.setState({ inputval: event.target.value });
  }

  handleonAdd(item) {
    item.active = true;
    let current = this.state.CustomList;
    current.push({
      inputval: "select1",
      color: "red1",
      active: false,
    });
    this.setState({ CustomList: current });
  }
  static List = [];

  static schema() {
    return Base.schema({
      type: "customselect",
      items: CustomSelect.List,
    });
  }

  static builderInfo = {
    title: "Custom Select",
    group: "basic",
    icon: "fa fa-table",
    weight: 70,
    documentation: "http://abc.com/userguide/#table",
    schema: CustomSelect.schema(),
  };

  attach(element) {

    //ReactDOM.hydrate(<CustomSelect></CustomSelect>,element)
    return super.attach(element);
  }

  render(children) {
    const CustomList = this.state.CustomList;
    return super.render(
      ReactDOMServer.renderToString(
        <div>
          {this.isBuilderMode() && (
            <dv>
              <h2>Builder Mode</h2>
              CustomList
              {CustomList.map((item) => (
                <div>
                  <TextField
                    id="standard-basic"
                    label="Item"
                    value={item.inputval}
                    onChange={(e) => {
                      this.handleInputChange(e,item);
                    }}
                  />
                  <TextField
                    id="standard-color"
                    label="Color"
                    value={item.color}
                    onChange={(e) => {
                      this.handleColorChange(e,item);
                    }}
                  />
                  <Button variant="contained" onClick={()=>{
                      console.log('fired');
                      this.handleonAdd(item)}}>Add</Button>
                </div>
              ))}
            </dv>
          )}
          {!this.isBuilderMode() && <h2>Preview Mode</h2>}
        </div>
      )
    );
  }
}
