import React from "react";
import { withStyles } from "@material-ui/core/styles";
import GridColors from "../../components/Common/GridColors";


const styles = (theme) => ({
    table: {
        minWidth: 650,
    },
    cell: {
        fontSize: 12,
    },
    alignItems: {
        display: "flex",
        //width: 600,
        //"justify-content": "space-between",
        "padding-bottom": 20,
        flexDirection: "row",
        //flexWrap: "nowrap"
        justifyContent: "space-between",
    },
    formDiv: {
        margin: theme.spacing(4, 4),
        display: "flex",
        flexDirection: "column",
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    fabIcon: {
        marginRight: theme.spacing(1),
    },
});

class Grids extends React.Component {
    constructor(props) {
        super(props);

        const {
            match: { params },
        } = this.props;

        this.state = {
            rows: 3,
            columns: 3,
            data: []
        };
        this.handleClickNew = this.handleClickNew.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDataChange = this.handleDataChange.bind(this);
    }

    async componentDidMount() {

    }

    handleClickNew(item) {

    }

    handleDataChange(coldata) {
        this.setState({ data: [coldata] })
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async componentWillReceiveProps(newProps) {

    }


    render() {

        const { classes } = this.props;


        return (
            <div className="pageHeaderPanel">
                <div className="page-header1">
                    <h1>Grids</h1>
                    <div className="row input-group mb-3 input-group-sm">
                        <div className="col-md-6 form-row">
                            <input type="text" className="form-control"
                                required
                                id="rows"
                                placeholder="Rows"
                                name="rows"
                                autoComplete="3"
                                autoFocus
                                value={this.state.rows}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-md-6 form-row">
                            <input type="text" className="form-control"
                                required
                                id="columns"
                                placeholder="Columns"
                                name="columns"
                                autoComplete="3"
                                autoFocus
                                value={this.state.columns}
                                onChange={this.handleChange}
                            />
                        </div>

                    </div>
                    <div>
                        <GridColors onChange={this.handleDataChange} Rows={this.state.rows} Columns={this.state.columns} ></GridColors>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Grids);
