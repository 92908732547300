import React from "react";
import RenderForm from "../RenderForm";
import { withStyles } from "@material-ui/core/styles";
import Service from "../../Services";
import LookUpService from "../../Services/lookupApi";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import SaveAltOutlinedIcon from "@material-ui/icons/SaveAltOutlined";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import * as FormUtil from "../../Utils/formData";
import { Tooltip } from "bootstrap";
import WorkflowActions from "../Common/WorkflowActions"
import Modal from '../Common/Modal'
import Loading from "../Common/Loading"
import { toast } from "react-toastify";
import { ConfigContext } from "../../contexts/ConfigContext";
import * as actionType from "../../store/actions";
import ImportData from "../../views/ImportData";

const styles = (theme) => ({
  actionButton: {
    padding: "3px",
  },
  actionsContainer: {
    //float:'right'
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
});

class EntityInstanceForm extends React.Component {
  static contextType = ConfigContext

  constructor(props) {
    super(props);
    console.log("props-", this.props);
    var defTabId = new URLSearchParams(this.props.location.search).get("tabId");
    if (isNaN(defTabId)) {
      defTabId = "0";
    }
    const {
      match: { params },
      location: { key }
    } = this.props;
    this.CurrentFormDomReference = React.createRef()
    this.submissionDataTemp = {};
    this.state = {
      entityName: "",
      loading: true,
      components: [],
      display: "form",
      submission: this.submissionDataTemp,
      id: Number(params.entityId),
      entityTypeId: Number(params.entityTypeId),
      pid: Number(params.pentityId),
      pentityTypeId: Number(params.pentityTypeId),
      //linkId: Number(params.linkId),
      linkEntityId: Number(params.linkEntityId),
      linkFramweworkId: Number(params.linkFramweworkId),
      linkApiKey: params.linkApiKey || "",
      schemaText: "",
      dataText: "",
      showDialog: false,
      validationMessages: [],
      key: key,
      defTabId: defTabId,
      renderChild: true,
      permissionsDataSet: {
        showDelete: false,
        showCtxReport: false,
        showSave: false
      },
      showConfirmDialog: false,
      confirmHandler: () => { },
      dataBeforeChange: {},
      showImportDialog: false,
      KeyPathToImport: "",
      ImportTableTemplate:"",//need to see on how to best pick this value automatically
    }; //default

    this.handleChange = this.handleChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDialog = this.handleDialog.bind(this);
    this.handleFormReady = this.handleFormReady.bind(this);
    this.handleRender = this.handleRender.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.handleConfirmDialog = this.handleConfirmDialog.bind(this);
    this.hanldeFormioCustomEvent = this.hanldeFormioCustomEvent.bind(this);
    this.handleImportDialog = this.handleImportDialog.bind(this);
    this.handleImportedData = this.handleImportedData.bind(this);
  }


  async componentWillReceiveProps(newProps) {
    const {
      match: { params },
      location: { key }
    } = newProps;
    // :entityTypeId/:entityId/:pentityTypeId/:pentityId/:viewId
    if (Number(params.entityId) !== this.state.id ||
      Number(params.entityTypeId) !== this.state.entityTypeId ||
      Number(params.pentityId) !== this.state.pid ||
      Number(params.pentityTypeId) !== this.state.pentityTypeId
    ) {
      this.submissionDataTemp = {}
      this.CurrentFormDomReference = React.createRef()
      this.setState(
        {
          id: Number(params.entityId),
          entityTypeId: Number(params.entityTypeId),
          pid: Number(params.pentityId),
          pentityTypeId: Number(params.pentityTypeId),
          linkEntityId: Number(params.linkEntityId),
          linkFramweworkId: Number(params.linkFramweworkId),
          linkApiKey: params.linkApiKey || "",
          key: key,
          loading: true,
          submission: { ...this.submissionDataTemp},
        },
        this.getData
      );
      return true;
    }
    return false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Rendering the component only if
    // passed props value is changed

    const {
      match: { params },
      location: { key }
    } = nextProps;
    if (key === this.state.key) {
      return true;
    } else {
      return false;
    }
  }

  componentWillUnmount() {
    this.CurrentFormDomReference = null
    this.submissionDataTemp = null
  }

  setBlockingValue(val) {
    const { dispatch } = this.context;
    //when the entity is loaded thru entitytab component "dispatch" will not be available
    if (dispatch)
      dispatch({ type: actionType.SET_BLOCK_NAVIGATION, valueToSet: val })

  }

  async getData() {


    const item = await Service().getDetailByEntityIdAndType(
      this.state.entityTypeId,
      this.state.id,
      this.state.pentityTypeId,
      this.state.pid
    );

    console.log("item-", item);
    if (item) {
      if (item.dataJSON && item.dataJSON.data.domainpermissiona) {
        item.dataJSON.data.permissionList = {
          jsonData: {
            assigned: [...item.dataJSON.data.domainpermissiona],
            unassigned: [],
          },
        };
      }

      let lookUps = {}
      //const item = await Service().getAdminEntityInstance(this.state.id);
      if (item.dataJSON.data.frameworkId > 0) {
        lookUps = await LookUpService.getlookupsForFramework({
          frameworkId: item.dataJSON.data.frameworkId,
        });
      }

      this.submissionDataTemp = {
        data: item.dataJSON
          ? { ...item.dataJSON.data, lookupHashMap: { ...lookUps } }
          : { lookupHashMap: { ...lookUps } },
      };

      const meth = item.formJSON;

      const modifiedData = await this.loadCalculatedValues();
      //adding the below link Params in data obj to be available for customcompoents
      modifiedData.linkId = this.state.linkEntityId
      modifiedData.linkFrameworkId = this.state.linkFramweworkId
      modifiedData.linkApiKey = this.state.linkApiKey

      FormUtil.transformFieldValuesByType(meth.components, modifiedData, 'file', 'json');

      this.submissionDataTemp = {
        ...this.submissionDataTemp,
        data: { ...modifiedData }
      }

      const Permissions = {
        ...this.state.permissionsDataSet
      }
      if (item.dataJSON.permissionsDataSet) {
        Permissions.showDelete = item.dataJSON.permissionsDataSet.delete;
        Permissions.showCtxReport = item.dataJSON.permissionsDataSet.showreport ? item.dataJSON.permissionsDataSet.showreport : false;
        Permissions.showSave = item.dataJSON.permissionsDataSet.modify;
        Permissions.ShowConfirmpopup = item.dataJSON.permissionsDataSet.ShowConfirmpopup;
      }

      //trigger confirm dialog based on this value temporarily
      if (Permissions.ShowConfirmpopup)
        this.setBlockingValue(true)

      const nameObjectContainer = FormUtil.getPropLevelObject(this.submissionDataTemp, 'name');
      const referencenumObjectContainer = FormUtil.getPropLevelObject(this.submissionDataTemp, 'referencenum');

      const name = nameObjectContainer ? nameObjectContainer.name : ""
      const referencenum = referencenumObjectContainer ? referencenumObjectContainer.referencenum : ""
      if (meth) {
        this.setState(
          {
            components: meth.components,
            display: meth.display,
            entityName: `${referencenum} ${name}`,
            submission: { ...this.submissionDataTemp},
            dataBeforeChange: JSON.parse(JSON.stringify(this.submissionDataTemp)),
            loading: false,
            permissionsDataSet: {
              ...Permissions
            }
          },
          () => {

            // this.setState({
            //   submission: this.submissionDataTemp,
            // });
            //this.loadCalculatedValues()

            //this.loadCalculatedValues()
          }
        );
      }
    } else {
      //not a valid methFormId
      console.log("not a valid ID");
      this.props.history.replace(`/navbar`);
      return;
    }
  }

  async componentDidMount() {
    await this.getData()

  }

  async loadCalculatedValues() {

    const meth = {
      components: this.state.components
    };
    let { data } = this.submissionDataTemp;
    const entityParams = {
      frameworkId: data.frameworkId,
      entityId: data.entityId,
      entityTypeId: data.entityTypeId,
      pentityId: data.pentityId,
      pentityTypeId: data.pentityTypeId
    }
    const calculatedValues = await FormUtil.calculateItems(meth, entityParams, "customCalculatedkey")

    const modifiedObj = FormUtil.bindCalculatedItems(data, calculatedValues)
    if (modifiedObj) {
      return modifiedObj
      //alert('inside')
      //return;
      this.submissionDataTemp = {
        ...this.submissionDataTemp,
        data: modifiedObj
      }
      this.setState({
        submission: this.submissionDataTemp,
      });
    }
    return data

  }

  handleChange(submission) {
    if (submission.changed) {
      console.log(submission);
      //--------------------------------------->//
      //todo this is workaround as setstate is causing infinite rerender for submission
      //. submission has to move to state later
      this.submissionDataTemp = submission;

      var ControlTypesToCompare = ["textfield"];
      if (ControlTypesToCompare.indexOf(submission.changed.component.type) > -1) {
        if (this.isDataDirty()) {
          console.log('dirty');
          //this.setBlockingValue(true)
        }
      }
      //const submissionData = submission.data;
      //this.setState({ submission: submission });
      //this.setState({ schemaText: JSON.stringify() });
      console.log(FormUtil.dataToSave(this.state.components, submission.data));
    }
  }
  handleFormReady(formInstance) {
    if (formInstance.display && formInstance.display == "wizard") {
      console.log('formWizardId', formInstance.id);
    }
  }

  hanldeFormioCustomEvent(eventData) {
    console.log("eventData", eventData);
    if (eventData.type == "showImport") {
      this.setState({
        showImportDialog: true,
        KeyPathToImport: eventData.component.properties.KeyPathToImport,
        ImportTableTemplate:eventData.component.properties.ImportTableTemplate
      })
    }
  }

  handleImportDialog(showDialog) {

    this.setState({
      showImportDialog: showDialog
    })
  }

  async handleImportedData() {


    const KeyPathToImport = this.state.KeyPathToImport;
    try {
      if (KeyPathToImport) {

        const item = await Service().getDetailByEntityIdAndType(
          this.state.entityTypeId,
          this.state.id,
          this.state.pentityTypeId,
          this.state.pid
        );

        console.log("newitem-", item);

        var currentFullObj={ ...this.submissionDataTemp };
        var newData = item.dataJSON.data;
        var oldData = currentFullObj.data;
        KeyPathToImport.split(".").map(key => {
          newData = newData[key];
          oldData = oldData[key];
        }
        )
        //newData.push({auditArea:'Inventory'})
        oldData.splice(0,oldData.length,...newData);//replace with the new data
        //oldData = newData; this overwrites so doesnot work to replace
        this.setState(
          {
            submission: { ...currentFullObj },
            showImportDialog:false
          })
      }
    }
    catch (e) {
      console.log('import data error')
    }


  }

  handleRender(currentForm) {
    //currentForm.instance.setPage can be used to set default tab/page

    //this.state.currentForm= currentForm;

    // if(!isNaN(this.state.defTabId) && this.state.defTabId >= 0)
    // {
    //   currentForm.instance.setPage(this.state.defTabId)
    // }
    // else{
    //   currentForm.instance.setPage(0)
    // }
  }
  async handleDialog(openState) {
    this.setState({ showDialog: openState })
  }

  handleConfirmDialog(openState) {
    if (!openState) {
      this.setState({
        showConfirmDialog: openState,
        confirmHandler: () => { }
      })
    }

  }

  isDataDirty = () => {
    let modified = FormUtil.compareData(this.state.components, this.state.dataBeforeChange.data, this.state.submission.data);
    console.log(modified);
    return modified;
  }




  async handleSubmit(event) {

    //let modified= this.isDataDirty()
    if ("function" === typeof event.preventDefault) {
      event.preventDefault();
    }

    if (this.CurrentFormDomReference.current.instance.instance.checkValidity(this.submissionDataTemp) && this.submissionDataTemp.isValid == true )
    //if(this.state.currentForm.instance.checkValidity(this.submissionDataTemp))
    {
      //WIP
      //alert(event.form.submited)
      ///********************use the below for button detection**************////
      //alert(event.nativeEvent.submitter.value)
      const workflowStateId = event.nativeEvent.submitter.value;
      ///need to check for entitytype as userType

      // if (event.target && event.target.checkValidity && !event.target.checkValidity(null, true, null, true)) {
      //   return false;
      // }
      // else if (this.submissionDataTemp && !this.submissionDataTemp.isValid) {
      //   alert("Please fill all the mandatory information");
      //   return false;
      // }

      ///need to check for entitytype as userType
      let { data } = this.submissionDataTemp;

      FormUtil.transformFieldValuesByType(this.state.components, data, 'file', 'string');

      let dataToSave = JSON.parse(JSON.stringify(data))//cloning the data
      delete dataToSave.lookupHashMap;
      if (Number(dataToSave.entityId))
        delete dataToSave.entityId
      if (Number(dataToSave.entityTypeId))
        delete dataToSave.entityTypeId
      if (Number(dataToSave.pentityId))
        delete dataToSave.pentityId
      if (Number(dataToSave.pentityTypeId))
        delete dataToSave.pentityTypeId
      if (Number(dataToSave.frameworkId))
        delete dataToSave.frameworkId

      //deleting the linkParams
      delete dataToSave.linkId;
      delete dataToSave.linkFrameworkId;
      delete dataToSave.linkApiKey;

      let params = {
        dataJSON: JSON.stringify(dataToSave),
        id: this.state.id,
        entityTypeId: this.state.entityTypeId,
        pid: this.state.pid,
        pentityTypeId: this.state.pentityTypeId,
        //linkId:this.state.linkId>0 ? this.state.linkId:0
        linkEntityId: this.state.linkEntityId > 0 ? this.state.linkEntityId : 0,
        linkFramweworkId: this.state.linkFramweworkId > 0 ? this.state.linkFramweworkId : 0,
        linkApiKey: this.state.linkApiKey,
        workflowStateId: (workflowStateId == "saveClose") ? "" : workflowStateId
      };
      //let resultId = await Service().saveUserDetail(params);
      this.setState({ loading: true });
      const savedResponse = await Service().saveEntityDetailByType(params);
      this.setState({ loading: false });
      //alert(JSON.stringify(savedResponse))
      if (!savedResponse) {
        return;
      }
      let resultId = savedResponse.id
      let resultData
      try {
        resultData = JSON.parse(savedResponse.dataJson)
      } catch (e) { }
      if (resultData && Array.isArray(resultData.Errors)) {
        if (resultData.Errors.length > 0) {
          this.setState({
            showDialog: true,
            validationMessages: [...resultData.Errors]
          })
          return;
        }
      }
      if (workflowStateId == "saveClose") {
        if (this.props.renderType == "modalForm") {
          if ("function" === typeof this.props.handleModalDialog)
            this.props.handleModalDialog(false);
          else
            this.handleDialog(false);

          return;
        }
        else if (this.props.renderType == "regularForm") {
          this.navigateToParent();
        }
        else {//when no renderType
          this.navigateToParent();
        }
      }
      //if(savedResponse.dataJSON)
      if (resultId > -1) {

        //when saving new entity from modal, close it
        if (this.props.renderType == "modalForm" && this.state.id < 1) {
          if ("function" === typeof this.props.handleModalDialog)
            this.props.handleModalDialog(false);
            return;
        }
        
        if (this.state.id > 0) {
          await this.getData()//reload for existing
        }
        else {
          this.setBlockingValue(false)
          this.props.history.push(
            `/entity/${this.state.entityTypeId}/${resultId}/${this.state.pentityTypeId}/${this.state.pid}`
          );
        }
        //alert("Save success");

      }

    }
    else {
      toast.error("Please fill all required fields");
      //not sure about the infinte loop context here..need to check with sridhar
      //if (this.submissionDataTemp.isValid == true)//if form is already invalid, no need to resubmit as it will create infinte loop
      {
        try{
          //commenting this block to give latest build to vkb begin
          //if(!this.CurrentFormDomReference.current.instance.instance.submitted)
            //this.CurrentFormDomReference.current.instance.instance.submit();
          //commenting this block to give latest build to vkb end
        }
        catch(e){//submit is called just to trigger validation messages of formio
          console.log(e);
        }
      }
      //this.state.currentForm.instance.submit();
      return;
    }

  }



  async handleReport(e) {
    e.preventDefault()
    if (this.state.submission.data.adminContainer.reporturl) {
      console.log("Rendering Report:" + this.state.submission.data.adminContainer.reporturl);
      window.open(this.state.submission.data.adminContainer.reporturl, '_blank', 'noreferrer');
    }
    else {
      toast.error("Report(s) configuration not available");
    }
  }

  async handleDelete(e) {
    e.preventDefault()

    this.setState(
      {
        showConfirmDialog: true,
        confirmHandler: async () => {
          const result = await Service().deleteEntity(
            {
              entityTypeId: this.state.entityTypeId,
              entityId: this.state.id,
              pentityTypeId: this.state.pentityTypeId,
              pentityId: this.state.pid,
            }
          );
          toast.success("Deleted Successfully");
          this.props.history.push(
            `/entityTypeList/${this.state.entityTypeId}/${-1}/${this.state.pentityTypeId}/${this.state.pid}`
          )
        }
      });

  }
  navigateToParent = () => {
    if(this.props.isLightUser)
    {
      this.props.history.push(
        this.props.defaultHomeUrl
      );
    }
    else{
      this.props.history.push(
        //`/entityTypeList/${x.entityTypeId}/${-1}/${x.entityTypeId}/${x.entityId}`,
        `/entityTypeList/${this.state.entityTypeId}/${-1}/${this.state.pentityTypeId}/${this.state.pid}`
      );
    }
    
  }
  render() {
    const { classes } = this.props;
    const showSchemaAndData = false
    const showDeleteButton = this.state.id > 0 ? true : false;
    const { data } = this.state.submission
    let registerId;
    if (data)
      registerId = this.state.pid
    let frameworkId;
    if (data)
      frameworkId = data.frameworkId;
    const showWorkflowButtons = frameworkId ? true : false

    var renderType = "regularForm"
    if (this.props != null && this.props.renderType != null) {
      renderType = this.props.renderType;
    }

    const showCtxReportButton = this.state.id > 0 ? true : false;

    console.log("rendered", new Date());
    return (
      <>
        {!this.state.loading && <div className="dynamicForm">

          <form onSubmit={this.handleSubmit}>

            {/* <div className="page-header1"> */}
            <div className="btn-toolbar justify-content-end col-xs-12 breadcumBtn ">
              {renderType != "regularForm" && renderType != "nestedForm" && <h5>{this.state.entityName}</h5>}
              {(renderType == "regularForm") && <div className="dt-buttons  float-right">
                {/* <input type="submit" value="Save" /> */}
                <a className="btn  btn-icon btn-outline-secondary btnAction"
                  onClick={this.navigateToParent}
                  Tooltip="Back">
                  <i className="fas fa-arrow-left"></i>
                </a>&nbsp;
              </div>}
              <div className="dt-buttons  float-right">
                {(showCtxReportButton) && <Button hidden={!this.state.permissionsDataSet.showCtxReport} onClick={this.handleReport}
                  className="btn  btn-icon btn-outline-primary btnAction" title="Show Report N">
                  <i className="fa fa-list-alt"></i>
                </Button>
                }
                <Button type="submit" disabled={!this.state.permissionsDataSet.showSave} className="btn  btn-icon btn-outline-primary btnAction" title="Save">
                  <i className="fas fa-save"></i>
                </Button>
                {(renderType != "nestedForm") && <Button type="submit" disabled={!this.state.permissionsDataSet.showSave} className="btn  btn-icon btn-outline-primary btnAction" title="Save and Close" onclick="this.form.submited=this.value;" name="saveCname" value="saveClose">
                  <i className="fa fa-window-close"></i>
                </Button>}
                {showDeleteButton && <Button disabled={!this.state.permissionsDataSet.showDelete} onClick={this.handleDelete} className="btn  btn-icon btn-outline-primary btnAction" title="Delete">
                  <i className="fas fa-trash"></i>
                </Button>
                }
                {/* <Button type="submit" className="btn  btn-icon btn-outline-primary btnAction" title="Save and Close" onclick="this.form.submited=this.value;" name="saveCname" value="saveC">
                <i className="fa fa-window-close"></i>
              </Button>
              <Button type="submit" className="btn  btn-icon btn-outline-primary btnAction" onclick={(e) => { this.buttonName = "SaveNnameclicked" }} name="saveNname" value="saveN"
                title="Save and New">
                <i className="fa fa-clipboard"></i>
              </Button>
              <Button type="submit" className="btn  btn-icon btn-outline-primary btnAction"
                color="primary"
                title="Delete"
                value="delete"
              >
                <i className="fa fa-trash"></i>
              </Button>
              <Button type="button" className="btn  btn-icon btn-outline-primary btnAction"
                color="primary"
                title="Copy"
              >
                <i className="fa fa-copy"></i>
              </Button> */}
                {(renderType == "regularForm") && <Button type="button" className="btn  btn-icon btn-outline-primary btnAction"
                  onClick={() =>
                    this.props.history.push(
                      //`/entityTypeList/${x.entityTypeId}/${-1}/${x.entityTypeId}/${x.entityId}`,
                      `/entityTypeList/${this.state.entityTypeId}/${-1}/${this.state.pentityTypeId}/${this.state.pid}`
                    )}
                  color="primary"
                  title="Close">
                  <i className="fa fa-times"></i>
                </Button>}
              </div>
            </div>
            {/* </div> */}
            <div>
              {this.CurrentFormDomReference && <RenderForm
                CurrentFormDomReference={this.CurrentFormDomReference}
                Components={this.state.components}
                Display={this.state.display}
                Submission={this.state.submission}
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                onRender={this.handleRender}
                onFormReady={this.handleFormReady}
                onCustomEvent={this.hanldeFormioCustomEvent}
              ></RenderForm>
              }
              
              {
                
                showWorkflowButtons && (
                  <div>

                    <WorkflowActions component={{
                      entityId: this.state.id,
                      entityTypeId: this.state.entityTypeId,
                      frameworkId: frameworkId,
                      registerId: registerId
                    }}>

                    </WorkflowActions>
                  </div>
                )
              }

              {showSchemaAndData && (
                <>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    //required
                    //fullWidth
                    id="schema"
                    label="schema"
                    name="schema"
                    value={this.state.schemaText}
                    rows="10"
                    multiline

                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    //required
                    //fullWidth
                    id="data"
                    label="Data"
                    name="data"
                    value={this.state.dataText}
                    rows="10"
                    multiline
                  />
                </>
              )}
            </div>
          </form>

          {this.state.showImportDialog && (<Modal closeModal={() => this.handleImportDialog(false)}>
            <div>
              <ImportData
                match={{
                  params: {
                    entityId: this.state.id,
                    entityTypeId: this.state.entityTypeId,
                    pentityTypeId: this.state.pentityTypeId,
                    pentityId: this.state.pid
                  }
                }}
                CallbackDataHandler={this.handleImportedData}
                //ImportTemplateKey={this.state.KeyPathToImport}
                ImportTableTemplate={this.state.ImportTableTemplate}
              >

              </ImportData>
            </div>
          </Modal>)}
          {this.state.showDialog && (<Modal closeModal={() => this.handleDialog(false)}>
            <div className="table-responsive">
              <table className="table table-bordered table-sm">
                <tr class="table-secondary">
                  <th>SNO</th>
                  <th>Tab Name</th>
                  <th>Field Name</th>
                  <th>Current Value</th>
                  <th>Error Message</th>
                </tr>

                {this.state.validationMessages.map(x =>
                  <tr key={x}>
                    <td>
                      {x.sno}
                    </td>
                    <td>
                      {x.tabname}
                    </td>
                    <td>
                      {x.fieldname}
                    </td>
                    <td>
                      {x.currentvalue}
                    </td>
                    <td>
                      {x.errormessage}
                    </td>
                  </tr>)}
              </table>
            </div>
          </Modal>)}
        </div>}
        {this.state.loading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}><Loading></Loading></div>}
        {//wip make this common component
          this.state.showConfirmDialog && (
            <Modal closeModal={() => this.handleConfirmDialog(false)} title="Warning"
              footerActions={[{ Name: "Confirm", className: "btn btn-secondary mr-3", callBack: () => { this.state.confirmHandler() } },
              { Name: "Cancel", className: "btn btn-primary", callBack: () => { this.handleConfirmDialog(false) } }
              ]}>
              <p>This action will delete all the directly associated entities and unlink related entities</p>
            </Modal>
          )
        }
      </>
    );
  }
}

export default withStyles(styles)(EntityInstanceForm);
