import Dexie from "dexie";

var db;

export default {
  initialize: () => {
    if (!db) {
      db = new Dexie("MyDatabase");
      db.version(1).stores({
        formMeta: "++id, meta,name",
        formData: "++id, metaId,name,data",
        framework:"++id, meth,name",
        universe:"++id, frameworkId,name",
        registerInstanceData: "++id, metaId,registerId,name,data",      
      });
    }
  },

  addFormMeta: async (params) => {
    const id = await db.formMeta.add({ meta:params.meta, name:params.name});
    return id;
  },

  updateFormMeta: async (id, params) => {
    const updated = await db.formMeta.update(Number(id), { meta:params.meta, name:params.name});
    return updated;
  },

  getFormMetaList: async (params) => {
    const list = await db.table("formMeta").toArray();
    return list;
  },

  getFormMeta: async (id) => {
    const result = await db.formMeta.get(Number(id));
    return result;
  },

  addFormData: async (params) => {
    const id = await db.formData.add({ metaId:Number(params.metaId), name:params.name, data:params.data});
    return id;
  },

  getFormData: async (id) => {
   return await db.formData.get(Number(id));
  },

  getFormListByMetaId: async (metaId) => {
    console.log("metaId",metaId)
    const list = await db.formData.where('metaId').equals(Number(metaId)).toArray();
    return list;
  },

  updateFormData: async (id, params) => {
    const updated = await db.formData.update(Number(id), { data:params.data, name:params.name});
    return updated;
  },


  addFramework: async (params) => {
    const id = await db.framework.add({ meth:params.meth, name:params.name});
    return id;
  },

  updateFramework: async (id, params) => {
    const updated = await db.framework.update(Number(id), { meth:params.meth, name:params.name});
    return updated;
  },

  getFrameworkList: async (params) => {
    const list = await db.table("framework").toArray();
    return list;
  },


  
  getFramework: async (id) => {
    const result = await db.framework.get(Number(id));
    return result;
  },

  addUniverse: async (params) => {
    const id = await db.universe.add({ frameworkId:params.frameworkId, name:params.name});
    return id;
  },

  updateUniverse: async (id, params) => {
    const updated = await db.universe.update(Number(id), { frameworkId:params.frameworkId, name:params.name});
    return updated;
  },

  getUniverseList: async (params) => {
    const list = await db.table("universe").toArray();
    return list;
  },

  getUniverse: async (id) => {
    const result = await db.universe.get(Number(id));
    return result;
  },


  addRegisterInstanceData: async (params) => {
    const id = await db.registerInstanceData.add({ metaId:Number(params.metaId),registerId:Number(params.registerId), name:params.name, data:params.data});
    return id;
  },

  getRegisterInstanceData: async (id) => {
   return await db.registerInstanceData.get(Number(id));
  },

  getRegisterInstanceListByRegisterId: async (registerId) => {
    console.log("registerId",registerId)
    const list = await db.registerInstanceData.where('registerId').equals(Number(registerId)).toArray();
    return list;
  },

  updateRegisterInstanceData: async (id, params) => {
    const updated = await db.registerInstanceData.update(Number(id), { data:params.data, name:params.name});
    return updated;
  },
};
