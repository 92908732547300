import React, {Component} from 'react';
import Button from "@material-ui/core/Button";

//import moment from 'moment'
//import 'moment/locale/zh-cn';
import Scheduler, {SchedulerData, ViewTypes, DATE_FORMAT, DemoData} from 'react-big-scheduler';
import withDragDropContext from './withDnDContext';
import './style.css';
import MaxCard from '../Card/MainCard';
import GanttChart from "./GanttChart";
import Modal from './../Common/Modal';
const styles={
    "actionEvent":"lightgreen",
    "taskEvent":"lightblue"
}
//replace this object with data from server
const defaultData ={
    resources:[{
        id: 'r1',
        name: 'Resource 1',
        groupOnly: true,
    },
    {
        id: 'r2',
        name: 'Resource 2',
        parentId: 'r1',
    },
    {
        id: 'r3',
        name: 'Resource 3',
        parentId: 'r1',
    }],
    events:[               
    {
        id: 'Research',
        start: '2021-10-17 09:30:00',
        end: '2021-10-18 23:30:00',
        resourceId: 'r1',
        title: 'Find sources',
        bgColor:styles.actionEvent,
        showPopover: false
    },
    {
        id: 'Write',
        start: '2021-10-19 00:30:00',
        end: '2021-10-19 23:30:00',
        resourceId: 'r2',
        title: 'Write paper',
        bgColor:styles.taskEvent,
        resizable: false
    },
    {
        id: 'Cite',
        start: '2021-10-20 05:30:00',
        end: '2021-10-20 23:30:00',
        resourceId: 'r3',
        title: 'Create bibliography',
        bgColor:styles.taskEvent,
        movable: false
    },
    {
        id: 'Complete',
        start: '2021-10-21 05:30:00',
        end: '2021-10-21 16:30:00',
        resourceId: 'r2',
        title: 'Present the paper',
        bgColor:styles.actionEvent,
        startResizable: false,
    }]
}

class Basic extends React.Component{
    constructor(props){
        super(props);
        //let schedulerData = new SchedulerData(new moment("2021-10-18").format(DATE_FORMAT), ViewTypes.Week);
        let schedulerData = new SchedulerData('2021-10-18', ViewTypes.Week, false, false, {
            views: [
                {viewName: 'Agenda View', viewType: ViewTypes.Week, showAgenda: true, isEventPerspective: false},
                {viewName: 'Resource View', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false},
                {viewName: 'Task View', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: true},
            ],
            schedulerWidth:"80%"
        });
        // schedulerData.localeMoment.locale('en');

        

        schedulerData.setResources(defaultData.resources);
        schedulerData.setEvents(defaultData.events);
        this.state = {
            viewModel: schedulerData,
            showGanttChart:false
        };
    }


    handleGanttDialog(openState) {
        this.setState({showGanttChart:openState})
    }

    prevClick = (schedulerData)=> {
        schedulerData.prev();
        schedulerData.setEvents(defaultData.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    nextClick = (schedulerData)=> {
        schedulerData.next();
        schedulerData.setEvents(defaultData.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    onViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        schedulerData.setEvents(defaultData.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    onSelectDate = (schedulerData, date) => {
        schedulerData.setDate(date);
        schedulerData.setEvents(defaultData.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    eventClicked = (schedulerData, event) => {
        alert(`You just clicked an event: {id: ${event.id}, title: ${event.title}}`);
    };

    ops1 = (schedulerData, event) => {
        alert(`You just executed Cancel to event: {id: ${event.id}, title: ${event.title}}`);
    };

    ops2 = (schedulerData, event) => {
        alert(`You just marked the event as Completed: {id: ${event.id}, title: ${event.title}}`);
    };
    newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
        let newFreshId = 0;
        schedulerData.events.forEach((item) => {
            if(item.id >= newFreshId)
                newFreshId = item.id + 1;
        });

        let newEvent = {
            id: newFreshId,
            title: 'New event you just created',
            start: start,
            end: end,
            resourceId: slotId,
            bgColor: 'purple'
        }
        schedulerData.addEvent(newEvent);
        this.setState({
            viewModel: schedulerData
        })
    }

    updateEventStart = (schedulerData, event, newStart) => {
        schedulerData.updateEventStart(event, newStart);
        this.setState({
            viewModel: schedulerData
        })
    }

    updateEventEnd = (schedulerData, event, newEnd) => {
        schedulerData.updateEventEnd(event, newEnd);
        this.setState({
            viewModel: schedulerData
        })
    }

    moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
        schedulerData.moveEvent(event, slotId, slotName, start, end);
        this.setState({
            viewModel: schedulerData
        })
    }
    toggleExpandFunc = (schedulerData, slotId) => {
        schedulerData.toggleExpandStatus(slotId);
        this.setState({
            viewModel: schedulerData
        });
    }

    render(){
        const {viewModel} = this.state;
        return (
            <MaxCard title='Basic Planner' isOption>
            <div>                
                <div className="btn-toolbar justify-content-end col-xs-12">
                    <div className="dt-buttons btn-group float-right btn">
                        <Button type="button" className="btn-icon btn-outline-secondary btnAction" onClick={() => this.handleGanttDialog(true)} >
                            <i className="fa fa-line-chart" aria-hidden="true" title="View Critical Path"></i>
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <Scheduler schedulerData={viewModel}
                               prevClick={this.prevClick}
                               nextClick={this.nextClick}
                               onSelectDate={this.onSelectDate}
                               onViewChange={this.onViewChange}
                               eventItemClick={this.eventClicked}
                               viewEventClick={this.ops1}
                               viewEventText="Cancel"
                               viewEvent2Text="Completed"
                               viewEvent2Click={this.ops2}
                               updateEventStart={this.updateEventStart}
                               updateEventEnd={this.updateEventEnd}
                               moveEvent={this.moveEvent}
                               newEvent={this.newEvent}
                               toggleExpandFunc={this.toggleExpandFunc}                               
                    />
                </div>
            </div>
            { this.state.showGanttChart && (<Modal closeModal={() => this.handleGanttDialog(false)}> 
                    <div
                        style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height:"640px"
                        }}> 
                        <div className="container">
                            <div  className="row">
                                <div className="col-md-12">
                                    <GanttChart chartTitle="Basic Planner - Critical Path" chartHeight="640px"/>
                                </div>
                            </div>
                        </div>
                    </div>
            </Modal>
            )}
            </MaxCard>
        )
    }
}

export default withDragDropContext(Basic)
