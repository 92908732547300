import React, { useEffect } from 'react'

const Iframe = (props) => {
  const { src } = props
  return(
    <div width="calc(100%)" height="calc(100%-50px)"
          dangerouslySetInnerHTML={{
            __html:
              "<iframe src='" +src+"'  width='100%' height='640px'/>"
          }}
        />
  ) // <iframe ref={iframe}  title='react-iframe' {...props} />
}

export default Iframe