import React from "react";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import TableSortLabel from '@material-ui/core/TableSortLabel';
import Box from '@material-ui/core/Box';
//import { visuallyHidden } from '@material-ui/utils';
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
//import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Badge from "@material-ui/core/Badge";
import ColorPicker from "../ColorPicker"
import { Pagination } from "react-bootstrap";
import TablePaginationActions from "../TablePagination"

const useStyles = makeStyles((theme) => ({

}));

export default function List({
  Headers: headers = [],
  Data: data,
  DisplayCard: displayCard,
  Pagination: pagination,
  ShowName: showName = true,
  ShowDelete: showDelete = false,
  Sorting: sorting = {
    handleSort: () => { },
    sortByColumnId: "",
    sortOrder: "asc"

  }
}) {
  const classes = useStyles();
  const history = useHistory();
  const changePage = (event, newPage) => {
    pagination.handleChangePage(newPage);
  };


  const handleSortInternal = (colId) => {

    let sortOrder = "asc"
    if (sorting.sortByColumnId === colId) {
      if (sorting.sortOrder === "asc")
        sortOrder = "desc"
      else
        sortOrder = "asc"
    }
    if (typeof sorting.handleSort === "function")
      sorting.handleSort(colId, sortOrder);
  }

  var getClassNames = function (idx) {
    //usage: class={`bg-${getClassNames(i)}`}
    //var clsArray = ["primary","secondary","success","info","danger","warning"];
    var clsArray = ["info", "success", "danger", "warning", "secondary", "primary"];
    var clsIdx = idx % (clsArray.length);
    return clsArray[clsIdx];
  };

  const descendingComparator = function (a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = function (order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  const stableSort = function (array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  //disable ui sort as calling from db now
  // if (sorting.sortByColumnId) {
  //   data = stableSort(data, getComparator(sorting.sortOrder, sorting.sortByColumnId))
  // }


  const nameCol = "name"
  return (
    <div className={classes.root}>
      <Grid justify="flex-start">


        <div className="row cardsRow innerCard">

          {displayCard &&
            data &&
            data.map((item, i) => (
              <div key={`card_${i}`} className="col-xl-3 col-md-3 animate__animated animate__zoomIn">

                <div className="mdl-card mdl-shadow--2dp displayCard">

                  <div className="pcoded-mtext" onClick={item.handleClick}> <span> {item.name}</span></div>

                  <div disableSpacing className="titleSection">
                    {item.children.map((childItem, j) => (
                      <IconButton key={`child_${j}`} onClick={childItem.handleListClick}>
                        <Badge
                          color="secondary"
                          //className={classes['.MuiBadge-colorSecondary']}
                          overlap="circle"
                          badgeContent={childItem.count}
                          style={{ margin: "0px" }}
                          max="1000"
                        >
                          <Avatar aria-label="recipe" className={`btn-${getClassNames(j)}`} title={childItem.name} variant="circular">
                            {childItem.name[0]}
                            {/* R add margin 0 for alignment on counts */}
                          </Avatar>
                        </Badge>
                      </IconButton>
                    ))}
                  </div>
                </div>
              </div>
            ))}

        </div>

        {!displayCard && data && (
          <div class="tablePanel-body">
            <div component={Paper} className="table-responsive fixtableWindow">
              <table className="table table-hover m-b-0">
                <thead className="headerGrid">
                  <tr>
                    {showName && <th className={classes.colHeader}>
                      {/* Name */}
                      <span
                        active={sorting.sortByColumnId === nameCol}
                        direction={sorting.sortByColumnId === nameCol ? sorting.sortOrder : 'asc'}
                        onClick={() => { handleSortInternal(nameCol) }}
                      >
                        Name
                        {sorting.sortByColumnId === nameCol ? (
                          // <span>
                          //   {sorting.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          // </span>
                          <Box component="span">
                            {sorting.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </span>
                    </th>}
                    {data && data.length && data[0].children.map((childItem, j) => (
                      <th className={classes.colHeader}>
                        <b>{childItem.name}</b>

                      </th >
                    ))}
                    {headers && headers.map((col, j) => (
                      <th key={col.colName + j} className={classes.colHeader}>
                        {/* <b>{col.colName}</b> */}
                        <TableSortLabel
                          active={sorting.sortByColumnId === col.colId}
                          direction={sorting.sortByColumnId === col.colId ? sorting.sortOrder : 'asc'}
                          onClick={() => { handleSortInternal(col.colId) }}
                        >
                          <b>{col.colName}</b>
                          {sorting.sortByColumnId === col.colId ? (
                            // <span>
                            //   {sorting.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            // </span>
                            <Box component="span">
                              {sorting.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </th >
                    ))}

                    <th className={classes.colHeader}>
                      <b>Actions</b>
                    </th >
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => (
                    <tr key={`grid_${i}`}>
                      {showName && <td>
                        <div style={{ textTransform: 'none', cursor: "pointer" }} onClick={item.handleClick}>{item.name}</div>
                      </td >}
                      {item.children.map((childItem, j) => (
                        <td>
                          {/* <b>{childItem.count}</b> */}
                          <Link to={`/entityTypeList/${childItem.navListPath}`}>{childItem.count}</Link>
                        </td >
                      ))}
                      {headers && headers.map((col, j) => (
                        <td key={col.colId + j} >
                          {
                            col.colId === "name" && <div class="btn-link" style={{ textTransform: 'none', cursor: "pointer" }} onClick={item.handleClick}>{item.name}</div>
                          }{
                            col.colId !== "name" && <div
                              dangerouslySetInnerHTML={{
                                __html: item[col.colId]
                              }}></div>
                          }
                          {item.hasOwnProperty(col.colId + "_color") &&
                            <ColorPicker disableEdit={true} color={item[col.colId + "_color"]} ></ColorPicker>
                          }
                        </td >
                      ))}

                      <td >
                        <button className="btn btn-icon btn-outline-primary btnAction"><Link to={item.EditPath} title="Edit"><i className="fas fa-pen" aria-hidden="true"></i></Link> </button>
                        {Boolean(item.write) && <button className="btn  btn-icon btn-outline-primary btnAction"><Link to={item.AddPath} title="Add"><i className="fa fa-plus" aria-hidden="true"></i></Link> </button>
                        }
                        {Boolean(item.delete) &&
                          <button className="btn btn-icon btn-outline-secondary btnAction" onClick={item.handleDelete} title="Delete"><i className="fa fa-trash" aria-hidden="true"></i></button>
                        }
                        <button className="btn btn-icon btn-outline-primary btnAction" onClick={item.handleImportClick} title="Import"><i className="fa fa-file-excel-o" aria-hidden="true"></i></button>

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {pagination && (
                // <TablePagination style={{ "display": "flex", "justifyContent": "center" }}
                //   rowsPerPageOptions={[5, 10, 25]}
                //   component="div"
                //   count={pagination.count}
                //   rowsPerPage={
                //     pagination.rowsPerPage ? pagination.rowsPerPage : 5
                //   }
                //   page={pagination.page ? pagination.page : 0}
                //   //pageNumber={pagination.pageNumber ? pagination.pageNumber : 0}
                //   onChangePage={changePage}
                //   onChangeRowsPerPage={changePageSize}
                //   ActionsComponent={TablePaginationActions}
                // />
                <TablePaginationActions style={{ "display": "flex", "justifyContent": "center" }}
                  rowsPerPageOptions={[5, 10, 25]}
                  onChangeRowsPerPage={pagination.handleChangeRowsPerPage}
                  count={pagination.count}
                  page={pagination.page ? pagination.page : 0}
                  rowsPerPage={
                    pagination.rowsPerPage ? pagination.rowsPerPage : 5
                  }
                  onChangePage={pagination.handleChangePage}
                />
              )}


            </div>
          </div>
        )}

        {!data && (
          <Grid item xs={3} spacing={1}>
            <Paper className={classes.paper}>
              <Button color="primary">Coming Soon</Button>
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
