import React from "react";
import { useHistory } from 'react-router-dom';

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Card } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({  
  root: {
    flexGrow: 1,
    margin: "auto",
    padding: 10,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    verticalAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "#349feb",
    height: 80,
  },
  item2: {
    padding: theme.spacing(1),
    textAlign: "center",
    verticalAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "#11ff33",
    height: 80,
  },
  viewButtonLabel: { textTransform: "none", color:"white" }
}));

export default function NestedGrid({ NavItems: navItems }) {
  const classes = useStyles();
  const history = useHistory();

  
  return (
    <div className={classes.root}>
     
        <div className="row cardsRow">
          {navItems &&
              navItems.map((item,i) => (   
              <div className="col-xl-3 col-md-3 animate__animated animate__zoomIn"  key={i}>
                <div class="mdl-card mdl-shadow--2dp displayCard " >
                  <div className="pcoded-mtext ">{item.description}</div>                   
                   <div className="titleSection">     
                      <div class="row" onClick={() => history.push(item.path)}>
                        <div class="col-md-10 col-sm-10"><span class="cardTittle">{item.name}</span></div>
                        <div class="col-md-2 col-sm-2"><div class="btn pull-right" ><i class="fas fa-arrow-right"></i></div>
                      </div>
                    </div>                 
                  </div>
              </div>
          </div>             
          ))}
        </div>




    </div>

  );
}
