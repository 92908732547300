import baseEditForm from "formiojs/components/_classes/component/Component.form";

// schema = {"data": {
//   "values": [
//     {
//       "label": "11",
//       "value": "11"
//     },
//     {
//       "label": "12",
//       "value": "12"
//     },
//     {
//       "label": "13",
//       "value": "13"
//     },
//     {
//       "label": "14",
//       "value": "14"
//     }
//   ],
//   "json": "",
//   "url": "",
//   "resource": "",
//   "custom": ""
// }}

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            // You can ignore existing fields.
            key: "placeholder",
            ignore: true,
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "select",
            input: true,
            label: "Relation Type",
            weight: 12,
            key: "relationType", // This will be available as component.myCustomSetting
            data: {
              values: ["Link", "Child"],
            },
            defaultValue:"Link"
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected EntityId",
            weight: 12,
            key: "entityId", // This will be available as component.myCustomSetting
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected EntityTypeId",
            weight: 12,
            key: "entityTypeId", // This will be available as component.myCustomSetting
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected FrameworkId",
            weight: 12,
            key: "frameworkId", // This will be available as component.cframeworkId
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected StepItemId",
            weight: 12,
            key: "stepItemId", // This will be available as component.stepItemId
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Config Key",
            weight: 12,
            key: "configKey", // This will be available as component
          },
          {
            type: "checkbox",
            input: true,
            label: "Same level",
            weight: 12,
            defaultValue:true,
            key: "isSameLevel", // This will be available as component
          },    
          {
            type: "checkbox",
            input: true,
            label: "Show Link existing Button",
            weight: 12,
            defaultValue:true,
            key: "showLinkExisting", // This will be available as component
          },    
          {
            type: "checkbox",
            input: true,
            label: "Show Create New Link Button",
            weight: 12,
            defaultValue:true,
            key: "showCreateNewLinkButton", // This will be available as component
          },    
          {
            type: "checkbox",
            input: true,
            label: "Show Copy Existing Button",
            weight: 12,
            defaultValue:true,
            key: "showCopyExistingButton", // This will be available as component
          },    
          {
            type: "checkbox",
            input: true,
            label: "Show Copy Dependents Button",
            weight: 12,
            defaultValue:true,
            key: "showCopyDependentsButton", // This will be available as component
          }, 
          {
            type: "checkbox",
            input: true,
            label: "Display Component as Link Buttons",
            weight: 12,
            defaultValue:false,
            key: "displayComponentAsLinkButton", // This will be available as component
          },  
          {
            type: "checkbox",
            input: true,
            label: "Show Actions in Linked grid data",
            weight: 12,
            defaultValue:true,
            key: "showActionsInLinkedGridData", // This will be available as component
          },            
        ],
      },

      {
        key: "data",
        components: [],
      },
      {
        key: "validation",
        components: [],
      },
      {
        key: "api",
        components: [],
      },
      {
        key: "conditional",
        components: [],
      },
      {
        key: "logic",
        components: [],
      },
    ],
    ...extend
  );
};
