import React from "react";
import * as dc from "dc";
import { scaleLinear } from "d3";
import * as d3 from "d3";
import { ChartTemplate } from "./chartTemplate";

var title ="Data";


var numFormat = d3.format(".2f");
const selectChartFunc = (divRef, ndx,config,layout) => { 
  
  var groupKey = "gender";
  if(config)
  {
    groupKey = config.groupKey;
    title=config.title;
  }

  const chartDimension = ndx.dimension((d) => {return d[groupKey]?d[groupKey]:"N.A.";}); 

  //create a table container node and append to parent div as dc.datatable only creates thead and tbody elements
  var tContainer = document.createElement("table");
  tContainer.className = "table table-sm table-bordered table-hover";
  divRef.appendChild(tContainer);

  //use table container as target to render datatable 
  var chart = dc.selectMenu(divRef);
  
  const pElement  = divRef.parentElement.parentElement; 

  var pWidth=pElement.clientWidth,
      pHeight=pElement.clientHeight;

  chart
    .width(pWidth)
    .dimension(chartDimension)
    .group(chartDimension.group()) 
    .controlsUseVisibility(true);

  //control  multi select option
  if(config.multiSelect ==true)
  {
    //show lesser of itemcount or 10 for multiselect
    var visibleCount=10; 
    if(visibleCount > chartDimension.top(Infinity).length)
    {
      visibleCount = chartDimension.top(Infinity).length;
    }    
    chart.multiple(true).numberVisible(visibleCount);
  }

  //control display text  
  chart.title(d => d.key);

  //if(config.showCounts == false){   
  //  chart.title(d => d.key);
  //}

  chart.render();
     
  return chart;

};

export const SelectChart = (props) => (
    <ChartTemplate chartFunction={selectChartFunc} title={props.config.title} config={props.config} layout={props.layout}/>  
);

export default SelectChart;
