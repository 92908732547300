import React from "react";
import crossfilter from "crossfilter2";
import { csv, timeFormat, timeParse, timeMonth, format } from "d3";


import apiService from "../../Services/api";

export const CXContext = React.createContext("CXContext");

export const dateFormatSpecifier = "%m/%d/%Y";
export const dateFormat = timeFormat(dateFormatSpecifier);
export const dateFormatParser = timeParse(dateFormatSpecifier);
export const numberFormat = format(".2f");

export class DataContext extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, hasNDX: false, dashId: props.dashId };
  }

  async componentDidMount() {

    if (this.state.hasNDX) {
      return;
    }
    if (this.state.loading) {
      return;
    }
    this.setState({ loading: true });

    const DashBoardCurrentContext = this.props.DashBoardCurrentContext
    // let entityId;
    // let entityTypeId;
    // if (this.props.DashBoardConfig && this.props.DashBoardConfig.dataSources) {
    //   console.log(this.props.DashBoardConfig.dataSources);
    //   const dataStources = this.props.DashBoardConfig.dataSources;
    //   if (Array.isArray(dataStources) && dataStources.length > 0) {
    //     entityId = dataStources[0].entityId;
    //     entityTypeId = dataStources[0].entityTypeId;
    //   }
    // }
    if (this.state.dashId > 1 && this.props.DashBoardConfig && this.props.DashBoardConfig.dataSources) {
      const allDataSets = {}
      const dataStources = this.props.DashBoardConfig.dataSources;
      if (Array.isArray(dataStources) && dataStources.length > 0) {
        for (var i = 0; i < dataStources.length; i++) {
          var getParams = this.state.contextData;
          if (!getParams) getParams = {
            entityId: dataStources[i].entityId,
            entityTypeId: dataStources[i].entityTypeId,
            contextParams: DashBoardCurrentContext ? DashBoardCurrentContext : {}
          };
          let data = await apiService.GetCustomQueryData(getParams);
          allDataSets[`${dataStources[i].entityId}_${dataStources[i].entityTypeId}`] = crossfilter(data[0]);
        }
        this.ndx = allDataSets;
      }
      // var getParams = this.state.contextData;
      // if (!getParams) getParams = {
      //   entityId,
      //   entityTypeId,
      //   contextParams: DashBoardCurrentContext ? DashBoardCurrentContext : {}
      // };
      // let data = await apiService.GetCustomQueryData(getParams);

      // this.ndx = crossfilter(data[0]); //TODO possibly need to update this
      this.setState({ loading: false, hasNDX: true });

    }
    else {
      csv('/ndx.csv')
        .then((data) => {
          data.forEach(function (d) {
            d.date = dateFormatParser(d.date);
            d.month = timeMonth(d.date); // pre-calculate month for better performance
            d.close = +d.close; // coerce to number
            d.open = +d.open;
          });

          this.ndx = crossfilter(data); //TODO possibly need to update this
          this.setState({ loading: false, hasNDX: true })
        });

    }


  }

  render() {
    if (!this.state.hasNDX) {
      return null;
    }
    return (
      <CXContext.Provider value={{ ndx: this.ndx }}>
        <div ref={this.parent}> {this.props.children} </div>
      </CXContext.Provider>
    );
  }
}

