import React from "react";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import EditIcon from '@material-ui/icons/Edit';
import ViewListIcon from '@material-ui/icons/ViewList';
import TablePaginationActions from "../TablePagination"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    padding: 20,
    width: "100%",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    verticalAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "green",
    height: 80,
  },
  item2: {
    padding: theme.spacing(1),
    textAlign: "center",
    verticalAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "#11ff33",
    height: 80,
  },
  viewButtonLabel: { textTransform: "none", color: "white" },
  table: {
    minWidth: 650,
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  cell: {
    fontSize: 12,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  alignItems: {
    display: "flex",
    //width: 600,
    //"justify-content": "space-between",
    "padding-bottom": 20,
    flexDirection: "row",
    //flexWrap: "nowrap"
    justifyContent: "space-between",
  },
  formDiv: {
    margin: theme.spacing(2, 2),
    display: "flex",
    flexDirection: "column",
  },
}));



export default function List({
  Headers: headers = [],
  Data: data,
  DisplayCard: displayCard,
  Pagination: pagination,
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className=" ">
      <Grid justify="flex-start">

        <div className="row cardsRow rowMargin">
          {displayCard &&
            data &&
            data.map((item, i) => (
              <Grid item xs={3} spacing={1} key={`grid_${i}`} className="col-xl-3 col-md-3 animate__animated animate__zoomIn">


                <Paper className="mdl-card mdl-shadow--2dp displayCard">

                  <div className="pcoded-mtext ">{item.description}</div>


                  <div className="titleSection">
                    <div class="row">
                      <div class="col-md-10 col-sm-10"><span class="cardTittle"

                        classes={{ label: classes.viewButtonLabel }}
                        onClick={() => history.push(item.path)}
                      >
                        {item.name}


                      </span>
                      </div>
                    </div>
                  </div>




                </Paper>
              </Grid>
            ))}

        </div>


        <div className="tablePanel-body">

          {!displayCard && data && (
            <div component={Paper} className="table-responsive fixtableWindow">
              <table aria-label="simple table" className="table table-hover m-b-0">
                <thead className="headerGrid">
                  <tr>
                    <th >Name</th>
                    {headers && headers.map((col, j) => (
                      <th key={col.colName + j} >
                        <b>{col.colName}</b>
                      </th>
                    ))}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => (
                    <tr key={`grid_${i}`} >
                      <td component="th" scope="row">
                        <Link href="" style={{ textTransform: 'none' }} onClick={item.handleClick}>{item.name}</Link>
                      </td>
                      {headers && headers.map((col, j) => (
                        <td key={col.colId + j}>
                          {
                            <div>{item[col.colId]}</div>
                          }

                        </td>
                      ))}
                      <td>
                        {
                          item.navItems && item.navItems.map((navItem, j) =>
                            <Link to={navItem.path} ><ViewListIcon fontSize="small" color="primary" title={navItem.name} /></Link>
                          )
                        }
                        <button className="btn  btn-icon btn-outline-primary btnAction"><Link to={item.path} title="Edit"><i className="fas fa-pen" aria-hidden="true"></i></Link></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {pagination && (
                // <TablePagination className="123"
                //   rowsPerPageOptions={[5, 10, 25]}
                //   component="div"
                //   count={data.length}
                //   rowsPerPage={pagination.rowsPerPage ? pagination.rowsPerPage : 5}
                //   page={pagination.page}
                //   onChangePage={pagination.handleChangePage}
                //   onChangeRowsPerPage={pagination.handleChangeRowsPerPage}
                // />
                <TablePaginationActions style={{ "display": "flex", "justifyContent": "center" }}
                  rowsPerPageOptions={[5, 10, 25]}
                  onChangeRowsPerPage={pagination.handleChangeRowsPerPage}
                  count={pagination.count}
                  page={pagination.page ? pagination.page : 0}
                  rowsPerPage={
                    pagination.rowsPerPage ? pagination.rowsPerPage : 5
                  }
                  onChangePage={pagination.handleChangePage}
                />
              )}
            </div>
          )}
        </div>

        {!data && (
          <Grid item xs={3} spacing={1}>
            <Paper className={classes.paper}>
              <Button color="primary">Coming Soon</Button>
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
