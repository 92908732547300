import React from "react";
import * as dc from "dc";
import { timeYear, schemeRdYlGn, scaleLinear } from "d3";
import { numberFormat } from "./DataContext";
import { ChartTemplate } from "./chartTemplate";

const groupAddReducer =(p,v)=>{
    ++p.count;
    p.fin_absGain += v.close - v.open;
    p.fin_fluctuation += Math.abs(v.close - v.open);
    p.fin_sumIndex += (v.open + v.close) / 2;
    p.fin_avgIndex = p.fin_sumIndex / p.count;
    p.fin_percentageGain = p.fin_avgIndex ? (p.fin_absGain / p.fin_avgIndex) * 100 : 0;
    p.fin_fluctuationPercentage = p.fin_avgIndex
      ? (p.fin_fluctuation / p.fin_avgIndex) * 100
      : 0;
    return p;
  }
const groupRemoveRudcer =(p, v) =>{
    --p.count;
    p.fin_absGain -= v.close - v.open;
    p.fin_fluctuation -= Math.abs(v.close - v.open);
    p.fin_sumIndex -= (v.open + v.close) / 2;
    p.fin_avgIndex = p.count ? p.fin_sumIndex / p.count : 0;
    p.fin_percentageGain = p.fin_avgIndex ? (p.fin_absGain / p.fin_avgIndex) * 100 : 0;
    p.fin_fluctuationPercentage = p.avgIndex ? (p.fin_fluctuation / p.fin_avgIndex) * 100 : 0;
    return p;
}

const groupInitalizer = ()=> {
    return {
        count: 0,
        fin_absGain: 0,
        fin_fluctuation: 0,
        fin_fluctuationPercentage: 0,
        fin_sumIndex: 0,
        fin_avgIndex: 0,
        fin_percentageGain: 0
    };
}

const bubbleChartFunc = (divRef, ndx,config) => {
  var dataKey = "date",
  chartKey="fin_absGain",
  valKey="fin_percentageGain",
  colourKey="fin_absGain",
  rValKey="fin_fluctuationPercentage",
  xVals = [-2500, 2500], 
  yVals = [-100, 100],
  rVals = [0, 4000]; 
  if(config){
    if(config.dataKey) dataKey = config.dataKey;
    if(config.chartKey) chartKey = config.chartKey;
    if(config.valKey) valKey = config.valKey;
    if(config.colourKey) colourKey = config.colourKey;
    if(config.rValKey) rValKey = config.rValKey;
    if(config.xVals && Array.isArray(config.xVals)) xVals = config.xVals;
    if(config.yVals && Array.isArray(config.yVals))  yVals = config.yVals;
    if(config.rVals && Array.isArray(config.rVals)) rVals = config.rVals;
  }

  const yearlyDimension = ndx.dimension(d => timeYear(d[dataKey]).getFullYear());
  const yearlyPerformanceGroup = yearlyDimension.group()
  .reduce(groupAddReducer,groupRemoveRudcer,groupInitalizer);
  const yearlyBubbleChart = dc.bubbleChart(divRef); // Divref is a refere3nce to the div we're attaching to
  yearlyBubbleChart
    .transitionDuration(1500)
    .useViewBoxResizing(true)
    .dimension(yearlyDimension)
    .group(yearlyPerformanceGroup)
    .colors(schemeRdYlGn[9]) // why the 9 ?
    .colorAccessor(d => d.value[colourKey])
    .keyAccessor(p => p.value[chartKey])
    .valueAccessor(p=>p.value[valKey])
    .radiusValueAccessor(p => p.value[rValKey])
    .maxBubbleRelativeSize(0.3)
    .x(scaleLinear().domain(xVals))
    .y(scaleLinear().domain(yVals))
    .r(scaleLinear().domain(rVals))
    .elasticY(false)
    .elasticX(true)
    .yAxisPadding(10)
    .xAxisPadding('10%')
    .renderHorizontalGridLines(true)
    .renderVerticalGridLines(true)
    .xAxisLabel('Index Gain')
    .yAxisLabel('Index Gain %')
    .renderLabel(true)
    .label(p=>p.key)
    .renderTitle(true)
    .title( (p)=>(
         [
            p.key,
            'Index Gain: ' + numberFormat(p.value[chartKey]),
            'Index Gain in Percentage: ' + numberFormat(p.value[valKey]) + '%',
            'Fluctuation / Index Ratio: ' + numberFormat(p.value[rValKey]) + '%'
        ].join('\n')
    ))
    .yAxis().tickFormat(v=>`${v}%`)
    return yearlyBubbleChart

};
export const BubbleChart = props => (
    <ChartTemplate chartFunction={bubbleChartFunc} title="Yearly Fluctuation [Default]" config={props.config}/>
)
