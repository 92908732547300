
import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField";
class PieChartConfig extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleDataSourceSelection = this.handleDataSourceSelection.bind(this);
    }

    handleChange(val) {

    }

    handleTextChange(event) {

        let value = event.target.value;

        // this.setState({
        //     [event.target.name]: value,
        // });
        this.updateParent({
            [event.target.name]: value
        })
    }

    updateParent(modifiedObj) {
        if (this.props.onConfigChange)
            this.props.onConfigChange(modifiedObj, this.props.id)
    }

    handleDataSourceSelection(event) {

        let objToModify = {
            entityId: -1,
            entityTypeId: -1,
            groupKey: ''
        }

        let value = event.target.value;
        const { dataSources } = this.props.metaData;
        const selectedDataSource = dataSources.find(x => x.entityId == value);

        if (selectedDataSource) {
            objToModify.entityId = selectedDataSource.entityId;
            objToModify.entityTypeId = selectedDataSource.entityTypeId;
        }
        this.updateParent(objToModify);
    }

    render() {

        const { title, groupKey, entityTypeId, entityId, chartType,aggregateType } = this.props.chartConfig
        const {
            groupKeyObj,
            dataSources,
            aggregateFunctions
        } = this.props.metaData;
        const aggreagetFunctionsToShow = aggregateFunctions[chartType].default || [];
        let groupKeys = [];
        if (groupKeyObj[`${entityTypeId}_${entityId}`]) {
            groupKeys = groupKeyObj[`${entityTypeId}_${entityId}`];
        }
        return (
            <>
                <div className="dashboardForm">
                    <div className="form-group">
                        {/* Pie chart config - {config.chartType} */}
                        Title:  <input
                            className='form-control'
                            variant="outlined"
                            margin="normal"
                            id="title"
                            label="Pie chart title"
                            name="title"
                            autoComplete="title"
                            autoFocus
                            value={title}
                            onChange={this.handleTextChange}
                        />
                    </div>
                    <div className='row form-group'>
                        <div className="col-md-4">
                            Data Sources:
                            <select className="form-control"
                                value={entityId} onChange={this.handleDataSourceSelection}
                            >
                                <option value="-1">Select</option>
                                {dataSources.map(x =>
                                    <option value={x.entityId} key={x.entityId}>
                                        {x.displayName}
                                    </option>
                                )
                                }
                            </select>
                        </div>
                        <div className="col-md-4">
                            Group key:
                            <select className="form-control"
                                name="groupKey"
                                value={groupKey} onChange={this.handleTextChange}
                            >
                                {groupKeys.map(x =>
                                    <option value={x.StepItemKey} key={x.StepItemKey}>
                                        {x.StepItemName}
                                    </option>
                                )
                                }
                            </select>
                        </div>

                        <div className="col-md-4">
                            Aggregate by:
                            <select className="form-control"
                            name="aggregateType"
                                value={aggregateType} onChange={this.handleTextChange}
                            >
                                <option value="-1">Select</option>
                                {aggreagetFunctionsToShow.map(x =>
                                    <option value={x.code} key={x.code}>
                                        {x.name}
                                    </option>
                                )
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </>)
    }
}

export default PieChartConfig;