// react/productpreview.js

import React from 'react';

export default class LinkReplacePreview extends React.Component {
    render() {

        return <a href={this.props.linkKey}><b>{this.props.textKey}
        </b></a>
    }
}
