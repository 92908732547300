
import React, { Component } from "react";

import lookUpService from "./../../../Services/lookupApi";
import Button from "@material-ui/core/Button";

import Modal from './../Modal';


const MessageTypes = {
  WARNING: 1,
  INFORMATION: 2
}

const WorkflowInstanceComp = class extends React.Component {
  constructor(props) {
    super(props);

    let data;
    try {
      //data = JSON.parse(props.component.jsonData);
      data = {}; //set default
    } catch (e) {
      data = {}; //set default
    }



    this.handleChange = this.handleChange.bind(this);
    this.handleDialog = this.handleDialog.bind(this);
    this.handleWorkflow = this.handleWorkflow.bind(this);

    let value = props.value ? props.value : props.submissionData;
    this.state = {
      value: value,
      jsonData: value ? value.jsonData : data,
      currentState: {
        name: '',
        state: 0
      },
      transitions: [
      ],
      selectedTransition: {
        msg: '',
        name: '',
        transitionId: '',
        messageType: MessageTypes.WARNING
      },
      showDialog: false
    };


  }

  async componentDidMount() {


    //this.setValue({ a: 1 })
    // if (!Boolean((Number(this.props.component.registerId) > 0)))
    //   return
    const params = {
      entityId: this.props.component.entityId || 2,
      entityTypeId: this.props.component.entityTypeId || 3,
      frameworkId: this.props.component.frameworkId || 5,
      registerId: this.props.component.registerId || 6
    }

    await this.getData(params);

  }
  componentWillReceiveProps(newProps) {
    //if (newProps.component.entityId !== this.props.component.entityId) {
       //not checking as workflows updates in the backend for the same propss
      const params = {
        entityId: newProps.component.entityId || 2,
        entityTypeId: newProps.component.entityTypeId || 3,
        frameworkId: newProps.component.frameworkId || 5,
        registerId: newProps.component.registerId || 6
      }
      this.getData(params);
    //}
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.component.entityId !== this.props.component.entityId) {
  //     //not checking as workflows updates in the backend for the same props
  //     this.getData();
  //   }
  // }

  async getData(params) {

    const workflowData = await lookUpService.getEntityWorkflowData(params);
    if (workflowData) {
      this.setState(
        {
          currentState: workflowData.currentState,
          transitions: [...workflowData.transitions],
        }
      )
    }
  }


  setValue = (jsonDataToSave) => {

    this.setState(
      (prevState) => ({
        value: {
          jsonData: jsonDataToSave,
        },
      }),
      // () => this.props.onChange(null,this.state.value)
      () => this.props.onChange(this.state.value)
    );
  };



  handleChange(e) {
    this.setState({
      searchText: e.target.value
    })
  }

  handleDialog(openState) {
    this.setState({ showDialog: openState })
  }

  handleWorkflow(workflowObj) {
    this.setState({
      selectedTransition: {
        ...this.state.selectedTransition,
        name: workflowObj.name,
        transitionId: workflowObj.transitionId
      }
    })
    this.handleDialog(true)
  }

  render() {


    const tableStyle = {
      "borderWidth": "1px",
      'borderColor': "#aaaaaa",
      'borderStyle': 'solid',
      fontSize: 18
    }

    return (
      <div>
        <div
          style={{ display: "flex", margin: 20, justifyContent: "space-between" }}
        >
          {/* <div className="m-b-10"><h5>
              {"Workflow"}
            </h5>
              Current state : <h6>{this.state.currentState.name}</h6>
            </div> */}

        </div>
        <div>
          {
            this.state.transitions.map(x => {
              //return <Button color="primary" variant="contained" type="submit">
              //return <Button key={x.name} style={{ marginRight:5}}  color="primary" variant="contained" onClick={() => { this.handleWorkflow(x) }} >
              return <Button key={x.name} style={{ marginRight: 5 }} color="primary"
                variant="contained" type="submit"
                value={x.transitionId}>
                {x.name}
              </Button>
            })
          }
          {this.state.showDialog && (<Modal closeModal={() => this.handleDialog(false)}>
            <div>
              <h3> Message for button {this.state.selectedTransition.name} </h3>
              {
                this.state.selectedTransition.messageType === MessageTypes.INFORMATION && (
                  <Button color="primary" variant="contained" type="submit" value={this.state.selectedTransition.transitionId} >
                    OK
                  </Button>
                )
              }
              {this.state.selectedTransition.messageType !== MessageTypes.INFORMATION && (
                <>
                  <Button color="primary" variant="contained" type="submit" value={this.state.selectedTransition.transitionId} >
                    Approve
                  </Button>

                  <Button color="primary" variant="contained"
                    value={this.state.selectedTransition.name}
                    onClick={() => { this.handleDialog(false) }}
                  >
                    Reject
                  </Button></>
              )
              }

            </div>

          </Modal>)}

        </div>
        {/* <p>EntityId - {this.props.component.entityId}</p>
          <p>EntityTypeId - {this.props.component.entityTypeId}</p>
          <p>FrameworkId - {this.props.component.frameworkId}</p>
          <p>registerId - {this.props.component.registerId}</p>
   */}

      </div>
    );
  }
};

export default WorkflowInstanceComp