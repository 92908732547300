const JWT = require("jsonwebtoken");

module.exports = {
  verify: () => {
    //return JWT.verify(token)
    var isValid=true;
    try {
      const token = window.localStorage.getItem("token");
      var decodedToken = JWT.decode(token, { complete: true });
      var dateNow = Date.now().valueOf() / 1000;;

      if (decodedToken.payload.exp < dateNow) 
            isValid = false; //return invlaid when token expired
    } catch (e) {
        console.log(e)
        isValid= false;
    }
    return isValid;
  },
  isAdmin: () => {
    //return JWT.verify(token)
    var isValid=false;
    try {
      const token = window.localStorage.getItem("token");
      var decodedToken = JWT.decode(token, { complete: true });

      if (decodedToken.payload.data.isAdmin == 1) 
            isValid = true; //return invlaid when token expired
    } catch (e) {
        console.log(e)
        isValid= false;
    }
    return isValid;
  },  
  isModuleAdmin: () => {
    //return JWT.verify(token)
    var isValid=false;
    try {
      const token = window.localStorage.getItem("token");
      var decodedToken = JWT.decode(token, { complete: true });

      if (decodedToken.payload.data.isModuleAdmin == 1) 
            isValid = true; //return invlaid when token expired
    } catch (e) {
        console.log(e)
        isValid= false;
    }
    return isValid;
  },
};
