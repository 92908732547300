import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Form, Formio, form } from "react-formio";
import FileService from "./FileService";
import { getElementToFocus, setElementToFocus } from './../../Utils/formData'
import  ClassicEditor  from "ckeditor5-custom-build";

//override formio.requirelibary to load content from local server instead of CDN
const requireLibrary = Formio.requireLibrary;

//added Ckeditor to the window object as formio first checks here before loading new
window.ClassicEditor =ClassicEditor 
const libraryName='ckeditor'
// Formio.libraries[libraryName] = {};
// Formio.libraries[libraryName].ready = new Promise((resolve, reject)=>{
//   Formio.libraries[libraryName].resolve = resolve(ClassicEditor);
//   Formio.libraries[libraryName].reject = reject;
// });

Formio.requireLibrary = function (name, property, src, polling) {
  if (name == 'ckeditor') {
    //not required as we are setting in global (window) object
    //src = "//" + document.location.host + "/libs/ckeditor/ckeditor.js";
  }
  else if (name == 'flatpickr-css') {
    if (Array.isArray(src) && src.length > 0) {
      src[0].src = "//" + document.location.host + "/libs/flatpickr/flatpickr.min.css";
    }
    else
      src = "//" + document.location.host + "/libs/flatpickr/flatpickr.min.css";
  }
  else if (name == 'flatpickr') {
    src = "//" + document.location.host + "/libs/flatpickr/flatpickr.min.js";
  }
  //return requireLibrary(name, property, src.replace('https://cdn.form.io', 'http://localhost:3030'), polling);
  return requireLibrary(name, property, src, polling);
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    padding: 20,
  },
}));

export default function RenderForm({
  Components: components, Display: display, Submission: submission,
  CurrentFormDomReference,
  onChange: onChange, onSubmit: onSubmit, onRender: onRender, onFormReady: onFormReady, onCustomEvent:hanldeFormioCustomEvent }) {


  const classes = useStyles();
  const history = useHistory();
  const currentForm = CurrentFormDomReference ? CurrentFormDomReference : React.createRef();
  const focusInput = React.createRef();

  const getActivePage = () => {
    let activePage = null
    const key = getFormDataKey()
    if (key != null) {
      const dataJSON = localStorage.getItem("formActivePages")
      if (dataJSON) {//obj will be stored as json string
        const data = JSON.parse(dataJSON)
        if (data[key] != null)
          activePage = data[key]
      }
    }
    return activePage
  }

  const getFormDataKey = () => {
    let key = null
    if (submission && submission.data && submission.data.entityId > 0) {
      key = `formUIKey_${submission.data.entityId}_${submission.data.entityTypeId}`
    }
    if (submission && submission.data && submission.data.pentityId > 0)
      key = `${key}_${submission.data.pentityId}_${submission.data.pentityTypeId}`
    return key
  }
  const setActivePage = (page) => {

    const key = getFormDataKey()
    if (key != null) {

      let data = {}
      const dataJSON = localStorage.getItem("formActivePages")
      if (dataJSON) {//obj will be stored as json string
        data = JSON.parse(dataJSON)
      }
      data[key] = page
      localStorage.setItem("formActivePages", JSON.stringify(data))
    }

  }

  const handleFocus = (e) => {
    e.preventDefault();
    alert(focusInput.current.value)
    // console.log(currentForm)
    // console.log(currentForm.current);
    //formInput. current.formio.setPage(2)
    currentForm.current.formio.focusOnComponent(focusInput.current.value)
  }

  const onSubmitForm = (submissionObj) => {
    if (onSubmit) {
      onSubmit(currentForm);
    }
  }

  const onFormRender = () => {
    console.log('onFormRender')
    if (onRender) {
      //onRender(currentForm.current.instance);
    };
  }

  const onReadyForm = (formInstance) => {
    const page = getActivePage()
    if (page != null && currentForm != null) {
      //added delay as conditional tabs are loading

      //BEGIN:workaround to load richtext properly, 
      //instead of directly activating applicable tab, 
      //activate adjacent tab first then actual tab
      //NOTE: set RedrawOn event to fire for AnyChange (rich text field)
      var tempPage = page + 1;
      if (currentForm && currentForm.current && currentForm.current.instance
        && currentForm.current.instance.instance
        && tempPage >= currentForm.current.instance.instance.pages.length) {
        if (page - 1 >= 0)
          tempPage = page - 1;
      }
      setTimeout(() => {
        if (currentForm && currentForm.current && currentForm.current.instance
          && currentForm.current.instance.instance
          && tempPage < currentForm.current.instance.instance.pages.length)//temp fix as of now pages is active pages
          currentForm.current.instance.instance.setPage(tempPage);
      }, 100);
      //END:workaround to load richtext properly, 
      setTimeout(() => {
        const componentToFocus = getElementToFocus() //'headofficepreparation';
        if (componentToFocus) {
          setElementToFocus("")//clear after setting
          if (currentForm && currentForm.current && currentForm.current.instance
            && currentForm.current.instance.instance
          ) {
            currentForm.current.instance.instance.focusOnComponent(componentToFocus);
          }
          return;
        }
        if (currentForm && currentForm.current && currentForm.current.instance
          && currentForm.current.instance.instance
          && page < currentForm.current.instance.instance.pages.length)//temp fix as of now pages is active pages
          currentForm.current.instance.instance.setPage(page)
      }, 300)
    }
    //currentForm.current.formio.focusOnComponent(focusInput.current.value)
    // const page = getActivePage()
    // if (page) {
    //   if(page < currentForm.current.instance.instance.pages.length)//temp fix as of now pages is active pages
    //   currentForm.current.instance.instance.setPage(page)
    //   //currentPanel
    // }
    //currentForm.current.formio.focusOnComponent("page4")
    if (currentForm.current) {
      currentForm.current.instance.instance.on('wizardPageSelected', () => {
        console.log('wiz1');
        if (currentForm.current)
          setActivePage(currentForm.current.instance.instance.page)
      })
    }


    if (onFormReady) {
      onFormReady(formInstance);
    }
  }

  const updateOnFocus = (e) => {

    console.log(e.component.key)
    alert(e.component.key)
  }
  const bindEvents = () => {
    alert('rendered')
    //currentForm.current.formio.on('wizardPageSelected', () =>{console.log('wiz');alert('wizard') });
    //currentForm.current.
    // Formio.createForm(focusInput.current, form).then((formio) => {
    //   formio.on('wizardPageSelected', () => {
    //     console.log('wiz');
    //   });
    // })
  }
 const onCustomEventFired =(event)=>{
  console.log("customEvent called",event)
  if(typeof hanldeFormioCustomEvent=="function")
  hanldeFormioCustomEvent(event);
}

  //formIO event hanlding done using the below URL as reference
  //https://github.com/formio/react#event-props
  //https://github.com/formio/react/blob/master/src/components/Form.jsx

  return (
    <div>
      {/* <input ref={focusInput}  />
      <input type="button" onClick={handleFocus} value="Focus on this Key"  ></input> */}

      <Form
        ref={currentForm}
        form={{
          components: components,
          display: display,
          isBuilder:false
          //setPage:2     
        }}
        submission={submission}
        onChange={onChange}
        onSubmit={onSubmitForm}
        //onFocus={updateOnFocus} use this for focus
        //onComponentChange={updateOnFocus}
        //onRender={bindEvents} //binding events
        onRender={onFormRender}
        onFormLoad={onReadyForm}
        formReady={onReadyForm}
        options={{ fileService: new FileService() }}
        onCustomEvent={onCustomEventFired}
      ></Form>

    </div>
  );
}
