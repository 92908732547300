import React from "react";

class NotFound extends React.Component {
    constructor(props) {
      super(props);
      
      
      //this.handleSubmit = this.handleSubmit.bind(this);
    }
     
    async handleSubmit(event) {
      event.preventDefault();
      console.log("A form was submitted: " + JSON.stringify(this.state));
  
    }
  
    render() {
      console.log("rendered", new Date());
      return (
        
        <div className="tablePanel-body tablePanelonly ">
        <div className="dynamicForm">
          <form onSubmit={this.handleSubmit}>
                <h4>NotFound Form WIP</h4>
          </form>
        </div>
        </div>
      );
    }
  }
  
  export default NotFound;
  