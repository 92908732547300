import React, { Component } from "react";
import Service from "../../../Services";
import Button from "@material-ui/core/Button";

import Modal from './../Modal';
import TextField from "@material-ui/core/TextField";


const ColumnValues = class extends React.Component {
    constructor(props) {
        super(props);


        this.handleDialog = this.handleDialog.bind(this);
        this.handleChange = this.handleChange.bind(this);
      
        this.handleColumnChange = this.handleColumnChange.bind(this)
        
        this.renderFilterItems = this.renderFilterItems.bind(this);
        this.handleAddFilterItem = this.handleAddFilterItem.bind(this);
        this.handleRemoveFilterItem = this.handleRemoveFilterItem.bind(this);

       


        this.state = {
            showDialog: props.showDialog,
         
                    
            //columns: [{ name: "Select", id: -1 }, { name: "All", id: -100 }, { name: "Any", id: -200 }, { name: "Name", id: 1 },],
            //columns,
            
            columnValues: [{
                columnId: -99,
                colDataType: '',
                colKey: '',
             
                items: [
                ]
            }],
           
        };

    }


    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

   

    async handleDialog(openState) {
        this.setState({ showDialog: openState })
        if (typeof this.props.handler === "function") {
            this.props.handler({
                ...this.props.data,
                columnValues: this.state.columnValues,

            })
        }
    }


    renderFilterItems(items) {

        const columns = Array.isArray(this.props.data.columns) ? this.props.data.columns : []

        const lookupByColId = this.props.data.lookupByColId;

        return items.map((x, itemIndex) => {

            const renderChildren = (x.columnId === "-100" || x.columnId === "-200") ? true : false;
            return (<div>

                {

                    (<div className="row">
                        <div className="container">
                            <span >Column:</span>

                            <select value={x.columnId} onChange={
                                (e) => { this.handleColumnChange(x, e) }
                            }>
                                {columns.map(y =>
                                    <option value={y.colId} key={y.colId}>
                                        {y.colName}
                                    </option>
                                )
                                }
                            </select>

                            {!renderChildren && (<>


                     

                             
                                    <>


                                        {
                                            ((x.colDataType !== "datetime" && x.colDataType !== "select")
                                                //|| (x.colDataType === "datetime" && x.daysRequired)
                                            )
                                            && (
                                                <>
                                                    <span> Value1: </span>
                                                    <input value={x.value1} onChange={(e) => {
                                                        x.value1 = e.target.value
                                                        this.setState({
                                                            ...this.state
                                                        })
                                                    }} />
                                                </>
                                            )
                                        }


                                        {
                                            x.colDataType === "datetime" && (
                                                <>

                                                    {x.currentDateRequired === 1 &&
                                                        <>
                                                        <span> Value1: </span>
                                                            <label class="checkbox-inline">
                                                                <input type="checkbox" checked={x.currentDateSelected1}
                                                                    onChange={(e) => {
                                                                        x.currentDateSelected1 = e.target.checked
                                                                        this.setState({
                                                                            ...this.state
                                                                        })
                                                                    }} /> Current Date
                                                            </label>
                                                        </>
                                                    }
                                                    {!x.currentDateSelected1 && !x.daysRequired &&
                                                        <TextField
                                                            id="date"
                                                            label="Value1"
                                                            type="date"
                                                            //defaultValue={new Date()}
                                                            //className={classes.textField}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            value={x.value1} onChange={(e) => {
                                                                x.value1 = e.target.value
                                                                this.setState({
                                                                    ...this.state
                                                                })

                                                            }}
                                                        />}

                                                    {x.daysRequired &&
                                                        <>
                                                            <span> Value1: </span>
                                                            <input value={x.value1} onChange={(e) => {
                                                                x.value1 = e.target.value
                                                                this.setState({
                                                                    ...this.state
                                                                })
                                                            }} />
                                                        </>
                                                    }
                                                </>
                                            )
                                        }

                                        {
                                            x.colDataType === "select" && Array.isArray(lookupByColId[x.columnId]) && (

                                                <select value={x.value1} onChange={
                                                    (e) => { x.value1 = e.target.value 
                                                        this.setState({
                                                            ...this.state
                                                        })
                                                    }

                                                }>
                                                    {
                                                        !x.value1 &&  <option value={""} key={'empty'}>
                                                        {""}
                                                    </option>
                                                    }
                                                    {lookupByColId[x.columnId].map(y =>
                                                        <option value={y.lookUpValue} key={y.lookUpValue}>
                                                            {y.lookUpName}
                                                        </option>
                                                    )
                                                    }
                                                </select>

                                            )
                                        }




                                    </>
                               



                            </>)}

                            {
                                renderChildren &&
                                <Button className="form-control" onClick={() => this.handleAddFilterItem(x.items)} type="button" className="btn btn-outline-secondary">
                                    Add
                                </Button>
                            }
                            <Button className="form-control" onClick={() => this.handleRemoveFilterItem(items, itemIndex)} type="button" className="btn btn-outline-secondary">
                                X
                            </Button>

                        </div>
                    </div>
                    )
                }
               
            </div>)


        })

    }

    handleAddFilterItem(parent) {

        parent.push({
            columnId: -99,
            colDataType: '',
            colKey: '',
            value1: '',
            
            items: []
        })
        this.setState({
            columnValues: [...this.state.columnValues]
        })
    }

    handleRemoveFilterItem(parent, itemIndex) {

        //try using filter to remove without mutating the array
        //parent = parent.filter((x, i) => itemIndex !== i)
        //TODO mutating array here
        parent = parent.splice(itemIndex, 1);
        this.setState({
            columnValues: [...this.state.columnValues]
        })
    }


    handleColumnChange(item, e) {
       
        item.value1= ''
       
        item.columnId = e.target.value
        const colSelected = this.props.data.columns.find(c => c.colId === Number(item.columnId))
        item.colDataType = colSelected ? colSelected.colDataType : ''
        item.colKey = colSelected ? colSelected.colKey : ''
       
        this.setState({
            columnValues: [...this.state.columnValues]
        })
    }

    render() {

        const columns = Array.isArray(this.props.data.columns) ? this.props.data.columns : []
        const buttonName = typeof this.props.data.buttonName ? this.props.data.buttonName : 'Column Values'
        return (
            <>
                <div className="btn  btn-icon btn-outline-primary btnAction">
                    <div onClick={(e)=>{e.preventDefault();  this.handleDialog(true)}} >{buttonName}<i className="fa fa-filter" ></i></div>
                </div>
                {this.state.showDialog && (<Modal closeModal={() => this.handleDialog(false)}>
                    <div className="row">
                        <div className="col-sm-12">
                            <h5>Columns</h5>
                        
                        </div>
                    </div>


                 

                    <Button className="form-control" onClick={(e)=>{e.preventDefault();  this.handleAddFilterItem(this.state.columnValues)}} type="button" className="btn btn-outline-secondary">
                        Add
                    </Button>
                    <div >
                        {this.renderFilterItems(this.state.columnValues)}
                    </div>

               

                </Modal>)}

            </>
        );
    }
};

export default ColumnValues