import Dexie from "./dexieForm";
import Api from "./api";

export default function () {
  if (global.runOffline) {
    Dexie.initialize();
    return Dexie;
  }
  return Api;
}
