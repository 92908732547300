import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { FormBuilder } from "react-formio";
import { Utils } from "formiojs";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    padding: 20,
  },
}));

export default function RenderBuilder({
  Components: components,
  Display: selectedDisplay,
  OnChange: onChange,
  OnDisplayChange: onDisplayChange,
}) {
  const classes = useStyles();
  const showFormIoDebugInputs = false;
  const [includeLayoutComp, setIncludeLayoutComp] = React.useState(false);
  const [shemaText, setshemaText] = React.useState("");
  const handleIncludeChange = (event) => {
    setIncludeLayoutComp(event.target.checked);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Display</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          //value={display}
          value={selectedDisplay}
          onChange={(e) => {
            onDisplayChange(e.target.value);
          }}
        >
          <MenuItem value={"form"}>Form</MenuItem>
          <MenuItem value={"wizard"}>Wizard</MenuItem>
        </Select>
      </FormControl>
      <FormBuilder

        // ref={(el) => console.log(el)}
        form={{
          components: components,
          display: selectedDisplay,
          isBuilder: true
        }}
        onChange={(schema) => {
          let flattenedComponents = Utils.flattenComponents(components, includeLayoutComp);
          let KeysWithoutPeriod = Object.keys(flattenedComponents)
            .filter(x => {
              return (x.indexOf(".") == -1 || flattenedComponents[x].type === 'container')
            }
            );
          // console.log("Before", Object.keys(flattenedComponents).length)
          if (Array.isArray(KeysWithoutPeriod)) {
            KeysWithoutPeriod.forEach(keyToDelete => {
              delete flattenedComponents[keyToDelete]
            })
            // console.log("After", Object.keys(flattenedComponents).length)
          }
          setshemaText(JSON.stringify(flattenedComponents, null, 4));
          const obj = {
            schema,
            dbSchema: JSON.stringify(flattenedComponents)
          }

          onChange(obj);
        }}
      ></FormBuilder>

      {
        showFormIoDebugInputs && (
          <>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              variant="outlined"
              margin="normal"
              required
              //fullWidth
              id="schema"
              label="schema"
              name="schema"
              value={shemaText}
              rows="10"
              multiline
              onChange={(e) => { setshemaText(e.target.value) }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              variant="outlined"
              margin="normal"
              required
              //fullWidth
              id="comp"
              label="components"
              name="components"
              value={JSON.stringify(components)}
              rows="10"
              disabled
              multiline
            />
          </>
        )
      }

      <Checkbox
        checked={includeLayoutComp}
        color="primary"
        onChange={handleIncludeChange}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </div>
  );
}
