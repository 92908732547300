import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./edit";
import Filters from "./../../../Common/Filters";

import Service from "../../../../Services";


/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */

const FiltersCustomComp = class extends React.Component {
  constructor(props) {
    super(props);
    this.handleFilterData = this.handleFilterData.bind(this);
     this.state={
      filtersData:{
        filterName:this.props.component.filterName,
        columns:[],
        lookupByColId:{},
        conditionsByDataType:{}
      }
     } 
  }


  setValue = (jsonDataToSave) => {

    this.setState(
      (prevState) => ({
        value: {
          jsonData: jsonDataToSave,
        },
      }),
      // () => this.props.onChange(null,this.state.value)
      () => this.props.onChange(this.state.value)
    );
  };

  async componentDidMount() {

    console.log(this.props.contextData)

    // if( !Boolean(this.props.component.entityId>0))
    //   return

    const params = {
      entityId: this.props.component.entityId || 2,
      entityTypeId: this.props.component.entityTypeId || 3,
      //contextId:this.contextData.transitionId
    }
    //const workflowData = await lookUpService.getEntityWorkflowData(params);
    const filterInformation = await Service().getFilterDataByEntityIdAndType(
      this.props.component.entityTypeId|| 14,
      this.props.component.entityId || 2,
      -1,
      -1,
      -1,
      this.props.component.frameworkId
    );
    this.setState({
      filtersData:{...filterInformation}
    })

  }

  handleFilterData(data){

    // this.setState({
    //   filtersData:data
    // })
    this.setState(
    (prevState) => ({
      filtersData: data,
    }),
    () =>{ 
      let dataToSaveInFormIo={...this.state.filtersData};
      //below properties or not required in final save
      delete dataToSaveInFormIo.columns;
      delete dataToSaveInFormIo.lookupByColId;
      delete dataToSaveInFormIo.conditionsByDataType;
      this.props.onChange(dataToSaveInFormIo)}
    );
    
  }

  render() {
    //const data = this.state.jsonData;


    const filtersData={
      ...this.state.filtersData,
      filterName:this.props.component.filterName,
      // columns:[],
      // lookupByColId:{},
      // conditionsByDataType:{}
    }
    const handlefilterHandler=()=>{

    }

    return (
      <>
        {/* <h1>Filter renders</h1> */}
        <Filters filterHandler={this.handleFilterData}
          filtersData={filtersData}
        ></Filters>
      </>
    );
  }
};


export default class customFilters extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "10Filters",
      icon: "square",
      group: "basic",
      documentation: "",
      weight: -10,
      schema: customFilters.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "filters",
      label: "Filters",
      logic: [
        {
          "name": "on Load",
          "trigger": {
            "type": "javascript",
            "javascript": "result= true;"
          },
          "actions": [
            {
              "name": "populateIds",
              "type": "mergeComponentSchema",
              //              "schemaDefinition": "schema = { myWidgetURI:data[data.apiKeyToPick] }"
              "schemaDefinition": "schema = {frameworkId:data.frameworkid, entityId:data.entityid,entityTypeId:data.entitytypeid,pentityId:data.pentityId, pentityTypeId:data.pentityTypeId }"
            }
          ]
        }
      ]
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    //console.log('this.dataForSetting',this.dataForSetting)
    // if (this.shouldSetValue) {
    //   //this.setValue(this.dataForSetting);
    //   this.updateValue(this.dataForSetting);
    // }
    return ReactDOM.render(
      <FiltersCustomComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        contextData={this._data}
      />,
      element
    );
  }

  setValue(data) {
    console.log("Called wit data-", data);
    //hacked to rerender the react component by adding new prop //temp fix
    //hacked to rerender the react component by adding new prop submissionData//temp fix
    //TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO
    if (data) {
      ReactDOM.render(
        <FiltersCustomComp
          component={this.component} // These are the component settings if you want to use them to render the component.
          value={this.dataValue} // updatedvalue of the component.
          onChange={this.updateValue} // The onChange event to call when the value changes.
          submissionData={data}
          contextData={this._data}
        />,
        this.element
      );
    }
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
