import { TrendingUpOutlined } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import { Link } from "react-router-dom";

import { ConfigContext } from "../../../../../contexts/ConfigContext";
import * as actionType from "../../../../../store/actions";

const NavSearch = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const configContext = useContext(ConfigContext);
    const { dispatch } = configContext;

    const handleSearch = (e) => {
        e.preventDefault();
        console.log(searchText);
        dispatch({
            type: actionType.SEARCH_TRIGGERED,
            searchText,
        })
    }

    const closeSearch = (e) => {
        e.preventDefault();
        setIsOpen(false)
        dispatch({ type: actionType.SEARCH_CLOSED })
    }

    let searchContent = '';
    if (isOpen) {
        searchContent = (
            <div className="search-bar">
                <FormControl type="text" value={searchText} onChange={(e)=>setSearchText(e.target.value)} className="border-0 shadow-none" placeholder="Search here" />
                <Button  className="searchBtn" variant='link' type="button" onClick={handleSearch}>
                    <span  className="feather icon-search"></span>
                </Button>
                <Button variant='link' type="button" className="close" aria-label="Close" onClick={closeSearch}>
                    <span aria-hidden="true"><i className="feather icon-x" /></span>
                </Button>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Link to='#' className="pop-search" onClick={() => setIsOpen(true)}><i className="feather icon-search" /></Link>
            {searchContent}
        </React.Fragment>
    );
};

export default NavSearch;
