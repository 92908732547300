import React from "react";
import RenderForm from "../RenderForm";
import { withStyles } from "@material-ui/core/styles";
import Service from "../../Services";
import LookUpService from "../../Services/lookupApi";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import SaveAltOutlinedIcon from "@material-ui/icons/SaveAltOutlined";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import * as FormUtil from "../../Utils/formData";
import { Tooltip } from "bootstrap";

import CustIframe from "../../views/iframe";


const styles = (theme) => ({
  actionButton: {
    padding: "3px",
  },
  actionsContainer: {
    //float:'right'
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  reportFrame: {
      height: "860px",
      width: "100%",
  }
});
var token = localStorage.getItem("token");

class ReportViewerForm extends React.Component {
  constructor(props) {
    super(props);
    //console.log("props-", this.props);
    const {
      match: { params },
    } = this.props;
    var qParams = this.props.location.search;
    if(qParams && qParams.length>0 && qParams.substr(0,1) == '?')
    {
      qParams = "&"+qParams.substr(1,qParams.length);
    }
    this.state = {
      reportId: Number(params.reportId),
      queryParams : qParams.length>1 ? qParams : ''
    }; //default
  
  }

  render(){
    const { classes } = this.props;  
    var currentUrl = window.location.href;
    const showSchemaAndData = false
    console.log("rendered", new Date());
    var reportURI =window.location.protocol+"//"+window.location.hostname+"/ReportsApp/SQLReportViewer.aspx";
    var envSettings = window.localStorage.getItem("envSettings");
    
    if(typeof envSettings === "string") envSettings =JSON.parse(envSettings); 
    if( envSettings && envSettings["url_reportviewer"]){
      reportURI = envSettings["url_reportviewer"];
    }
    reportURI =reportURI+"?rptId=" + this.state.reportId + this.state.queryParams;
    return (
      <div className={classes.reportFrame} class="dynamicForm">
        <CustIframe
          src={reportURI}
          headers={{
            "jToken": `Bearer ${token}`
          }}
          />;    
      </div>
      
    );
  }
}

export default withStyles(styles)(ReportViewerForm);
