import { toast } from "react-toastify";
import React from "react";
//import ReactDOM from 'react-dom';
import { Form } from "react-formio";
import DBService from "../../Services/dexieForm";
import { Link } from "react-router-dom";


const fields = [
  {
    type: "textfield",
    key: "firstName",
    label: "First Name",
    placeholder: "Enter your first name.",
    input: true,
    tooltip: "Enter your <strong>First Name</strong>",
    description: "Enter your <strong>First Name</strong>",
  },
  {
    label: "Email",
    labelPosition: "top",
    placeholder: "",
    customClass: "",
    multiple: false,
    inputFormat: "plain",
    protected: false,
    dbIndex: false,
    validateOn: "change",
    validate: {
      required: false,
      pattern: "",
      customMessage: "",
      custom: "",
      customPrivate: false,
      json: "",
      minLength: "",
      maxLength: "",
      strictDateValidation: false,
      multiple: false,
      unique: false,
    },
    unique: false,
    kickbox: { enabled: false },
    errorLabel: "",
    key: "email",
    tags: [],
    properties: {},
    conditional: { show: null, when: null, eq: "", json: "" },
    type: "email",
    input: true,
    refreshOn: "",
    inputType: "email",
    inputMask: "",
    defaultValue: "t",
  },
];
const dataExample = {
  data: {
    email: "dussa@gmail.com",
    firstName: "sri",
  },
};

class SubmissionForm extends React.Component {
  constructor(props) {
    super(props);
    console.log("props-", this.props);

    const {
      match: { params },
    } = this.props;

    this.submissionDataTemp = {};
    this.state = {
      components: [],
      display:'form',
      submission: this.submissionDataTemp,
      id: Number(params.id),
      metaId: params.metaId,
      name: "form Name",
    }; //default
    this.CurrentFormDomReference = React.createRef()
    this.handleChange = this.handleChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    DBService.initialize();
   
    const item = await DBService.getFormMeta(this.state.metaId);
    console.log("item-", item);
    if (item) {
      this.setState({
        components: item.meta.components,
        display:item.meta.display
      });
    } else {
      //not a valid methFormId
      console.log("not a valid methFormId");
      this.props.history.replace(`/buildList`);
      return;
    }
    if (this.state.id > 0) {
      const submissionData = await DBService.getFormData(this.state.id);
      console.log("submissionData-", submissionData);
      if (submissionData) {
        this.submissionDataTemp = {
          data: submissionData.data,
        };


        this.setState({
          submission: this.submissionDataTemp,
          name: submissionData.name,
        });
      } else {
        //not a valid formId
        console.log("not a valid formId");
        this.props.history.replace(`formList/${this.state.metaId}`);
        return;
      }
    }
  }

  handleChange(submission) {
    if (submission.changed) {
      console.log(submission);
      //--------------------------------------->//
      //todo this is workaround as setstate is causing infinite rerender for submission
      //. submission has to move to state later
      this.submissionDataTemp = submission;
      //const submissionData = submission.data;
      //this.setState({ submission: submission });
    }
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();
    console.log("A form was submitted: " + JSON.stringify(this.state));

    if (this.CurrentFormDomReference.current.instance.instance.checkValidity(this.submissionDataTemp)){

    }
    else{
      this.CurrentFormDomReference.current.instance.instance.submit();
      toast.error("Please fill all required fields");
      return
    }

    DBService.initialize();

    const id = this.state.id;

    if (id === -100) {
      //add
      const newId = await DBService.addFormData({
        name: this.state.name,
        data: this.submissionDataTemp.data,
        metaId: this.state.metaId,
      });
      console.log("newId", newId);
      //this.props.history.push(`/build/${newId}`);
      this.props.history.push(`/formList/${this.state.metaId}`);
    } else {
      //update
      const updated = await DBService.updateFormData(id, {
        name: this.state.name,
        data: this.submissionDataTemp.data,
      });
      console.log("updated", updated);
    }
  }

  render() {
    console.log("rendered", new Date());
    return (
      <div className="dynamicForm">
        <form onSubmit={this.handleSubmit}>
          <div className="formMain">
            <label>
              Form Name:
              <input
                type="text"
                value={this.state.name}
                placeholder="Name"
                required
                onChange={this.handleNameChange}
              />
            </label>
            <input type="submit" value="Save Form" />
            <Link to={`/formList/${this.state.metaId}`}>Back to Form List</Link>
          </div>
          <div>
            <Form
              ref={this.CurrentFormDomReference}
              //ref={(el) => console.log(el)}
              form={{
                components: this.state.components,
                display: this.state.display,
              }}
              submission={this.state.submission}
              onChange={this.handleChange}
            ></Form>
          </div>
        </form>
      </div>
    );
  }
}

export default SubmissionForm;
