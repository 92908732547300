import React, { Component } from 'react';
import TextEditorComp from '../Editor'
import lookUpService from "./../../../../Services/lookupApi";

const RenderType = {
    Text: 1,
    Link: 2
}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailContent: "",
            selectedFrameworkId: -1,
            frameworks: [],
            columns: [],
            editorReference: null
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleFrameworkChange = this.handleFrameworkChange.bind(this);
        this.handleOnColumnSelect = this.handleOnColumnSelect.bind(this);
        this.hanldeOnEditorReady = this.hanldeOnEditorReady.bind(this)
    }


    async componentDidMount() {
        this.getData();
    }

    async getData() {
        const params = {
            customkey: "frameworklist"
        }
        const list = await lookUpService.getCustomKeyProcedures(params);
        this.setState({
            frameworks: list,
            selectedFrameworkId: list.length > 0 ? list[0].id : -1
        }, () => {
            this.getColumns();
        })
    }

    async getColumns() {
        const params = {
            customkey: "getstepItemsbyframeworktemplate",
            //frameworkId:this.state.selectedFrameworkId
            entityTypeId: 25, //passing selected frameworkId as the entityid/typeid in this case
            entityId: this.state.selectedFrameworkId

        }
        const list = await lookUpService.getCustomKeyProcedures(params);
        this.setState({
            columns: list,
        })
    }

    handleChange(data) {

        console.log(data)
        this.setState({
            emailContent: data
        })
        this.props.onChange(data);
    }

    handleFrameworkChange(e) {
        this.setState({
            selectedFrameworkId: e.target.value
        }, () => {
            this.getColumns();
        });


    }

    handleOnColumnSelect(col, renderType) {

        if (this.state.editorReference != null) {
            const editor = this.state.editorReference;
            //editor.execute('insertProduct', col.id,col);
            if (renderType === RenderType.Text) {
                editor.execute('insertTextReplace', col.idString);
            }
            else if (renderType === RenderType.Link) {
                editor.execute('insertLinkReplace', {
                    textKey: col.idString,
                    linkKey:`${col.idString}_Link`
                });
            }
            editor.editing.view.focus();
        }
    }

    hanldeOnEditorReady(editor) {
        this.setState({
            editorReference: editor
        })
    }
    render() {
        let value = this.props.value ? this.props.value : this.props.submissionData;
        const dataToLoad = value;
        const showFlat = false;
        return (

            <div className="">
                {/* <h2>Framework Template</h2> */}

                <div className="frameworkDiv">
                    <div className="row">
                        <div className=" col-md-3">
                            <label className="form-label" id="basic-meth">Frameworks:</label>
                            <select className="form-control"
                                value={this.state.selectedFrameworkId} onChange={(e) => {
                                    this.handleFrameworkChange(e);
                                }}>
                                {this.state.frameworks.map(x =>
                                    <option value={x.id} key={x.id}>
                                        {x.name}
                                    </option>
                                )
                                }
                            </select>
                        </div>

                        <div className="col-md-6">
                           
                           
                            <label className="form-label" id="basic-meth">Columns:</label>
                           <div className='input-group'>
                            <select className="form-control"
                                value={-1} onChange={(e) => {
                                    const selectedCol = this.state.columns.find(x => x.id == e.target.value);
                                    this.handleOnColumnSelect(selectedCol, RenderType.Text);
                                }}>
                                <option value={-1} key={-1}>
                                    {"Insert Column"}
                                </option>
                                {this.state.columns.map(x =>
                                    <option value={x.id} key={x.id}>
                                        {x.name}
                                    </option>
                                )
                                }
                            </select>

                            {/* <span className="form-label" id="basic-meth">Columns:</span> */}
                            <select className="form-control"
                                value={-1} onChange={(e) => {
                                    const selectedCol = this.state.columns.find(x => x.id == e.target.value);
                                    this.handleOnColumnSelect(selectedCol,RenderType.Link);
                                }}>
                                <option value={-1} key={-1}>
                                    {"Insert Column As Link"}
                                </option>
                                {this.state.columns.map(x =>
                                    <option value={x.id} key={x.id}>
                                        {x.name}
                                    </option>
                                )
                                }
                            </select>

                            {showFlat && this.state.columns.map(x =>

                                <span key={x.id}>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.handleOnColumnSelect(x)
                                        }}
                                        title="Add to the Template"
                                    >
                                        <span>+</span>
                                    </button>
                                    <span>{x.name}</span>

                                </span>
                            )
                            }
                            </div>
                             </div>
                        <div className="col-md-3">
                            <label className="form-label" id="basic-meth"> Dependent StepItems:</label>
                            <select className="form-control"></select>
                        </div>

                    </div>
                </div>

                <TextEditorComp
                    //value={this.state.emailContent}
                    value={dataToLoad}
                    onChange={this.handleChange}
                    OnEditorReady={this.hanldeOnEditorReady}
                >
                </TextEditorComp>
            </div>
        );
    }
}

export default App;
