import React, { Component } from "react";
import Service from "../../../Services";
import Button from "@material-ui/core/Button";

import Modal from './../Modal';
import TextField from "@material-ui/core/TextField";

const ColumnPicker = class extends React.Component {
    constructor(props) {
        super(props);


        this.handleDialog = this.handleDialog.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleViewType= this.handleViewType.bind(this);
        
        this.handleDrag = this.handleDrag.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.state = {
            showDialog: props.showDialog,
            dragId:-1,
            columnsToChoose: this.props.columns
        };

    }


    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleViewType(e) {

        this.setState({
            viewType:e.target.checked? 1:2
        })
    }

    handleSelect(item) {

        item.isSelected = item.isSelected ? !item.isSelected : true;
        this.setState({
            columnsToChoose: this.state.columnsToChoose
        })
        if(typeof this.props.columnHandler==="function"){
            this.props.columnHandler(this.state.columnsToChoose)
        }
    }
    async handleDialog(openState) {
        this.setState({ showDialog: openState })
    }


    handleDrag = (ev) => {
        this.setState(
            {
                dragId:ev.currentTarget.id
            })
        
      };
    
    handleDrop = (ev) => {
        const dragBox = this.state.columnsToChoose.find((item) => item.colId === this.state.dragId);
        const dropBox = this.state.columnsToChoose.find((item) => item.colId === ev.currentTarget.id);
    
        const dragBoxOrder = dragBox.orderid;
        const dropBoxOrder = dropBox.orderid;
    
        const newItemState = this.state.columnsToChoose.map((item) => {
          if (item.colId === this.state.dragId) {
            item.orderid = dropBoxOrder;
          }
          else if(item.orderid>=dropBoxOrder){
            item.orderid = item.orderid +1;
          }
          return item;
        });
    
        this.setState({
            columnsToChoose: newItemState
        })
        if(typeof this.props.columnHandler==="function"){
            this.props.columnHandler(newItemState.sort((a,b)=>a.orderid-b.orderid))
        }
      };

  
    render() {


        return (
            <div>
                 <div className="btn  btn-icon btn-outline-primary btnAction" title="Select Columns">
                    <div  onClick={(e) =>{e.preventDefault(); this.handleDialog(true)}} ><i className="fa fa-columns" ></i></div>                   
                </div>
                {this.state.showDialog && (<Modal closeModal={() => this.handleDialog(false)} SaveData={() => this.handleDialog(false)} ShowSaveButton="true">                
                    <div className="headerHeading">
                            <h5>Column Chooser </h5>
                    </div>
                    <div className="chooseColum">
                        <div className="">                          
                            {Array.isArray(this.state.columnsToChoose) &&
                                this.state.columnsToChoose
									.sort((a, b) => a.orderid - b.orderid)
									.map(col => {
                                    return <div draggable={true}
		                            onDragOver={(ev) => ev.preventDefault()}
		                            onDragStart={this.handleDrag}
		                            onDrop={this.handleDrop}
		                            id={col.colId} className="col-md-4"
		                            >
										<div className="row input-group input-group-sm" key={`${col.colId}-${col.isSelected}`}>
	                                         <label className = "checkbox-inline">
	                                            <input type = "checkbox" 
	                                                        checked={col.colId==="name"? true: col.isSelected}
                                                            disabled={col.colId==="name"? true: false}
	                                                        onChange={() => {
	                                                            this.handleSelect(col);
	                                                        }} />  {col.orderid} - {col.colName}
                                                            
	                                        </label>                                          
	                                    </div> 

									</div>   
                                })

                            }              
                        </div>
                    </div>
                </Modal>)}

            </div>
        );
    }
};

export default ColumnPicker