import baseEditForm from "formiojs/components/_classes/component/Component.form";

// schema = {"data": {
//   "values": [
//     {
//       "label": "11",
//       "value": "11"
//     },
//     {
//       "label": "12",
//       "value": "12"
//     },
//     {
//       "label": "13",
//       "value": "13"
//     },
//     {
//       "label": "14",
//       "value": "14"
//     }
//   ],
//   "json": "",
//   "url": "",
//   "resource": "",
//   "custom": ""
// }}

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            // You can ignore existing fields.
            key: "placeholder",
            ignore: true,
          },
          // {
          //   // Or add your own. The syntax is form.io component definitions.
          //   type: "textfield",
          //   input: true,
          //   label: "Button Name",
          //   weight: 12,
          //   key: "buttonName", // This will be available as component.myCustomSetting
          // },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Custom Grid Key",
            weight: 12,
            key: "customGridKey", // This will be available as component.myCustomSetting
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected EntityId",
            weight: 12,
            key: "entityId", // This will be available as component.myCustomSetting
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected EntityTypeId",
            weight: 12,
            key: "entityTypeId", // This will be available as component.myCustomSetting
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected FrameworkId",
            weight: 12,
            key: "frameworkId", // This will be available as component.cframeworkId
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected Parent EntityId",
            weight: 12,
            key: "pentityId", // This will be available as component.entityId
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Selected Parent EntityTypeId",
            weight: 12,
            key: "pentityTypeId",
          },   
         
        ],
      },

      {
        key: "data",
        components: [],
      },
      {
        key: "validation",
        components: [],
      },
      {
        key: "api",
        components: [],
      },
      {
        key: "conditional",
        components: [],
      },
      {
        key: "logic",
        components: [],
      },
    ],
    ...extend
  );
};
