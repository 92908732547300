import axios from "axios";

const Graphql = async (query, variables = {}) => {
  const url = "/graphql";
  try {
    var data = {
      query: query,
      variables,
    };
    const response = await axios.post(url, data);
    if (response && response.data && response.data.errors) {
      alert(response.data.errors[0].message);
      return;
    }

    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export default {
    Graphql
}