import React, { Component } from "react";
import lookUpService from "./../../../Services/lookupApi";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Modal from '../Modal'

const CustomGridComp = class extends React.Component {
    constructor(props) {
        super(props);

        let data;
        try {
            //data = JSON.parse(props.component.jsonData);
            data = { unassigned: [], assigned: [] }; //set default
        } catch (e) {
            data = { unassigned: [], assigned: [] }; //set default
        }

        this.handleAssign = this.handleAssign.bind(this);
        this.handleUnAssign = this.handleUnAssign.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

        this.handleNotify = this.handleNotify.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
        let value = props.value ? props.value : props.submissionData;
        this.state = {
            value: value,
            jsonData: value ? value.jsonData : data,
            searchText: "",
            columns: props.component.columns,
            showDialog: false,
        };
    }

    setValue = (jsonDataToSave) => {

        this.setState(
            (prevState) => ({
                value: {
                    jsonData: jsonDataToSave,
                },
            }),
            // () => this.props.onChange(null,this.state.value)
            () => this.props.onChange(this.state.value)
        );
    };

    async componentDidMount() {
        //if(this.props.component.frameworkId>-1){
        await this.getData()
        //}
    }

    handleAssign() {
        console.log(this.refs);
        var data = this.state.jsonData;
        var selected = data.unassigned.filter((x) => x.selected)
        data.unassigned = data.unassigned.filter((x) => !x.selected);
        selected = selected.map(x => { delete x.selected; return x; });

        let jsonDataToSave = {
            assigned: [...data.assigned, ...selected],
            unassigned: data.unassigned
        }
        this.setState({
            jsonData: jsonDataToSave
        });
        this.setValue(jsonDataToSave);
    }

    handleUnAssign() {
        console.log(this.refs);

        var data = this.state.jsonData;
        var selected = data.assigned.filter((x) => x.selected);
        data.assigned = data.assigned.filter((x) => !x.selected);
        selected = selected.map(x => { delete x.selected; return x; });
        let jsonDataToSave = {
            unassigned: [...data.unassigned, ...selected],
            assigned: data.assigned,
        }

        this.setState({
            jsonData: jsonDataToSave
        });
        this.setValue(jsonDataToSave);
    }
    handleSelect(item) {
        item.selected = item.selected ? !item.selected : true;
    }


    handleNotify(item) {
        item.notify = item.notify ? !item.notify : true;
    }

    handleChange(e) {
        this.setState({
            searchText: e.target.value
        })
    }

    async handleSearch(e) {
        e.preventDefault();
        await this.getData();
    }

    async getData() {
        if (!Boolean(this.props.component.entityId > 0))
            return

        const searchParams = {
            searchText: this.state.searchText,
            customGridKey: this.props.component.customGridKey,
            entityId: this.props.component.entityId || -1,
            entityTypeId: this.props.component.entityTypeId || -1,
            stateId: this.props.contextData.id,
        }

        const list = await lookUpService.getCustomGridListSearch(searchParams);

        let jsonData = {
            ...this.state.jsonData,
            unassigned: list.data.filter(x => x.isselected === 0),//.map(x => { x.role = -1; return x; })
            assigned: list.data.filter(x => x.isselected === 1)
        };

        this.setState({
            jsonData,
        })

    }

    handleDialog(openState) {
        this.setState({ showDialog: openState })
    }

    render() {
        const data = this.state.jsonData;
        const ButtonName = this.props.component.buttonName ? this.props.component.buttonName : 'User/User Groups'
        const columns =  this.props.component.columns ? this.props.component.columns : []
        var btnClassName = "fa fa-user";
        if(ButtonName.toString().indexOf("Owner - Roles")>-1){
            btnClassName="fa fa-id-card";
        }
        const tableStyle = {
            "borderWidth": "1px",
            'borderColor': "#aaaaaa",
            'borderStyle': 'solid',
            fontSize: 18
        }

        return (
            <div>
                    <div className="dt-buttons btn-group">
                        <Button onClick={() =>
                            this.handleDialog(true)
                        } className="btn  btn-icon btn-outline-primary btnAction" title={ButtonName}>
                            {/* <i className="fa fa-id-card" aria-hidden="true">
                                 {ButtonName}
                            </i> */}
                            <i className={btnClassName} aria-hidden="true" />
                        </Button>
                    </div>
                
                {/* <p>EntityId - {this.props.component.entityId}</p>
            <p>EntityTypeId - {this.props.component.entityTypeId}</p>
            <p>frameworkId - {this.props.component.frameworkId}</p> */}

                {this.state.showDialog && (<Modal closeModal={() => this.handleDialog(false)}>
                    <div
                        style={{
                           
                            flexDirection: "row",
                            justifyContent: "space-between",
                            
                        }}
                    >
                        <div className="">
                        <div className="gridTableScrolladding">
                            <table className="table">
                                <thead>
                                    <tr>
                                        {
                                            columns.map(x => {
                                                return <>
                                                    {x.visible && (<th >{x.name}</th>)}
                                                </>
                                            })
                                        }


                                    </tr>
                                </thead>
                                <tbody>
                                    {data &&
                                        data.assigned &&
                                        data.assigned.map((item, i) => (
                                            <tr key={i}>
                                                {/* <td > {item.name} </td> */}
                                                {
                                                    columns.map(x => {
                                                        return <>
                                                            {x.visible && (<th >{item[x.dbfield]}</th>)}
                                                        </>
                                                    })
                                                }

                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="input-group mb-3">
                                        <input id="standard-basic" type="text" value={this.state.searchText} onChange={this.handleChange}
                                            className="form-control" placeholder={this.state.searchText} aria-label={this.state.searchText} aria-describedby="basic-addon2" />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" onClick={this.handleSearch} type="button"><i className="fa fa-search" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row"><br /></div>
                            {data && (data.assigned.length > 0 || data.unassigned.length > 0) &&

                                <div className="row">
                                    <div className="col-md-5">
                                        <h4>Available</h4>
                                       
                                       <div className="gridTableScroll">
                                        <table className="table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    {
                                                        columns.map(x => {
                                                            return <>
                                                                {x.visible && (<th >{x.name}</th>)}
                                                            </>
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data &&
                                                    data.unassigned &&
                                                    data.unassigned.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                <input
                                                                    key={`unassigned ${i}`}
                                                                    type="checkbox"
                                                                    key={item.id}
                                                                    onChange={() => {
                                                                        this.handleSelect(item);
                                                                    }}
                                                                />
                                                            </td>
                                                            {/* <td > {item.name} </td> */}
                                                            {
                                                                columns.map(x => {
                                                                    return <>
                                                                        {x.visible && (<td >{item[x.dbfield]}</td>)}
                                                                    </>
                                                                })
                                                            }
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    <div className="col-md-2 align-self-center text-center">
                                        <input type="button" onClick={this.handleAssign} value=">" /><br /><br />
                                        <input type="button" onClick={this.handleUnAssign} value="<" />
                                    </div>
                                    <div className="col-md-5">
                                        <h4>Assigned</h4>
                                        <div className="gridTableScroll">
                                        <table className="table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    {
                                                        columns.map(x => {
                                                            return <>
                                                                {x.visible && (<th >{x.name}</th>)}
                                                            </>
                                                        })
                                                    }

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data &&
                                                    data.assigned &&
                                                    data.assigned.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                {" "}
                                                                <input
                                                                    type="checkbox"
                                                                    key={item.id}
                                                                    checked={item.selected}
                                                                    onChange={() => {
                                                                        this.handleSelect(item);
                                                                    }}
                                                                />{" "}
                                                            </td>
                                                            {
                                                                columns.map(x => {
                                                                    return <>
                                                                        {x.visible && (<td >{item[x.dbfield]}</td>)}
                                                                    </>
                                                                })
                                                            }
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                        </div> 
                                    </div>
                                </div>
                            }

                        </div>

                    </div>

                </Modal>)}
            </div>
        );
    }
};

export default CustomGridComp